<template>
  <div>
    <apexchart width="320" type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: 'geo',
  props: {
    parentObj: Object,
    parentDomain: Number,
  },

  data() {
    return {
      percent: {},
      percentile: {},

      series: [],
      chartOptions: {
        labels: [],
        colors: ['#274b69', '#3f4d63', '#85a1c1', '#95724B', '#C1A585', '#BDBDBD'],
        chart: {
          type: 'donut',
        },
        tooltip: {
          enabled: true,

        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        
        dataLabels: {
            enabled: false
            },
            
        legend: {
          show: true,
          offsetY: -5,
          position: 'bottom',
          formatter: function(seriesName) {
            let arr = seriesName.split("-")
              return [arr[0]]
          }
          
        },
        responsive: [{
          breakpoint: '1000',
          options: {
            chart: {
              width: 300
            },
            legend: {
              show: true,
              width: 240,
              offsetX: 10,
              position: 'bottom',
              formatter: function(seriesName) {
                let arr = seriesName.split("-")
                  return [arr[0]]
              }
            }
          }
        }]
      },
    }
  },

  created() {
    this.createVars(this.parentObj, this.parentDomain)
  },

  methods: {

    createVars(data, domain) {
      let set = data.us_msa.domains[domain].top_percent
      let total = 0;
      let arr= []
      let labels = []
      for (const i in set) {
        if (!isNaN(i)) {
          arr.push(set[i].percent)
          labels.push(set[i].msa)
          total += set[i].percent
        }
      }
        arr.push(100-total);
        labels.push('Other')
      this.series = arr
      this.chartOptions.labels = labels

      this.chartOptions.tooltip = {
     
         y: {
            formatter: function(value, opts ) {
              if (value == 100-total) {return 'percent: '+ (value).toFixed(1) + '%'}
              else {
                let item = data.us_msa.domains[domain].top_percent[opts.dataPointIndex]
                return 'percent: '+ (value).toFixed(1) + '%' + '<br>' + 'percentile: ' + item.percentile + 'th'}
              }
               ,
            
            title: {
              formatter: function (seriesName) {
                return '  '+seriesName+':' + '<br>'
              }}
          },

      }
    },
    },

  
}
</script>

<style lang="scss">
.tooltipA {
  min-width: 120px;
  width: 100%;
  height:80px;
  background-color: #FFF;
  opacity: .8;
  
}

.tooltip-title {
  height:30px;
  padding:.2em;
  
}

.tooltip-item {
  padding:.2em;
  //height:20px;
}
</style>
