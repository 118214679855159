<template>
  <div>
    <v-treeview rounded hoverable
                v-if="done"
                active-class="text--primary"
                selected-color="indigo"
                :items="renamed"
                dense
                caption-field="name"
                children-field="product_services">
       <template slot="label" slot-scope="{ item }">  
        <a v-if="item.object_sid" @click="onClick(item)" style="color: #333;">
          <v-icon v-if="item.type_code === 'PL'" small style="color: #bf8da7">mdi-square-rounded</v-icon>
          <v-icon v-if="item.type_code === 'PH'" small style="color: #cf4784">mdi-square-rounded</v-icon> {{ item.name }}</a>
        <div v-else style="font-weight: 600"> {{ item.name }}</div>
       </template>
    </v-treeview>
    <v-progress-circular
        v-else
        :size="48"
        color="primary"
        indeterminate
        style="margin-left:3em;">
      </v-progress-circular>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import objectFunctions          from '@/mixins/objectFunctions';

export default {
  name: 'browse',
  mixins: [objectFunctions],
  data() {
    return {
      full: {},
      renamed: '',
      done: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters([
      'getCurrentSource'
    ]),
  },
  methods: {

    ...mapActions([
      'browseProducts',
      'getObjectInfo'
    ]),

    init: async function() {
      await this.browseProducts()
          .then((data) => {
            this.full = data.data.domains;
            this.restructure(data.data.domains)
          })
          .catch((err) => console.log(err))
    },

    restructure(data) {
      let rename = [];
     
      for (const i in data) {
        if (!isNaN(i)) {
          rename.push({
          name: data[i].domain_name,
          id: data[i].domain_sid,
          children: data[i].product_services,
          color: '#21212'
        })
        }
      }

      for (const n in rename) {
          for (const m in rename[n].children) {
            if (rename[n].children[m].sub_product_services) {
              rename[n].children[m].children = rename[n].children[m].sub_product_services;
              rename[n].children[m].color = '#61616'
            }
            if (rename[n].children[m].children) {
              for (const o in rename[n].children[m].children) {
                if (rename[n].children[m].children[o].sub_product_services) {
                  rename[n].children[m].children[o].children = rename[n].children[m].children[o].sub_product_services;
                  rename[n].children[m].children[o].color = '#BDBDB'
                }
             }
            } 
          }
      }
      this.renamed = rename
      this.done = true;
      console.log("RENAMED")
      console.log(this.renamed)
    },

    onClick: async function (item) {
      let payload = {
        sid: item.object_sid
      }
      this.getObjectInfo(payload)
          .then((data) => {
            let domain_list = []
            for (const n in data.data.object.domains) {
              domain_list.push(data.data.object.domains[n].domain_sid)
            }
            for (const i in data.data.object.main_domain_ids) {
              // console.log('main_domains[0] sid', data.data.object.main_domain_ids[i].domain_sid)
              // console.log('domain_;list', domain_list)

              let domainSid = domain_list.includes(data.data.object.main_domain_ids[i].domain_sid) ?
                  data.data.object.main_domain_ids[i].domain_sid : data.data.object.domains[0].domain_sid

              let routeData = this.$router.resolve(this.getLookupViewPath(data.data.object_sid,
                  domainSid, data.data.object.type_code, this.getCurrentSource));
              window.open(routeData.href, '_blank');
            }
          })
          .catch((err) => {
            console.log(err)
          })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

