<template>
  <v-app v-if="this.$route.path.includes('ConsumerForecast')">
    <v-content>
      <router-view />
    </v-content>
  </v-app>

  <v-app v-else-if="this.$route.path.includes('AppView')">
    <content>
      <AppBar />
    </content>
    <v-content>
      <router-view />
    </v-content>
  </v-app>

  <v-app v-else-if="this.$route.path.includes('dashboard-list') || this.$route.path === '/v2/dashboard' || this.$route.path.includes('template')">
    <content>
      <ForecastBar />
    </content>
    <v-content style="padding-top: 52px;">
      <router-view />
    </v-content>
  </v-app>

  <v-app v-else>
    <content>
      <NavBar />
    </content>
    <v-content>
      <router-view />
    </v-content>
    <Footer class="footer no-print" />
  </v-app>
</template>

<script>
import NavBar from "@/components/Navigation/Base";
import AppBar from "@/components/Navigation/AppViews/AppBar.vue";
import Footer from "@/components/Footer";
import ForecastBar from "@/components/Navigation/Forecast.vue";
import { mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Footer,
    AppBar,
    ForecastBar,
    NavBar,
  },

  created() {
    //console.log(this.$route.path)
  },

  methods: {
    ...mapMutations(["SET_USER"]),
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState(["ConsumerForecast"]),
  },
};
</script>

<style lang="scss">
@import "./assets/css/fonts.css";
@import "./assets/css/print.css";

$light_blue: #71c6e6;
$orange: #f5a941;

.v2-component-wrapper {
  background-color: #131737;
}

#app {
  font-family: "Roboto-Regular", "Sans-Serif", "Arial";
  font-size: 14px;
}
a {
  text-decoration: none;
}

.ss_blue {
  color: $light_blue;
}
.centered-text {
  text-align: center;
  color: white;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.flex_col {
  align-self: center;
  flex-direction: column;
}
.orange_hover:hover {
  color: $orange;
  border-color: $orange;
}
.btn {
  text-align: center;
  font-size: 1.1em;
  border: 2px solid $light_blue;
  width: 8em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 12px;
}
.center-vert {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner-text {
  font-weight: 200;
  color: white;
  text-align: center;
}

@media only screen and (min-height: 650px) {
  .footer {
    max-height: 40%;
  }
}
@media only screen and (min-height: 750px) {
  .footer {
    max-height: 30%;
  }
}
@media only screen and (min-height: 1000px) {
  .footer {
    max-height: 20%;
  }
}
@media only screen and (min-height: 1150px) {
  .footer {
    max-height: 15.5%;
  }
}

</style>
