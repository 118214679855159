<template>
<div id="AD">
  <v-list dark shaped style="background-color:transparent;">
    <v-list-item link>
      <router-link :to="{ name: 'admin-view'}" style="color:#FFFF;">Admin Home</router-link>
    </v-list-item>
    <v-list-item link>
      <router-link :to="{ name: 'company-management'}" style="color:#FFFF;">Org Management</router-link>
    </v-list-item>
    <v-list-item link @click="setResources()">
      Employee Resources + 
    </v-list-item>
    <span v-if="resources">
      <v-list-item link>
        <router-link :to="{name:'knowledgebase'}" target="_blank"><v-list-item-subtitle style="margin-left:2em;">Knowledgebase</v-list-item-subtitle></router-link>
      </v-list-item>
      <v-list-item link>
        <a href="https://socialstandards.com/page/JcLdz9TnjeiK5YqoDwGQZC.html" target="_blank"><v-list-item-subtitle style="margin-left:2em;">Wiki Home</v-list-item-subtitle></a>
      </v-list-item>
      <v-list-item link>
        <a href="https://socialstandards.com/page/nW23S1Fribie2uiSbvkd7o.html?CX_Team_Wiki" target="_blank"><v-list-item-subtitle style="margin-left:2em;">CX Wiki</v-list-item-subtitle></a>
      </v-list-item>
      <v-list-item link>
        <a href="https://socialstandards.com/page/fHZEXImGijK3yDKudpHIuO.html?DMS_Team_Wiki" target="_blank"><v-list-item-subtitle style="margin-left:2em;">DMS Wiki</v-list-item-subtitle></a>
      </v-list-item>
      <v-list-item link>
        <a href="https://socialstandards.com/page/hIKTdb9KiGGLFZiqAe6U8Y.html?Employee%20Reference%20Materials" target="_blank"><v-list-item-subtitle style="margin-left:2em;">Employee Materials</v-list-item-subtitle></a>
      </v-list-item>
    </span>
  </v-list>
 
        <!-- <ul style="padding-top:4em;">
          <li><router-link :to="{ name: 'admin-view'}">Admin Home</router-link></li>
          <li><router-link :to="{ name: 'company-management'}">Company Management</router-link></li>
          <li><EmployeeResources /></li>
        </ul> -->
   
    </div>
</template>

<script>
 //import EmployeeResources from '@/components/Navigation/EmployeeResources'
 export default {
   name:'admin-drawer',
   data() {
     return {
       resources: false,
     }
   },
   methods: {
     setResources() {
       if (this.resources == false) {
         this.resources = true;
       }
       else {
         this.resources = false;
       }
     }
   },
   components: {
     //EmployeeResources,
     }
 }
</script>

<style>
#AD ul li a {
  color:white;
  font-size:1.1em;
}
#AD ul li {
  padding-bottom:.7em;

}
#AD ul {
  list-style-type: none;
}
#AD ul li a:hover {
  color:#71c6e6;
}

</style>