import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{attrs:{"id":"syndicated-title"}},[_c('h1',[_vm._v("Syndicated Reports")])]),_c(VLayout,{attrs:{"row":"","wrap":"","flex-lg-row-reverse":"","flex-md-row-reverse":""}},[_c(VFlex,{staticClass:"features",attrs:{"xs12":"","sm12":"","md4":"","lg2":"","xl2":"","flex-sm-row-reverse":""}},[_c(VForm,[_c(VSelect,{ref:"sel",attrs:{"label":"Select Market Vertical","items":_vm.verticals,"item-value":"sid","item-text":"name","menu-props":{ bottom: true, offsetY: true, maxHeight: 450},"height":"100%","clearable":""},model:{value:(_vm.selectedVertical),callback:function ($$v) {_vm.selectedVertical=$$v},expression:"selectedVertical"}}),_c(VTextField,{ref:"search",attrs:{"label":"Search","clearable":""},model:{value:(_vm.searchedReports),callback:function ($$v) {_vm.searchedReports=$$v},expression:"searchedReports"}})],1)],1),_c(VFlex,{staticClass:"rep",staticStyle:{"text-align":"center"},attrs:{"xs12":"","sm12":"","md8":"","lg10":"","xl10":""}},[(_vm.callFilteredPub!=='' || _vm.callFilteredPub=='not initiated')?_c(VCard,{staticClass:"d-flex flex-wrap align-content-center cardsalign",staticStyle:{"text-align":"center"},attrs:{"flat":"","tile":""}},_vm._l((_vm.callFilteredPub),function(product){return _c('ReportCard',{key:product.object_uid,staticClass:"mx-auto pa-2 ma-2 align-content-center",attrs:{"product":product},on:{"clicked":_vm.menuTold}})}),1):_c(VCard,{staticClass:"d-flex flex-wrap align-content-center cardsalign justify-center",attrs:{"flat":"","title":""}},[_c('span',{staticStyle:{"font-size":"1.1em"}},[_vm._v("No reports for the current selection! Try changing your filters. ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }