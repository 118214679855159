<template>
  <v-footer class="object-footer">
    <v-container>
       <v-row justify="center">
      <!-- <router-link :to="{name: 'portal'}"><v-btn style="background-color:transparent; color:white;" elevation="0">Worklists</v-btn></router-link>
      <router-link :to="{name: 'portal'}"><v-btn style="background-color:transparent;  color:white;" elevation="0">Bookmarks</v-btn></router-link> -->
      <router-link :to="{name: 'ObjectSearch'}"><v-btn style="background-color:transparent; color:#FFFF;" elevation="0">Home</v-btn></router-link>

      <Bookmarks @bookmarkPush="markPush" v-model="bookmarksWrap">
        <template v-slot:activator="{ open }">
          <a @click="start(open)" style="background-color:transparent; position:relative; top:.5em; padding:0em 1em 0em 1em; color:#FFFF;" >Bookmarks</a>
        </template>
      </Bookmarks>

      <a v-if="!getLoggedUser.anon" :href="lensesUrl" target="_blank">
        <v-btn style="background-color:transparent; color:#ffff;" elevation="0">Lenses</v-btn>
      </a>
      <router-link :to="{path: 'help?page=general'}"><v-btn style="background-color:transparent; color:#FFFF;" elevation="0">FAQ</v-btn></router-link>
    </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Bookmarks from '@/components/ConsumerForecast/Bookmarks.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'cf-footer',
  data: () => ({
      bookmarksWrap: '',
    }),
  components: {
    Bookmarks
  },
  computed: {
    ...mapGetters([
      'getLoggedUser'
    ]),
    lensesUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/reports-list' // Prod URL
        : 'https://app.socialstandards.net/reports-list'; // Dev URL
    },
  },
  methods: {
    start: function(method) {
      setTimeout(() => method());
    },

    markPush(load) {
      console.log("Bookmark - footer")
      console.log(load)
      this.$emit('pushB', load)
      this.bookmarksWrap = false;
    }
  }
  
}
</script>

<style lang="scss" scoped>
  .object-footer {
    height:200px;
    //background-color: #131737 !important;
    background-color: #3f4d63;
    font-weight:300;
    color:#fff !important;
  }
  .hor-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
</style>