<template>
  <div style="background-image: linear-gradient(to top right,  #303A4B, #1a204c); height:100%;">

    <ForecastBar/>

    <v-container id="searchBar" :style="{position:relative, top:this.fromTop}">
      <v-row justify="center">
        <!-- {{this.$vuetify.breakpoint.name}} -->

        <span style="padding-bottom:2em;">
         
          <span id="quick">Consumer</span><span id="sight">Forecast</span><span style="color:#FFFF;font-size:2.5em;"></span><span id="period"> lite</span> 
        </span>
      </v-row>
      <v-row justify="center">
        <div style="width:65%;">
          <v-text-field solo style="padding-left:2em; padding-right:2em; position:relative; bottom:.4em;" v-model="searchMatches"  append-icon="mdi-magnify" label="Search" @click:append="pushResult()" @keydown.enter="pushResult()" rounded hide-details light></v-text-field>
          <span id="ad" style="float:right; margin-right:3.5em;"><a class="adv" style="margin-right:1em;" @click="openAdvancedSearch()">Advanced Search</a>
          <a v-if="!surprising" @click="lucky()" class="adv">Surprise Me! </a>
          <a v-else @click="lucky()" class="adv loading" id="">Surprise Me</a>
          </span>
          
       </div>
      </v-row>

      <v-row justify="center" style="padding-top:2.5em;" id="help">
        <div>
          <span style="color:#fff">Don't know what to search? Get some ideas <a @click="openBrowse()" id="here">here</a></span>
            <!-- <router-link id="here" tag="a" :to="{path: '/ConsumerForecast/help' + '?page=explore'}">here</router-link></span> -->
        </div>

      </v-row>
    </v-container>

    <v-dialog v-model="advSearch" width="650" @click:outside="closeAdvancedSearch()">
      <AdvancedSearch v-on:closeDialog="closeAdvancedSearch()"></AdvancedSearch> 
    </v-dialog>

    <v-dialog v-model="browse" width="700">
      <v-card style="width:700px;">
        <v-card-text style="padding-bottom:3em;">
          <v-icon @click="openBrowse()" style="position:absolute; right:.5em; top:.5em;" sm>mdi-close</v-icon>
          <router-link id="explore-learn" tag="a" :to="{path: '/ConsumerForecast/help' + '?page=explore'}"><span style=" color:#333; position:absolute; right:4em; top:2em; font-size:1em;">Click here to learn more <v-icon small>mdi-help-circle</v-icon></span></router-link>
        </v-card-text>
        <v-card-text>
          <Browse />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sourceSwitch" width="450" @click:outside="closeSourceSwitch()">
      <SourceSwitch
          v-on:closeSourceDialog="closeSourceSwitch()"
          v-on:updateSource="closeSourceSwitch()"
      ></SourceSwitch>
    </v-dialog>

  </div>
</template>

<script>
import stringFunctions from '@/mixins/stringFunctions';
import processingMixin from '@/mixins/processingMixin';
import { mapActions, mapGetters } from 'vuex'
import Browse from '@/components/ConsumerForecast/Browse.vue';
import objectFunctions from "@/mixins/objectFunctions";
import AdvancedSearch from "@/components/ConsumerForecast/Lookups/PageElements/AdvancedSearch";
import SourceSwitch from "@/components/ConsumerForecast/Lookups/PageElements/SourceSwitch";
import ForecastBar from "@/components/Navigation/Forecast.vue";


export default {
  name: 'cf-home-search',
  mixins: [
    processingMixin,
    stringFunctions,
    objectFunctions,
  ],
  components: {
    ForecastBar,
    Browse,
    AdvancedSearch,
    SourceSwitch,
  },
  data () {
    return {
      sourceSwitch: false,
      sourceUpdater: '',
      searchMatches: '',
      settingsWrap: '',
      BookmarksWrap: '',
      matchResults: {},
      ready: false,
      loading: false,
      relevant: '',
      advSearch: false,
      advancedSearch: '',
      accountMenu: '',
      selectedFilters: [],
      filter: '',
      sort: '',
      browse: false,
      all: '',
      surprising: false,
      checked: '',
      allSelect: false,
      radioGroup: '',
      fromTop: '10em',
      headersB: [
        {text: '', value: 'image', align: 'center', sortable: false},
        {text: '', align: 'start', value: 'actions'},
        {text: '', value: 'type'},
        {text: '', value: 'main_domains'}
        ],
      filterItems: [
        {text: objectFunctions.codeToObjectType['brand']['name'], value: 'brand'},
        {text: objectFunctions.codeToObjectType['product_type']['name'], value: 'product_type'},
        {text: objectFunctions.codeToObjectType['descriptive']['name'], value: 'descriptive'},
      ],
      sortItems: [
        {text: 'Most Relevant', value: 'relevance'},
        {text: 'PPM High to Low', value: 'ppm'},
      ]
    }

  },

  computed: {

    ...mapGetters([
      'getAccount',
      'getDomainsAvailable',
      'isAdmin',
      'getLoggedUser',
      'getCurrentGap',
      'getCurrentSource',
      'getSourceList'

    ]),
  },

  created() {
    window.onpopstate = function () {
          location.reload()
          };
    if (this.$route.query.search) {
      this.matchMe(this.$route.query.search)
    }

    this.logPageLoad({
        app_name: 'CF',
        page_name: this.$options.name,
      })  

    setTimeout(function () {
      let ele = document.getElementById('quick');
      ele.classList.add('appear');
      }, 200);
    setTimeout(function () {
      document.getElementById('quick').style.opacity = 1;
      }, 400);

    setTimeout(function () {
      let ele = document.getElementById('sight');
      ele.classList.add('appear');
      }, 400);
    setTimeout(function () {
      document.getElementById('sight').style.opacity = 1;
      }, 600);

    setTimeout(function () {
      let ele = document.getElementById('period');
      ele.classList.add('appear');
      }, 800);
    setTimeout(function () {
      document.getElementById('period').style.opacity = 1;
      }, 900);

      setTimeout(function () {
      let ele = document.getElementById('help');
      ele.classList.add('appear');
      document.getElementById('ad').classList.add('appear');
      }, 800);
    setTimeout(function () {
      document.getElementById('help').style.opacity = 1;
      document.getElementById('ad').style.opacity = 1;
      }, 900);
  },

  methods: {

    ...mapActions([
       'getQueryMatch',
      ]),

    start: function(method) {
        setTimeout(() => method());
    },
    markDialog: function(method) {
        setTimeout(() => method());
    }, 



    pushResult() {
      this.searchMatches = encodeURIComponent(this.searchMatches)
      this.$router.push({path: '/ConsumerForecast/search-result' + '?search=' + encodeURIComponent(this.searchMatches)})
    },

    randomPick(results) {
        let num = Math.floor(Math.random() * results.length);
        return results[num]
    },

    lucky() {
      this.surprising = true;
      let alpha = "abcdefghijklmnopqrstuvwxyz".split('');
      this.getMatch(this.randomPick(alpha));
    },

    getMatch: async function (item) {
      let payload = {
        query: item,
        source: this.getCurrentSource,
        type: 'PH'
      }
      await this.getQueryMatch(payload)
          .then((data) => {
            console.log(data);
            let resultsMatch = data.data.matches
            let resultsTmp = resultsMatch.filter(x => x.has_data === true).filter(x => x.domains[0].total_posts > 30000);
            let results = resultsTmp.map(x =>
                ({domain_sid: x.domains[0].domain_sid, object_sid: x.object_sid, type_code: x.type_code})
            )
            this.luckyPush(results)
          })
          .catch((err) => {
            console.log(err)
          })
    },

    luckyPush(results) {
      console.log(results.length)

      if (results.length > 0) {

        //if there are domain constraints (portal_user role only atm)
        if (this.getDomainsAvailable !== '') {
          // pick first available domain
          let pickDomain = this.getDomainsAvailable[this.getCurrentSource].map(x => x.domain_sid)[0]
          results = results.filter(x => x.domain_sid === pickDomain)
          if (results.length === 0) this.lucky();
        }
        console.log(results)
        let item = this.randomPick(results)

        let logSurprise = {
          event: 'click',
          app_name: 'CF',
          page_name: this.$options.name,
          form_name: 'surprise-me',
          object_sid: item.object_sid,
          domain_sid: item.domain_sid,
          source: this.getCurrentSource
        }

          this.$router.push(this.getLookupViewPath(item.object_sid, item.domain_sid,
            item.type_code, this.getCurrentSource));
          this.logAction(logSurprise)
      }
      else {
        this.lucky()
      }
      //this.surprising = false;
    },

    openAdvancedSearch() {
      this.advSearch = true;
    },

    closeAdvancedSearch() {
      this.advSearch = false;
    },

    openBrowse() {
      if (this.browse == false) {
        this.browse = true;
      }
      else {
        this.browse = false;
      }
    },

    closeSourceSwitch() {
        this.sourceSwitch = false;
      },


  }
}
</script>


<style scoped>

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

.btn-hover:hover {
  color:#71C6E6 !important;
}

#no-background-hover::before {
   background-color: transparent !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes up {
  0% {
    transform: translateY(120%);
  }
  100% {
    transform: translateY(0%);
  }
}
#toolbar {
  animation: fadein .8s ease-in 0s;
}    
    
#worklists {
  animation: fadein .8s ease-in 0s;
  
}
#bookmarks {
  animation: fadein 1s ease-in 0s;
}

#worklists:hover{
  position:relative;
  bottom:.2em;
}
#bookmarks:hover {
  position:relative;
  bottom:.2em;
}

#explore-learn:hover {
  color: #448AFF !important;
}

.mark {
  color: #448AFF;
}
.mark:hover {
  color: #BDBDBD;
}

.adv {
  color: #FFF !important;
}
.adv:hover {
  color: #85BFC1 !important;
}
#ad {
  opacity: 0;
}

.image {
  animation: up 1s 0s;
  /* animation-direction: alternate; */
}

.search-btn {
  float:right; 
  margin-right:2em;
  color:#333;
  border-radius:25px;
  background-color:#E3F2FD !important;  
}
.search-btn:hover {
  color:#333 !important;
  position:relative;
  bottom:.2em;
}

.name {
  animation: fadein 2s ease-in 0s;
}

#quick {
  font-family: 'Roboto-Light' !important;
  color:#fff; 
  padding-bottom:.8em; 
  font-size:3.5em;
  opacity: 0;
  font-weight: 100 !important;
}

.appear {
  animation: fadein 1s;
  animation-timing-function: ease-out;
}

#sight {
  font-family: 'Roboto-Medium' !important;
  color: #71c6e6;
  padding-bottom:.8em;
  font-size: 3.5em;
  opacity: 0;
}

#period {
  font-family: 'Roboto-Light' !important;
  color:#fff; 
  padding-bottom:.8em; 
  opacity: 0;
  font-size:1.5em; 
  position:relative; 
  bottom:1em; 
  left:0em;
}

#searchBar {
  position:relative; 
  top:13em !important; 
}

 a {
   text-decoration: none;
   color:#333 !important;
 }

.hor-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#here {
  color:#85BFC1 !important;
}

#help {
  opacity: 0;
}

</style>