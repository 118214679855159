
<template>
<div>
    <v-container>
        <v-layout wrap row>
            <v-flex  sm12 xs12 md12 lg12 xl12>
                <img class="center p_graphics" style="padding-bottom:1em;" width="220em"  src="@/assets/products-graphics/MarketInsights.png" />
            </v-flex>

            <v-flex  xs12 sm12 md12 lg12 xl12>
                <h3 class="title_studies" >Market Insights OS</h3>
            </v-flex>


    </v-layout>
    </v-container>

    <v-container class="mid">
        <a class="anchor" href="https://s3.amazonaws.com/ss-resource/Social_Standards_Analytics_Installer.exe"><span class="ss_blue">Click Here</span> to Install</a>
    </v-container>
</div>
</template>

<script>
import processingMixin from '@/mixins/processingMixin';
export default {
    name: 'installer-page',
    mixins: [processingMixin],
    created() {
        this.logPageLoad({
			app_name: 'S',
			page_name: this.$options.name,
      })
    }
}
</script>

<style>
.mid {
    text-align:center;
    padding-bottom:2em;
    padding-top:3em;
}
.anchor {
    color:black!important;
    font-size:2em;
}
</style>