<template>
  <div>
    <v-container>
      <v-row justify="center">
        <apexchart
          width="360"
          height="250"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "demo-age",
  props: {
    parentDemo: Object,
    parentDomain: String,
  },

  created() {
    this.deconstruct(this.parentDemo);
  },

  data() {
    return {
      series: [
        {
          data: [],
        },
      ],
      myCat: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          grid: {
            show: false,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          yaxis: {
            type: "numeric",
            tickAmount: 10,
            max: 100,
            min: 0,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function formatter(val) {
              let ending = "th";
              if (val.toString()[1] == 1) {
                ending = "st";
              } else if (val.toString()[1] == 2) {
                ending = "nd";
              } else if (val.toString()[1] == 3) {
                ending = "rd";
              }
              return val + ending;
            },
          },
        },
        states: {
          active: {
            filter: {
              type: "none" /* none, lighten, darken */,
            },
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
        },

        //colors: ['#20246F', '#9FA8DA'],
        colors: ["#274b69", "#85a1c1"],

        fill: {
          opacity: 1,
        },
        annotations: {
          xaxis: [
            {
              x: 50,
              strokeDashArray: 0,
              borderColor: "#BDBDBD",
              offsetY: -5,
              label: {
                orientation: "horizontal",
                borderColor: "#BDBDBD",
                offsetY: -15,
                style: {
                  color: "#333",
                  background: "#fff",
                },
                text: "50th Percentile",
              },
            },
          ],
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: "960",
            options: {
              chart: {
                width: 310,
              },
            },
          },
        ],
      },
    };
  },

  methods: {
    deconstruct(data) {
      let d = data.demo.domains[this.parentDomain].data;
      let percent = [];
      let percentile = [];
      let cat = [];
      for (const i in d) {
        if (i[0] == 2) {
          percent.push(d[i].percent);
          percentile.push(d[i].percentile);
          cat.push(d[i].name);
        }
      }
      this.chartOptions.xaxis = {
        categories: cat,
      };
      this.series = [
        {
          name: "percentile",
          data: percentile,
        },
      ];

      this.chartOptions.yaxis = {
        type: "numeric",
        tickAmount: 6,
        max: 100,
        min: 0,
      };

      console.log(this.series);
      console.log(this.chartOptions.xaxis);
    },
  },
};
</script>
