<template>
<div>
  <v-dialog 
   width="700px"
   overlay-opacity=".85"
   v-model="dialog" 
   v-bind="$attrs" 
   v-on="$listeners"
   >

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" :open="open"/>
    </template>

    <v-card style="height:100%;" >
      <v-card-title>Account Details
        <a id="ex" @click="closed()">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
            style="position:absolute; top:1em; right:.5em;">
            mdi-close
            
          </v-icon>
        </a>
      </v-card-title>

      <v-card-text>
        Name:
        <span v-if="this.editName===false">
            {{this.fullName}} <v-icon right style="position:relative; bottom:.2em;" @click="editName=true" small>mdi-marker</v-icon><br>
        </span>
        <span v-else-if="this.editName===true"><v-icon right style="position:relative; bottom:.2em;" @click="editName=false" small>mdi-marker-cancel</v-icon>
          <div class="d-flex" style="width: 60%; align-items: baseline;">
            <v-text-field style="padding:1em;" :rules="fullNameRules"
                          v-model="fullName" :value="fullName" @keydown.enter="setFullName"
                          dense>
            </v-text-field>
            <v-btn small @click="setFullName" style="left:2em;" rounded elevation="0"><v-icon small>mdi-check</v-icon></v-btn>
            &nbsp;&nbsp;&nbsp;
            <v-btn small @click="editName=false" style="left:3em;" rounded elevation="0"><v-icon small>mdi-close</v-icon></v-btn>
          </div>
        </span>
        <span v-else>{{this.fullName}} <br></span>

        Email: {{this.getLoggedUser.email}}<br>
        Company: {{this.getLoggedUser.company_name}}
        <span v-if="this.getMyOrgs.length > 1">
         <a @click="openChange()">
           change
         </a>
        </span>
      </v-card-text>

      <v-card-actions style="position:relative; bottom:1em;">
        <div style="padding-left:1em;">
          <router-link :to="{name: 'reset-pass-view'}"><a @click="closed()">Reset Password</a></router-link>
        </div>
      </v-card-actions>

      <v-card-title style="position:relative; bottom:0em;">
        Access Profile Details
      </v-card-title>

      <v-card-text v-if="Object.keys(this.getCurrentGap).length > 0">
        <v-card-text>
          <b>Access Profile:</b> {{getCurrentGap.profile_name}}<br>
          <a @click="openSwitchGAP" v-if="profiles.length > 1">switch profile</a>
        </v-card-text>
        <v-card-text style="text-align:left;">
          <b>Entitlements:</b>
        </v-card-text>

        <v-card-text style="text-align:left;">

          <span v-if="getCurrentGap.portal">
           <v-icon small>mdi-check-underline</v-icon><b> Client Portal Access: </b><span>Yes</span><br>
          </span>
          <span v-else>
           <v-icon small>mdi-window-close</v-icon><b> Client Portal: </b><span>No</span><br>
          </span>

          <span v-if="getCurrentGap.market_insights">
           <v-icon small>mdi-check-underline</v-icon><b> CF Pro Access: </b><span>Yes</span><br>
          </span>
          <span v-else>
           <v-icon small>mdi-window-close</v-icon><b> CF Pro: </b><span>No</span><br>
          </span>

          <span v-if="getCurrentGap.consumer_forecast">
           <v-icon small>mdi-check-underline</v-icon><b> CF Lite Access: </b><span>Yes</span><br>
          </span>
          <span v-else>
           <v-icon small>mdi-window-close</v-icon><b> CF Lite Access: </b><span>No</span><br>
          </span>

          <span v-if="getCurrentGap.allow_data_export">
           <v-icon small>mdi-check-underline</v-icon><b> CF Lite Data Export Access: </b><span>Yes</span><br>
          </span>
          <span v-else>
           <v-icon small>mdi-window-close</v-icon><b> CF Lite Data Export Access: </b><span>No</span><br>
          </span>

          <span v-if="getCurrentGap.dashboards">
           <v-icon small>mdi-check-underline</v-icon><b> Lenses: </b><span>Yes</span><br>
          </span>
          <span v-else>
           <v-icon small>mdi-window-close</v-icon><b> Lenses: </b><span>No</span><br>
          </span>

          <span v-if="getCurrentGap.brand_pulse">
           <v-icon small>mdi-check-underline</v-icon><b> Brand Maps: </b><span>Yes</span><br>
          </span>
          <span v-else>
           <v-icon small>mdi-window-close</v-icon><b> Brand Maps: </b><span>No</span><br>
          </span>

        </v-card-text>

        <v-card-text style="text-align:left;">
          <div style="padding-bottom:.5em;">
            <span><b>Sources:</b></span><br>
            
            <span v-for="(item, idx) in Object.keys(getCurrentGap.profile_sources)" :key="idx">
              <div style="margin-left:1em; padding-top:1em;">
                <span v-if="item == 1">Instagram</span>
                <span v-else-if="item == 3">Twitter</span>
                <span v-else-if="item == 5">Reddit</span>
                <!-- <span v-else-if="item == 6">News</span> -->
                <span v-else-if="item == 7">TikTok</span>
              </div>
              
              <div v-if="item!=6" style="margin-left:2em;">
                 <span v-for="(vert, idx) in getCurrentGap.profile_sources[item]"  :key="idx">
                   <span style="margin-right:1em;">{{vert.domain_name}} </span>
                  <!-- <span v-else>, {{vert.domain_name}}</span> -->
                 </span>
              </div>
            </span>
          </div>
        </v-card-text>
      </v-card-text>
      <v-card-text v-else>
        You haven't been given any entitlements yet. <br>
        Please reach out to your organization manager, or contact Social Standards for help.
      </v-card-text>


    </v-card>


  </v-dialog>

 <v-dialog v-model="changeDialog" width="375" overlay-opacity=".9">
    <v-card style="height:200px;">
      <v-card-actions style="padding-top:2em;">
        <v-select style="width:80%;" :items="this.getMyOrgs" item-text="name" item-value="uid" label="Choose Company" v-model="switchOrg"></v-select>
      </v-card-actions>
      <v-btn style="float:right; margin:1em;">switch</v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="openSwitchProfiles" width="375" overlay-opacity=".9">
    <SwitchProfiles :profiles="profiles" @resetSwitchProfile="resetSwitchProfileSettings"></SwitchProfiles>
  </v-dialog>
  </div>

</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import stringFunctions from '@/mixins/stringFunctions';
import SwitchProfiles  from "@/components/Navigation/SwitchProfiles";

export default {
  name: "settingsEditor",
  props: {
    value: String
  },
  mixins: [
    stringFunctions
  ],
  components:{
    SwitchProfiles
  },
  data() {
    return {
      dialog: false,
      changeDialog: false,
      editName: false,
      fullName: '',
      fullNameRules: [
        v => !!v || 'Name is required',
        v => /[a-zA-Z]{2,}/.test(v) || 'Name must be valid',
      ],
      switchOrg: '',
      profiles: [],
      openSwitchProfiles: false,
      switchGap: '',
      message: ''
    };
  },

  mounted() {
    console.log(this.getAccount.organizations[this.getCurrentOrg.company_uid].profiles)
    for (const i in this.getAccount.organizations[this.getCurrentOrg.company_uid].profiles) {
      this.profiles.push(this.getAccount.organizations[this.getCurrentOrg.company_uid].profiles[i])
    }
    console.log(this.profiles)
  },

  computed: {

    ...mapGetters([
      'getAccount',
      'getLoggedUser',
      'getMyOrgs',
      'getCurrentGap',
      'getCurrentOrg'
    ]),
    ...mapState([
      'loggedUser',
    ]),

  },

  created () {
    this.fullName = this.getLoggedUser.name
  },

  methods: {

    ...mapActions([
      'updateUserName',
    ]),

    open() {
      this.dialog = true;
    },

    openChange() {
      this.changeDialog = true
    },

    closed() {
      this.dialog = false;
    },

    setFullName: async function() {

      console.log("SetFullName")

      if (this.fullName !== '') {
        let payload = {
          full_name: this.fullName,
       //   settings: this.getAccount.account_settings ? this.getAccount.account_settings : '',
          token: this.loggedUser.token,
        }
        console.log("fullname", this.fullName)

        await this.updateUserName(payload).then((response) => {
          console.log(response)
          console.log(response.status)
          if (response.status === 200) {
            console.log('Name Changed')
          }

        })
        .catch((err) => {
          console.log(err)
          console.log(err.response.status)
        })
      }

     this.editName = false

    },


    openSwitchGAP(){
      this.openSwitchProfiles=true;
      console.log('profiles', this.profiles)
    },
    resetSwitchProfileSettings(){
      this.openSwitchProfiles=false;
      this.message=''
    }

  }
};
</script>
