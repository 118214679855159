<template>
  <div>
    <v-container fluid class="banner2">
        <div>
          <h3 class="banner-text">Our BI solutions unlock strategic insights—empowering <span class="ss_blue">faster,<br> better decision-making</span> for CPG executives and investors.</h3>
        </div>
    </v-container> 

    <!-- Timeline -->
    <v-container  style="padding-top:6em; padding-bottom:5em; padding-left:1em; padding-right:1em;" class="timeline" id="process">
      <h2 class="ss_blue" style="font-weight:400;">Our Process</h2>
      <v-timeline>
        <v-timeline-item color="#71C6E6" small>
          <template v-slot:opposite>
            <img class="timeline_graphics" src="@/assets/process-icons/collect_T.png" alt="Collect"/>
          </template>

          <div class="py-4">
            <h2 style="font-weight:500;" class="ss_blue">Collect</h2>
            <div class="timeline_desc">Every month we collect 613+ million public posts.</div>
          </div>
        </v-timeline-item>

     <v-timeline-item color="#71C6E6" small>
      <template v-slot:opposite>
        <img  class="timeline_graphics" src="@/assets/process-icons/clean_T.png" alt="Clean"/>
        </template>
          <div class="py-4">
            <h2 style="font-weight:500;" class="ss_blue">Clean</h2>
          <div class="timeline_desc">
            We identify and remove spam and other problematic posts so you have direct access to authentic consumer data ready for strategic analysis, without the noise of bots and fake accounts.
          </div>
        </div>
    </v-timeline-item>

    <v-timeline-item color="#71C6E6" small>
      <template v-slot:opposite>
        <img  class="timeline_graphics" src="@/assets/process-icons/supplement_T_8.png" alt="Supplement"/>
        </template>
          <div class="py-4">
            <h2 style="font-weight:500;" class="ss_blue">Supplement</h2>
          <div class="timeline_desc">
            We add corresponding demographic & geographic data, so you can track which consumer cohorts are driving new trends, talking about your brand, or converting to your competition.
          </div>
        </div>
    </v-timeline-item>

    <v-timeline-item color="#71C6E6" small>
      <template v-slot:opposite>
        <img  class="timeline_graphics" src="@/assets/process-icons/organize_T.png" alt="Organize"/>
      </template>
      <div class="py-4">
        <h2 style="font-weight:500;" class="ss_blue">Organize</h2>
        <div class="timeline_desc">
          Our team of linguists and social media experts use sophisticated proprietary software to classify & organize all incoming social data at the brand, product, ingredient, benefit, and descriptor level.        </div>
        </div>
    </v-timeline-item>

    <v-timeline-item color="#71C6E6" small>
      <template v-slot:opposite>
        <img class="timeline_graphics" src="@/assets/process-icons/track_T_1.png" alt="Track"/>
      </template>
      <div class="py-4">
        <h2 style="font-weight:500;" class="ss_blue">Track</h2>
      <div class="timeline_desc">
          We track 129+ million unique keywords and are constantly updating and refining our database. Our system flags new topics of conversation for review and inclusion, ensuring that our database is up to date with industry-relevant topics ready for on-demand analysis. </div>
      </div>
    </v-timeline-item>

    <v-timeline-item color="#71C6E6" small>
      <template v-slot:opposite>
       <img class="timeline_graphics" src="@/assets/process-icons/contextualize_T.png" alt="contextualize"/>
      </template>
        <div class="py-4 timeline_desc">
          <h2 style="font-weight:500;" class="ss_blue">Contextualize</h2>
        <div>
            We structure our data into 7 market verticals, allowing you to instantly measure market share, benchmark performance, and track emerging consumer trends. </div>
        </div>
    </v-timeline-item>

    <v-timeline-item color="#71C6E6" small>
    <template v-slot:opposite>
       <img class="timeline_graphics" src="@/assets/process-icons/visualize_T.png" alt="Visualize"/>
      </template>
        <div class="py-4">
          <h2 style="font-weight:500;" class="ss_blue">Visualize</h2>
          <div class="timeline_desc">
          Our dynamic user interface provides an array of data visualization tools designed to facilitate rigorous data exploration, discovery, and analysis. </div>
        </div>
    </v-timeline-item>
  </v-timeline>

  <p style="text-align:center; padding-top:2.2em; font-size:1.2em;"><span><strong>To learn more about our process</strong></span> <router-link to="processarticle" target="_blank"><span @click="actionLog('process-article', 'sdvUxSbBi5WBBqm1_ujLHD', 'https://socialstandards.com/page/sdvUxSbBi5WBBqm1_ujLHD.html?Our_Process')" class="ss_blue"><strong>click here</strong></span></router-link></p>
  <v-container fluid class="why">
    <h2 style="text-align:center; padding-bottom:1.2em; font-size:1.em;">Why Social Standards?</h2>
    <v-layout wrap row>

      <v-flex sm3 md3 lg3 class="why_points">
        <h3 style="padding-bottom:.3em;">Targeted Insight</h3>
        <p>Market dynamics can be viewed through many different lenses: political, economic, financial, etc. For CPG industries one particularly important indicator of health, direction, and product affinities is the voice of the consumer.</p>
      </v-flex>

      <v-flex sm3 md3 lg3 class="why_points">
        <h3 style="padding-bottom:.3em;">Untapped Potential</h3>
        <p>The voice of the consumer as expressed via social media, blogs, forums and other “in the moment” activities is, in turn, a reflection of the tightly organized and coherent market force we call <span style="color:#fba939"><strong>Collective Intelligence.</strong></span></p>
      </v-flex>

      <v-flex sm3 md3 lg3 class="why_points">
        <h3 style="padding-bottom:.3em;">Clarity and Reliability</h3>
        <p>Through Social Standards' unique data analysis methods, billions of individual activities converge into a highly detailed and clear picture of the market and all its constituent parts: Brands, Products, Audience types, and more.</p>
      </v-flex>

      <v-flex sm3 md3 lg3 class="why_points">
        <h3 style="padding-bottom:.3em;">Compatibility</h3>
        <p>We turn ubiquitous, previously unstructured social data into objective insights, expressed in familiar industry terms and well-aligned with other analytical products and data sources.</p>
      </v-flex>
    </v-layout>
  </v-container>

  <v-container style="background-color:	hsla(35, 96%, 60%,.2); padding:2em; border-radius:0px 0px 19px 19px">
  <p style="font-size:1.3em;"><span style="color:#fba939"><strong>Collective intelligence</strong></span> is the core concept upon which our business is built—<br><br>
Collective intelligence is shared or group intelligence that emerges from the collaboration, collective efforts, and competition of many individuals and appears in consensus decision making. 
<br><br>Our tools help you unlock the power of collective intelligence and use the insights it provides to form more comprehensive business and investment strategies.</p>
<br><p style="text-align:center; font-size:1.1em;"><span><strong>To learn more about how we harness collective intelligence</strong></span> <router-link to="collectivearticle" target="_blank"><span @click="actionLog('collective-intelligence-article', '8mivQDXsjzKMfwr4tBTkcn', 'https://socialstandards.com/page/8mivQDXsjzKMfwr4tBTkcn.html?Harnessing_Collective_Intelligence')" style="color:#fba939"><strong>click here</strong></span></router-link></p>
</v-container>

</v-container>
<!-- Section 3: Our Products -->
  <v-container class="p_section" style="padding-top:3em; padding-bottom:4em;">
   <h2 class="wwd_headings" >Our Products</h2>
    <v-layout row wrap>
    <!-- Row Zero: graphics: -->
    <v-flex md1 lg1  hidden-sm-and-down></v-flex>
    
    <v-flex  md4 lg4>
     <img class="center p_graphics" width="220em"  src="@/assets/products-graphics/MarketInsights.png" />
    </v-flex>

    <v-flex md2 lg2  hidden-sm-and-down></v-flex>
    
    <v-flex  md4 lg4 hidden-sm-and-down >
      <img class="center" width="210em"  style="padding:1em;" src="@/assets/products-graphics/workshops_T.png" />
    </v-flex>

    <v-flex md1 lg1  hidden-sm-and-down></v-flex>

    <!-- Row One: titles: -->
    <v-flex md1 lg1  hidden-sm-and-down></v-flex>
    
    <v-flex  md4 lg4 hidden-sm-and-down >
     <h3 class="title_studies" >Insights Tools</h3>
    </v-flex>

    <v-flex md2 lg2  hidden-sm-and-down></v-flex>
    
    <v-flex  md4 lg4 hidden-sm-and-down >
       <h3 class="title_studies">Professional Services</h3>
    </v-flex>

    <v-flex md1 lg1  hidden-sm-and-down></v-flex>

    <!-- Row Two: paragraphs: -->
    <v-flex sm6 md1 lg1  hidden-sm-and-down></v-flex>
    
    <v-flex sm12 md4 lg4 >
            <p class="studies"><span class="hidden-md-and-up">Insights Tools</span><br>Our insights tools allow you to explore traditionally complex, unstructured data in a contextualized way.</p>
    </v-flex>

    <v-flex xs12 md12 lg12 hidden-md-and-up>
        <router-link :to="{name:'products', hash:'#os-product'}"><p class="btn center ss_blue orange_hover btn-txt">Learn More</p></router-link>
      </v-flex>

    <v-flex md2 lg2  hidden-sm-and-down></v-flex>
    
    <v-flex  sm12 hidden-md-and-up>
      <img class="center p_graphics" width="210em"  style="padding:1em;" src="@/assets/products-graphics/workshops_T.png" />
    </v-flex>

    <v-flex sm12 md4 lg4 >
              <p  class="studies"><span class="hidden-md-and-up">Professional Services</span><br>Our team of data analysts work with you to unlock your most pressing business questions with our unique data set.</p>
    </v-flex>

    <v-flex md1 lg1  hidden-sm-and-down></v-flex>

    <!-- Row Three: buttons: -->
    <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 >
        <router-link :to="{name:'products', hash:'#os-product'}"><p class="btn center ss_blue orange_hover btn-txt">Learn More</p></router-link>
      </v-flex>
    <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 hidden-sm-and-down>
       <router-link :to="{name:'products', hash:'#Workshops'}"><p class="btn center ss_blue orange_hover btn-txt">Learn More</p></router-link>
      </v-flex>
  </v-layout>
</v-container>

<!-- Case Studies: -->
<v-container fluid style="background-color:#f3faff;">
  <v-container id="case_studies" >
    <h2 class="wwd_headings" id="strategy">Case Studies <i></i></h2>
    <!-- Case Studies: Strategy -->
    <v-layout row wrap>

      <v-flex md2 lg2></v-flex>
      
      <v-flex xs12 sm12 md12  hidden-md-and-up style="padding-bottom:1em;">
        <img class="center" width="250em;" src="@/assets/products-graphics/Thayers.png" alt="" />
      </v-flex>

      <v-flex xs12 md10 lg10 id="strategy">
        <p class="case_headings">Strategy</p>
      </v-flex>

      <v-flex md2 lg2></v-flex>
      
      <v-flex md4 lg4 style="font-size:1.1em;"> 
        <p>One of our clients wanted to conduct due diligence prior to a potential acquisition.<br><br>
        Their goal was to better understand the competitive positioning of Thayer’s Natural Remedies, from a social perspective.<br><br>
        Our team conducted a series of analyses, breaking down organic conversation volume on social attributed to Thayers, to understand which conversation topics were most integral to the brand.<br><br>
        </p>
      </v-flex>

      <v-flex md4 lg4 hidden-sm-and-down>
        <img class="case_graphics center" width="70%;" src="@/assets/products-graphics/Thayers.png" alt="" />
      </v-flex>

      <v-flex md2 lg2></v-flex>
      

      <v-flex md2 lg2></v-flex>

      <v-flex md8 lg8 class="part2" style="font-size:1.1em;">
        <p>We also identified potential expansion opportunities for Thayers in line with the brand’s DNA and consumer preferences.<br><br>
        L’Oreal acquired Thayers on June 18th, 2020, integrating it into its Consumer Products Division.</p>
        <strong><p style="font-weight:500;">Open full report <a href="https://socialstandards.com/report/YGQlD7PeiYWHWZdM9W4hQC.html?Strategy_Case_Study_-" target="_blank" class="ss_blue" @click="actionLog('strategy-case-study', 'YGQlD7PeiYWHWZdM9W4hQC', 'https://socialstandards.com/report/YGQlD7PeiYWHWZdM9W4hQC.html?Strategy_Case_Study_-')">here.</a></p></strong>   
      </v-flex>

      <v-flex md2 lg2></v-flex>
    </v-layout>

   <!-- Case Studies: Investment -->

    <v-layout row wrap  style="padding-top:3em; padding-bottom:3em;" id="investment">
      <v-flex md2 lg2></v-flex>
      
      <v-flex xs12 sm12 hidden-md-and-up style="padding-bottom:1em;">
        <img class="center" width="250em;" src="@/assets/products-graphics/claw2.png" alt="" />
      </v-flex>

      <v-flex xs12 sm12 md10 lg10>
        <p class="case_headings">Investment</p>
      </v-flex>

      <v-flex md2 lg2></v-flex>
      
      <v-flex md4 lg4 style="font-size:1.1em;">
        <p>Our client wanted to analyze the social conversations of the hard seltzer market, to understand whether the growth of the market was being captured by White Claw exclusively, or if other producers were benefitting as well.<br><br>
        Our team analyzed the conversation share of hard seltzer brands over time and noted that, as the category grew, White Claw’s share of total conversations declined.</p>
      </v-flex>

      <v-flex md4 lg4 hidden-sm-and-down>
        <img class="case_graphics center"  src="@/assets/products-graphics/claw2.png" alt="" />
      </v-flex>

      <v-flex md2 lg2></v-flex>
      
      <v-flex md2 lg2></v-flex>
      
      <v-flex md8 lg8 style="font-size:1.1em;">
        <p>When we analyzed its top competitors, we noticed that they were not experiencing any significant increases in share, but many small craft hard seltzers were growing extremely quickly.<br><br>
        This analysis cements White Claw as the market leader in the category, but also shows that incremental growth has expanded into the craft space as the category has matured, suggesting that this summer’s growth for White Claw will slow when compared to the previous summer.</p>
        <strong><p style="font-weight:500;">Open full report <a href="https://socialstandards.com/report/Uwd5z7RdheOfRJNgqdI5x0.html?Investment_Case_Study_-_Website#hd1" target="_blank" class="ss_blue" @click="actionLog('strategy-case-study', 'Uwd5z7RdheOfRJNgqdI5x0', 'https://socialstandards.com/report/Uwd5z7RdheOfRJNgqdI5x0.html?Investment_Case_Study_-_Website#hd1')">here.</a></p></strong>
      </v-flex>

      <v-flex md2 lg2></v-flex>
    </v-layout>
  </v-container>
</v-container>
</div>
</template>

<script>
  import processingMixin from '@/mixins/processingMixin';

  export default {
    name:'what-we-do-page',
    mixins: [processingMixin],
    created () {
      this.logPageLoad({
        app_name: 'S',
        page_name: this.$options.name,
      })
    },
    methods: {
        validate () {
          if (this.$refs.form.validate()) {
            this.snackbar = true
          }
        },

        actionLog(action,uid, url) {

          console.log('clicked')
          let payload = {
            event: 'view-report',
            app_name: 'S',
            page_name: this.$options.name,
            form_name: action,
            report_uid: uid,
            url: url
          }
          this.logAction(payload)
        },
    },
  }
</script>


<style>
  .banner2 {
    background-color:#131737;
    height:45vh;
    display:flex;
    justify-content: center;
    align-items:center; 
    font-size:2em;
  }

  .OurProcess > h1 {
    font-weight:300;
    font-size:2.3em;
    text-align:center;
    border:solid;
    padding-left:1em;
    padding-right:1em;
  }

  .timeline > h2 {
    text-align:center;
    font-size:2.4em;
    font-weight:200;
    padding-bottom:.5em;
  }

  .wwd_headings {
      color:black !important;
      font-weight:400;
      font-size: 2.3em;
      padding: 1em;
      text-align:center;
  }

  .case_headings {
    text-align:justify;
    font-size:2em;
    padding-top:0em;
  }

  .process_headings {
      font-weight:400;
      font-size:1.2em;
  }

  .case_graphics {
    position:relative;
    bottom:3.7em;
    padding-left:1em;
    width:80%;
  }

  .why {
    margin-top:4em; 
    padding-top:2em; 
    padding-bottom:2em; 
    padding-left:2em;
    padding-right:2em;
    text-align:center;
    border: solid;
    border-width:.15em;
    border-color:hsla(35, 96%, 60%,.2);
    border-radius: 19px 19px 0px 0px;
  }

  .why_points {
    padding-left:1em;
    font-size:1.1em;
  }

  .timeline_graphics {
    width:7em;
  }

  .p_graphics {
    padding-top:1.5em;
  }

  .p_section {
    padding-bottom:3em; 
    position:relative; 
    bottom:3em;
  }

  .timeline_desc {
    font-size:1.1em;
  }

  @media only screen and (min-width: 1264px){
    .timeline {
      width:70%;
    }
    .case_graphics {
      width: 280px;
    }
    
  }

  @media only screen and (min-width: 960px) and (max-width:1264px){
    .timeline {
      width:70%;
    }
  }

  @media only screen and (max-width: 960px) {
    .case_headings {
        text-align:center;
      }
    #case_studies {
      text-align:center;
      
    }
  }

  @media only screen and (max-width: 600px) {
    .banner2  {
      font-size:1.3em;
    }
    .timeline {
      text-align:center;
    }
    .p_graphics {
      padding-bottom:2em;
    }
    .p_section {
      padding-bottom:0em;
    }
    .timeline.desc {
      font-size:1em;
    }
  }
</style>