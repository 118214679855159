<template>
  <div ref="themescard">
    <v-row v-if="parentBP == 'sm' || parentBP == 'xs'" style="position:relative; right:3em;">
      <v-row>
      <span  v-if="getCurrentGap.allow_data_export" style="font-size:.8em; position:absolute; right:3em; top:-1em; z-index:1000;"><br><a class="csv-hover" @click="createCSV()">Download CSV</a></span> 
      </v-row>
      <v-row justify="center">

        <v-container>
          <span>'{{this.parentObject.name}}' Themes by <router-link class="percentile-link" target="_blank" tag="a" :to="{path: '/ConsumerForecast/help?page=definitions#percentile'}">Percentile </router-link>
        <v-tooltip light right max-width="500">
          <template v-slot:activator="{ on, attrs }">
          <v-icon x-small width="10px" v-bind="attrs" v-on="on">mdi-information</v-icon>
          </template>
          <span v-if="this.firstHalf[2]">
            <strong>Read as:</strong>
              {{firstHalf[2].name}} is in the {{firstHalf[2].percentile}}{{readAsEnding(firstHalf[2].percentile)}} percentile in terms of its percent contribution to {{this.parentObject.name}} conversations. This means that only {{100-firstHalf[2].percentile}}% of similarly categorized objects in {{domainName}}  have a higher penetration of {{this.parentObject.name}}. </span>
              <span v-else>
            <strong>Read as:</strong>
              {{firstHalf[0].name}} is in the {{firstHalf[0].percentile}}{{readAsEnding(firstHalf[0].percentile)}} percentile in terms of its percent contribution to {{this.parentObject.name}} conversations. This means that only {{100-firstHalf[0].percentile}}% of similarly categorized objects in {{domainName}} have a higher penetration of {{this.parentObject.name}}. </span>
        </v-tooltip>
        </span>
          <v-data-table
            v-model="contextTable"
            :items="firstHalf.concat(secondHalf)"
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [20]
            }"
            dense
            class="table"
            style="color:#333; padding-top:.5em;"
                 
            >
            <template v-slot:item="{ item }">
              <tr style="height:10px; overflow: visible;">
                <td v-if="item.type_code!==''">
                  <router-link tag="a" style="color: #333;" :to="getLookupViewPath(item.object_sid, parentDomain, item.type_code, $route.query.source)"   >
                     <span @click="loadTheme(item)">{{item.name}}</span>
                  </router-link>
                </td>
                <td v-else>{{item.name}}</td>
                <td style="width:20px;">{{item.percentile}}{{readAsEnding(item.percentile)}}</td>
                <td>
                      <div class="rectangle" >
                          <div class="rectangle2" :style="{ width:item.percentile+'%', backgroundColor:item.color, borderRadius: item.border_radius}">
                            <div class="vl">
                          </div>
                        </div>
                      </div>
                    
                  
                </td>
                <td>
                  <v-tooltip light top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-if="item.change == 0">
                        <v-icon v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                      <span v-else-if="item.change > 0">
                        <v-icon v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                      <span v-else>
                        <v-icon style=" transform: rotate(180deg)" v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                    </template>
                    <span v-if="item.change == 0">nc</span>
                    <span v-else-if="item.change < 0">-</span>
                    <span v-else>+</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table> 

        </v-container>
      </v-row>
      
    </v-row>
    
    
    
    <span v-else>
    <v-row>
    <span v-if="getCurrentGap.allow_data_export" style="font-size:.8em; position:absolute; right:6em; top:.5em; z-index:1000;"><br><a class="csv-hover" @click="createCSV()">Download CSV</a></span> 
    </v-row>
    <v-row justify="center" style="padding-top:1.6em; padding-left:3em;">
      <v-flex sm6 style="position:relative; left:3em;">
        <v-card  width='85%'  elevation="0">
        <span>'{{this.parentObject.name}}' Themes by <router-link class="percentile-link" target="_blank" tag="a" :to="{path: '/ConsumerForecast/help?page=definitions#percentile'}">Percentile </router-link>
        <v-tooltip light right max-width="500">
          <template v-slot:activator="{ on, attrs }">
          <v-icon x-small width="10px" v-bind="attrs" v-on="on">mdi-information</v-icon>
          </template>
          <span v-if="this.firstHalf[2]">
            <strong>Read as:</strong>
              {{firstHalf[2].name}} is in the {{firstHalf[2].percentile}}{{readAsEnding(firstHalf[2].percentile)}} percentile in terms of its percent contribution to {{this.parentObject.name}} conversations. This means that only {{100-firstHalf[2].percentile}}% of similarly categorized objects in {{domainName}} have a higher penetration of {{this.parentObject.name}}. </span>
              <span v-else>
            <strong>Read as:</strong>
              {{firstHalf[0].name}} is in the {{firstHalf[0].percentile}}{{readAsEnding(firstHalf[0].percentile)}} percentile in terms of its percent contribution to {{this.parentObject.name}} conversations. This means that only {{100-firstHalf[0].percentile}}% of similarly categorized objects in {{domainName}} have a higher penetration of {{this.parentObject.name}}. </span>
        </v-tooltip>
        </span>

        <v-data-table
            v-model="contextTable"
            :items="firstHalf"
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [20]
            }"
            :hide-default-footer="true"
            dense
            class="table"
            style="color:#333; padding-top:.5em;"
                 
            >
            <template v-slot:item="{ item }">
              <tr style="height:10px; overflow: visible;">

                <td v-if="item.type_code!==''">
                  <router-link tag="a" style="color: #333;" :to="getLookupViewPath(item.object_sid, parentDomain, item.type_code, $route.query.source)"   >
                     <span @click="loadTheme(item)">{{item.name}}</span>
                  </router-link>
                </td>

                <td v-else>{{item.name}}</td>

                <td style="width:20px;">{{item.percentile}}{{readAsEnding(item.percentile)}}</td>
                <td>
                  
                      <div class="rectangle">
                          <div class="rectangle2" :style="{ width:item.percentile+'%', backgroundColor:item.color, borderRadius: item.border_radius}">
                            <div class="vl">
                          </div>
                        </div>
                      </div>
                    
                </td>
                <td>
                  <v-tooltip light top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-if="item.change == 0">
                        <v-icon v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                      <span v-else-if="item.change > 0">
                        <v-icon v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                      <span v-else>
                        <v-icon style=" transform: rotate(180deg)" v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                    </template>
                    <span v-if="item.change == 0">no change</span>
                    <span v-else-if="item.change < 0">-</span>
                    <span v-else>+</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table> 
      </v-card>
      </v-flex>
      
      
      <v-flex sm6 style="position:relative; top:1em;">
        <v-card  width='85%'  elevation="0">
        <v-data-table
            v-model="contextTable"
            :items="secondHalf"
            :key="object_sid"
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [20]
            }"
            :hide-default-footer="true"
            dense
            style="color:#333; padding-top:1em;"
                 
            >
            <template v-slot:item="{ item }">
              <tr style="height:10px; overflow: visible;">
                <td v-if="item.type_code!==''">
                  <router-link tag="a" style="color: #333;" :to="getLookupViewPath(item.object_sid, parentDomain, item.type_code, $route.query.source)"   >
                     <span @click="loadTheme(item)">{{item.name}}</span>
                  </router-link>
                </td>
                <td v-else>{{item.name}}</td>
                <td style="width:20px;">{{item.percentile}}{{readAsEnding(item.percentile)}}</td>
                <td>
                  
                      <div class="rectangle">
                          <div class="rectangle2" :style="{ width:item.percentile+'%', backgroundColor:item.color, borderRadius: item.border_radius}">
                            <div class="vl">
                          </div>
                        </div>
                      </div>
                    
                </td>
                <td>
                  <v-tooltip light top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-if="item.change == 0">
                        <v-icon v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                      <span v-else-if="item.change > 0">
                        <v-icon v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                      <span v-else>
                        <v-icon style=" transform: rotate(180deg)" v-bind="attrs" v-on="on" small :style="{color: item.change_color}">mdi-triangle</v-icon>
                      </span>
                    </template>
                    <span v-if="item.change == 0">no change</span>
                    <span v-else-if="item.change < 0">-</span>
                    <span v-else>+</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table> 
      </v-card>
      </v-flex>
    </v-row>
    </span>
  </div>
</template>

<script>
import stringFunctions from '@/mixins/stringFunctions';
import objectFunctions from '@/mixins/objectFunctions';
import processingMixin from '@/mixins/processingMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'themes',
  props: {
    parentObject: Object,
    parentDomain: String,
    parentBP: String
  },
  mixins: [stringFunctions, objectFunctions, processingMixin],
  data() {
    return {
      contextTable: '',
      domainName: '',
      items: [],
      firstHalf: '',
      secondHalf: '',
      headers: [
        {text: 'Theme', value: 'name', align:'left', sortable: false},
        {text: '', value: '', align:'right', sortable: false},
        {text: 'Percentile', value: 'percentile', align:'left', sortable: false},
        {text: 'Chng', value: 'percentile', align:'left', sortable: false},
      ],
      sortBy: 'percentile',
      sortDesc: true,
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentGap'
    ]),

    listHeight() {
      //this.sendHeight(this.$refs.themescard.clientHeight)
      return this.$vuetify.breakpoint.width
    }

  },
  created() {
    this.init(this.parentObject)
  },
  mounted() {
    this.$emit('height', this.$refs.themescard.clientHeight)
  },
  methods: {

    sendHeight() {
      console.log(this.$refs.themescard.clientHeight)
      this.$emit('height', this.$refs.themescard.clientHeight)
    },

    loadTheme(item) {
      let theme = {
        sid: item.object_sid,
        domain: this.parentDomain,
        type: item.type_code,
        source: this.$route.query.source
      }
      this.$emit('themeLoad', theme)
    },
    
    createCSV() {
    console.log("CSV THEMES")
    console.log(this.parentObject)
    let data = this.parentObject.theme.domains[this.parentDomain].objects;
    let rows = [['Object Uid', 'Name', 'Percentile']]
    for (const i in data) {
      if (!isNaN(i)) {
        rows.push([
        data[i].object_uid,
        data[i].name,
        data[i].percentile
      ])
      }
    }
    
    let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    
    var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.parentObject.name+"_Themes");
      document.body.appendChild(link); // Required for FF

      link.click();

      this.logAction({
        event: 'csv-download',
        app_name: 'CF',
        page_name: 'look-up-view',
        form_name: 'themes',
        object_sid: this.myObject.object_sid,
        domain_sid: this.myDomain, 
        source: 1
      });
  },

    init(object) {
      let selectedDomain = this.$route.query.domain;
      this.domainName = objectFunctions.marketVerticals[selectedDomain];
      let myArray= [];
        let myArr = [];
      let data = object.theme.domains[this.parentDomain].objects;
      this.items = data
      for (const i in data) {

      if (!isNaN(i))  {
        if (data[i].percentile > 50) {
          //data[i].color = '#20246F';
          data[i].color = '#3f4d63';
          if (data[i].percentile == 100) {
            data[i].border_radius = '4px 4px 4px 4px'
          }
          else { data[i].border_radius = '4px 0px 0px 4px'}
        }
        else {
          //data[i].color = '#9FA8DA';
          data[i].color = '#A8B1C3';
           data[i].border_radius = '4px 0px 0px 4px'
        }
       
        let change = Math.round((data[i].percentile - data[i].qoq_percentile) / data[i].qoq_percentile*220);
        if (change == Infinity) {
          change = 100
        }
       
        data[i].change = change
        

        if (change == 0) {
          data[i].change_color = 'transparent';
        }
        
        else if (change < 0) {
          //hsla(0, 68%, 42%, .5)
          //let ic = Math.max(50, 255-change * 6)/10
          //data[i].change_color = 'rgb(220,'+ ic+','+ ic+')'
          myArray.push(Math.abs(change))
          let ic = Math.abs(change)/50
          //let ic = Math.max(50, 255-change*6);
          data[i].change_color = 'hsla(0, 100%, 35%,'+ic +')'
          //data[i].change_color = 'rgb(220,'+ ic+','+ ic+')'

        }
        else {
          //let ic = Math.max(0, 255-change * 9)
          //data[i].change_color = 'rgb(' + ic + ',' + 170+(ic/2)+','+ic+ ')'
          myArr.push(change)
          let ic =change/40
          data[i].change_color = 'hsla(114, 100%, 18%,'+ic +')'
        }
      }
    }
     
      let half = Math.ceil(data.length / 2 ) ;
    
      if (half >= 2) {
        this.firstHalf = data.slice(0, half)
        this.secondHalf = data.slice(half, data.length)
      }
      else this.firstHalf = data
      
    }
  },

  
}
</script>

<style scoped lang="scss">
  .rectangle {
  height: 15px;
  width: 100px;
  background-color:#E0E0E0;
  border-radius: 4px 4px 4px 4px;
}
.rectangle2 {
  height: 15px;
  //width: 70%;
  //background-color: #20246f;
  //border-radius: 4px 0px 0px 4px;
  z-index: 90;
}
.table {
  background-color: transparent !important;
}
.vl {
  border-left: 2px solid  #BDBDBD;
  height: 10px;
  position:relative;
  top:5px;
  left:50px;
  z-index: 900 !important;
}
.percentile-link {
  color: #6387B0;
}
.percentile-link:hover {
  position:relative;
  bottom:.15em;
}

.v-tooltip__content {
  opacity: .96!important;
  background-color:#3f4d63 !important;
}

.csv-hover {
  color: #6387B0 !important;
}
.csv-hover:hover {
  position:relative;
  bottom:.2em;
}
</style>