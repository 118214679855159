<template>
  <v-card width="450px" height="100%">
    <v-card-title>
      <span>Current Data Source: {{translateSource(getCurrentSource)}}</span>
      <v-icon @click="closeSourceSwitch()" style="position:absolute; right:.5em; top:.5em;" sm>mdi-close</v-icon>
    </v-card-title>
    <v-card-text>

      <v-select
          @change="redirectSource()"
          hint="switch data source"
          persistent-hint
          :menu-props="{ bottom: true, offsetY: true}"
          v-model="sourceUpdater"
          :items="filteredSources"
          item-text="name"
          item-value="value"
          :placeholder="translateSource(getCurrentSource)"
      ></v-select>
    </v-card-text>
    <v-card-text>
      Learn more about the social data sources we offer by contacting your Social Standards contact.
    </v-card-text>
  </v-card>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import stringFunctions          from "@/mixins/stringFunctions";
import objectFunctions from "@/mixins/objectFunctions";

export default {
  name: "lookup-source-switch",
  props: [
      'filteredSources'
  ],
  mixins: [stringFunctions, objectFunctions],

  created() {
    console.log('filteredSources: ', this.filteredSources)
  },

  data() {
    return {
      sourceUpdater: '',
      availableSources: [],
    }
  },

  computed: {

    ...mapGetters([
      'getCurrentSource',
      'getSourceList'
    ]),

  },

  methods: {
    ...mapActions([
      'changeCurrentSource',
        'getFullObjectInfo',
    ]),

    // Note this component is actually used in two places: searchResult and LookupView
    redirectSource: async function () {
      let payload = {
        sid: this.$route.query.sid,
        domain: this.$route.query.domain,
        type: this.$route.query.type ? this.$route.query.type : '',
        source: this.sourceUpdater
      }
      let path = this.getLookupViewPath(payload.sid, payload.domain, payload.type, payload.source)
      await this.$router.push(path)
      this.$emit('updateSource')

    },

    closeSourceSwitch() {
      this.$emit('closeSourceDialog')
    },

  }

}
</script>

<style scoped>

</style>