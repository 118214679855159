<template>
  <nav id="nav" class="no-print" style="z-index: 999999; position: relative">
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
      hide-overlay
      id="draw"
    >
      <v-list>
        <v-list-item link>
          <v-list-item-content>
            <router-link :to="{name:'what-we-do'}"><v-list-item-title style="color:white;">What We Do</v-list-item-title></router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <router-link :to="{name:'products'}"><v-list-item-title style="color:white;">Products</v-list-item-title></router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link >
          <v-list-item-content>
            <router-link :to="{name:'about'}"><v-list-item-title style="color:white;">About Us</v-list-item-title></router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <router-link :to="{ name: 'resources'}"><v-list-item-title style="color:white;">Reports</v-list-item-title></router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <router-link :to="{ name: 'videos'}"><v-list-item-title style="color:white;">Videos</v-list-item-title></router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link v-if="!isLoggedIn">
          <v-list-item-content>
           <LoginDialog />
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
             <MobileDropdown style="color:white;" v-if="isLoggedIn" />
             </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      id="navbar"
      app
      dark
      height="52px"
      elevation=0
      clipped-left
      style="padding-top:.5em; padding-left: 1em;"
    >
      <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      
      <v-toolbar-items d-flex style="padding-top:.5em;">
        <router-link to="/" depressed text><v-img width="215px" src="@/assets/fp-graphics/logo.png" alt=""></v-img></router-link>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items  class="hidden-sm-and-down" style="padding-top:.5em;">
        <router-link :to="{ name: 'what-we-do'}"><v-btn class='mybtns' depressed text style="font-size:1em;">What We Do</v-btn></router-link>
        <router-link :to="{ name: 'products'}"><v-btn class='mybtns' depressed text style="font-size:1em;">Products</v-btn></router-link>
        <router-link :to="{ name: 'about' }"><v-btn class='mybtns' depressed text style="font-size:1em;">About Us</v-btn></router-link>
        <router-link :to="{ name: 'resources'}"><v-btn class='mybtns' depressed text style="font-size:1em;" id="reports">Reports</v-btn></router-link>
        <router-link :to="{ name: 'videos'}"><v-btn class='mybtns' depressed text style="font-size:1em;" id="reports">Press & Media</v-btn></router-link>
        <router-link :to="{ name: 'career-page'}"><v-btn class='mybtns' depressed text style="font-size:1em;" id="reports">Careers</v-btn></router-link>
        <v-toolbar-items v-if="!isLoggedIn"><LoginDialog /></v-toolbar-items>
        <!-- <v-toolbar-items v-if="isLoggedIn"><LoggedInMenu /></v-toolbar-items> -->
        <span v-if="isLoggedIn && !isAdmin"><router-link :to="{ name: 'client-home'}" target="_blank"><v-btn class='mybtns' depressed text style="font-size:1em;">Client Home<v-icon class="mr-2" small >mdi-arrow-top-right</v-icon></v-btn></router-link></span>
         <span v-if="isLoggedIn && isAdmin"><router-link :to="{ name: 'client-home'}" target="_blank"><v-btn class='mybtns' depressed text style="font-size:1em;">Internal Home<v-icon class="mr-2" small >mdi-arrow-top-right</v-icon></v-btn></router-link></span>

        <!-- <router-link :to="{ name: 'admin-view'}"><v-btn class='mybtns' v-if="isAdmin & isLoggedIn" depressed text style="font-size:1em;">Admin</v-btn></router-link> -->
        <!-- <router-link v-if="isLoggedIn" :to="{ name: 'ObjectSearch'}" target="_blank"><v-btn @click="notifyBar()" depressed  class='mybtns' text style="font-size:1em;" id="reports">CF Lite<v-icon class="mr-2" small >mdi-arrow-top-right</v-icon></v-btn></router-link> -->
      </v-toolbar-items>    
    </v-app-bar>
    </nav>

</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'
//import LoggedInMenu from '@/components/Navigation/LoggedInMenu'
import MobileDropdown from '@/components/Navigation/MobileDropdown'
import LoginDialog from "@/components/Navigation/LoginDialog"
  export default {
    name:'Nav-Base',

    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
    }),

    components: {
      //LoggedInMenu,
      LoginDialog,
      MobileDropdown,
    },

    methods: {

    ...mapActions([
      'newApp'
    ]),

    notifyBar(){
      this.newApp()
    }

    },

    computed: {
      ...mapState([
        'loggedUser',
      ]),
      ...mapGetters([
        'isLoggedIn',
        'isAdmin'
      ])
    }
  }
</script>

<style lang="scss">
@import "../../assets/css/print.css";

#navbar {
  background-color: #131737 !important ;
}
.v-navigation-drawer {
  background-color: #131737 !important ;
}
@media only screen and (min-width: 960px) 
{ #nav .v-navigation-drawer { display: none !important; } }
.v-btn:hover:before {
      background-color: transparent;
      display: none; 
    }
.v-ripple__animation{ display: none; }

.v-btn {
  font-weight: 300 !important;
  text-transform: none !important;
}
.mybtns:hover {
  color: #6ECAEB !important;
}

</style>
