<template>
  <v-card width="450px" height="100%">
    <v-card-title>
      <span>Current Data Source: {{translateSource(getCurrentSource)}}</span>
      <v-icon @click="closeSourceSwitch()" style="position:absolute; right:.5em; top:.5em;" sm>mdi-close</v-icon>
    </v-card-title>
    <v-card-text>

      <v-select
          @change="redirectSource()"
          hint="switch data source"
          persistent-hint
          :menu-props="{ bottom: true, offsetY: true}"
          v-model="sourceUpdater"
          :items="getSourceList"
          item-text="name"
          item-value="value"
          :placeholder="translateSource(getCurrentSource)"
      ></v-select>
    </v-card-text>
    <v-card-text>
      Learn more about the social data sources we offer by contacting your Social Standards contact.
    </v-card-text>
  </v-card>


</template>

<script>

import {mapActions, mapGetters} from "vuex";
import stringFunctions          from "@/mixins/stringFunctions";
import objectFunctions from "@/mixins/objectFunctions";

export default {
  name: "source-switch",
  mixins: [stringFunctions, objectFunctions],

  data() {
    return {
      sourceUpdater: '',
    }
  },

  computed: {

    ...mapGetters([
      'getCurrentSource',
      'getSourceList'
    ]),

  },

  methods: {
    ...mapActions([
      'changeCurrentSource'
    ]),

    // Note this component is actually used in two places: searchResult and LookupView
    redirectSource: async function () {
      if (this.$route.path == "/ConsumerForecast/search-result") {
        let payload = {
          item: this.$route.query.search,
          source: this.sourceUpdater,
          filter: this.$route.query.filter,
          order: this.$route.query.order
        }
        let path = this.getSearchViewPath(payload)
        this.$router.push(path)
        this.$emit('updateSource')
      }
      else {
        let payload = this.sourceUpdater
        await this.changeCurrentSource(payload)
            .catch((err) => {
              console.log(err)
            })
        this.$emit('updateSource')
      }


    },

    closeSourceSwitch() {
      this.$emit('closeSourceDialog')
    },

  }

}
</script>

<style scoped>

</style>