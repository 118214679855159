<template>
  <div style="height:700px; padding-top:2em;">
    <v-container>
      <span class="org-title">
        {{this.orgUid.company_name}} Users
      </span>

      <UserMgmt :userAdmin="this.orgUid" />
    </v-container>
    
  </div>
</template>

<script>
 import UserMgmt from '@/components/AdminMgmt/UserMgmt.vue';
 import {mapGetters} from 'vuex'
export default {
 
  name: 'user-settings',
  components: {
    UserMgmt
  },
  data () {
    return {
      orgUid: ''
    }
  },
  created() {
    this.orgUid = this.getCurrentOrg
  },

  computed: {
    ...mapGetters([
      'getCurrentOrg'
    ])
  }
  
}
</script>

<style scoped lang="css">
  .org-title {
    padding-left: .5em;
    font-size:1.5em;
  }
</style>