<template>
  <div style="padding-bottom:2em;">

    <v-app-bar elevation="0" style="background-color:transparent" >
        <router-link :to="{name: 'Home'}"><img src="@/assets/logo_navyL.png" width="190" id="logo" style="margin-top:.6em;"></router-link>
        <!-- <router-link :to="{name: 'client-home'}"><v-btn class="btn-hover" style="background-color:transparent; color:#333; margin-top:.5em; margin-left:1em;" elevation="0">Client Home</v-btn></router-link> -->
        <v-spacer></v-spacer>
        <router-link :to="{name: 'ObjectSearch'}"><v-btn class="btn-hover" style="background-color:transparent; color:#333;" elevation="0">CF Home</v-btn></router-link>
       <!-- <router-link :to="{name: 'portal'}"><v-btn style="background-color:transparent; color:white;" elevation="0">Worklists</v-btn></router-link> -->

        <Bookmarks v-if="!getLoggedUser.anon" @bookmarkPush="markPush" v-model="bookmarksWrap" >
          <template v-slot:activator="{ open }">
            <v-btn @click="begin(open)" class="btn-hover" style="background-color:transparent;  color:#333;" elevation="0">Bookmarks</v-btn>
          </template>
        </Bookmarks>

        <a class="btn-hover" style="background-color:transparent; color:#ffff;" :href="lensesUrl" target="_blank"> Lenses</a>
        <router-link :to="{path: 'help?page=general'}"><v-btn class="btn-hover" style="background-color:transparent; color:#333;" elevation="0">FAQ</v-btn></router-link>
        <v-menu
          v-model="accountMenu"
          absolute
          offset-y
          style="max-width: 600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="btn-hover" id="no-background-hover" v-bind="attrs" v-on="on" style="background-color:transparent; margin: 0em 10em 0em 0em; color:#333;" elevation="0">Account</v-btn>
          </template>

        <v-list>
          <v-list-item link v-if="!getLoggedUser.anon">
            <settingsEditor v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">My Account</a>
              </template>
            </settingsEditor>
          </v-list-item>
          <v-list-item v-else>
            <GapSettings v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">Account Settings</a>
              </template>
            </GapSettings>
          </v-list-item>
          <v-list-item>
            <a @click="loggingOff()" style="color:#333;">Logout</a>
          </v-list-item>
        </v-list>
       </v-menu>
    </v-app-bar>

    
    <v-container style="padding-top:2em; width:85%; align-items: center">

      <v-row justify="center">
        <div style="width:100%;">

          <v-text-field solo  style="padding-left:2em; padding-right:2em; position:relative; bottom:.4em; border-radius:25px;" v-model="searchMatches"  append-icon="mdi-magnify" label="Search All Objects" @click:append="matchMe()" @keydown.enter="matchMe()" single-line hide-details light></v-text-field>
                  <span style="float:right; margin-right:3.5em;">
            <a class="adv" style="margin-right:1em;" @click="openAdvancedSearch()">Advanced Search</a>
            <a class="adv" @click="openSourceSwitch()">Source: {{translateSource(getCurrentSource)}} <v-icon style="margin-left: -5px; margin-top: -3px;" medium width="10px" v-bind="attrs" v-on="on">mdi-menu-swap</v-icon></a>
          </span>
       </div>
      </v-row>

      <div align="center" style="padding-bottom:2em;" v-if="this.loading">
      <img class="wheel-a" width="75px" height="75px" src="@/assets/loaderA.png" alt="A">
      <img class="wheel-b" style="position:relative; right:52px; bottom:22.5px;" width="30px" height="30px" src="@/assets/loaderB.png" alt="B">
      </div>
      <span v-if="!loading">
      <v-row justify="center" style="padding-top:1em;">
      <v-card elevation="0" style="width:70%" v-if="tableReady">
        
        <v-row>
          <span v-if="currentFilter">

            <span v-if="!multiFilter">
              <span><a class="searchSet" @click="removeFilter()" style="position:relative; bottom:.5em; padding-right:1em;">Filter: {{currentFilter}} <v-icon style="position:relative; bottom:.1em;" small>mdi-close-circle-outline</v-icon></a></span>
            </span>
            <span v-else>
              <span><a @click="removeMulti(item)" class="searchSet" v-for="(item, idx) in currentFilter" :key="idx" style="position:relative; bottom:.5em; padding-right:1em;">Filter: {{item.type}} <v-icon style="position:relative; bottom:.1em;" small>mdi-close-circle-outline</v-icon></a></span>
            </span>

          </span>
          <span v-if="currentSort"><a class="searchSet" @click="removeSort()" style="position:relative; bottom:.5em; left:0em;">Sort: {{currentSort}} <v-icon style="position:relative; bottom:.1em;" small>mdi-close-circle-outline</v-icon></a></span>
        </v-row>

        <v-row v-if="!currentFilter && !currentSort && this.$route.query.search " justify="center">
          <div style="height:10px; position:relative; bottom:0em;">
            <!-- <span style="padding-right:2em;">Quickfilter for: </span>  -->
            <a class="bounce" @click="quickfilter('brand')">
              <span v-if="br" style="padding-right:1em;"><span class="selectedQuickFilter"><v-icon class="quickBrand" small>mdi-square-rounded</v-icon> Brands</span></span>
              <span v-else style="padding-right:1em;"><v-icon class="quickBrand" small>mdi-square-rounded</v-icon>Brands</span>
            </a>
            <a class="bounce" @click="quickfilter('product_type')">
              <span v-if="prod" style="padding-right:1em;"><span class="selectedQuickFilter"><v-icon class="quickProducts" small>mdi-square-rounded</v-icon> Products</span></span>
              <span v-else style="padding-right:1em;"><v-icon class="quickProducts" small>mdi-square-rounded</v-icon>Products</span>
            </a>
            <a class="bounce" @click="quickfilter('descriptive')">
              <span v-if="desc" style="padding-right:1em;"><span class="selectedQuickFilter"><v-icon class="quickDesAll" small>mdi-square-rounded</v-icon> Descriptive Objects</span></span>
              <span v-else style="padding-right:1em;"><v-icon class="quickDesAll" small>mdi-square-rounded</v-icon> Descriptive Objects</span>
            </a>
            <a class="bounce" @click="quickAll()">
              <span v-if="qall" style="padding-right:1em;"><span class="selectedQuickFilter"><v-icon class="quickDesAll" small>mdi-square-rounded</v-icon> All</span></span>
              <span v-else style="padding-right:1em;"><v-icon class="quickDesAll" small>mdi-square-rounded</v-icon> All</span>
            </a>
          </div>
        </v-row>

        <v-row v-if="matchResults.length == 0" justify="center" style="padding-top:5em;">
          <span>No Matches</span>
        </v-row>

        <!-- removed @click:row="push"  -->
        <v-layout row wrap>

          <v-flex xs12>
            <v-data-table
                @click:row="handleClick"
                v-if="matchResults.length > 0 && this.$route.query.search"
                :headers="headers"
                :items="matchResults"
                :hide-default-footer="true"
                disable-pagination
            >

              <template v-slot:item.name="{ item }">
                  <v-tooltip light left>
                  <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind:style="{color: item.color}" v-bind="attrs" v-on="on" style="position:relative; bottom:.1em; padding-right:.3em;" small>mdi-square-rounded</v-icon>
                  </template>
                  <span v-if="item.desc_name">{{item.desc_name}}</span>
                  <span v-else>{{item.type}}</span>
                  </v-tooltip>
                  <a>{{item.name}} <br> </a>
                  <span v-if="item.domains != undefined && item.push_domain == ''" style="font-size:.8em;"> N/A in user verticals</span>
                  <span v-else-if="item.domains == undefined && item.availableSources.length == 0" style="font-size:.8em;"> (Insufficient Data)</span>
                  <span v-else-if="item.domains == undefined && item.availableSources.length > 0" style="font-size:.8em;">
                    (N/A in current source)
                    <v-tooltip light right opacity=".8" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small color="#37474F" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                      </template>
                      <span>
                        Object data available in sources:
                        {{item.availableSourcesText}}
                      </span>
                    </v-tooltip>
                  </span>

              </template>

              <template v-slot:item.logo="{ item }">
                <div class="p-2">
                  <v-img max-width="50px" max-height="60px"  style="margin:.4em;" :style="{opacity: item.opacity}" :src="item.logo" :alt="item.name"></v-img>
                </div>
              </template>

              <template v-slot:item.type="{ item }">
                <div class="p-2">
                  <span v-if="item.desc_name">{{item.desc_name}}</span>
                  <span v-else>{{item.type}}</span>
                </div>
              </template>

              <template v-slot:item.relList="{ item }">
                <span v-if="item.relList.length">{{item.relList}}</span>
                <span v-else>{{item.category}}</span>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card>
      </v-row>
      </span>
    </v-container>

    <v-dialog v-model="advSearch" width="650" @click:outside="closeAdvancedSearch()">
      <AdvancedSearch
          v-on:closeDialog="closeAdvancedSearch()"
          v-on:getAdv="getAdvanced()"
      ></AdvancedSearch>
    </v-dialog>

    <v-dialog v-model="sourceSwitch" width="450" @click:outside="sourceSwitch=!sourceSwitch">
      <SourceSwitch
          v-on:closeSourceDialog="sourceSwitch=!sourceSwitch"
          v-on:updateSource="closeAndReload()"
      ></SourceSwitch>
    </v-dialog>

  </div>
</template>

<script>

import settingsEditor from '@/components/Navigation/SettingsEditor.vue';
import GapSettings from '@/components/AdminMgmt/GapSettings.vue';
import Bookmarks from '@/components/ConsumerForecast/Bookmarks.vue'
import {mapActions, mapGetters} from 'vuex';
import stringFunctions from '@/mixins/stringFunctions';
import objectFunctions from '@/mixins/objectFunctions';
import processingMixin from '@/mixins/processingMixin';
import AdvancedSearch from "@/components/ConsumerForecast/Lookups/PageElements/AdvancedSearch";
import SourceSwitch from "@/components/ConsumerForecast/Lookups/PageElements/SourceSwitch";


export default {
  name: 'search-results',
  mixins: [
      stringFunctions,
      processingMixin,
      objectFunctions
    ],
  components: {
      settingsEditor,
      GapSettings,
      Bookmarks,
      AdvancedSearch,
      SourceSwitch
  },
  data () {
    return {
      bookmarksWrap: '',
      totalDesserts: 0,
      searchMatches: '',
      matchResults: {},
      tableReady: false,
      loading: false,
      relevant: '',
      radioGroup: '',
      fromTop: '10em',
      advSearch: false,
      sourceSwitch: false,
      keywordSearch: '',
      selectedFilters: [],
      all: '',
      qall: true,
      filter: '',
      sort: '',
      br: false,
      prod: false,
      desc: false,
      headers: [
        {text: '', value: 'name',  sortable: false},
        {text: '', value: 'logo', align: 'center', sortable: false},
        {text: '', value: 'type', align: 'left', sortable: false},
        {text: '', value: 'relList', sortable: false},
        {text: '', value: 'main_domains',  sortable: false},
      ],
      filterItems: [
        {text: objectFunctions.codeToObjectType['brand']['name'], value: 'brand'},
        {text: objectFunctions.codeToObjectType['product_type']['name'], value: 'product_type'},
        {text: objectFunctions.codeToObjectType['descriptive']['name'], value: 'descriptive'},
      ],
      sortItems: [
        {text: 'Most Relevant', value: 'relevance'},
        {text: 'PPM High to Low', value: 'ppm'},
      ],
      pagin: {
          page: 1,
          pageStart: 1,

          //itemsLength: number
        }
    }

  },

  computed: {

    ...mapGetters([
      'getAccount',
      'getDomainsAvailable',
      'getLoggedUser',
      'getCurrentSource',
      'getSourceList',
    ]),

    lensesUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/reports-list' // Prod URL
        : 'https://app.socialstandards.net/reports-list'; // Dev URL
    },

    currentFilter() {
      let x = this.$route.query.filter
      if (x) {
        if (x.includes(',')) {
          //console.log("This is Multi filter");
          //console.log(this.Multi(x));
          return this.Multi(x);
        }
        else {
          //console.log("This is one filter");
          //console.log(objectFunctions.codeToObjectType[x]['name']);
          return objectFunctions.codeToObjectType[x]['name'];
        }
      }
      else {
        return ''
      }
    },

    multiFilter() {
      return (this.$route.query.filter.includes(','));
    },

    currentSort() {
      if (this.$route.query.order) {
        let x = this.$route.query.order
        switch(x) {
          case 'relevance':
            return 'By relevance'
          case 'ppm':
            return 'PPM High To Low'
          default:
            return ''
        }
      }
      else { return '' }
    }
  },

  created() {
    console.log(this.getAccount)
    console.log(this.searchMatches)
    this.searchMatches = decodeURIComponent(this.$route.query.search)

    // update source if URL provides one
    let sourceParam = this.$route.query.source ? this.$route.query.source : this.getCurrentSource
    this.updateCurrentSource(sourceParam)

    // this checks if currently using advanced filter search
    if (this.$route.query.filter || this.$route.query.order) {
      this.getAdvanced()
    }
    else if (this.$route.query.search) {
      this.searchMatches = decodeURIComponent(this.$route.query.search)
      var en
      if (this.$route.query.search.includes('%')) {
        en = this.$route.query.search
      }
      else { en  = encodeURIComponent(this.$route.query.search) }

      this.matchMe(en)
    }

    this.logPageLoad({
      app_name: 'CF',
      page_name: this.$options.name,
      params: this.searchMatches
    })
  },

  methods: {

    ...mapActions([
      'getQueryMatch',
      'getAdvancedMatch',
      'logoutUser',
      'changeCurrentSource'
    ]),

    handleClick(item) {
      if (item.opacity != 0.4 && item.push_domain != '') {
        this.$router.push(this.getLookupViewPath(item.object_sid, item.push_domain, item.type_code, this.getCurrentSource))
      }
    },

    start: function(method) {
        setTimeout(() => method());
      },

    loggingOff() {
      this.logoutUser()
      this.$router.push({name: 'Home'});
      this.logAction({
        event: 'logout'
      });
    },

    begin: function(method) {
      setTimeout(() => method());
    },

    getSearchPath(keyword, filter='', order=''){
      return {path: '/ConsumerForecast/search-result?search=' + keyword + '&filter=' + filter + '&order=' + order + '&source=' + this.getCurrentSource}
    },

    updateCurrentSource: async function (new_source) {
      let availableSources = this.getSourceList.map(o => o.value)
      console.log(availableSources)
      if (availableSources.includes(Number(new_source))){
        await this.changeCurrentSource(new_source)
            .catch((err) => {
              console.log(err)
            })      }
      else {
        this.$router.push({path: '/AppView/something-went-wrong' + '?status=' + '400'})
      }
    },

    // ??? is this ever used? why source 5?
    markPush(item) {
      console.log("Bookmark-search")
      console.log(item)
      this.bookmarksWrap = false;
      //this.$router.push({path: '/ConsumerForecast/lookup-view' + '?sid=' + item.object_sid + '&domain='+ item.domain + '&type=' + item.type_code + '&source=5'});
      this.$router.push(this.getLookupViewPath(item.object_sid, item.push_domain, item.type_code, '5')
);
    },

    matchMe: async function (item) {
      console.log('matching me')
      this.br = false;
      this.prod = false;
      this.desc = false;
      this.qall = true;
      console.log(this.getCurrentSource)
      if (item) {
        this.loading = true;
        this.$router.push({path: '/ConsumerForecast/search-result' + '?search=' + item + '&source=' + this.getCurrentSource})
        let payload= {
          query: this.$route.query.search,
          source: this.getCurrentSource
        }
        await this.getQueryMatch(payload)
            .then((data) => {
              console.log(data)
              this.processDomains(data.data.matches)
              this.totalDesserts = data.data.matches.length   // what is this used for?
              this.matchResults = data.data.matches
              this.setRelevant(data.data.matches)
              this.loading = false
              //this.addDomain(data.data.matches)   // why do we call addDomain here when we already do it within addColorFontDomain()?

            })

            .catch((err) => {
              console.log(err)
            })
        this.createTable()
      }
      else {
        this.loading = true;
        var en = encodeURIComponent(this.searchMatches)
        this.$router.push({path: '/ConsumerForecast/search-result' + '?search=' + en + '&source=' + this.getCurrentSource})
        let payload= {
          query: this.$route.query.search,
          source: this.getCurrentSource
        }
        await this.getQueryMatch(payload)

            .then((data) => {
              console.log(data)
              this.processDomains(data.data.matches)
              this.matchResults = data.data.matches
              this.setRelevant(data.data.matches)
              //this.addDomain(data.data.matches)
              this.loading = false
            })

            .catch((err) => {
              console.log(err)
            })
        this.createTable()
      }
    },


    quickfilter: async function (filter) {
      // reset previous filters
      this.br = false;
      this.prod = false;
      this.desc = false;
      this.qall = false;

      // set new filter
      switch (filter) {
        case 'brand':
          this.br = true;
          break;
        case 'product_type':
          this.prod = true;
          break;
        case 'descriptive':
          this.desc = true;
          break;
      }
      this.loading = true;
      var search = this.$route.query.search;

      let payload = {
        query: search,
        filter: filter,
        source: this.getCurrentSource,
      }
      await this.getAdvancedMatch(payload)
          .then((data) => {
            console.log(data);
            this.processDomains(data.data.matches);
            this.matchResults = data.data.matches;
            this.setRelevant(data.data.matches);
            //this.addDomain(data.data.matches)
            this.loading = false;
          })
          .catch((err) => {
            console.log(err)
          })
      this.createTable()
    },

    quickAll() {
      this.br = false;
      this.prod = false;
      this.desc = false;
      this.qall = true;

      this.matchMe()
    },

    getAdvanced: async function () {
      this.br = false;
      this.prod = false;
      this.desc = false;
      this.loading = true;
      var search = this.$route.query.search;
      var filter = this.$route.query.filter;
      var order = this.$route.query.order;

      // ensure source is part of URL
      if (this.$route.query.source == undefined) {
        this.$router.push({path: '/ConsumerForecast/search-result' + '?search=' + decodeURIComponent(search) + '&filter=' + filter + '&order=' + order + '&source=' + this.getCurrentSource})
      }

      let payload = {
        query: search,
        filter: filter,
        order: order,
        source: this.getCurrentSource,
      }
      this.searchMatches = decodeURIComponent(search)
      console.log(payload)
      await this.getAdvancedMatch(payload)
          .then((data) => {
            console.log(data);
            this.processDomains(data.data.matches);
            this.matchResults = data.data.matches;
            this.setRelevant(data.data.matches);
            //this.addDomain(data.data.matches)
            this.loading = false;
          })
          .catch((err) => {
            console.log(err)
          })
      this.createTable()
    },

    removeFilter() {
      let search = this.$route.query.search
      let order = this.$route.query.order
      this.$router.push(this.getSearchPath(search, '', order))
      this.getAdvanced()
    },

    removeSort() {
       let search = this.$route.query.search
       let filter = this.$route.query.filter
       this.$router.push(this.getSearchPath(search, filter))
       this.getAdvanced()
    },

    elseSelected() {
      if (this.allSelect) {
        this.allSelect = false
        this.all = ''
      }
    },

    allSelected() {
      if (this.allSelect === false) {
        this.allSelect = true
        this.selectedFilters = []
      }
      else {
        this.allSelect = false
      }

    },

    Multi(items) {
      let set = []
      items = items.split(',')
      for (const i in items) {
        set.push({type: objectFunctions.codeToObjectType[items[i]]['name'], code:items[i]})
      }
      return set
    },

    removeMulti(item) {
      let set = this.currentFilter
      set = set.filter(x => x.code != item.code)
      let load = []
      for (const i in set) {
        load.push(set[i].code)
      }
      this.$router.push(this.getSearchPath(this.$route.query.search, load, this.$route.query.order))
      this.getAdvanced()
    },

    openAdvancedSearch() {
      this.advSearch = true;
    },
    closeAdvancedSearch() {
      this.advSearch = false;
    },

    openSourceSwitch(){
      this.sourceSwitch = true;
    },

    closeAndReload() {
      this.sourceSwitch = false
      location.reload()
          .catch((err) => {
            console.log(err)
          })
    },

    setRelevant(data) {
      console.log('setting')
      for (const i in data) {
        let set = []
        if (data[i].product_types) {
            for (const n in data[i].product_types) {
            set.push(data[i].product_types[n].product_type)
          }
        }
        if (data[i].main_domains) {
          data[i].main_domains = this.replaceColon(data[i].main_domains)
        }
        data[i].relList = set.join(", ")
        data[i].relList = this.shortenList(data[i].relList)
      }
      console.log('setting done')
    },

    createTable(){
      console.log(this.matchResults)
      this.tableReady = true
    },

    processDomains(data) {
      let available = []
      if (this.getDomainsAvailable !== '') {
        available = this.getDomainsAvailable[this.getCurrentSource].map(x => x.domain_sid)
        console.log("available", available)
      }

      for (let object of data) {

        // set colors
        this.setColorName(object)

        // find available sources for object
        let mySources = this.getSourceList
        let usableSources = []
        for (const [s, d] of Object.entries(object.source_domains)) {
          if (d != '') {
            usableSources.push(s)
          }
        }
        object.availableSources = usableSources
        object.availableSourcesText = this.displayableSources(usableSources)
        let filteredSources = []
        for (const s of mySources) {
          if (usableSources.includes(s.value.toString())) {
            filteredSources.push(s)
          }
        }
        object.userReachableSources = filteredSources


        if (object.domains) {

          object.font = '#333';
          object.opacity = 1

          if (available.length > 0) {

            // domainMatches will store all the user-accessible domains
            object.domainMatches = object.domains.filter(x => available.includes(x.domain_sid))


            if (object.domainMatches.length > 0) {
              object.push_domain = object.domainMatches[0].domain_sid  // domain with the highest total_posts (object.domains is sorted by highest total_posts)
            }
            else {
              console.log('no domains match')
              object.color = '#E0E0E0';
              object.font = '#9E9E9E';
              object.opacity = .4
              object.push_domain = ''
            }

          }
          else {
            object.push_domain = object.domains[0].domain_sid
          }
        }
        else {
          object.color = '#E0E0E0';
          object.font = '#9E9E9E';
          object.opacity = .4
        }
      }
    },

    clearFilter() {
      this.all = ''
      this.selectedFilters = []
    },

    displayableSources(source_nums) {
      let display = ''
      let allSources = this.allSources()
      for (const i of source_nums) {
        display += '\n' + allSources[parseInt(i)]
      }
      return display
    },
  },

  // ??? is this used anywhere?
  push(item) {
    console.log(item)
    this.$router.push(this.getLookupViewPath(item.object_sid, item.push_domain, item.type_code, '1'))
  }
}
</script>

<style scoped lang="scss">

@keyframes rotateleft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes rotateright {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adv {
  color: #37474F !important;
}
.adv:hover {
  color: #6387b0 !important;
}

.wheel-a {
  animation: rotateleft 4s infinite;
}
.wheel-b {
  animation: rotateright 4s infinite;
}

.bounce:hover {
  position: relative;
  bottom:.2em;
}

.searchSet:hover {
  color:#71C6E6 !important;
}
.searchSet:hover .v-icon {
  color:#71C6E6 !important;
}

.btn-hover:hover {
  color:#71C6E6 !important;
}

#no-background-hover::before {
   background-color: transparent !important;
}

 a {
   text-decoration: none;
   color:#333 !important;
 }

.search-btn {
  float:right; 
  margin-right:2em;
  color:#333;
  border-radius:25px;
  background-color:#E3F2FD !important;
}
.search-btn:hover {
  color:#333 !important;
  position:relative;
  bottom:.2em;
}

.loader {
	max-width: 5rem;
	width: 100%;
	height: auto;
	stroke-linecap: round;
}

circle {
	fill: none;
	stroke-width: 10;
	animation-name: preloader;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	transform-origin: 170px 170px;
	will-change: transform;

	&:nth-of-type(1) {
		stroke-dasharray: 550px;
	}

	&:nth-of-type(2) {
		stroke-dasharray: 500px;
	}

	&:nth-of-type(3) {
		stroke-dasharray: 450px;
	}

	&:nth-of-type(4) {
		stroke-dasharray: 300px;
	}

	@for $i from 1 through 4 {
		&:nth-of-type(#{$i}) {
			animation-delay: -#{$i * 0.15}s;
		}
	}
}

@keyframes preloader {
	50% {
		transform: rotate(360deg);
	}
}

.selectedQuickFilter{
  background-color:#E3F2FD;
  padding:.8em;
  border-radius:25px;
}

.quickBrand{
  position:relative;
  bottom:.1em;
  padding-right:.1em;
  color:#ad4f22;
}

.quickProducts{
  position:relative;
  bottom:.1em;
  padding-right:.1em;
  color:#cf4784;
}

.quickDesAll{
  position:relative;
  bottom:.1em;
  padding-right:.1em;
  color:#8b8b8b;
}

</style>