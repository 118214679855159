<template>
  <div>
    <!-- Section 1: Banner-->
    <v-container fluid class="banner">
      <h1 class="centered-text"><span id="consumer">Consumer Data,</span> <span class="ss_blue" id="coded"><strong>(De)Coded.</strong></span></h1>
      <span id="topping">

      <img class="centered-text" width=100% src="@/assets/banners/Banner150.png" alt="" />
      <p id="home-phrase" class="centered-text" >Next-generation business and investment strategies<br> rooted in the voice of the consumer</p>  
      <router-link :to="{name:'contact'}"><p id="button-one" class="ss_blue orange_hover btn center" style="margin-bottom:6em; position:relative; top:2em;">Get In Touch</p></router-link>
      
      </span>
    </v-container>

    <!-- Section 2: Talking Points -->
    <v-container class="section2">
      <v-layout row wrap>

        <v-flex xs12 md6 lg4 class="graphics" order-sm-1 order-md-0 order-lg-0 hidden-xs-only> 
          <v-img class="center" width="17em" src="@/assets/fp-graphics/freq.png" />
        </v-flex>

        <v-flex class="posts center" xs12 md6 lg8 order-sm-2 order-md-1 order-lg-1>
          <h3>350+ million social posts a month</h3>
          <p>We structure and contextualize our data into 7 market verticals, allowing you to instantly measure market share, benchmark performance, and track emerging trends without the need for boolean queries.</p>
        </v-flex>
  
        <v-flex sm12 lg12s  order-sm-3 order-md-2 order-lg-2 class="front-btn">
          <router-link :to="{name:'what-we-do', hash:'#process'}"><p class="btn center ss_blue orange_hover">Our Process</p></router-link>
        </v-flex>

        <v-flex class="posts center" xs12 md6 lg8 order-sm-5 order-md-3 order-lg-3>
          <h3>Explore the data without the added work</h3>
          <p>Our dynamic user interface provides a wide array of data visualization tools designed to facilitate rigorous data exploration, discovery, and analysis.</p>
        </v-flex>

        <v-flex class="graphics" xs12 md6 lg4 order-sm-4 order-md-4 order-lg-4 hidden-xs-only>
          <v-img class="center" width="18em" hidden-sm-and-down src="@/assets/fp-graphics/3d.png" />
        </v-flex>

        <v-flex sm12 lg12s order-sm-6 order-md-5 order-lg-5 class="front-btn weird-btn">
            <router-link :to="{name:'products', hash:'#products-btn'}"><p class="btn center ss_blue orange_hover">Our Products</p></router-link>
        </v-flex>

        <v-flex xs12 md6 lg4 class="graphics" order-sm-7 order-md-6 order-lg-6 hidden-xs-only>
          <v-img class="center" width="17em" src="@/assets/fp-graphics/g3.png" />
        </v-flex>

        <v-flex class="posts center" xs12 md6 lg8 order-sm-8 order-md-7 order-lg-7>
          <h3>Key insights, faster than ever before</h3>
          <p>We combine our proprietary technology with a team of experienced consultants in an innovative live workshop format, enabling you to ask your most pressing questions in real-time.</p>
        </v-flex>

        <v-flex sm12 lg12s order-sm-9 order-md-8 order-lg-8 class="front-btn">
            <router-link :to="{name:'products', hash:'#Workshops'}"><p class="btn center ss_blue orange_hover">Learn More</p></router-link>
        </v-flex>

      </v-layout>
    </v-container>

    <!-- Section 3: logos and Slideshow -->
    <v-container fluid class="section3">
      <h2 class="centered-text">Trusted by the world's largest CPG & Investment Firms</h2>


       <v-row  v-for = "i in index_arr()" :key="i">
         <v-flex sm6 md2 lg2 hidden-sm-and-down></v-flex>
            <v-flex v-for="logo in logos.slice(i,i+4)" :key="logo" sm6 md2 lg2 class="logos">
              <v-img class="center" :width="logo.width" :src="showLogo(logo.image)" />
            </v-flex>
         <v-flex sm6 md2 lg2 hidden-sm-and-down></v-flex>
       </v-row>

       <v-row  v-if="this.logos.length%4===1" :key="i">
          <v-flex sm6 md2 lg2  hidden-sm-and-down></v-flex>
          <v-flex sm6 md3 lg3  hidden-sm-and-down></v-flex>
          <v-flex sm12 md2 lg2 class="logos">
             <v-img class="center" :width="logos[logos.length-1].width" :src="showLogo(logos[logos.length-1].image)" />
          </v-flex>
          <v-flex sm6 md3 lg3  hidden-sm-and-down></v-flex>
          <v-flex sm0 md2 lg2  hidden-sm-and-down> </v-flex>
       </v-row>

      <v-row  v-if="this.logos.length%4===2" :key="i">
          <v-flex sm6 md2 lg2  hidden-sm-and-down></v-flex>
          <v-flex sm6 md2 lg2  hidden-sm-and-down></v-flex>
          <v-flex v-for="logo in logos.slice(logos.length-2,logos.length)" :key="logo" sm6 md2 lg2 class="logos">
             <v-img class="center" :width="logo.width" :src="showLogo(logo.image)" />
          </v-flex>
          <v-flex sm6 md2 lg2  hidden-sm-and-down></v-flex>
          <v-flex sm0 md2 lg2  hidden-sm-and-down> </v-flex>
       </v-row>

      <v-row  v-if="this.logos.length%4===3" :key="i">
          <v-flex sm6 md2 lg2  hidden-sm-and-down></v-flex>
          <v-flex sm6 md1 lg1  hidden-sm-and-down></v-flex>
          <v-flex v-for="logo in logos.slice(logos.length-3,logos.length-1)" :key="logo" sm6 md2 lg2 class="logos">
             <v-img class="center" :width="logo.width" :src="showLogo(logo.image)"/>
          </v-flex>
          <v-flex sm12 md2 lg2 class="logos"> <!--last elem on small screen should take full row size to be in the center-->
             <v-img class="center" :width="logos[logos.length-1].width" :src="showLogo(logos[logos.length-1].image)" />
          </v-flex>
          <v-flex sm6 md1 lg1  hidden-sm-and-down></v-flex>
          <v-flex sm0 md2 lg2  hidden-sm-and-down></v-flex>
       </v-row>

      <v-row style="height:2em"></v-row>

      <SlideshowHomeView />
    </v-container>



    <!-- Section 4: Case Studies -->
    <v-container class="section4">
    <h2 class="centered-text" style="font-size:2.5em;">Case Studies</h2>
      <v-layout row wrap>

        <!-- Row Zero: graphics: -->
        <v-flex md1 lg1  hidden-sm-and-down></v-flex>
          
        <v-flex  md4 lg4 >
        <img class="center case-imgs" width="210em" src="@/assets/fp-graphics/str150.png" />
        </v-flex>

        <v-flex md2 lg2  hidden-sm-and-down></v-flex>
        
        <v-flex  md4 lg4 hidden-sm-and-down>
          <img class="center case-imgs" width="210em" src="@/assets/fp-graphics/inv150.2.png" />
        </v-flex>

        <v-flex md1 lg1  hidden-sm-and-down></v-flex>
        
        <!-- Row One: titles: -->
        <v-flex md1 lg1  hidden-sm-and-down></v-flex>
        
        <v-flex  md4 lg4 hidden-sm-and-down >
        <h3 class="title_studies" >Strategy</h3>
        </v-flex>

        <v-flex md2 lg2  hidden-sm-and-down></v-flex>
        
        <v-flex  md4 lg4 hidden-sm-and-down >
          <h3 class="title_studies">Investment</h3>
        </v-flex>

        <v-flex md1 lg1  hidden-sm-and-down></v-flex>

    <!-- Row Two: paragraphs: -->
        <v-flex sm6 md1 lg1  hidden-sm-and-down></v-flex>
        
        <v-flex sm12 md4 lg4 order-xs="2">
          <p class="studies"><span class="hidden-md-and-up" style="font-size:1.5em;">Strategy </span><br>Consumer data has always been a key staple of corporate strategy for CPG firms. Learn how our clients are taking their consumer analytics to the next level with the help of Social Standards.</p>
        </v-flex>

        <v-flex md2 lg2  hidden-sm-and-down></v-flex>
        
        <v-flex  md4 lg4 hidden-md-and-up>
        <img class="center case-imgs" width="210em" src="@/assets/fp-graphics/inv150.2.png" />
        </v-flex>

        <v-flex sm12 md4 lg4 order-xs="4">
            <p  class="studies"><span class="hidden-md-and-up" style="font-size:1.5em;">Investment </span><br>Social Standards serves as a valuable partner to investment groups looking for a competitive advantage. Learn how our data can help you make better, faster investment decisions, and give you valuable insight as part of your M&A Strategy.</p>
        </v-flex>

        <v-flex md1 lg1  hidden-sm-and-down></v-flex>
        
    <!-- Row Three: buttons: -->
        <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 >
            <router-link :to="{name:'what-we-do', hash:'#strategy'}"><p class="btn center ss_blue orange_hover">Learn More</p></router-link>
          </v-flex>
        <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 hidden-sm-and-down>
          <router-link :to="{name:'what-we-do', hash:'#investment'}"><p class="btn center ss_blue orange_hover">Learn More</p></router-link>
          </v-flex>
        
      </v-layout>
    </v-container>
  </div>
</template>


<script>
  import SlideshowHomeView from '@/components/SlideshowHomeView'
  import processingMixin from '@/mixins/processingMixin';
  import placeholderImage from '@/assets/images/placeholder.png';
  export default {
    name: 'Home',
    mixins: [processingMixin],
    data(){
      return {
        logos: [
          {width:"10.5em", image:"AB-01.png"},
          {width:"12em", image:"BAML-01.png"},
          {width:"11em", image:"barclays-logo.png"}, 
          {width:"8em", image:"BCG-01.png"},                 
          {width:"10em", image:"coka-01.png"},           
          {width:"14em", image:"Constellation-01.png"},           
          {width:"13em", image:"clorox.png"},          
          {width:"8em", image:"diageo.png"},          
          {width:"6em", image:"ELF-01.png"},           
          {width:"13.5em", image:"ESTEE-01.png"},                           
          {width:"13em", image:"FourSig-01.png"},                   
          {width:"13em", image:"J&JGrey-01.png"},                                     
          {width:"9em", image:"kpmg.png"},                           
          {width:"13em", image:"kraftHeinz.png"},                           
          {width:"13.5em", image:"LCat-01.png"},                          
          {width:"11.5em", image:"MOET-01.png"},                           
          {width:"11.5em", image:"pdc-01.png"},                           
          {width:"11em", image:"PRIMAL-01.png"},                           
          {width:"12em", image:"shisheido.png"},                                         
          {width:"9em", image:"TPG-01.png"},                                  
          {width:"8.5em", image:"wells-fargo.png"},
          {width:"13.5em", image:"YellowWood-01.png"},
        ],
      }
    },
    components: {
      SlideshowHomeView,
    },
    created() {

      this.logPageLoad({
			app_name: 'S',
			page_name: this.$options.name,
      })
      
      setTimeout(function () {
      let ele = document.getElementById('consumer');
      ele.classList.add('appear');
      }, 800);
      setTimeout(function () {
      document.getElementById('consumer').style.opacity = 1;
      }, 900);
      setTimeout(function () {
      let ele = document.getElementById('coded');
      ele.classList.add('appear');
      }, 1300);
      setTimeout(function () {
      document.getElementById('coded').style.opacity = 1;
      }, 1400);
      setTimeout(function () {
      let ele = document.getElementById('button-one');
      ele.classList.add('goUp');
      }, 1300);
      setTimeout(function () {
      document.getElementById('button-one').style.opacity = 1;
      }, 1400);
    },
    methods: {
      showLogo (img){
        return img ? require("@/assets/fp-logos/" + img) : placeholderImage
      },
      index_arr(){
        let logos_index = [...Array(Math.floor(this.logos.length/4)*4).keys()] // 4 logos per row; to get
        const index_list = logos_index.filter(number => {
          return number % 4 === 0;
          });
        console.log("logos_index", logos_index)
        console.log("index_list", index_list)
        return index_list
      },
    }
    }
</script>

<style lang="css">
#home-phrase {
  font-size: 1.5em;
  position:relative; 
  bottom:0em;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(250%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
#button-one {
  opacity: 0;
}
#coded {
  opacity: 0;
}
#consumer {
  opacity: 0;
}
#topping {
  animation: fadein 2s ease-in-out;
}
.appear {
  animation: fadein 1s;
  animation-timing-function: ease-out;
}
.goUp {
  animation: up 1s;}
/* Banner css*/
  .banner {
    font-weight:300;
    background-color: #131737;
    height: 100%; 
  }
  .banner > h1 {
    position: relative;
    top:1.4em;
    font-size:3.5em;
    font-weight: inherit;
  }
  .banner > img {
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: .3em;
  }
  .banner > p {
    font-size:1.5em; 
    position:relative; 
    bottom:2em;
  }
  /* section 2 CSS*/
  .section2 {
    height: 100%;
    padding-bottom:8em;
    position: relative;
    top: 2.5em;
  }
  .posts > h3 {
    font-size: 2em;
    font-weight: 400;
    padding-bottom: .2em;
    color:black;
    position:relative;
    top:0em;
  }
  .posts p {
    font-size:1.1em;
  }
  .posts {
    align-self:center;
    flex-direction: column;
    text-align: justify;
  }
  .graphics {
    padding: 2em;
    align-self:center;
    flex-direction: column;
  }
  .weird-btn  {
    padding-top:1em;
  }
  /* section 3 CSS*/
  .section3 {
    height:100%;
    background-color:#f3faff;
    font-family: 'Roboto-Regular', Arial;
    padding-bottom:5em;
  }
  .logos {
    align-self:center;
    flex-direction: column;
    padding:1em;
    padding-top:3em;
  }
  .section3 > h2 {
    color: #808285;
    font-size:1.6em;
    font-weight:400;
    padding-top: 1em;
    padding-bottom:1em;
  }
  /* section 4 CSS*/
  .section4 {
    padding-bottom: 4em;
  }
  .section4 > h2{
    color:black;
    padding-top: 2em;
    padding-bottom: 1em;
    font-weight: 400;
    font-size: 1.8em;
  }
  .studies {
    text-align:center;
    padding-left:1em;
    padding-right:1em;
    padding-bottom:1em;
    font-size: 1.2em;
  }
  .title_studies {
    text-align:center;
    font-size:2em;
    font-weight:400 ;
    position:relative;
    top:.2em;
    color:black;
  }
  .slides_container {
    background-color:white; 
    height:35vh; 
    width:65vw;
    border-radius:12px;
  }
  .case-imgs {
    padding:1em;
  }
  @media only screen and (min-width: 1904px){
    .section2 {
      width:80%;
    }
  }
  @media only screen and (max-width: 960px) {
    .banner > h1 {
      font-size: 2em;
      top: 1em;
    }
    .banner > p {
      font-size: .9em;
    }
    .section2 {
      height: 100%
    }
    .posts {
      text-align:center;
    }
    .info-btn-sel {
      bottom:0em;
    }
    .section4 {
      width:80%;
    }
  }
  @media only screen and (max-width: 600px) {
    .banner {
      height:100%;
      padding-top:.5em;
    }
    .banner > h1 {
      top: 0em;
      font-size: 2.4em;
    }
    .banner > p {
      font-size: 1em;
      bottom: 0em;
      padding-bottom: 3em;
    }
    .btn {
      font-size: 1em;
      }
    .section2 {
      top: 10px;
      height:50%;
      padding-bottom:2.5em;
      padding-top:3em;
    }
    .slides_container {
      width:100%;
      height:45vh;
    }
    .front-btn {
      padding-bottom:2em;
    }
    .weird-btn  {
    padding-top:0em;
    }
  }
  
  @media only screen and (max-width: 480px) {
      .slides_container {
        width:100%;
        height:50vh;
      }
      .banner br { 
        content: ' '; 
      }
  }
</style>