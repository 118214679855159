<template>


  <div>
    <v-container id="syndicated-title">
      <h1>Syndicated Reports</h1>
    </v-container>
    <v-layout row wrap flex-lg-row-reverse flex-md-row-reverse>
      <v-flex xs12 sm12 md4 lg2 xl2  flex-sm-row-reverse class="features">
        <!--:items=verticals pulls from store.state.verticals-->
        <v-form>
          <v-select
              v-model="selectedVertical"
              label="Select Market Vertical"
              :items="verticals"
              item-value="sid"
              item-text="name"
              :menu-props="{ bottom: true, offsetY: true, maxHeight: 450}"
              height="100%"
              ref="sel"
              clearable></v-select>
          <v-text-field v-model="searchedReports" label="Search" clearable ref="search"></v-text-field>
        </v-form>
      </v-flex>

      <v-flex xs12 sm12 md8 lg10 xl10 style="text-align:center;" class="rep">

        <v-card v-if="callFilteredPub!=='' || callFilteredPub=='not initiated'"
                class="d-flex flex-wrap align-content-center cardsalign"
                flat
                style="text-align:center;"
                tile>
          <!-- workproduct card renderer calls filtering mixin function -->
          <ReportCard
              class='mx-auto pa-2 ma-2 align-content-center'
              v-for="product in callFilteredPub"
              v-bind:key="product.object_uid"
              v-bind:product="product"
              @clicked="menuTold"
          ></ReportCard>
        </v-card>
        <v-card v-else class="d-flex flex-wrap align-content-center cardsalign justify-center" flat title>
          <span style="font-size:1.1em;">No reports for the current selection! Try changing your filters. </span>
        </v-card>

      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import processingMixin from '@/mixins/processingMixin';
  import reportFilteringFunctions from '@/mixins/reportFilteringFunctions';
  import ReportCard from '@/components/Portal/ReportCard';
  import objectFunctions from '@/mixins/objectFunctions';

  export default {
    name:'resources',
    mixins: [processingMixin, reportFilteringFunctions, objectFunctions],
    components: {
    ReportCard
    },

    data () {
      return {
        selectedVertical: '',
        searchedReports: '',
        placeholderValue:'Select Market Vertical',
      }
    },
    created () {
      this.logPageLoad({
        app_name: 'S',
        page_name: this.$options.name,
      })
      // get public work products
      // this is the reason data updates and then displays stuff. This function updates the state
      this.getWorkProducts('EzmmIA_wjlinFAZBA_nHRs');
      console.log('page loaded')
    },

    methods: {
      ...mapActions([
        'getWorkProducts'
      ]),
      ...mapMutations([
        'SET_USER'
      ]),

      clearFilter() {
        this.selectedVertical = null
      },
      clearSearch() {
        this.$refs.search.reset()
        this.searchedReports = null
      },
      menuTold(value) {
        this.selectedVertical = value
      },
    },

    computed: {
      ...mapState([
        'loggedUser',
        'publicWorkProducts',
        'verticals'
      ]),

      ...mapGetters([
        'isLoggedIn',
        'getWorkProductsState'
      ]),

      callFilteredPub() {
        return this.filteredWorkProducts(this.publicWorkProducts, this.selectedVertical, this.searchedReports)
      },
     },

    }
</script>

<style>

.wheel-a {
  animation: rotateleft 4s infinite;
}
.wheel-b {
  animation: rotateright 4s infinite;
}

  #syndicated-title > h1 {
    padding-bottom:.5em; 
    padding-top:.5em; 
    font-weight:400; 
    font-size:2.5em; 
    text-align:center;
  }

  .cardsalign {
    padding:4px;
  }

  .features {
    padding-right:3em; 
    padding-top:2.5em; 
    padding-top:1em;
    padding-left:0em;
  }

  @media only screen and (max-width: 600px) {
    .cardsalign {
      padding:0;
      position:relative;
      right:1em;
    }
    .features {
      padding-left:3em;
    }
  }

  @media only screen and (max-width: 960px) and (min-width: 600px) {
    .features {
      padding-left:4em;
      padding-right:4em;
    }
  }
  
  @media only screen and (max-width: 1100px) and (min-width: 960px) {
    .features {
      padding-left:4em;
      padding-right:4em;
    }
    .features {
      padding-right:5em;
      padding-left:0em;
    }
  }

  @media only screen and (max-width: 1264px) and (min-width: 1100px) {
    .features {
      padding-left:4em;
      padding-right:4em;
    }
    .features {
      padding-left:0em;
      padding-right:9em;
    }
  }

  @media only screen and (max-width: 1285px) and (min-width: 1264px) {
    .features {
      padding-left:4em;
      padding-right:4em;
    }
    .features {
      padding-left:0em;
      padding-right:3em;
      position: relative;
      right:8em;
    }
    .rep {
      position: relative;
      right:10em !important;
    }
  }

  @media only screen and (max-width: 1264px) and (min-width: 1100px) {
    .features {
      padding-left:4em;
      padding-right:4em;
    }
    .features {
      padding-left:0em;
      padding-right:9em;
    }
  }

  @media only screen and (max-width: 1285px) and (min-width: 1264px) {
    .features {
      padding-left:4em;
      padding-right:4em;
    }
    .features {
      padding-left:0em;
      padding-right:3em;
      position: relative;
      right:8em;
    }
    .rep {
      position: relative;
      right:10em !important;
    }
  }
</style>
  