
import { mapActions } from 'vuex';

export default {
  methods: {
  ...mapActions([
    'queryLogs'
  ]),

  month(item) {
    let first = new Date(item)
    return first.getMonth()
  },

  objectLogs(item) {
    let payload = {
      period: {
        from: '2021-12-01', to: '2021-12-31'
      },
      filters: [
        {
          field: 'account_uid',
          value: item.token || item.account_uid,
          operation: '='
        },
        {
          field: 'object_sid',
          is_not_null: true
        },
      ],
      return: [
        {
          field: 'ts',
          do_order: true,
          descending: true
        },
        {
          field: 'account_name'
        },
        {
          field: 'object_sid'
        },
        {
          field: 'object_name'
        },
        {
          field: 'domain_name'
        },
        {
          field: 'account_name'
        }
      ],
    }
    return payload
  },

  eventsOutput(item, month) {

    let p 
    if (month == 'Dec') {p = {from: '2021-12-01', to: '2021-12-31'} }
    if (month == 'Jan') {p = {from: '2022-01-01', to: '2022-01-31'} }
    if (month == 'Feb') {p = {from: '2022-02-01', to: '2022-02-28'} }
    let payload = {
      period: p,
      filters: [
        {
          field: 'account_uid',
          value: item.token || item.account_uid,
          operation: '='
        },
      ],
      aggregates: [
        {
          field: 'event',
          do_return: true, 
          do_order: true,
        }
      ],
      summaries: [
        {
          function: 'count',
          field: 'event', 
          do_order: true, 
          descending: true
        }
      ],
    }

    return payload
  },

  addEventDetails(item, month, event) {

    let p 
    if (month == 'Dec') {p = {from: '2021-12-01', to: '2021-12-31'} }
    if (month == 'Jan') {p = {from: '2022-01-01', to: '2022-01-31'} }
    if (month == 'Feb') {p = {from: '2022-02-01', to: '2022-02-28'} }
    let payload = {
      period: p,
      filters: [
        {
          field: 'account_uid',
          value: item.token || item.account_uid,
          operation: '='
        },
        {
          field: 'event',
          value: event,
          operation: '='
        },
      ],
      return: [
        {field: 'object_name'},
        {field: 'domain_name'},
        {field: 'params'},
        {field: 'page_name'},
        {field: 'event_count'},
        {field: 'form_name'},
        {field: 'report_uid'},
        {
          field: 'ts',
          do_order: true,
          descending: true,
        },

      ]
    }
    return payload
  },

  objectLoadAggregates(item, month) {

    let p 
    if (month == 'Dec') {p = {from: '2021-12-01', to: '2021-12-31'} }
    if (month == 'Jan') {p = {from: '2022-01-01', to: '2022-01-31'} }
    if (month == 'Feb') {p = {from: '2022-02-01', to: '2022-02-28'} }
    let payload = {
      period: p,
      filters: [
        {
          field: 'account_uid',
          value: item.token || item.account_uid,
          operation: '='
        },
        {
          field: 'object_sid',
          is_null: false
        },
      ],
      aggregates: [
        {
          field: 'object_sid',
          do_return: true, 
          do_order: true,
        }
      ],
      summaries: [
        {
          function: 'count',
          field: 'object_sid', 
          do_order: true, 
          descending: true
        }
      ],
    }
    return payload
  },


  monthlyEventCount(item) {
    let payload = {
      period: {
        from: '2021-12-02',
      },   
      filters: [
        {
          field: 'account_uid',
          value: item.token || item.account_uid,
          operation: '='
        }
      ],
      aggregates: [
        {
          field: 'ts_month',
          do_return: true, 
          do_order: true,
        }
      ],
      summaries: [
        {
          function: 'count',
          field: 'ts', 
          do_order: true, 
          descending: true
        }
      ],
    }
    return payload
  },

  reportEventCount(item) {
    let payload = {
      period: {
        from: '2021-12-02',
      },
      filters: [
        {
          field: 'report_uid',
          value: item.object_uid,
          operation: '='
        }
      ],
      aggregates: [
        {
          field: 'ts_month',
          do_return: true, 
          do_order: true,
        }
      ],
      summaries: [
        {
          function: 'count',
          field: 'ts', 
          do_order: true, 
          descending: true
        }
      ],
    }
    return payload
  },

  reportUsers(item) {
    let payload = {
      period: {
        from: '2021-12-02',
      },
      filters: [
        {
          field: 'report_uid',
          value: item.object_uid,
          operation: '='
        }
      ],
      return: [
        {field: 'page_name'},
        {field: 'account_name'},
        {field: 'email'},
        {field: 'report_uid'},
        {field: 'event_count'},
        
        {
          field: 'ts',
          do_order: true,
          descending: true,
        }
      ]

    }
    return payload
  },

  groupMe(set, key) {

    var counts = set.info.reduce((p, c) => {
      var name 
      if (key == 'page_name') {
        name = c.page_name;
      }
      else if (key == 'object_name') {name = c.object_name}
      else if (key == 'form_name') {name = c.form_name}
      else if (key == 'report_uid') {name = c.report_uid}

      if (!p[name]) {
        p[name] = 0;
      }
      p[name]++;
      return p;
    }, {});


    return counts
  },


  monthlyFilteredCount(item, param) {
    let payload = {
      period: {
        from: '2021-12-02',
      },
      filters: [
        {
          field: 'account_uid',
          value: item.token || item.account_uid,
          operation: '='
        }
      ],
      aggregates: [
        {
          field: 'ts_month',
          do_return: true, 
          do_order: true,
        }
      ],
      summaries: [
        {
          function: 'count',
          field: 'ts', 
          do_order: true, 
          descending: true
        }
      ],
    }
    if (param != 'all') {
      payload.filters.push({
        field: 'event',
        value: param,
        operation: '='
      })
    }
    return payload
  },

 

  }
}