<template>

    <v-card style="height:220px;">
      <v-card-actions style="padding-top:2em;">
<!--        <v-select
            v-model="switchGap"
            style="width:80%;"
            :style="{zIndex: 10000010}"
            :items="profiles" item-text="profile_name"
            item-value="gap_uid" :placeholder="getCurrentGap.profile_name"
            label="Choose Profile"
        ></v-select>-->

         <v-radio-group v-model="switchGap">
             <v-radio
                hide-details
                v-for="(item, idx) in profiles"

                :key="idx"
                :label="item.profile_name"
                :value="item.gap_uid">
             </v-radio>
         </v-radio-group>
      </v-card-actions>
      <v-card-actions style="text-align:center;" v-if="message.length">
       <span style="margin-left:2em; background-color:#C5E1A5; padding:.5em; border-radius:5px;">{{message}}</span>
      </v-card-actions>
      <v-btn
          style="float:right; margin:1em; background-color:#E3F2FD;"
          rounded elevation="0"
          @click="switchProfile()"
          :disabled="isButtonDisabled"
      >switch
      </v-btn>

    </v-card>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  props: {
    profiles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      switchGap: '',
      message: '',
    };
  },
  mounted() {
    this.switchGap = this.getCurrentGap.gap_uid
    console.log('this switch gap mountes', this.switchGap)
  },
  computed: {

    ...mapGetters([
      'getCurrentGap',
      'getCurrentOrg',
    ]),

    isButtonDisabled() {
      return this.switchGap === this.getCurrentGap.gap_uid;
    },
  },
   methods: {

    ...mapActions([
      'changeProfile',
      'setSwitch',
    ]),

      switchProfile: async function() {
      console.log(this.switchGap)
      console.log(this.getCurrentGap)
      if (this.switchGap && this.switchGap != this.getCurrentGap.gap_uid) {

        this.message = 'switching...';
        let payload = {
          org_uid: this.getCurrentOrg.company_uid,
          gap_uid: this.switchGap
        }
        console.log('switch')
        await this.changeProfile(payload).then((data) => {
          console.log(data);
          
          this.set(data)

          let currentPage = this.$router.history.current.name

          // if the current page is an error page redirect to home,
          if (['error','error-page'].includes(currentPage)){
            this.$router.push({name: 'client-home'});
          }
          // otherwise reload current page to get the newly switched gap info
          else { location.reload() }

        }).catch((err) => {console.log(err)})
      }
      else {
        console.log('Already using '+ this.getCurrentGap.profile_name + ' profile')
        this.message = "Already using the '"+ this.getCurrentGap.profile_name + "' profile!"
      }
    },

    set: async function(data) {
      console.log(data)
      let payload = {
        token: data.data.jwt,
        gap: this.switchGap
      }
      console.log(payload)
      this.setSwitch(payload)
          .then(() => {
            this.$emit('resetSwitchProfile')
            this.message=''
            // this.openSwitchProfiles=false; this.message=''

          })
          .catch((err) => {console.log(err)})
    },
   }
};
</script>