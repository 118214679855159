<template>
  <div>
    <v-app-bar absolute color="#D6E1EF" elevation="0" height="80">
      <router-link v-if="breakpoint > 1264" :to="{ name: 'Home' }"
        ><img src="@/assets/logo_navyL.png" width="190" id="logo"
      /></router-link>
      <!-- <v-btn style="margin-left:15em; background-color:transparent; color: #131737;" elevation="0">Home</v-btn> -->
      <router-link :to="{ name: 'ObjectSearch' }"
        ><v-btn
          style="
            background-color: transparent;
            margin-left: 1em;
            margin-top: 0.5em;
          "
          elevation="0"
          ><span style="font-size: 1em; font-weight: 500">CF Home</span></v-btn
        ></router-link
      >

      <v-form
        ref="smallSearch"
        style="width: 250px"
        v-if="this.$vuetify.breakpoint.name != 'xs'"
      >
        <v-text-field
          style="padding-left: 2em"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          @click:append="pushResult()"
          @keydown.enter="pushResult()"
          single-line
          hide-details
          light
        ></v-text-field>
      </v-form>

      <v-spacer></v-spacer>
      <!-- <SourceDateMonthAndCurrentSource
        view="main"
        :filtered-sources="filteredSources"
        :breakpoint="breakpoint"
        :parent-source="parentSource"
        :parent-month="parentMonth"
  
      /> -->

      <Bookmarks
        v-if="!getLoggedUser.anon"
        @bookmarkPush="markPush"
        v-model="bookmarksWrap"
      >
        <template v-slot:activator="{ open }">
          <a @click="start(open)"
            ><div style="padding: 1em; margin-bottom: 0.9em">
              <v-icon small>mdi-bookmark-multiple</v-icon>
            </div></a
          >
        </template>
      </Bookmarks>

      <router-link
        v-if="breakpoint > 1264"
        style="position: relative; bottom: 0.5em"
        target="_blank"
        tag="a"
        :to="{ path: '/ConsumerForecast/help?page=general' }"
        ><v-icon small> mdi-help-circle </v-icon></router-link
      >

      <v-btn
        v-if="breakpoint <= 1264"
        @click="openI()"
        elevation="0"
        style="background-color: transparent"
      >
        <v-icon medium>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Bookmarks from "@/components/ConsumerForecast/Bookmarks.vue";
//import SourceDateMonthAndCurrentSource from "@/components/V2/HTMLElements/SourceDateMonthAndCurrentSource.vue";

export default {
  name: "objectToolbar",
  props: {
    parentMonth: String,
    parentDomain: String,
    breakpoint: Number,
    parentSource: String,
    filteredSources: Array,
  },
  components: {
    Bookmarks,
    // SourceDateMonthAndCurrentSource,
  },
  data() {
    return {
      miniSearch: "",
      searchResults: {},
      search: "",
      bookmarksWrap: "",
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
  },
  methods: {
    ...mapActions(["getQueryMatch"]),

    start: function (method) {
      setTimeout(() => method());
    },

    markPush(load) {
      console.log("Bookmark: objectToolbar");
      console.log(load);
      this.$emit("pushB", load);
      this.bookmarksWrap = false;
    },

    openD() {
      this.$emit("monthDialog", true);
    },
    openS() {
      this.$emit("sourceDialog", true);
    },
    openI() {
      this.$emit("infoDialog", true);
    },

    pushResult() {
      this.search = encodeURIComponent(this.search);
      this.$router.push({
        path: "/ConsumerForecast/search-result" + "?search=" + this.search,
      });
    },

    // ??? When/where is this used?
    push(item) {
      let myObject = {
        sid: item.object_sid,
        domain: this.parentDomain,
      };
      this.$emit("itemLoad", myObject);
    },
  },
};
</script>

<style scoped>
#logo {
  margin: 1em 0em 0em 3em;
}

#logo:hover {
  position: relative;
  bottom: 0.2em;
}

ul {
  padding-top: 1em;
}

ul > li {
  list-style-type: none;
}

@media only screen and (max-width: 960px) {
  #logo {
    margin: 1em 0em 0em 0em;
  }
}
</style>
