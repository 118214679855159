import Vue from "vue";

export default {
  isLoggedIn: (state) => {
    /* 
    in production, check that the state has the user logged in and that the auth-token
    cookie exists. if so, the user is actully logged in. 

    in dev, just check for state
    */

    let loggedIn = false;
    let loggedUser = "uid" in state.loggedUser;
    let hasAuthCookie = Vue.$cookies.isKey("auth-token");

    if (state.nodeEnv === "production") {
      if (loggedUser && hasAuthCookie) {
        loggedIn = true;
      }
    } else {
      if (loggedUser) {
        loggedIn = true;
      }
    }

    return loggedIn;
  },

  isAdmin: (state) => {
    let admin = false;
    if (state.loggedUser.is_admin === 1) {
      admin = true;
    }
    return admin;
  },

  getCurrentGap: (state) => {
    return state.gap;
  },

  getAccount: (state) => {
    return state.account;
  },

  getAvailableDomains: (state) => {
    let domainList = state.availableDomains;
    domainList.sort((a, b) => (a.domain_name > b.domain_name ? 1 : -1));
    return domainList;
  },

  getDomainsAvailable: (state) => {
    return state.availableSourceDomains;
  },

  getAccess: (state) => {
    return state.access;
  },

  getOrgList: (state) => {
    return state.orgList;
  },

  getMyOrgs: (state) => {
    return state.myOrgs;
  },

  getAssumedOrg: (state) => {
    return state.assumedOrg;
  },

  getAssumeToken: (state) => {
    return state.assumeToken;
  },

  getCurrentOrg: (state) => {
    return state.currentOrg;
  },

  getLoggedUser: (state) => {
    return state.loggedUser;
  },

  userSettings: (state) => {
    return state.userSettings;
  },

  getUserGuides: (state) => {
    return state.userGuides;
  },

  getSourceList: (state) => {
    let sources = state.sourceList;
    sources.sort((a, b) => (a.name > b.name ? 1 : -1));
    return sources;
  },

  getCurrentSource: (state) => {
    return state.currentSource;
  },

  getDashboardBlobData: (state) => {
    return state.dashboardBlobData;
  },

  getDashboardListData: (state) => {
    return state.dashboardList;
  },

  getGeoShowPercentiles: (state) => state.geoShowPercentiles,
  getDemoShowPercentiles: (state) => state.demoShowPercentiles,

  getKebabMenuState: (state) => state.kebabMenuState,
};
