import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"background-color":"white","border-radius":"12px"}},[_c(VCarousel,{attrs:{"cycle":"","height":"100%","width":"100%","hide-delimiter-background":"","show-arrows-on-hover":"","light":"","hide-delimiters":""}},_vm._l((_vm.slides),function(slide){return _c(VCarouselItem,{key:slide.quote,staticClass:"item",attrs:{"align":"center"}},[_c(VSheet,{staticStyle:{"padding":"1em"},attrs:{"color":"white","align":"center"}},[_c(VLayout,{staticClass:"slides",staticStyle:{"padding":"1em","justify":"center","text-align":"center"},attrs:{"wrap":"","row":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VFlex,{staticStyle:{"padding-bottom":"1.5em"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_vm._v(" "+_vm._s(slide.quote)+" ")]),_c(VFlex,{staticClass:"authors",attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('strong',[_vm._v(_vm._s(slide.from))])]),_c(VFlex,{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('img',{staticClass:"slide_logo center",attrs:{"src":slide.img_src}})])],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }