<template>
  <div>
    <v-container>
      <v-row justify="center">
       <apexchart style="margin-right:2em;" width="400" type="donut" :options="chartOptions" :series="series"></apexchart>
      </v-row>
    </v-container>
        
    </div>
</template>

<script>
export default {
  name:'demo-pie', 
  props: {
    parentDemo: Object,
    parentDomain: String,
  },

  created() {
    this.deconstruct(this.parentDemo)
  },

  data() {
    return {
      series: [],
      chartOptions: {
        labels: [],
        colors: ['#274b69', '#3f4d63', '#85a1c1', '#95724B', '#B08C63', '#C1A585', '#BDBDBD'],
        chart: {
          type: 'donut',
          // events: {
          //   legendHovered: function() {
          //     console.log('hovere')
          //   }
          // }
        
        },
        dataLabels: {
            enabled: false
          },
          tooltip: {
          enabled: true,
          theme: 'dark',
          y: {
            formatter: function formatter(val) {
              return (val).toFixed(1) + '%'},
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        legend: {
          show: true,
          offsetY: 0,
          position: 'right',
        },
        responsive: [{
          breakpoint: '960',
          options: {
            chart: {
              width: 320
            },
            legend: {
              show: true,
              offsetY: 0,
              position: 'bottom',
            }
          }
        }]
      },
    }
  },

  methods: {

    deconstruct(data) {
      let d = data.demo.domains[this.parentDomain].data
        let percent = []
        let cat = []
        for (const i in d) {
            if (i[0] == 8) {
              if (d[i].demo_id == 800099 || d[i].demo_id == 800699 || d[i].demo_id == 800399) {
                percent.push(d[i].percent)
                cat.push(d[i].name)
              }
          }
        }
        this.chartOptions = {...this.chartOptions, ...{
          labels: cat
        }}
        this.series = percent
    },
  },
}
</script>
