// processingMixin -- performs user logging functions
import { mapState, mapActions } from 'vuex';

export default {

  computed: {
    ...mapState([
      'loggedUser'
    ]),
  },

  methods: {
    logPageLoad: function (load) {
      //if (this.loggedUser.token) {}
      load['event'] = 'page-load'
      //console.log(load)
      this.postLog(load)

     },

     logAction: function (load) {
       let payload = load
       this.postLog(payload)
     },

    ...mapActions([
      'postLog'
    ])
  
  },

}