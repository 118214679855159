import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify-right":""}},[_c(VDialog,{attrs:{"max-width":"340","hide-overlay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"font-size":"1em","position":"relative","right":".2em"},attrs:{"dark":"","elevation":"0","text":"","id":"logDialog"}},'v-btn',attrs,false),on),[_vm._v(" Login/Register ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticStyle:{"max-width":"320px","position":"absolute","top":"4em","right":"1em"}},[_c(VCardTitle,[(_vm.login)?_c('span',{staticStyle:{"color":"#333","margin-right":".5em","font-size":".8em"}},[_vm._v("Login ")]):_c('a',{staticStyle:{"margin-right":".5em","font-size":".8em"},on:{"click":function($event){_vm.login=true}}},[_vm._v("Login ")]),_vm._v(" | "),(!_vm.login)?_c('span',{staticStyle:{"color":"#333","margin-left":".5em","font-size":".8em"}},[_vm._v(" Register")]):_c('a',{staticStyle:{"margin-left":".5em","font-size":".8em"},on:{"click":function($event){_vm.login=false}}},[_vm._v(" Register")])]),_c('v-card-content',[(_vm.login)?_c('span',[_c('LoginForm')],1):_vm._e(),(!_vm.login)?_c('span',[_c('RegisterForm')],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }