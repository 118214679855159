<template>
  <div>
    <v-container>
      <v-row justify="center">
        <apexchart width="380" height="250" type="bar" :options="chartOptions" :series="series"></apexchart>
      </v-row>
    </v-container>
        
    </div>
</template>

<script>
export default {
  name:'demo-age', 
  props: {
    parentDemo: Object,
    parentDomain: String
  },

  created() {
    this.deconstruct(this.parentDemo)
  },

  data() {
    return {
      series: [{
        data: []
      }],
      myCat: [],
      chartOptions: {
        
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          },
          grid: {
            show: false,      // you can either change hear to disable all grids
            xaxis: {
              lines: {
                show: false  //or just here to disable only x axis grids
              }
            },  
            yaxis: {
              lines: { 
                show: false  //or just here to disable only y axis
              }
            },   
          },
          
        
          yaxis: {
              type: 'numeric',
              tickAmount: 6,
              max: 100,
              min: 0,
            },
          },
          legend: {
            show: false
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function formatter(val) {
                let ending = 'th'
                if (val.toString()[1] == 1) {ending = 'st'}
                else if (val.toString()[1] == 2) {ending = 'nd'}
                else if (val.toString()[1] == 3) {ending = 'rd'}
                return val + ending
                },
            }
          },
          states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
          xaxis: {
              categories: []
            },
          
          //colors: ['#20246F', '#9FA8DA', '#C28BB5', '#BD4B80', '#AD1457', '#800F40'],
          colors: ['#274b69', '#85a1c1', '#c6ccd8', '#3f4d63', '#202022'],
            fill: {
              opacity:1, 
            //   colors: [function({ value }) {
            //     if(value > 50) {
            //       return '#FFA600'
            //   } else {
            //       return '#352d6a'
            //   }
            // }]
         },
          annotations: {
              xaxis: [
                {
                  x: 50,
                  strokeDashArray: 0,
                  borderColor: '#BDBDBD',
                  offsetY: -5,
                  label: {
                    orientation: 'horizontal',
                    borderColor: '#BDBDBD',
                    offsetY: -15,
                    style: {
                      color: '#333',
                      background: '#fff'
                    },
                    text: '50th Percentile'
                    
                  }
                }
              ]
            },
            plotOptions: {
            bar: {
              borderRadius: 2,
              horizontal: true,
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false
          },

          responsive: [{
          breakpoint: '960',
          options: {
            chart: {
              width: 320
            },
          }
        }]
          
      },
    }
  },

  methods: {

    deconstruct(data) {
        
        let d = data.demo.domains[this.parentDomain].data
        let percent = [];
        let percentile = [];
        let cat = [];
        for (const i in d) {
            if (i[0] == 4 ) {
              if (d[i].demo_id == 401099 || d[i].demo_id == 401299 || d[i].demo_id == 401499 || d[i].demo_id == 401699) {
                percent.push(d[i].percent);
                percentile.push(d[i].percentile);
                cat.push(d[i].name);
              }
          }
        }
        this.chartOptions.xaxis = {
          categories: cat
        }
        this.series = [{
          name: 'percentile',
          data: percentile
        }]
        this.chartOptions.yaxis = {
              type: 'numeric',
              tickAmount: 6,
              max: 100,
              min: 0,
            }
      
    },
  },
}
</script>
