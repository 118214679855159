import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center","height":"52px"}},[_c('router-link',{staticStyle:{"height":"32px"},attrs:{"to":{ name: 'Home' }}},[_c('img',{staticStyle:{"margin":"0em 0em 0em 3em"},attrs:{"src":require("@/assets/SSLogo.png"),"width":"200"}})]),(!_vm.isAdmin)?_c('span',[_c('router-link',{attrs:{"to":{ name: 'client-home' }}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white","margin-top":"inherit","margin-left":"1em"},attrs:{"elevation":"0"}},[_vm._v("Client Home")])],1)],1):_c('span',[_c('router-link',{attrs:{"to":{ name: 'client-home' }}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white","margin-left":"1em"},attrs:{"elevation":"0"}},[_vm._v("Internal Home")])],1)],1),(_vm.getLoggedUser.anon)?_c('router-link',{attrs:{"to":{ path: '/ConsumerForecast/help?page=general' }}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white","margin-top":"0.5em"},attrs:{"elevation":"0"}},[_vm._v("FAQ")])],1):_vm._e(),_c(VSpacer),(_vm.getCurrentGap.portal && !_vm.$route.path.includes('/AppView/portal'))?_c('span',[_c('router-link',{attrs:{"to":{ name: 'portal' }}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white"},attrs:{"elevation":"0"}},[_vm._v("Portal")])],1)],1):_vm._e(),(
      _vm.getCurrentGap.consumer_forecast &&
      !_vm.$route.path.includes('/ConsumerForecast/object-search')
    )?_c('span',[_c('router-link',{attrs:{"to":{ name: 'ObjectSearch' }}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white"},attrs:{"elevation":"0"}},[_vm._v("CF Lite")])],1)],1):_vm._e(),(
      _vm.getCurrentGap.dashboards && !_vm.$route.path.includes('/v2/dashboard-list')
    )?_c('span',[_c('router-link',{attrs:{"to":{ name: 'dashboard-list' }}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white"},attrs:{"elevation":"0"}},[_vm._v("Lenses")])],1)],1):_vm._e(),(!_vm.getLoggedUser.anon)?_c('router-link',{attrs:{"to":{ path: '/ConsumerForecast/help?page=general' }}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white"},attrs:{"elevation":"0"}},[_vm._v("FAQ")])],1):_vm._e(),(!_vm.getLoggedUser.anon)?_c(VMenu,{staticStyle:{"max-width":"600px"},attrs:{"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"btn-hover",staticStyle:{"background-color":"transparent","margin":"0em 10em 0em 0em","color":"white"},attrs:{"id":"no-background-hover","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v("Account")])]}}],null,false,956019558),model:{value:(_vm.accountMenu),callback:function ($$v) {_vm.accountMenu=$$v},expression:"accountMenu"}},[_c(VList,[(!_vm.getLoggedUser.anon)?_c(VListItem,{attrs:{"link":""}},[_c('settingsEditor',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var open = ref.open;
return [_c('a',{on:{"click":function($event){return _vm.start(open)}}},[_vm._v("My Account")])]}}],null,false,1057012880),model:{value:(_vm.settingsWrap),callback:function ($$v) {_vm.settingsWrap=$$v},expression:"settingsWrap"}})],1):_vm._e(),_c(VListItem,[_c('a',{on:{"click":function($event){return _vm.loggingOff()}}},[_vm._v("Logout")])])],1)],1):_vm._e(),_c('span',[_c(VIcon,{attrs:{"color":"#FFFF"},on:{"click":function($event){_vm.sourceSwitch=true}}},[_vm._v("mdi-cog-transfer-outline")])],1),_c(VDialog,{attrs:{"width":"450"},on:{"click:outside":function($event){_vm.sourceSwitch=!_vm.sourceSwitch}},model:{value:(_vm.sourceSwitch),callback:function ($$v) {_vm.sourceSwitch=$$v},expression:"sourceSwitch"}},[_c('SourceSwitch',{on:{"closeSourceDialog":function($event){_vm.sourceSwitch=!_vm.sourceSwitch},"updateSource":function($event){return _vm.closeAndReload()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }