<template>
  <div id="chart">
    <apexchart type="bar" height="200" :options="chartOptions" :series="series" @dataPointSelection="marker"></apexchart>
  </div>
</template>

<script> 
import stringFunctions from '@/mixins/stringFunctions';

export default {
  name: 'user-activity',
  props: {parentData: Object, title: String},
  mixins: [ stringFunctions ],
  created() {
    this.init()
  },
  methods: {

    init() {
      let months = [];
      let count = [];
      for (const i in this.parentData) {
        count.push(this.parentData[i].ts_count);
        months.push(this.transMonthString(this.parentData[i].ts_month))
      }
      this.series = [{
        name: 'Event Count',
        data: count
      }]
      this.chartOptions = {...this.chartOptions, ...{
       xaxis: {
         categories: months
       },
       title: {
        text: this.title,
        },
      }}
    },

    marker(event, chartContext, config) {
      console.log(event)
      console.log(chartContext)
      console.log(config)
      console.log(config.dataPointIndex)
      this.$emit( 'monthEmit',config.dataPointIndex)
    },
  },
  data() {
    return {
      series: [],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 300,
        toolbar: {
            show: false
          },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          columnWidth: '45%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      // subtitle: {
      //   text: 'total event count',
      //   offsetX: 15
      // },
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      colors: ['#85a1c1'],
        fill: {
          opacity:1, 
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // title: {
        //   text: 'Event Count'
        // }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    },

    }
  },
}
</script>