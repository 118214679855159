<template>
  <v-container>
    <v-row class="mb-7">
      <v-col
        v-for="(product, i) in trainingList"
        :key="i"
        cols="4">
          <a class="grid-content" v-on:click="viewFile(product.remote_file, product.remote_filename, product.object_uid, product.report_url)">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  class="pa-1 mx-auto"
                  tile
                  height="170"
                  :elevation="hover ? 6 : 1"
                  style="border:3px solid #f3faff; border-radius:12px;">

                  <!-- Card contents -->
                  <div class="vertical-center">
                    <v-container class="grid-title">
                      <div class="vertical-center"><b>{{product.name}}</b></div>
                    </v-container>
                    
                    <v-container class="grid-desc">
                      <div style="padding-top:.3em; padding-bottom:1.5em; padding-right:0em; padding-left:0em;" class="vertical-center">{{ product.description }}</div>
                    </v-container>  
                  </div>
                </v-card>
              </template>
            </v-hover>
          </a> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import processingMixin from '@/mixins/processingMixin';
export default {
  name:'wp-grid',
  props: ['trainingList'],
  mixins: [processingMixin],
  methods: {
    async viewFile (fileName, remote_filename, uid, report_url) {
      this.viewLoading = true;
      let showFile = report_url || fileName;

      if (showFile) {
        window.open(showFile)
      }     
      
      this.viewLoading = false;
      this.logAction({
        event: 'view-guide',
        app_name: 'P',
        page_name: 'portal-more',
        form_name: 'view',
        report_uid: uid,
        url: report_url
      });
    }
  }
}
</script>

<style scoped>
  .grid-content {
    color:#333;
  }
  .grid-title{
    height:70px;
  }
  .grid-desc {
    font-size:.9em;
    background-color:#f3faff;
    border-radius: 0px 0px 10px 10px;
    height:100px;
    width:103%;
    position: relative;
    right:.3em;
  }
  .vertical-center {
      margin: 0;
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
</style>
