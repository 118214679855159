<template>
  <div>
    <v-layout row wrap>
    <v-flex xs2>
      <!--Drawer (consider making hideable) -->
    <v-container style="background-color:#131737; height:100%">
        <AdminDrawer />
    </v-container>
        </v-flex>

    <v-flex xs10 style="padding-right:2em;">
  
        <v-container fill-height  style="min-height:100vh;">
        <v-layout row wrap class="text-center">

          <v-flex xs12 sm12 md8 lg9 xl9>
            <v-container v-if="menuGroup == 0" style="padding-left:2em; padding-top:5em;">

              <!--Tab Btns for Admin (difference is {{ company.name }})-->
              <v-tabs
               v-model="tabs"
              background-color="#f3faff"
              color="#131737"
              >
              <v-tabs-slider></v-tabs-slider>
                <v-tab style="margin-left:1em; margin-right:1em;">
                  <v-icon style="margin-right:.5em;">mdi-account-multiple</v-icon>
                  Users</v-tab>
                  <v-tab>
                  <v-icon style="margin-right:.5em;">mdi-eye</v-icon>
                  Profiles</v-tab>
                  <v-tab>
                  <v-icon style="margin-right:.5em;">mdi-briefcase</v-icon>
                  General</v-tab>
                <v-tab>
                  <v-icon style="margin-right:.5em;">mdi-poll</v-icon>
                  Reports</v-tab>
                  
              </v-tabs>
              <v-card
                style="background-color:transparent; float:right; position:relative; bottom:7em; padding-right:1em;"
                elevation="0"
                >
                <v-autocomplete
                  dense
                  rounded
                  outlined
                  style="border-radius:2px; position:relative; left:.8em;"
                  @change="getReports()" 
                  v-model="assumeCompany" 
                  item-value="organization_uid" 
                  item-text="name" 
                  :items="getOrgList" 
                  placeholder="SELECT ORGANIZATION"
                ></v-autocomplete>
              </v-card>

              <v-tabs-items v-model="tabs">
    
                <v-tab-item>
                  <UserMgmt :key="assumeCompany" :parentAssume="assumeCompany"  @loaded="tableLoader" @error="assumeError" />
                  <span v-if="assumError"> 
                    400 error
                  </span>
                </v-tab-item>

                <v-tab-item>
                  <div>
                  <GapManager :parentData="assumeCompany" :key="assumeCompany" @newGap="switchTab" />
                  </div>
                </v-tab-item>

                <v-tab-item>
                  <GenSettings :parentData="assumeCompany" :key="assumeCompany" />
                </v-tab-item>

               
                <v-tab-item>
                  <div style="height:700px;">

                  <div v-if="this.reports">
                    <v-text-field
                      v-model="reportSearch"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      style="padding-left:.6em; padding-top:2em;"
                      >
                    </v-text-field>
                  <v-data-table
                      :headers="reportHeaders"
                      :items="this.reports"
                      :search="reportSearch"
                      :footer-props="{
                        'items-per-page-options': [10]
                      }"
                      class="elevation-0"
                      style="padding-top:2em;"
                    >
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="openUserStats(item)"
                       >
                        mdi-chart-box
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        :loading="viewLoading"
                        @click="viewReport(item)">
                        mdi-application-export
                      </v-icon>
                    </template>
                    </v-data-table>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
           
          </v-container>

          <v-container v-if="menuGroup == 1" style="padding-left:2em; padding-top:1em;">

            <v-tabs
              v-model="orgTab"
              background-color="#f3faff"
              color="#131737"
              >
              <v-tabs-slider></v-tabs-slider>
                <v-tab style="margin-left:1em; margin-right:1em;">
                  <v-icon style="margin-right:.5em;">mdi-briefcase-plus</v-icon>
                  Create Marketing Access
                </v-tab>
                <v-tab>
                  <v-icon style="margin-right:.5em;">mdi-eye-plus</v-icon>
                  Create Group Access Profile
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="orgTab">

                <v-tab-item>

                  <v-row style="width:100%; padding:3em 3em 3em 3em;" justify="center">
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    style="width:350px;"
                  >

                    <v-snackbar
                        :value="orgNameError"
                        :timeout="2400"
                        color="#DB5040"
                        elevation="24"
                        centered
                        absolute
                        light
                    >
                      Organization already exists.
                    </v-snackbar>

                    <v-autocomplete
                      v-model="cms_company_uid"
                      :items="companies"
                      item-text="customerCompany"
                      item-value="uid"
                      placeholder="Find Company in CMS"
                      label="*not required"
                      clearable
                      @change="orgName = orgNamePlaceholder"
                    >
                    </v-autocomplete>

                    <v-text-field
                      v-model="orgName"
                      :rules="nameRules"
                      label="Organization name"
                      :placeholder="orgNamePlaceholder"
                      required
                     
                    ></v-text-field>
                    
                      <v-select
                        v-model="orgRoles"
                        :items="rolesArray"
                        item-text="name"
                        item-value="role"
                        :rules="nameRules"
                        label="Available roles"
                        required
                        multiple
                      >
                        <template slot="item" slot-scope="data">
                        <v-list-item-group>
                            <v-list-item disabled>
                              <v-list-item-content>
                                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ data.item.description }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                          </v-list-item-group>
                         
                        </template>
                      </v-select>
                     
                    
                    <v-btn
                      style="margin-top:2em; margin-bottom:1em; background-color:#E3F2FD;  border-radius:25px; float:right;"
                      elevation="0"
                      class="mr-4 create-btn"
                      @click="createOrg()"

                    >
                    create <v-icon x-small style="margin-top:.2em;">mdi-plus</v-icon>
                    </v-btn>  
                  </v-form>
                </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row v-if="!mChosen" justify="center">
                    <span style="padding-top:2em;">Do you want to create a Preview Access GAP?</span>
                    <span style="position:relative; top:1.5em; margin-left:1em;"><v-btn class="yesNo" :style="{backgroundColor: this.yColor, borderRadius: '50px'}" elevation="0" @click="mChoose(true)">Yes</v-btn><v-btn class="noYes" :style="{backgroundColor: this.nColor, borderRadius: '50px'}" elevation="0" @click="mChoose(false)">No</v-btn></span>
                  </v-row>

                  <v-row style="width:100%; padding:1em 1em 1em 6em;" justify="center" v-if="mChosen">
                     <span style="position:absolute; right:1em;"><v-btn @click="resetChoice" class="create-btn" style="background-color:#E3F2FD; border-radius:25px;" elevation="0"><v-icon x-small style="margin-top:.5em; margin-right:.7em;">mdi-keyboard-return</v-icon> reset </v-btn></span>
                    <!-- Reached when "no" is chosen when asked about preview access GAP. "Marketing" is preview access GAP-->
                    <span  v-if="!marketing">
                    <v-form lazy-validation v-model="valid2" ref="gapForm">
                      <!-- Will automatically populate with new org created if new org is created -->
                      <span style="padding:1em;"><v-autocomplete required :rules="nameRules" @change="foundOrg()" v-model="gapOrg" item-value="organization_uid" item-text="name" :items="getOrgList" placeholder="Find Organization" dense style="width:420px; height:20px;"></v-autocomplete></span>
                      <span v-if="gapOrg">

                        <!-- Should check if name already exists -->
                        <v-text-field v-model="gapName" dense required :rules="nameRules" style="width:420px;" hint="Add Name" :label="selectedName.name+':'"></v-text-field>
                        
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field dense :append-outer-icon="plusOne" @click:append-outer="toggleNext1" v-model="emailDomain" :rules="nameRules" style="width:420px;" hint="'domain.com'" label="Email Domain:" placeholder="@"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" v-if="next1">
                            <v-text-field dense v-model="emailDomain2" :append-outer-icon="plusTwo" @click:append-outer="toggleNext2" style="width:420px;" hint="'domain.com'" label="Second Email Domain:" placeholder="@"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" v-if="next2">
                            <v-text-field dense v-model="emailDomain3"  style="width:420px;" hint="'domain.com'" label="Third Email Domain:" placeholder="@"></v-text-field>
                          </v-col>
                        </v-row>
                        <!-- <v-row>
                          <v-text-field required :rules="dateRules" style="padding:0em 1em 1em .8em; max-width:225px;" hint="YY-MM-DD" label="Start Date"></v-text-field>
                          <v-text-field required :rules="dateRules" style="padding:0em 1em 2em .8em; max-width:225px;" hint="YY-MM-DD" label="End Date"></v-text-field>
                        </v-row> -->
                        <v-row style="position:relative; bottom:2em; width:660px;">
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >

                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          style="margin-bottom:3em;"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="startDate"
                              
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              
    
                              style="width:225px;"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="startDate"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >

                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          style="margin-bottom:3em;"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="endDate"
                              label="End Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              
                              style="width:225px;"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="endDate"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                        </v-col>
                        </v-row>

                        <v-row style="padding-top:0em; position:relative; bottom:2em;">
                          <span style="float:left; font-size:1.1em; padding-left:1em;">Entitlements:</span>
                        </v-row>
                        
                        <span style="position:relative; bottom:3em; left:2em;">
                          
                          <v-radio-group dense row>

                          <v-checkbox
                            dense
                            v-model="mi"
                            :label="'Market Insights Access'"
                            color="#283593"
                            value="true"
                            hide-details
                            style="margin-right:1em;"
                          ></v-checkbox>
                         

                          <v-checkbox
                            dense
                            v-model="fi"
                            label="Follower Insights Access"
                            color="#283593"
                            value="true"
                            hide-details
                            style="margin-right:1em;"
                          ></v-checkbox>
                          </v-radio-group>

                          <span style="position:relative; bottom:2em;">
                          <v-checkbox
                            dense
                            v-model="portal"
                            label="Portal Access"
                            color="#283593"
                            :value="true"
                            hide-details
                          ></v-checkbox>

                          <v-checkbox
                            dense
                            v-model="cFL"
                            label="CF Lite Access"
                            color="#283593"
                            :value="true"
                            hide-details
                          ></v-checkbox>
                          
                          <span v-if="cFL">
                            <v-checkbox
                              dense
                              v-model="cFExports"
                              label="CF Lite data exports"
                              color="#283593"
                              value="true"
                              hide-details
                              style="margin-left:2em;"
                            ></v-checkbox>
                            </span>

                          <v-checkbox
                            dense
                            v-model="dashboard"
                            label="Lenses"
                            color="#283593"
                            :value="true"
                            hide-details
                          ></v-checkbox>

                          <v-checkbox
                            dense
                            v-model="bpm"
                            label="Brand Maps"
                            color="#283593"
                            :value="true"
                            hide-details
                          ></v-checkbox>

                          </span>

                          <span style="position:relative; left:1em;">
                          <v-row>
                             <span style="float:left; padding-top:0em; font-size:1em;">Sources:</span>

                             <v-radio-group dense row style="position:relative; right:3.5em; padding-top:0em;">
                                <v-checkbox
                                  v-for="(item,idx) in source" :key="idx"
                                  style="margin-left:1em;"
                                  dense
                                  color="#FFCA28"
                                  v-model="sources"
                                  hide-details
                                  :label="item.name"
                                  :value="item"
                                ></v-checkbox>
                                </v-radio-group>
                          </v-row>
                          <v-row v-if="sources.length>1">
                            <span>Are vertical entitlements the same across sources?</span> <span style="position:relative; bottom:.5em; margin-left:1em;"><v-btn class="yesNo" :style="{backgroundColor: this.yColor, borderRadius: '50px'}" elevation="0" @click="yesNoClick">Yes</v-btn><v-btn class="noYes" :style="{backgroundColor: this.nColor, borderRadius: '50px'}" elevation="0" @click="noYesClick">No</v-btn></span>
                          </v-row>
                          <!-- {{same}}{{sources.length == 1}} -->

                          <v-row v-if="sources.length == 1 || same" style="padding-top:1em;">
                            <span style="float:left; padding-top:0em; font-size:1em;"><span v-for="(item, idx) in sources" :key="idx"><span v-if="sources.length == 1">{{item.name}}</span><span v-else><span v-if="idx == 0">{{item.name}}</span><span v-else>, {{item.name}}</span></span></span>
                              Verticals:</span>
                         
                              <v-radio-group row style="position:relative; bottom:2em;">
                                <v-checkbox
                                  v-for="(item,idx) in domains" :key="idx"
                                  style="margin-left:1em;"
                                  dense
                                  color="#FFCA28"
                                  v-model="cFDomains"
                                  :label="item.name"
                                  :value="item.sid"
                                  hide-details
                                ></v-checkbox>
                                </v-radio-group>
                          </v-row>

                          <span v-if="answered" style="position:relative; left:1em; padding-top:1em;">
                          <v-row v-if="!same & sources.length > 1">
                            <span>
                              <v-row v-for="(source,idx) in sources" :key="idx">
                                <span>{{source.name}}</span>
                               
                                <v-radio-group row style="position:relative; bottom:2em;">
                                <v-checkbox
                                  v-for="(item,i) in domains" :key="i"
                                  style="margin-left:1em;"
                                  dense
                                  color="#FFCA28"
                                  @change="otherSources(source, item)"
                                  v-model="sources[idx].verts"
                                  :label="item.name"
                                  :value="item.sid"
                                  hide-details
                                ></v-checkbox>
                                </v-radio-group>
                              </v-row>
                            </span>
                          </v-row>
                          </span>

                          </span>         
                          
                        </span>
                        <v-btn class="create-btn" style="float:right; background-color:#E3F2FD; border-radius:25px; position:relative; bottom:4em;" elevation="0" @click="next">Next <v-icon style="margin-left:.2em; margin-top:.1em;" small>mdi-arrow-right-thin-circle-outline</v-icon></v-btn>
                      </span>
                    </v-form> 
                  </span>

                  <span v-if="marketing">
                    <!-- <span style="position:absolute; left:1em;"><v-btn @click="mChosen=false" class="create-btn" style="background-color:#E3F2FD; border-radius:25px;" elevation="0"><v-icon x-small style="margin-top:.5em; margin-right:.7em;">mdi-keyboard-return</v-icon> reset </v-btn></span> -->
                    <span style="padding:1em;"><v-autocomplete required :rules="nameRules" @change="foundOrg()" v-model="gapOrg" item-value="organization_uid" item-text="name" :items="getOrgList" placeholder="Find Organization" dense style="width:420px; height:20px;"></v-autocomplete></span>
                    
                    <span v-if="gapOrg">
                      <v-form lazy-validation v-model="valid2" ref="gapForm">
                      <v-text-field v-model="gapName" dense required :rules="nameRules" style="width:420px;" hint="Add Name" :label="selectedName.name+':'"></v-text-field>


                        <v-row style="padding-top:0em; position:relative; bottom:0em;">
                          <span style="float:left; font-size:1.1em; padding-left:1em;">Entitlements:</span>
                        </v-row>
                        <v-row style="margin-left:0em; position:relative; bottom:.5em;">
                          <!-- <v-checkbox
                            dense
                            v-model="portal"
                            label="Portal Access"
                            color="#283593"
                            value="true"
                            hide-details
                            style="margin-right:1em;"
                          ></v-checkbox> -->

                          <v-checkbox
                            dense
                            v-model="cFL"
                            label="CF Lite Access"
                            color="#283593"
                            value="true"
                            hide-details
                          ></v-checkbox>
                          
                          <span v-if="cFL">
                            <v-checkbox
                              dense
                              v-model="cFExports"
                              label="CF Lite data exports"
                              color="#283593"
                              value="true"
                              hide-details
                              style="margin-left:2em;"
                            ></v-checkbox>
                          </span>

                          <v-checkbox
                            dense
                            v-model="dashboard"
                            label="Lenses"
                            color="#283593"
                            :value="true"
                            hide-details
                          ></v-checkbox>


                          <v-checkbox
                            dense
                            v-model="bpm"
                            label="Brand Maps"
                            color="#283593"
                            :value="true"
                            hide-details
                          ></v-checkbox>

                        </v-row>
                        <v-row style="padding-top:0em; position:relative; top:.5em;">
                          <span style="float:left; font-size:1.1em; padding-left:1em;">Instagram Verticals:</span>
                        </v-row>
                        <v-row>
                          <v-radio-group row style="position:relative; bottom:1em;">
                            <v-checkbox
                              v-for="(item,idx) in domains" :key="idx"
                              style="margin-left:1em;"
                              dense
                              color="#FFCA28"
                              v-model="cFDomains"
                              :label="item.name"
                              :value="item.sid"
                              hide-details
                            ></v-checkbox>
                            </v-radio-group>
                        </v-row>
                        <v-btn class="create-btn" style="float:right; background-color:#E3F2FD; border-radius:25px;" elevation="0" @click="next">Next <v-icon style="margin-left:.2em; margin-top:.1em;" small>mdi-arrow-right-thin-circle-outline</v-icon></v-btn>
                      </v-form>

                    </span>
                  </span>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>

          </v-container>

          </v-flex>

          <v-flex xs12 sm12 md4 lg3 xl3>
            <v-container style="text-align:left; padding-top:1em;">
              <v-card
                class="mx-auto lighten-5"  
                style="background-color:#f3faff; border-radius:0px; padding-bottom:1em;"
                elevation="0"
              >
              <v-list rounded style="background-color:transparent;">
                <v-subheader>MENU</v-subheader>
                <v-list-item-group
                    mandatory
                  color="primary"
                  v-model="menuGroup"
                >
                  <v-list-item link>
                    <v-list-item-icon>
                      <v-icon>mdi-gesture-double-tap</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Manage </v-list-item-title>
                      <v-list-item-subtitle>Edit orgs, profiles and users.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link v-if="getAccess.includes('internal_admin')">
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Give Access</v-list-item-title>
                      <v-list-item-subtitle>Create orgs and profiles.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </v-list-item-group>
              </v-list>

            </v-card>

            <v-dialog v-model="gapFinish" max-width="720">

              <v-card v-if="!createToken" style="width:720px; height:550px;">
                <v-card-title>
                  Review 
                </v-card-title>
                <v-card-text>
                  <span><b>GAP Name:</b><br></span><span v-if="this.gapOrg">{{this.selectedName.name}}: {{this.gapName}}</span>
                </v-card-text>
                <v-card-text v-if="this.email_domain">
                  <span><b>GAP Email Domain:</b><br></span><span v-if="this.gapOrg">
                    <span v-if="this.emailDoms.length > 1">{{this.emailDoms.join(", ")}}</span>
                    <span v-else>{{this.emailDoms.join("")}}</span>
                  </span>
                </v-card-text>
                <v-card-text v-if="this.startDate">
                  <span><b>Access Duration:</b></span>
                  <br>
                  <span>{{transDateString(this.startDate)}} - {{transDateString(this.endDate)}}</span>
                </v-card-text>
                <v-card-text>
                  <span><b>Entitlements:</b>
                    <span v-if="mi"><br><v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon>Market Insights Access</span>
                    <span v-if="fi"><br><v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon>Follower Insights Access</span>
                    <span v-if="portal"><br><v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon>Portal Access <span style="font-size:.8em;"></span></span>
                    <span v-if="cFL"><br><v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon>CF Lite Access</span>
                    <span v-if="cFExports"><br><v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon>CF Lite Exports</span>
                    <span v-if="dashboard"><br><v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon>Lenses</span>
                    <span v-if="bpm"><br><v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon>Brand Maps</span>
                    <span v-if="cFDomains.length"><br><br><b>Domains ({{this.cFDomains.length}}):</b> <span>{{this.translateDomains(cFDomains)}}</span></span>

                    <!--<span v-if="cFDomains.length"><br><br><b>Source-Domains ({{this.cFDomains.length}}):</b> <span>{{this.translateDomains(cFDomains)}}</span></span-->
                    <span v-if="!same && sources.length > 1"><br><br>
                      <span v-for="(source,idx) in sources" :key="idx">
                        <v-row style="margin-left:0em;">
                          <v-icon small style="margin-right:.2em;">mdi-check-underline</v-icon><b>{{source.name}}:</b>
                        </v-row>
                        <v-row style="margin-left:1.5em;">
                          {{translateDomains(source.verts)}}
                        </v-row>
                        
                      </span> 
                    </span>
                  </span>
                </v-card-text>
                <v-card-text>
                  <v-btn @click="createGAP()" class="create-gap-btn" style="position:absolute; bottom:2em; right:2em; background-color:#E3F2FD; border-radius:25px;" elevation="0">Create GAP <v-icon style="margin-left:.2em;" small>mdi-plus-circle-outline</v-icon></v-btn>
                </v-card-text>
              </v-card>

              <v-card v-else style="width:720px; height:255px;">
                <span v-if="!yesToken">
                <v-card-text style="text-align:center; padding:3em;">
                  Do you want to add an Anonymous User Token for this Profile?
                </v-card-text>
                <v-card-text>
                  <v-row justify="center">
                    <v-btn @click="notNow()" class="create-btn" style="background-color:#E3F2FD; border-radius:25px; margin-right:.5em;" elevation="0">Not Now</v-btn><v-btn @click="yesToken=true" elevation="0" class="create-btn" style=" margin-left:.5em; background-color:#A5D6A7; border-radius:25px;"> Yes <v-icon small style="margin-top:.1em;">mdi-arrow-right-thin</v-icon></v-btn>
                  </v-row>
                </v-card-text>
                </span>

                <v-card-text v-if="yesToken">
                  <div style="padding:1em;">
                  <v-form lazy-validation ref="anonForm">
                    <v-text-field v-model="anonName" label="Add name/description"></v-text-field>
                    <v-radio-group row
                      v-model="defaultEx"
                      mandatory
                    >
                      <v-radio
                        label="Default Expiry (7 days)"
                        value="default"
                      ></v-radio>
                      <v-radio
                        label="Other"
                        value="other"
                      ></v-radio>
                    </v-radio-group>

                    <span v-if="defaultEx == 'other'" style="position:relative; bottom:1em;">
                      <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="expiryDate"
                              label="Expiry Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              required
                              :rules="dateRules"
                              style="width:225px;"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="expiryDate"
                            @input="menu3 = false"
                          ></v-date-picker>
                        </v-menu>
                    </span>
                    <v-btn @click="createAnonToken()" class="create-btn" elevation="0" style="background-color:#E3F2FD; border-radius:25px; position:absolute; bottom:2em; right:2em;">Create <v-icon small>mdi-plus</v-icon></v-btn>
                  </v-form>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-dialog v-model="userStats" max-width="800">
              <v-card class="mx-auto" style="height:100%; width:800px; padding: 1em 1em 4em 1em; ">
                <v-card-title>
                  <v-icon
                    small
                    class="mr-2"
                    @click="userStats=false"
                    style="position:absolute; top:1em; right:.5em;">
                    mdi-close
                  </v-icon>
                </v-card-title>
                <span v-if="userStatsLoaded">
                <v-card-text>
                  <div style="padding-left:1em; padding-right:1em;">
                    <UserActivity :parentData="monthAnon" :title="'Portal Report Views'" :key="monthAnon" />
                  </div>
                </v-card-text>

                <v-card-text>
                  <div v-for="(item, idx) in reportClickUsers" :key="idx">
                    <span style="margin-left:1em;">{{item.account_name}}</span><span style="margin-left:1em;" v-if="item.page_name === 'company-management-page'">Admin View</span><span v-else>Customer Portal</span> <span style="margin-left:1em;">{{transDateString(item.ts)}}</span>
                  </div>
                </v-card-text>
                </span>               
              </v-card>
            </v-dialog>

            
          </v-container>
          </v-flex>

      </v-layout>
    </v-container>
    </v-flex>
    </v-layout>

    <div >
<!--      <v-snackbar
          v-model="gapNameError"
          :timeout="timeout"
          color="#DB5040"
          elevation="24"
      >
        GAP name is already in use
      </v-snackbar>-->

    </div>

  </div>  
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import reportFilteringFunctions from '@/mixins/reportFilteringFunctions';
  import processingMixin from '@/mixins/processingMixin';
  import roleFunctions from '@/mixins/roleFunctions';
  import stringFunctions from '@/mixins/stringFunctions';
  import objectFunctions from "@/mixins/objectFunctions";
  import logQueries from '@/mixins/logQueries';

  import AdminDrawer     from '@/components/Navigation/AdminDrawer';
  import UserMgmt        from '@/components/AdminMgmt/UserMgmt.vue';
  import GapManager      from '@/components/AdminMgmt/GapManager.vue';
  import GenSettings     from '@/components/AdminMgmt/GenSettings.vue';
  import UserActivity    from '@/components/AdminMgmt/UserActivity.vue';

  export default {
    name: 'company-management-page',
    mixins: [processingMixin, reportFilteringFunctions, roleFunctions, stringFunctions, logQueries],
    components: {
      AdminDrawer,
      UserMgmt,
      GapManager,
      GenSettings,
      UserActivity
    },
    data () {
      return {
        createOpen: false,
        gapFinish: false,
        marketing: null,
        mChosen: true,    // let's hide option for preview access gap menu
        userStats: false,
        userStatsLoaded: false,
        next1: false,
        next2: false,
        defaultEx: null,
        otherEx: '',
        expiryDate: '',
        anonName: '',
        form: this.$options.name,
        tabs: 0,
        addUsers: true,
        menuGroup: 0,
        yColor: 'transparent',
        nColor: 'transparent',
        gapOrg: '', // Org ID
        searchedReports: '' ,
        selectedVertical: '',
        viewLoading: false,
        assumeCompany: '',
        reportSearch: '',
        justCreatedGap: {},
        orgRoles: '',
        gapName: '',
        orgTab: 0,
        cFL: '',
        emailDomain2: '',
        emailDomain3: '',
        plusOne: 'mdi-plus',
        plusTwo: 'mdi-plus',
        mi: false,
        miD: false,
        miDomains: [],
        cFDomains: [],
        cFExports: false,
        createToken: false,
        yesToken: false,
        sources: [],
        reportClickUsers: {},
        fi: false,
        portal: false,
        dashboard: false,
        bpm: false,
        reports: '',
        tokenStats: {},
        loader: false,
        parent: {},
        startDate: '',
        cms_company_uid: '',
        endDate: '',
        menu: false,
        emailDomain: '',
        email_domain: '',
        same: false,
        answered: false,
        assumError: '',
        activityFilter: '',
        monthAnon: {},
        justCreatedOrg: '',
        reportHeaders: [
          {text: 'Publishing Date', align: 'start', sortable: true, value: 'pub_date'},
          {text: 'Name', sortable: true, value: 'name'},
          {text: 'Category', value: 'category_name'},
          {text: '', value: 'actions', sortable: false },
        ],
        rolesArray: [
          {role: 'portal_user', name: 'Web Access', description: 'Able to access web properties.'},
          {role: 'org_admin', name: 'Billing Administrator Access', description: 'External user with ability to manage organization settings and access the web properites.'},
          {role: 'user_admin', name: 'User Administrator Access', description: 'External user with ability to manage org users and access the web properites.'},
        ],
        source: [
          {name: 'Instagram', value: 1, verts: []},
          {name: 'Twitter', value: 3, verts: []},
          {name: 'Reddit', value: 5, verts: []},
          // {name: 'Weibo', value: 4},
          // {name: 'News', value: 6},
          {name: 'TikTok', value: 7, verts: []},
        ],
        domains: objectFunctions.domains,
        valid: true,
        valid2: true,
        fullVerticals: [],
        orgName: '',
        nameRules: [
          v => !!v || 'Field is required'
        ],
        dateRules: [
          v => !!v || 'Field is required',
        ],
        //gapNameError: false,
        orgNameError: false,
      }
    },
    created () {      
      this.getWorkProducts(this.loggedUser.company_uid);
      this.getCompanies();
      this.getOrgs()
      console.log(this.getOrgList)

      this.logPageLoad({
        app_name: 'P',
        page_name: this.$options.name,
      })   
      
    },

    computed: {
      ...mapState([
        'loggedUser',
        'workProducts',
        'downloadLoading',
        'viewLoading',
        'company',
        'companies',
        'verticals',
      ]),
      ...mapGetters([
        'getOrgList',
        'isLoggedIn',
        'isAdmin',
        'getAccess'
      ]),
      ...mapActions([
        'getOrgs',
      ]),

      orgNamePlaceholder() {
        let name = ''
        if (this.cms_company_uid) {
          let item = this.companies.filter(x => x.uid === this.cms_company_uid)
          console.log(item)
          name = item[0].customerCompany
          
        }
        return name
      },

      selectedName() {
        if (this.justCreatedOrg) {
          return this.justCreatedOrg
        }
        else {
          console.log(this.gapOrg)
          let item = this.getOrgList.filter(x => x.organization_uid == this.gapOrg)
          console.log(item)
          return item[0]
        }
      },

      emailDoms() {
        let domains = []
        console.log(this.emailDomain)
        console.log(this.emailDomain2)
        if (this.emailDomain2) {
          domains.push(this.emailDomain);
          domains.push(this.emailDomain2)
          if (this.emailDomain3) {
            domains.push(this.emailDomain3)
          }
        }
        else {domains.push(this.emailDomain)}
         return domains
      },

      matchSource(source) {
        let list
        if (source.includes('eddit')) {
          list = this.redditVerticals
        }
        else if (source.includes('gram')) {
          list = this.instaVerticals
        }
        else if (source.includes('witter')) {
          list = this.twitterVerticals
        }
        else if (source.toLowerCase().includes('iktok')) {
          list = this.tikTokVerticals
        }
        return list
      },
      

    },


    methods: {
      ...mapActions([
        'getOrgs',
        'getWorkProducts',
        'downloadAs',
        'downloadItem',
        'setTabs',
        'assumeOrg',
        'getAssumedOrgUsers',

        'postOrganization',
        'postGAP',
        'postAnonToken',
        'getCompanies',
        'queryLogs'

      ]),

      ...mapGetters([
        'isLoggedIn',
        'isAdmin'
      ]),

      resetWithOrg(currentOrg) {
        console.log("resetting with " + currentOrg)
        this.resetChoice()
        this.createToken = false
        this.gapFinish = false
        this.yesToken = false
        this.anonName = ''
        this.defaultEx = null
        this.expiryDate = null
        this.assumeCompany = currentOrg
        this.getReports()
        this.menuGroup = 0    // switch to Manage
        this.tabs = 1
      },

      openUserStats(item) {
        this.userStats = true;
        this.tokenStats = item;
        this.activityFilter = 'all',
        console.log(item);
        this.activity(item);
        this.users(item)
      },

      activity: async function(item) {
        let result
        result = this.reportEventCount(item)
        console.log(result)

        await this.queryLogs(result).then((data)=> {
          console.log(data)
          this.monthAnon = data.data.log_data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },

      users: async function(item) {
        let result = this.reportUsers(item);
        console.log(result);

        await this.queryLogs(result).then((data)=> {
          console.log(data);
          this.reportClickUsers = data.data.log_data
          this.userStatsLoaded = true
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },


      notNow() {
        this.createToken = false;
        //location.reload()
        this.resetWithOrg(this.gapOrg)
      },

      toggleNext1() {
        if (this.next1 == false) {
          this.next1 = true;
          this.plusOne = 'mdi-minus'
        }
        else {
          this.next1 = false;
          this.plusOne = 'mdi-plus'
        }
      },

      toggleNext2() {
        if (this.next2 == false) {
          this.next2 = true;
          this.plusTwo = 'mdi-minus'
        }
        else {
          this.next2 = false;
          this.plusTwo = 'mdi-plus'
        }
      },

      switchTab() {
        this.orgTab = 1
        this.menuGroup = 1
        this.gapOrg = this.assumeCompany
      },


      otherSources(source, item) {
        this.fullVerticals.push({source: source, vert: item})
        //console.log(array)
      },


      assumeError(value) {
        this.assumError = true;
        this.loader = false;
        console.log('assume', value)
      },

      resetChoice() {
        // this.mChosen = false;
        this.gapName = '';
        this.emailDomain = '';
        this.emailDomain2 = '';
        this.emailDomain3 = '';
        this.startDate = '';
        this.endDate = '';
        this.mi = false;
        this.fi = false;
        this.cFL = false;
        this.portal = false;
        this.dashboard = false;
        this.bpm = false;
        this.sources = [];
        this.cFDomains = [];
        this.answered = false;
        this.marketing = null;
        this.yColor = 'transparent';
        this.nColor = 'transparent';
        this.same = false;
      },

      translateDomains(sids) {
        let items = []
        for(let sid of sids){
          items.push(objectFunctions.marketVerticals[sid])
        }
        if (items.length > 1) {return items.join(", ")}
        else {return items.join("")}
      },

      yesNoClick() {
        this.same = true;
        this.yColor = '#C8E6C9';
        this.nColor = 'transparent';
        this.answered = true;
      },

      noYesClick() {
        this.same = false;
        this.yColor = 'transparent';
        this.nColor = '#FFE0B2';
        this.answered = true;
        this.cFDomains = []

      },

      mChoose(val) {
        if (this.justCreatedOrg) {
          this.gapOrg = this.justCreatedOrg["organization_uid"]
        }
        this.mChosen = true;
        this.marketing = val;
      },

      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
        this.orgRoles = ''
      },

      createToggle() {
        if (this.createOpen == false) {
          this.createOpen = true;
        }
        else {
          this.createOpen = false;
        }
      },

      createAnonToken: async function() {
        console.log(this.expiryDate)
        console.log(this.defaultEx)
        console.log(this.otherEx)
        console.log(this.anonName)
        console.log(this.gapOrg)

        if (this.expiryDate == '') {
          this.expiryDate = null
        }

        let payload = {
          org_uid: this.justCreatedGap.organization_uid,
          gap_uid: this.justCreatedGap.gap_uid,
          data: {
            note: this.anonName,
            expiry: this.expiryDate
          }
        }
        console.log(payload)
        await this.postAnonToken(payload).then((data) => {
          console.log(data)
        }).then(() => {
          // location.reload()
          this.resetWithOrg(this.gapOrg)
        }) 
        .catch((err)=> {
          console.log(err)
        }) 

      },

      autoCheck(role) {
        console.log(role)
        if (role == 'portal_user') { return true }
        else { return false}
      },

      // toDO: Prevent user from creating duplicate org name.
      createOrg: async function() {
        this.validate()

        // check if orgName is in use
        let existingOrgs = this.getOrgList
        if (existingOrgs.map(x => x.name).includes(this.orgName)) {
          console.log(this.orgNameError)
          console.log("Org already exists")
          this.orgNameError = true
          console.log(this.orgNameError)
        }
        else {
          if (this.orgName && this.orgRoles) {
            console.log(this.orgRoles)
            console.log(this.orgName)
            let roles = []

            for (const i in this.orgRoles) {
              if (!isNaN(i)) {
                let auto = this.autoCheck(this.orgRoles[i])
                roles.push({
                  role: this.orgRoles[i],
                  is_auto_assigned: auto
                })
              }
            }

            if (!this.orgRoles.includes('user_admin')) {
              roles.push({role: 'user_admin'})
            }

            let payload
            payload = {
              name: this.orgName,
              roles: roles
            }
            if (this.cms_company_uid) {
              payload = {
                name: this.orgName,
                roles: roles,
                cms_company_uid: this.cms_company_uid
              }
            }
            console.log(payload)
            await this.postOrganization(payload)
                .then((data) => {
                  console.log(data)
                  this.justCreatedOrg = data.data.organization
                  this.gapOrg = this.justCreatedOrg['organization_uid']
                  this.getOrgList.push(data.data.organization)
                  this.getCompanies()
                })
                .then(() => {
                  this.reset()
                  this.orgTab = 1
                })
                .catch((err) => {
                  console.log(err.response)
                })
          }
        }

      },

      next() {
        this.$refs.gapForm.validate()
        if (this.gapOrg) {
          this.gapFinish = true;
          // this.wrangleSources()
        }
      },

      menuTold(value) {
        this.selectedVertical = value
      },

      tabEmitter(str) {
        this.setTabs(str);
      },

      tableLoader(value) {
        this.loader = value
      },

      foundOrg() {
        console.log(this.gapOrg)
      },

      miDone() {
        this.miD = true;
      },
      miDT() {
        if (this.miD == true) {
          this.miD = false
        }
      },

      // toDo: handle case where GAP name already exists
      createGAP: async function() {
       console.log('cfDomains', this.cFDomains, this.cFDomains.length)
       console.log('sources array', this.sources)
       console.log(this.cFL)
        console.log(this.mi)
        let load = {}

        // when does this happen?
        if (this.cFDomains.length > 0 && this.sources.length >= 1) {
          console.log('if yes')
          for (const i in this.sources) {
            load[this.sources[i].value] = {}
            for (const n in this.cFDomains) {
              load[this.sources[i].value][this.cFDomains[n]] = {
                domain_sid: this.cFDomains[n]
              }
            }
          }
        }
        // if no domains are selected but more than one source is (why would we ever want this though?)
        else if (this.sources.length >= 1 ) {
          console.log('else-if one')
          console.log(this.sources)
          for (const s in this.sources) {
            load[this.sources[s].value] = {}
            for (const n in this.sources[s].verts) {
              console.log(this.sources[s].verts[n])
              load[this.sources[s].value][this.sources[s].verts[n]] = {
                domain_sid: this.sources[s].verts[n]
              }
            }
          }
        }
        // if no sources are selected, assume domains are all Instagram
        else if (!this.sources.length) {
          console.log('early marketing')
          load[1] = {}
          for (const n in this.cFDomains) {
              // adds Instagram source and it's user-selected domains
              load[1][this.cFDomains[n]] = {
                domain_sid: this.cFDomains[n]
              }
            }
        }

        if (!this.startDate && !this.endDate) {
          this.startDate = null;
          this.endDate = null;
        }

        let email = []
        if (this.emailDomain && !this.emailDomain2 && !this.emailDomain3) {
          email.push({ email_domain: this.emailDomain})
        }
        else if (this.emailDomain && this.emailDomain2 && !this.emailDomain3) {
          email.push({email_domain: this.emailDomain}, {email_domain: this.emailDomain2})
        }
        else if (this.emailDomain && this.emailDomain2 && this.emailDomain3) {
          email.push({email_domain: this.emailDomain}, {email_domain: this.emailDomain2}, {email_domain: this.emailDomain3})
        }
        else {
          email = null
        }

        let cfl = this.cFL === true;
        let mI = this.mi === true;
        let p = this.portal === true;
        let fI = this.fi === true;
        let ex = this.cFExports === true;
        let dash = this.dashboard === true;
        let bpulse = this.bpm === true;

        console.log(load)
         let payload = {
           //uid: this.gapOrg["organization_uid"],
           uid: this.gapOrg,
           data: {
              profile_name: this.selectedName.name + ': ' + this.gapName,
              market_insights: mI,
              influencer_insights: fI,
              portal: p,
              consumer_forecast: cfl,
              dashboards: dash,
              brand_pulse: bpulse,
              time_start: this.startDate,
              time_end: this.endDate,
              profile_sources: load,
              profile_email_domains: email,
              allow_data_export: ex
           }
          
        }
        console.log("New gap", payload)
        await this.postGAP(payload).then((data) => {
          console.log(data)
          this.justCreatedGap = data.data.profile
          console.log(this.justCreatedGap)
        })
        .then(() => {
          this.nextSteps()
        })
        .catch((err) => {
          console.log(err.response)
        })
      },

      nextSteps() {
          //console.log('close', this.gapFinish)
          this.createToken = true;
          console.log('create', this.createToken)
          //location.reload()
      },
  
      viewReport: async function (item) {
        //console.log(item)
        this.viewLoading = true;
        let showFile = item.report_url 
        if (showFile) {
          window.open(showFile)
        }     
        this.viewLoading = false;

        let payload = {
          event: 'view-report',
          app_name: 'P',
          page_name: this.$options.name,
          form_name: 'view',
          report_uid: item.object_uid,
          url: item.report_url
        }
        console.log(payload)

        this.logAction(payload)
      },

      getReports: async function () {

        let item = this.getOrgList.filter(o => o.organization_uid === this.assumeCompany)
        console.log(item)
        
        this.assumError = false;
        this.loader = true
        console.log(this.assumeCompany)
        await this.getWorkProducts(this.assumeCompany)
        .then((data)=> 
          this.reports = data.data.workProducts
        )
        .catch((err)=> {console.log(err)})

        for (const r in this.reports) {
          if (this.reports[r].pub_date) {
            this.reports[r].pub_date = this.transDateString(this.reports[r].pub_date)
          }
          else {this.reports[r].pub_date = 'Unpublished'}
        }
      },
    
    }
  }
</script>

<style lang="scss" scoped>

// .theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
//     opacity: 0;
// }
.btn-hover:hover {
  background-color: #f3faff !important;
  border-radius:25px;
  position:relative;
  bottom:.2em;
}

.yesNo:hover {
background-color: #C8E6C9 !important;
}

.noYes:hover {
  background-color: #FFE0B2 !important;
}

#createDrop:hover {
  background-color: #E3F2FD !important;
}

.create-gap-btn:hover {
  position: absolute;
  bottom:2.2em !important;
  right:2em !important;
}
.create-btn:hover {
  position:relative;
  bottom:.2em;
}
.v-list-item:hover {
  background-color: transparent !important;
}
.v-tab:hover:before {
      background-color: transparent;
    }

 #ex {
    position: absolute;
    top:.3em;
    right:.6em;
    color: black;
  }

  .red-text {
    color:red;
  }
  .inviteFormItems {
    position: relative;
    bottom:2em;
  }
  .save-changes {
    position:absolute;
    right:1em; 
    bottom:1em;
  }

  table {
    border-spacing: 2em;
  }
</style>