<template>
  <v-row justify="center">
    <v-hover v-slot="{ hover }">
      <v-card
          outlined
          tile
          class="card"
          :elevation="hover ? 6 : 1"
      >
        <router-link v-if="video.type=='video'" :to="goTo(video.id, video.type)">
          <v-img
              height="200px"
              style="border-bottom: 1px solid #ddd; border-radius: 12px 12px 0px 0px;"
              :src="showImage(video.image)"
          ></v-img>

          <v-card-title style="padding-left:1em; padding-right:1em; word-break: break-word;">
            <v-row justify="center">
              <span style="color:#333; font-size: .85em; font-weight: bold;">{{ video.title }}</span>
            </v-row>
          </v-card-title>
        </router-link>

        <a v-else :href="goTo(video.id, video.type)" target="_blank">
          <v-img
              height="200px"
              style="border-bottom: 1px solid #ddd; border-radius: 12px 12px 0px 0px;"
              :src="showImage(video.image)"
          ></v-img>

          <v-card-title style="padding-left:1em; padding-right:1em; word-break: break-word;">
            <v-row justify="center">
              <span style="color:#333; font-size: .85em; font-weight: bold;">{{ video.title }}</span>
            </v-row>
          </v-card-title>
        </a>

        <v-card-subtitle style="position:relative; bottom:1em;">
          <v-row justify="center">
            {{video.subtitle}}
          </v-row>
        </v-card-subtitle>

        <div style="position:relative; bottom:2.2em;">
          <v-card-text>
            <v-row justify="center">
          <span style="color:#333; text-align:center; padding:0em 1em 0em 1em"><i>
            {{video.summary}}
          </i></span>
            </v-row>
          </v-card-text>
        </div>

        <v-card-actions class="pr-4">
          <router-link v-if="video.type=='video'" :to="goTo(video.id, video.type)">
            <v-btn
                v-if="video.type=='video'"
                color="primary"
                text
                @click="goTo(video.id, video.type)"
                style="position: absolute; bottom: 0; left: 0;"
            >View Video
            </v-btn>
          </router-link>
          <a v-else :href="goTo(video.id, video.type)" target="_blank">
            <v-btn
                color="primary"
                text
                style="position: absolute; bottom: 0; left: 0;"
            >Read Article
            </v-btn>
          </a>
          <v-spacer></v-spacer>

          <!-- transform pub_date to MMM DD, YYYY via mixin stringFunctions.transDateString() -->
          <div style="position: absolute; bottom: 0; right: 0; padding:.8em;" class="caption"> {{video.date}}</div>

        </v-card-actions>

      </v-card>

    </v-hover>

  </v-row>
</template>

<script>

import placeholderImage from '@/assets/images/placeholder.png';

export default {
  name: "MediaCard",
  props: ['video'],
  methods: {
    goTo(id, type) {
      if (type === 'video') {
        //this.$router.push({path: '/player?video=' + id})
        return '/player?video=' + id
      }
      else {
        //window.open(id,'_newtab' + Date.now());
        return id
      }

    },
    showImage (img) {
      return img ? require("@/assets/video-covers/" + img) : placeholderImage
    },
  },
}
</script>

<style scoped>

.card {
  min-width:570px;
  max-width:570px;
  height:460px;
  border-radius: 14px 14px 12px 12px;
}

.card:hover {
  position:relative;
  bottom:.2em;
}

@media only screen and (max-width: 600px) {
    .card {
      min-width:400px;
      max-width:400px;
      height:400px;
    }
}

</style>