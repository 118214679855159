<template>
  <div>
    <v-container>
      <v-row justify="center">
       <apexchart width="360" type="donut" :options="chartOptions" :series="series"></apexchart>
      </v-row>
    </v-container>
        
    </div>
</template>

<script>
export default {
  name:'demo-pie', 
  props: {
    parentDemo: Object,
    parentDomain: String,
  },

  created() {
    this.deconstruct(this.parentDemo)
  },

  data() {
    return {
      series: [],
      chartOptions: {
        labels: [],
        //colors: ['#20246f', '#6c2d6a', '#B83e5D', '#DC722F', '#FFA600', '#ffd032'],
        //colors: ['#20246F', '#9FA8DA', '#C28BB5', '#BD4B80', '#AD1457', '#800F40'],
        colors: ['#274b69', '#85a1c1'],
        chart: {
          type: 'donut',
        },
        dataLabels: {
            enabled: false
          },
          tooltip: {
          enabled: true,
          theme: 'dark',
          y: {
            formatter: function formatter(val) {
              return (val).toFixed(1) + '%'},
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        legend: {
          show: true,
          offsetY: 0,
          position: 'right',
        },
        responsive: [{
          breakpoint: '960',
          options: {
            chart: {
              width: 320
            },
            legend: {
              show: true,
              offsetY: 0,
              position: 'bottom',
            }
          }
        }]
      },
    }
  },

  methods: {

    deconstruct(data) {
      let d = data.demo.domains[this.parentDomain].data
        let percent = []
        let cat = []
        for (const i in d) {
            if (i[0] == 2) {
              percent.push(d[i].percent);
              cat.push(d[i].name);
          }
        }
        this.chartOptions = {...this.chartOptions, ...{
          labels: cat
        }}
        this.series = percent
    },
  },
}
</script>
