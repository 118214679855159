<template>
  <div>
    <v-container fluid class="banner2">
      <div style="width:80%;">
        <h3 class="banner-text">Social Standards empowers brands to <span class="ss_blue">drive innovation, fuel strategy & outsmart the competition</span> with deep, comparative analytics.</h3>
      </div>
      </v-container> 
      <v-container class="pre-product">Explore the data with our  <strong>professional services</strong>
                                       team, or roll up your sleeves and jump into <strong>our suite of insights tools</strong> to uncover insights that serve your business objectives.
      </v-container>
      <v-container style="padding-top:6em; padding-bottom:7em;" id="products-btn">
        <v-layout row wrap>
          <!-- Row Zero: graphics: -->
          <v-flex md1 lg1  hidden-sm-and-down></v-flex>
          
          <v-flex  md4 lg4>
          <img class="center p_graphics" width="220em"  src="@/assets/products-graphics/MarketInsights.png" />
          </v-flex>

          <v-flex md2 lg2  hidden-sm-and-down></v-flex>
          
          <v-flex  md4 lg4 hidden-sm-and-down >
            <img class="center" width="210em"  style="padding:1em;" src="@/assets/products-graphics/workshops_T.png" />
          </v-flex>

          <v-flex md1 lg1  hidden-sm-and-down></v-flex>
          
          <!-- Row One: titles: -->
          <v-flex md1 lg1  hidden-sm-and-down></v-flex>
          
          <v-flex  md4 lg4 hidden-sm-and-down >
          <h3 class="title_studies">Insights Tools</h3>
          </v-flex>

          <v-flex md2 lg2  hidden-sm-and-down></v-flex>
          
          <v-flex  md4 lg4 hidden-sm-and-down >
            <h3 class="title_studies">Professional Services</h3>
          </v-flex>

          <v-flex md1 lg1  hidden-sm-and-down></v-flex>
          
          <!-- Row Two: paragraphs: -->
          <v-flex sm6 md1 lg1  hidden-sm-and-down></v-flex>
            
          <v-flex sm12 md4 lg4 >
            <p class="studies"><span class="hidden-md-and-up">Insights Tools</span><br>Our insights tools allow you to explore traditionally complex, unstructured data in a contextualized way.</p>
          </v-flex>

          <v-flex xs12 md12 lg12 hidden-md-and-up>
            <router-link :to="{name:'products', hash:'#os-product'}"><p class="btn center ss_blue orange_hover btn-txt">Learn More</p></router-link>
          </v-flex>

          <v-flex md2 lg2  hidden-sm-and-down></v-flex>
          
          <v-flex  sm12 hidden-md-and-up>
            <img class="center p_graphics" width="210em"  style="padding:1em;" src="@/assets/products-graphics/workshops_T.png" />
          </v-flex>

          <v-flex sm12 md4 lg4 >
              <p  class="studies"><span class="hidden-md-and-up">Professional Services</span><br>Our team of data analysts work with you to unlock your most pressing business questions with our unique data set.</p>
          </v-flex>

          <v-flex md1 lg1  hidden-sm-and-down></v-flex>

          <!-- Row Three: buttons: -->
          <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 >
            <router-link :to="{name:'products', hash:'#os-product'}"><p class="btn center ss_blue orange_hover btn-txt">Learn More</p></router-link>
          </v-flex>

          <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 hidden-sm-and-down>
            <router-link :to="{name:'products', hash:'#Workshops'}"><p class="btn center ss_blue orange_hover btn-txt">Learn More</p></router-link>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- Section 3: product descriptions: -->
      <v-container class="P_container" id="os-product">
        <v-layout row wrap>
          <v-flex md2 lg2></v-flex>
          
          <v-flex xs12 sm12 md12  hidden-md-and-up style="padding-bottom:1em; padding-top:1em;">
            <img class="center" width="40%;" src="@/assets/products-graphics/MI_T.png" alt="" />
          </v-flex>

          <v-flex xs12 md10 lg10>
            <h1 class="product_name" style="padding-top:1em;">Insights Tools</h1>
          </v-flex>

          <v-flex md2 lg2></v-flex>

          <v-flex md4 lg4>
            <p class="main_description" style="font-size:1.3em;">Our suite of tools give your team direct access to millions of social posts - structured and contextualized, enabling you to perform powerful comparative analytics quickly and efficiently.<br><br></p>
          </v-flex>

          <v-flex md4 lg4 hidden-sm-and-down>
            <img class="case_graphics center" style="padding-top:1em; position:relative; left:2em;" width="90%;" src="@/assets/products-graphics/MI_T.png" alt="" />
          </v-flex>

          <v-flex md2 lg2></v-flex>
        </v-layout>
      </v-container>

      <v-container class="T_container">
        <v-layout row wrap>
          <v-flex md2 lg2></v-flex>
          
          <v-flex md8 lg8 style="text-align:center;">
            <h2 class="product_point" style="padding-top:1.5em;">Market Verticals</h2>
            <p style="font-size:1.1em;">Our platform allows you to analyze consumer conversations through the lens of different market verticals:
                <span v-for="(itm, idx) in this.verticalList" :key="idx">
                <span v-if="idx !== verticalList.length - 1">{{itm.name}}, </span>
                <span v-else> and {{itm.name}}. <br><br></span>
              </span></p>
            <h2 class="product_point">Comparative Analytics</h2>
            <p style="font-size:1.1em;">Compare brands' authentic consumer conversations within product categories to determine what sets them apart from each other and identify which topics provide a competitive advantage.<br><br></p>
            <h2 class="product_point">Conversation Share</h2>
            <p style="font-size:1.1em;">Our brand and product hierarchies allow you to measure brands' conversation volumes relative to their competitors without having to set up boolean queries. Our proprietary technology allows us to capture brands as soon as they're generating significant chatter on social media and place them into relevant categories, meaning you can discover competing brands and products you didn't know existed.<br><br></p>
            <h2 class="product_point">Consumer Motivations</h2>
            <p style="font-size:1.1em;">Understand what consumers talk about when they mention a particular brand or product on social beyond simple sentiment analysis. Dive deeper into the motivations underpinning consumer decision-making, giving you deeper insight into a brand or trend's social equity and its underlying components.<br><br></p>
          </v-flex>

          <v-flex md2 lg2></v-flex>
        </v-layout>
      </v-container>

    
        <v-container class="P_container"  id="Workshops">
          <v-layout row wrap>
            <v-flex md2 lg2></v-flex>
            
            <v-flex xs12 sm12 md12  hidden-md-and-up style="padding-bottom:1em; padding-top:1em;">
              <img class="center" width="35%" src="@/assets/products-graphics/workshops_T.png" alt="" />
            </v-flex>

            <v-flex xs12 md10 lg10 style="padding-top:1em;">
              <h1 class="product_name" style="position:relative; top:1em;">Professional Services</h1>
            </v-flex>

            <v-flex md2 lg2></v-flex>
            
            <v-flex md4 lg4>
              <p class="main_description w-desc">Our data analytics team works with you in a live workshop style, allowing you to explore the data in real time and get the answers to your questions organically as they come up.<br><br></p>
            </v-flex>
    
            <v-flex md4 lg4 hidden-sm-and-down>
              <img class="center" width="55%" style="position:relative; left:2em; position:relative; bottom:3em;" src="@/assets/products-graphics/workshops_T.png" alt="" />
            </v-flex>

            <v-flex md2 lg2></v-flex>
          </v-layout>
        </v-container>


        <v-container class="T_container">
          <v-layout row wrap>
            <v-flex md2 lg2></v-flex>
            
            <v-flex md8 lg8 style="text-align:center;">
              <h2 class="product_point" style="padding-top:1.5em;">Time to Value</h2>
              <p style="font-size:1.1em;">Working with our consultants in this manner significantly reduces your time to value, and eliminates the need for much of the back-and-forth associated with traditional strategy engagements.<br><br></p>
              <h2 class="product_point">Dynamic Exploration</h2>
              <p style="font-size:1.1em;">Live exploration of the data allows you to discover unknown unknowns, and pursue interesting data points as they come up, allowing you to leave with the insights you need most, and some you may not have considered.<br><br></p>
              <h2 class="product_point">Resource Efficiency</h2>
              <p style="font-size:1.1em;">You're busy, we know the feeling. Our workshop model takes the work out of the analytics process for your team so that they can be more effective in their roles.<br><br></p>
              <h2 class="product_point">Data Expertise</h2>
              <p style="font-size:1.1em;">Our consultants have backgrounds in market research and data analytics, and are in the data on a daily basis—they are comfortable working with and explaining the nuances of highly dimensional data.<br><br></p>
            </v-flex>

            <v-flex md2 lg2></v-flex>
          </v-layout>
        </v-container>
    
        <!-- Case Studies -->
        <v-container id="case-container">
          <h2>Case Studies</h2>
          <v-layout row wrap>
            <!-- Row Zero: graphics: -->
            <v-flex md1 lg1  hidden-sm-and-down></v-flex>
            
            <v-flex  md4 lg4>
              <img class="center" width="210em" style="padding:1em;" src="@/assets/fp-graphics/str150.png" />
            </v-flex>

            <v-flex md2 lg2  hidden-sm-and-down></v-flex>
            
            <v-flex  md4 lg4 hidden-sm-and-down>
              <img class="center" width="210em"  style="padding:1em;" src="@/assets/fp-graphics/inv150.2.png" />
            </v-flex>

            <v-flex md1 lg1  hidden-sm-and-down></v-flex>

            <!-- Row One: titles: -->
            <v-flex md1 lg1  hidden-sm-and-down></v-flex>
            
            <v-flex  md4 lg4 hidden-sm-and-down>
            <h3 class="title_studies" >Strategy</h3>
            </v-flex>

            <v-flex md2 lg2  hidden-sm-and-down></v-flex>

            <v-flex  md4 lg4 hidden-sm-and-down>
              <h3 class="title_studies">Investment</h3>
            </v-flex>

            <v-flex md1 lg1  hidden-sm-and-down></v-flex>
            
            <!-- Row Two: paragraphs: -->
              <v-flex sm6 md1 lg1  hidden-sm-and-down></v-flex>
                
              <v-flex sm12 md4 lg4 order-xs="2">
                <p class="studies"><span class="hidden-md-and-up">Strategy </span><br>Consumer data has always been a key staple of corporate strategy for CPG firms. Learn how our clients are taking their consumer analytics to the next level with the help of Social Standards.</p>
              </v-flex>

              <v-flex md2 lg2  hidden-sm-and-down></v-flex>
    
              <v-flex  md4 lg4 hidden-md-and-up>
              <img class="center" width="210em" style="padding:1em;" src="@/assets/fp-graphics/inv150.2.png" />
              </v-flex>

              <v-flex sm12 md4 lg4 order-xs="4">
                  <p  class="studies"><span class="hidden-md-and-up">Investment </span><br>Social Standards serves as a valuable partner to investment groups looking for a competitive advantage. Learn how our data can help you make better, faster investment decisions, and give you valuable insight as part of your M&A Strategy.</p>
              </v-flex>

              <v-flex md1 lg1  hidden-sm-and-down></v-flex>
    
              <!-- Row Three: buttons: -->
              <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 >
                <router-link :to="{name:'what-we-do', hash:'#strategy'}"><p class="btn center ss_blue orange_hover">Learn More</p></router-link>
              </v-flex>
              <v-flex sm12 md6 lg6 order-sm-9 order-md-8 order-lg-8 hidden-sm-and-down>
                <router-link :to="{name:'what-we-do', hash:'#investment'}"><p class="btn center ss_blue orange_hover">Learn More</p></router-link>
              </v-flex>
   
          </v-layout>
        </v-container>

        <!-- contact form  -->
        <v-container id="contact_form">
          <ContactForm />
        </v-container>
  </div>
</template>

<script>
  import processingMixin from '@/mixins/processingMixin';
  import ContactForm from '@/components/ContactForm.vue';
  import objectFunctions from '@/mixins/objectFunctions';

    export default {
      name:'products-page',
      mixins: [processingMixin],
      components: {
        ContactForm
      },
      data(){
        return {
           verticalList: objectFunctions.domains,
        }
      },
      created() {
        this.logPageLoad({
          app_name: 'S',
          page_name: this.$options.name,

        })
      }
    }
</script>

<style>
  .platform_banner {
    background-color:#131737;
    height:45vh;
    color:white;
  }

  .platform_inner {
    display:flex;
    justify-content: center;
    align-items:center; 
    font-size:2em;
    padding-top:4em;
  }

  .product_name {
    font-size:2em;
    font-weight:500;
    padding-bottom:.4em;
  }

  .product_point {
    font-size:1.7em;
    font-weight:200;
    text-align:center;
    color:#71C6E6;
    padding-bottom:.3em;
  }

  .send {
    border-color:grey;
  }

  .send:hover {
    border-color:#71C6E6;
  }

  .blurb_font {
    font-size: 1.5em;
    text-align: center;
    padding-bottom:.5em;
  }

  .section_heading {
    font-size: 2em;
    font-weight:600;
  }

  .P_container {
    width:80%;
    padding:1em; 
    border:solid; 
    border-color:#131737; 
    background-color:#131737; 
    color:white;
    position:relative; top:15px;
    border-radius: 19px 19px 0px 0px;
  }

  .T_container {
    width:80%; 
    margin-bottom:4em; 
    padding:1em; 
    border:solid; 
    border-radius:19px;
    border-color:#131737;
  }

  .w-desc {
    font-size:1.3em; 
    position:relative; 
    top:2em; 
    padding-bottom:1em;
  }

  .pre-product {
    width:60%; 
    padding-top:4em; 
    font-size:1.5em; 
    text-align:center;
  }

  #case-container {
    padding-top:2em; 
    padding-bottom:5em;
  }

  #case-container > h2 {
    font-size:2.5em; 
    text-align:center; 
    font-weight:400; 
    padding-bottom:1em;
  }

  #contact_form {
    background-color:#f3faff; 
    margin-bottom:2em; 
    padding-bottom:2em; 
    width:80%;
  }

  @media only screen and (min-width: 1904px){
    .work {
    width:50% !important;
    }
  }
  
  @media only screen and (max-width: 960px) {
    .platform_inner {
      font-size:1.4em;
      width:90%;
    }
    .product_name {
      text-align:center;
    }
    .main_description {
      text-align:center;
      padding-bottom:1.4em;
    }
    .T_container {
      text-align:center;
      padding:1em;
    }
  }

  @media only screen and (max-width: 600px) {
    .products_full {
      text-align:center;
    }
    .section_heading {
      font-size:1em;
    }
    .blurb_font {
      font-size:.9em;
    }
    .T_container {
      width:90%;
    }
    .P_container {
      width:90%;
    }
    .pre-product {
      width:80%;
    }
    .platform_inner {
      padding-top:5em;
    }
  }
</style>