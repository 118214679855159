import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticStyle:{"margin":"0em 0em 0em 0em"},attrs:{"absolute":"","color":"#1a204c","elevation":"0"}},[_c('router-link',{attrs:{"to":{name: 'Home'}}},[_c('img',{staticStyle:{"margin":"1em 0em 0em 3em"},attrs:{"src":require("@/assets/SSLogo.png"),"width":"200"}})]),_c('router-link',{attrs:{"to":{name: 'ObjectSearch'}}},[_c(VBtn,{staticStyle:{"background-color":"transparent","margin-left":"1em","margin-top":".5em"},attrs:{"elevation":"0"}},[_c('span',{staticStyle:{"font-size":"1em","font-weight":"500","color":"#ffff"}},[_vm._v("Home")])])],1),_c(VSpacer),_c('span',{attrs:{"id":"toolbar"}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white"},attrs:{"elevation":"0"},on:{"click":function($event){return _vm.markDialog()}}},[_vm._v("Bookmarks")]),(!_vm.loggedUser.anon)?_c('a',{attrs:{"href":_vm.lensesUrl,"target":"_blank"}},[_c(VBtn,{staticStyle:{"background-color":"transparent","color":"#ffff"},attrs:{"elevation":"0"}},[_vm._v("Lenses")])],1):_vm._e(),_c('router-link',{attrs:{"to":{path: 'help?page=general'}}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"white"},attrs:{"elevation":"0"}},[_vm._v("FAQ")])],1),_c(VMenu,{staticStyle:{"max-width":"600px"},attrs:{"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"btn-hover",staticStyle:{"background-color":"transparent","margin":"0em 10em 0em 0em","color":"white"},attrs:{"id":"no-background-hover","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v("Account")])]}}]),model:{value:(_vm.accountMenu),callback:function ($$v) {_vm.accountMenu=$$v},expression:"accountMenu"}},[_c(VList,[(!_vm.loggedUser.anon)?_c(VListItem,{attrs:{"link":""}},[_c('settingsEditor',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('a',{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.start(open)}}},[_vm._v("My Account")])]}}],null,false,3995101080),model:{value:(_vm.settingsWrap),callback:function ($$v) {_vm.settingsWrap=$$v},expression:"settingsWrap"}})],1):_c(VListItem,[_c('GapSettings',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('a',{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.start(open)}}},[_vm._v("Account Settings")])]}}]),model:{value:(_vm.settingsWrap),callback:function ($$v) {_vm.settingsWrap=$$v},expression:"settingsWrap"}})],1),_c(VListItem,[_c('a',{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.loggingOff()}}},[_vm._v("Logout")])])],1)],1)],1)],1),_c(VContainer,{staticStyle:{"margin-top":"8em"}},[_c(VRow,{attrs:{"justify":"center"}},[_vm._v(" You do not have access to this domain. ")]),_c(VRow,{attrs:{"justify":"center"}},[_c('router-link',{attrs:{"to":{name: 'ObjectSearch'}}},[_vm._v("Return to search")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }