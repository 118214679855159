import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoggedIn)?_c(VToolbarItems,{staticClass:"hidden-sm-and-down"},[_c(VMenu,{staticStyle:{"border-radius":"7px"},attrs:{"max-height":"600","allow-overflow":"","open-on-hover":"","offset-y":"","open-delay":""},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c(VBtn,_vm._g({staticStyle:{"font-size":"1.05em","letter-spacing":"2px","margin-right":"1.5em"},attrs:{"slot":"activator","id":"dropdown","depressed":"","text":"","color":"white","dark":""},slot:"activator"},scope.on),[_vm._v(" Account ")])]}}],null,false,2036583852)},[_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c('settingsEditor',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c(VListItemTitle,{on:{"click":function($event){return _vm.start(open)}}},[_vm._v("My Account")])]}}],null,false,3151834173),model:{value:(_vm.settingsWrap),callback:function ($$v) {_vm.settingsWrap=$$v},expression:"settingsWrap"}})],1),_c(VListItem,{attrs:{"link":""}},[_c('router-link',{attrs:{"to":{name: 'Home'},"target":""}},[_c(VListItemTitle,{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.loggingOff()}}},[_vm._v("Logout")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }