<template>
<div id="Adminview" >
   
    <v-layout row wrap style="min-height:100vh;">
        <v-flex xs3 sm3 md3 lg2>
        <v-container style="background-color:#131737; height:100%;">
            <AdminDrawer />
       </v-container>
        </v-flex>

        <v-flex xs9 sm9 md9 lg10 style="padding:3em; min-height:100vh;" >
            <v-container> 
              <v-tabs
               v-model="tabs"
              background-color="#f3faff"
              color="#131737"
              >
              <v-tabs-slider></v-tabs-slider>
                <v-tab style="margin-left:1em; margin-right:1em;">
                  <v-icon style="margin-right:.5em;">mdi-account-multiple</v-icon>
                  personnel</v-tab>
                  <!-- <v-tab>
                  <v-icon style="margin-right:.5em;">mdi-account-group</v-icon>
                  Manage personnel</v-tab> -->
                <v-tab>
                  <v-icon style="margin-right:.5em;">mdi-bulletin-board</v-icon>
                  Bulletin Board</v-tab>
                  
              </v-tabs>
              

              <v-tabs-items v-model="tabs">
  
                <v-tab-item>
                  <div style="padding-top:1em;">
                    <v-data-table
                      :headers="headers"
                      :items="personnel"
                      hide-default-header
                      hide-default-footer
                      disable-pagination
                      class="elevation-0"
                    ></v-data-table>
                  </div>
              </v-tab-item>

                <!--Second v-tab-item in corresponds to second v-tab -->
                <v-tab-item>
                  <div style="padding:4em;">
                    In the works.
                  </div>
                </v-tab-item>
              
              </v-tabs-items>


            </v-container>
  
        </v-flex>
    </v-layout>

    <v-dialog v-model="personEdit" max-width="700">
      <v-card style="width:700px;">
        Edit
      </v-card>
    </v-dialog>


    
</div>
</template>

<script>
import AdminDrawer from '@/components/Navigation/AdminDrawer';
import { mapActions, mapState } from 'vuex';
export default {
    name:'admin-view',
    components: {
        AdminDrawer,
    },
    data() {
        return {
          personnel: [],
          tabs: 0,
          personnelDialog: false,
          personEdit: false,
          editItem: '',
          headers: [
          { text: '', value: 'name' },
          { text: '', value: 'departmentName' },
          { text: '', value: 'jobTitle' },
          { text: '', value: 'location' },
          { text: '', value: 'phoneNumber' },
        ],
        headers1: [
          { text: '', value: 'name' },
          { text: '', value: 'departmentName' },
          { text: '', value: 'jobTitle' },
          { text: '', value: 'actions' },
        ],
        }
    },
    created() {
        this.init() 
    },
    mounted: function () {
    //    this.items = this.personnel
    //     console.log(this.items)
    },
    computed: {
        ...mapState([
            'personnel'
        ])
    },
    methods: {
        ...mapActions([
        'getPersonnel'
			]),
			
			init: async function() {
				await this.getPersonnel()
				.then((data) => {
          console.log(data)
						this.personnel = data.data;
				}) 
				.catch((err) => {
						console.log(err.response)
				})
      },
      
      editPersonnel(item) {
        this.personEdit = true;
        this.editItem = item;
      },

			addPersDialog() {
				this.personnelDialog = true
			},

    }
}
</script>

<style>
#Adminview p {
   font-size:1.1em;
    }
#Adminview ul{
    list-style-type:none;
}
.emp_list {
    padding-bottom:.5em;
    padding-top:.5em;
    border: solid;
    border-width: 0px 0px 1px 0px;
    border-color:grey;
}
.col1{padding-left:2em;}
.col2{position:relative; right:0em; color:grey;}
.col3{position:relative; right:0em; color:grey;}
.col4{position:relative; right:0em; color:grey}
.col5{position:relative; right:2em;}
@media only screen and (max-width: 960px) {}
</style>