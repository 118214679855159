import axios from "axios";

const instance = axios.create({ withCredentials: true });

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
 
//     const {
//        pathname
//     } = window.location

//     if (error.response.status === 403 || error.response.status === 401 || error.response.status === 422) {
//       if(!['/login', '/login/','/', '/reset-password', '/reset-password/'].includes(pathname)){
//         window.localStorage.clear();
//         window.location.href = "/login";
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export default instance;
