<template>
  <div >
    <v-card
      class="mx-auto lighten-4"  
      style="background-color:#f3faff;" 
      elevation="0"   
      >

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline" style="color:#131737;">
            {{ company.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ loggedUser.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-text >
        <v-list flat three-line>
          <v-subheader>CONTACTS</v-subheader>
          <v-list-item-group color="black lighten-5">
            <v-list-item
              v-for="(rep, i) in company.reps"
              :key="i"
              :inactive=true
              @click="sendMail(rep.email)"
            >
              <v-list-item-avatar size="60px">
                <v-img 
                  :src="getImageURL(rep.imageUID)"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="pl-2" >
                <v-list-item-title class="text-left" style="padding-bottom:0em;"> {{ rep.full_name }} </v-list-item-title>
                <v-list-item-subtitle class="text-left" style="position:relative; bottom:.5em;"> {{ rep.jobTitle }} </v-list-item-subtitle>
              </v-list-item-content>

              <v-icon medium color="blue-grey lighten-3" >mdi-email</v-icon>

            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Company',
  data () {
    return {
      imgSRCPrefix: process.env.VUE_APP_RESOURCE_PREFIX
    }
  },

  created () {
    this.getCompany(this.loggedUser.company_uid);
  },

  computed: {
    ...mapState([
      'company'
    ])
  },

  methods: {
    sendMail (email) {
      window.open('mailto:'+ email, '_self')
    },

    getImageURL (imageUID) {      
      return this.imgSRCPrefix + imageUID
      },

    ...mapActions([
      'getCompany'
    ])
  }
}

</script>