import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Adminview"}},[_c(VLayout,{staticStyle:{"min-height":"100vh"},attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs3":"","sm3":"","md3":"","lg2":""}},[_c(VContainer,{staticStyle:{"background-color":"#131737","height":"100%"}},[_c('AdminDrawer')],1)],1),_c(VFlex,{staticStyle:{"padding":"3em","min-height":"100vh"},attrs:{"xs9":"","sm9":"","md9":"","lg10":""}},[_c(VContainer,[_c(VTabs,{attrs:{"background-color":"#f3faff","color":"#131737"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabsSlider),_c(VTab,{staticStyle:{"margin-left":"1em","margin-right":"1em"}},[_c(VIcon,{staticStyle:{"margin-right":".5em"}},[_vm._v("mdi-account-multiple")]),_vm._v(" personnel")],1),_c(VTab,[_c(VIcon,{staticStyle:{"margin-right":".5em"}},[_vm._v("mdi-bulletin-board")]),_vm._v(" Bulletin Board")],1)],1),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabItem,[_c('div',{staticStyle:{"padding-top":"1em"}},[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.personnel,"hide-default-header":"","hide-default-footer":"","disable-pagination":""}})],1)]),_c(VTabItem,[_c('div',{staticStyle:{"padding":"4em"}},[_vm._v(" In the works. ")])])],1)],1)],1)],1),_c(VDialog,{attrs:{"max-width":"700"},model:{value:(_vm.personEdit),callback:function ($$v) {_vm.personEdit=$$v},expression:"personEdit"}},[_c(VCard,{staticStyle:{"width":"700px"}},[_vm._v(" Edit ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }