import axios from "@/plugins/axios";
import router from "@/router";
import objectFunctions from "@/mixins/objectFunctions";

export default {
  errorHandler({ commit }, payload) {
    if (payload === 401) {
      commit("LOGOUT_USER");
      router.push({ name: "login" });
    } else {
      router.push({
        path: "/AppView/something-went-wrong" + "?status=" + payload,
      });
    }
  },

  getSparkChartData({ state }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/dashboard/timeline";

      axios({
        method: "post",
        url: api_uri,
        params: { ...payload.params },
        data: { ...payload.body },
      })
        .then((response) => {
          // todo
          // dispatch("setSparkChartData", response.data);
          resolve(response);
        })
        .catch((error) => {
          let error_codes = [401, 403, 404, 422];
          if (error.response) {
            if (error_codes.includes(error.response.status)) {
              console.log(error.response.status);
            } else {
              // dispatch("errorHandler", error.response.status);
            }
          }
          reject(error);
        });
    });
  },

  //Login and Logout functions -- setting key user info #1

  //login route
  getUser({ state, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/login";
      console.log("in get user");
      axios
        .post(api_uri, {
          email: credentials.username,
          password: credentials.password,
          token: credentials.token,
        })
        .then((response) => {
          let loggedUser = {};
          if (response.status == 200) {
            loggedUser = response.data;
          }
          dispatch("setUser", loggedUser);
          resolve(response);
        })
        .catch((error) => {
          let error_codes = [401, 403, 404, 422];
          if (error.response) {
            if (error_codes.includes(error.response.status)) {
              console.log(error.response.status);
            } else {
              dispatch("errorHandler", error.response.status);
            }
          }
          reject(error);
        });
    });
  },

  setShowPercentiles({ commit }, payload) {
    if (payload.type === "demo") {
      commit("SET_DEMO_SHOW_PERCENTILES", payload.showPercentiles);
    } else {
      commit("SET_GEO_SHOW_PERCENTILES", payload.showPercentiles);
    }
  },

  setKebabMenuOptions({ commit, state }, payload) {
    const componentKey = payload.componentKey;
    const menuItem = payload.menuItem;
    const value = payload.value;
    const kebabMenuState = state.kebabMenuState;
    if (!kebabMenuState[componentKey]) {
      kebabMenuState[componentKey] = {};
    }
    if (!kebabMenuState[componentKey][menuItem]) {
      kebabMenuState[componentKey][menuItem] = {
        checked: true,
      };
    }
    kebabMenuState[componentKey][menuItem].checked = value;
    commit("SET_KEBAB_MENU_STATE", kebabMenuState);
  },

  setWorkProducts({ commit }, payload) {
    commit("SET_WORKPRODUCTS", payload);
  },
  setPublicWorkProducts({ commit }, payload) {
    commit("SET_PUBLIC_WORKPRODUCTS", payload);
  },
  setUser({ commit }, payload) {
    if (payload.legacy_user) {
      commit("SET_USER", payload);
      commit("SET_TOKEN", payload.legacy_user);
      commit("SET_ACCESS", "legacy_user");
    } else if (payload.account) {
      //admin and regular web users
      commit("SET_USER", payload);
      commit("SET_TOKEN", payload);
      commit("SET_CURRENT_ORG", payload);
      commit("SET_ACCESS", payload.account);
      commit("SET_DOMAIN_ACCESS", payload.account);
      commit("SET_MY_ORGS", payload.account.organizations);
      commit("SET_DEFAULT_ORG", payload.account.account_settings);
      commit("SET_CURRENT_GAP", payload);
      commit("SET_SOURCE_LIST", payload); // try to rewrite code to do SOURCE LIST and DOMAIN ACCESS in the same f-on
    } else if (payload.organization) {
      //anonymous temp user
      commit("SET_USER", payload);
      commit("SET_TOKEN", payload);
      commit("SET_CURRENT_ORG", payload);
      commit("SET_ACCESS", payload);
      commit("SET_CURRENT_GAP", payload); // domain access is set here for anon user
      commit("SET_SOURCE_LIST", payload);
    } else {
      commit("SET_USER", payload);
      commit("SET_TOKEN", payload);
    }
  },

  async logoutUser({ state, commit }) {
    const api_uri = state.api_uri + "/auth/account/logout";

    await axios({
      method: "get",
      url: api_uri,
    })
      .then(() => {
        console.log("logout successful");
        commit("LOGOUT_USER");
      })
      .catch((e) => {
        console.log("logout error");
        console.error(e);
      });
  },

  changeProfile({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/profile/switch";
      axios({
        method: "get",
        url: api_uri,
        params: {
          organization: payload.org_uid,
          profile: payload.gap_uid,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  setSwitch({ commit }, data) {
    commit("SWITCHED_GAP", data);
  },

  //set basic info #2
  newApp({ commit }) {
    commit("SET_APP", true);
  },

  // gets list of users in organization (social standards only)
  getPersonnel({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/all-personnel";
      axios({
        method: "get",
        url: api_uri,
      })
        .then((response) => {
          commit("SET_PERSONNEL", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  // gets list of companies (social standards only)
  getCompanies({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get(state.api_uri + "/all-companies", {})
        .then((response) => {
          commit("SET_COMPANIES", response.data.companies);
          resolve(response);
        })
        .catch((error) => {
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  // gets list of
  getWorkProducts({ commit, state, dispatch }, customer_uid) {
    // set the commit type and api_uri based on request for publc work products or specfic company
    let setType = "SET_WORKPRODUCTS";
    let api_uri = state.api_uri + "/products";
    // set vars for public endpoint
    if (customer_uid === "EzmmIA_wjlinFAZBA_nHRs") {
      setType = "SET_PUBLIC_WORKPRODUCTS";
      api_uri = state.api_uri + "/public-resources";
    }

    return new Promise((resolve, reject) => {
      axios
        .get(api_uri, {
          params: {
            customer_uid: customer_uid,
          },
        })
        .then((response) => {
          if (
            response.data.workProducts == undefined ||
            response.data.workProducts.length == 0
          ) {
            commit(setType, "");
          } else {
            commit(setType, response.data.workProducts);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error.response);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getCompany({ commit, state, dispatch }, companyUID) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/company";
      axios
        .get(api_uri, {
          params: {
            company_uid: companyUID,
          },
        })
        .then((response) => {
          if (
            response.data.company == undefined ||
            response.data.company.length == 0
          ) {
            reject("no company");
          } else {
            commit("SET_COMPANY", response.data.company);
            resolve(response);
          }
        })
        .catch((error) => {
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  //Admin Role and Org Mgmt
  getOrgs({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization";
      axios({
        method: "get",
        url: api_uri,
      })
        .then((response) => {
          commit("SET_ORGS_LIST", response.data.organizations);
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getOrgInfo({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization";
      axios({
        method: "get",
        url: api_uri,
        params: {
          organization: payload,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  deleteOrgUser({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/invite";
      axios({
        method: "delete",
        url: api_uri,
        params: {
          organization: payload.org_uid,
        },
        data: {
          account_uid: payload.uid,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getOrgUsers({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization";
      axios({
        method: "get",
        url: api_uri,
        params: {
          organization: state.loggedUser.company_uid,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getAssumedOrgUsers({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization";
      axios({
        method: "get",
        url: api_uri,
        params: {
          organization: state.assumedOrg,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  assumeOrg({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/assume";
      axios({
        method: "get",
        url: api_uri,
        params: {
          organization: payload,
          role: "user_admin",
        },
      })
        .then((response) => {
          commit("ASSUMED_ORG", response);
          commit("SET_ASSUME_TOKEN", response.data.assume_token);
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          reject(error);
        });
    });
  },

  adminGetUsers({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/assume";
      axios({
        method: "get",
        url: api_uri,
        params: {
          organization: payload.uid,
          role: "user_admin",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  changeCurrentSource({ commit }, payload) {
    commit("SET_CURRENT_SOURCE", payload);
  },

  //updates/adds account settings and/or name (primary_org/default_org)
  putAccountSettings({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account";
      axios({
        method: "put",
        url: api_uri,
        data: {
          full_name: payload.full_name,
          settings: payload.settings,
        },
      })
        .then((response) => {
          resolve(response);
          // on successful post, update the state for this payload
          commit("SET_ACCOUNT_SET", response.data);
          commit("SET_USER_NAME", response.data);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  //updates/adds account settings
  updateSettings({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account";
      axios({
        method: "put",
        url: api_uri,
        data: {
          settings: payload.settings,
        },
      })
        .then((response) => {
          resolve(response);
          // on successful post, update the state for this payload
          commit("SET_ACCOUNT_SET", response.data);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  updateUserName({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account";
      axios({
        method: "put",
        url: api_uri,
        data: {
          full_name: payload.full_name,
        },
      })
        .then((response) => {
          resolve(response);
          // on successful post, update the state for this payload
          commit("SET_USER_NAME", response.data);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  //update/change user roles and or name
  editUserRoles({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/invite";
      axios({
        method: "post",
        url: api_uri,
        params: {
          organization: payload.org,
        },
        data: {
          account_uid: payload.uid,
          roles: payload.roles,
          profiles: payload.profiles,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  editUserGaps({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/invite";
      axios({
        method: "post",
        url: api_uri,
        params: {
          organization: payload.org,
        },
        data: {
          account_uid: payload.uid,
          profiles: payload.profiles,
          roles: payload.roles,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getSettings({ commit, state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/settings";

      axios({
        method: "get",
        url: api_uri,
        params: {
          id: payload.id,
          type: payload.settingsType,
          organization: payload.organization,
        },
      })
        .then((response) => {
          commit("SET_SETTINGS", response.data.settings[0].settings);
          resolve(response);
        })
        .catch((error) => {
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  postSettings({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/settings";

      axios({
        method: "post",
        url: api_uri,
        params: {
          id: payload.id,
          type: payload.settingsType,
          organization: payload.organization,
        },
        data: payload.settings,
      })
        .then((response) => {
          resolve(response);
          // on successful post, update the db & state for this payload
          dispatch("getSettings", payload);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  postOrganization({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization";

      axios({
        method: "post",
        url: api_uri,
        data: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  editOrganization({ state }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization";
      axios({
        method: "post",
        url: api_uri,
        data: {
          roles: payload.roles,
          organization: payload.organization_uid,
          name: payload.name,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          //dispatch('errorHandler', error.response.status);
          reject(error);
        });
    });
  },

  postGAP({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/profile";

      axios({
        method: "post",
        url: api_uri,
        params: {
          organization: payload.uid,
        },
        data: payload.data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  editGap({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/profile";
      axios({
        method: "put",
        url: api_uri,
        params: {
          organization: payload.org_uid,
          profile: payload.gap_uid,
        },
        data: payload.data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  //ConsumerForecast Data

  getObjectBlob({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let api_uri = state.api_uri + "/object/brand";

      if (
        objectFunctions.productTypeList.includes(payload.type) ||
        payload.type.toLowerCase() === "product" ||
        payload.type.toLowerCase() === "p"
      ) {
        api_uri = state.api_uri + "/object/product_service";
      } else if (
        objectFunctions.descriptiveList.includes(payload.type) ||
        payload.type.toLowerCase() === "descriptive" ||
        payload.type.toLowerCase() === "d"
      ) {
        api_uri = state.api_uri + "/object/descriptive";
      }

      axios({
        method: "get",
        url: api_uri,
        params: {
          object_sid: payload.sid,
          domains: payload.domain,
          source: payload.source,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  deleteBookmark({ state, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      let api_uri = `${state.api_uri}/dashboard/${payload.dashboardId}/bookmark/${payload.bookmarkId}`;
      axios({
        method: "DELETE",
        url: api_uri,
      })
        .then((response) => {
          commit("DELETED_DASHBOARD_BOOKMARK", {
            dashboardId: payload.dashboardId,
            bookmarkId: payload.bookmarkId,
          });
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  postBookmark({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let api_uri = `${state.api_uri}/dashboard/${payload.dashboard}/bookmark/`;
      axios({
        method: "POST",
        url: api_uri,
        data: {
          name: payload.name,
          url: payload.url,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  getDashboardList({ state, dispatch, commit }) {
    // https://api-dev.socialstandards-data.net/dashboard/my/
    return new Promise((resolve, reject) => {
      let api_uri = state.api_uri + "/dashboard/my";

      axios({
        method: "get",
        url: api_uri,
        params: {},
      })
        .then((response) => {
          commit("SET_DASHBOARD_LIST", response.data.dashboards);

          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  getDashboardBlob(
    { state, dispatch, commit },
    { dashboard, source, period, focused_object, shift, slices }
  ) {
    return new Promise((resolve, reject) => {
      let api_uri = state.api_uri + "/dashboard/load";

      axios({
        method: "get",
        url: api_uri,
        params: {
          dashboard,
          source,
          period,
          focused_object,
          shift,
          slices
        },
      })
        .then((response) => {
          commit("SET_DASHBOARD_BLOB", response.data.dashboard);
          resolve(response.data.dashboard);
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  getDashboardCSV({ state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      let api_uri = `${state.api_uri}/dashboard/export`;

      axios({
        method: "get",
        url: api_uri,
        params: {
          ...params,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  // Currently only provides for the Instagram source and it's domains. This must be updated to either allow for source
  // parameter or return source-domains for all sources
  getObjectInfo({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/object";
      axios({
        method: "get",
        url: api_uri,
        params: {
          object_sid: payload.sid,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  getFullObjectInfo({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/object";
      axios({
        method: "get",
        url: api_uri,
        params: {
          object_sid: payload.sid,
          source: payload.source,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404 || error.response.status === 405) {
            console.log("error", error.response.status);
          } else {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  browseProducts({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/object/browse/product_service";
      axios({
        method: "get",
        url: api_uri,
        params: {
          source: 1,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  //consumerForecast search
  getQueryMatch({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/object/search";
      axios({
        method: "get",
        url: api_uri,
        params: {
          query: payload.query,
          source: payload.source,
          type: payload.type ? payload.type : "",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getAdvancedMatch({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/object/search";
      axios({
        method: "get",
        url: api_uri,
        params: {
          query: payload.query,
          type: payload.filter,
          order: payload.order,
          source: payload.source,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  //Authentication calls -- Register, set password, invite etc

  setPassword({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/";
      axios({
        method: "post",
        url: api_uri,
        data: {
          password: payload.password,
          full_name: payload.full_name,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            let loggedUser = response.data.account;
            commit("SET_USER", loggedUser);
            resolve(response);
          }
        })
        .catch((error) => {
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getPasswordReset({ commit, state, dispatch }, email) {
    return new Promise((resolve, reject) => {
      commit("LOGOUT_USER");
      const api_uri = state.api_uri + "/auth/account/reset";

      axios
        .get(api_uri, {
          params: {
            email: email,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status !== 404) {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  resetPassword({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/";
      axios({
        method: "put",
        url: api_uri,
        data: {
          old_password: payload.oldPassword,
          new_password: payload.newPassword,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            let loggedUser = response.data;

            dispatch("setUser", loggedUser);
            resolve(response);
          }
        })
        .catch((error) => {
          console.error(error);
          // if (error.response) {
          //   dispatch("errorHandler", error.response.status);
          // }

          reject(error);
        });
    });
  },

  //invites/adds user for assumed company
  postAccount({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/invite";
      axios({
        method: "post",
        url: api_uri,
        params: {
          organization: payload.uid,
        },
        data: {
          email: payload.email,
          full_name: payload.name,
          roles: payload.roles,
          profiles: payload.profiles,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch("errorHandler", error.response.status);
          console.error("error", error);
          reject(error);
        });
    });
  },

  invitePassword({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account";
      axios({
        method: "post",
        url: api_uri,
        data: {
          password: payload.password,
          full_name: payload.full_name,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 403) {
            console.log("error");
          } else {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  acceptInviteFinal({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/invite";
      axios({
        method: "post",
        url: api_uri,
        data: {
          invite: payload.invite_token,
          organization: payload.org_uid,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch("errorHandler", error.response.status);
          console.error("error", error);
          reject(error);
        });
    });
  },

  readInvite({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/invite";
      axios({
        method: "get",
        url: api_uri,
        params: {
          invite: payload.token,
        },
      })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 403) {
            console.log("error");
          } else {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  register({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/register";
      axios({
        method: "get",
        url: api_uri,
        params: {
          email: payload.email,
          full_name: payload.fullName,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 404 || error.response.status === 400) {
            console.error("error", error);
          } else {
            dispatch("errorHandler", error.response.status);
          }
          reject(error);
        });
    });
  },

  postAnonToken({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/profile/token";

      axios({
        method: "post",
        url: api_uri,
        params: {
          organization: payload.org_uid,
          profile: payload.gap_uid,
        },
        data: payload.data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  getAnonToken({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/profile/token";

      axios({
        method: "get",
        url: api_uri,
        params: {
          organization: payload.org,
          profile: payload.gap_uid,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  editAnonToken({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/organization/profile/token";
      axios({
        method: "put",
        url: api_uri,
        data: {
          organization: payload.org_uid,
          profile: payload.gap_uid,
          token: payload.anon_token,
          expiration: payload.new_exp,
          note: payload.note,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("error", error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  joinAnon({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account/profile/join";
      axios({
        method: "get",
        url: api_uri,
        params: {
          token: payload,
        },
      })
        .then((response) => {
          dispatch("setUser", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  setInviteOrg({ commit }, payload) {
    commit("SET_INVITE_ORG", payload);
  },

  //form submission
  postContactForm({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/form-submission";

      // build the form data object
      var formData = new FormData();
      formData.append("email", payload.email);
      formData.append("source", payload.source);
      formData.append("type", payload.type);
      formData.append("phone", payload.phone);
      formData.append("company", payload.company);
      formData.append("message", payload.message);
      formData.append("hear_about", payload.hearAbout);

      axios({
        method: "post",
        url: api_uri,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch("errorHandler", error.response.status);
          reject(error);
        });
    });
  },

  //click log processing
  postLog({ state }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/log/write";
      const post = {
        method: "post",
        url: api_uri,
        data: payload,
      };

      axios(post)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          //dispatch('errorHandler', error.response.status);
          reject(error);
        });
    });
  },

  queryLogs({ state }, payload) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/log/query";
      axios({
        method: "post",
        url: api_uri,
        data: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  //other
  setTabs({ commit }, tab) {
    commit("SET_TABS", tab);
  },

  checkCredentials({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      const api_uri = state.api_uri + "/auth/account";
      console.log('checkCredentials');
      axios({
        method: "get",
        url: api_uri,
      })
        .then((response) => {
          let loggedUser = {};

          if (response.status == 200) {
            loggedUser = response.data;
          }
          dispatch("setUser", loggedUser);
          resolve(response);
        })
        .catch((error) => {
          let error_codes = [401, 403];
          if (error.response) {
            if (error_codes.includes(error.response.status)) {
              console.log(error.response.status);
            } else {
              dispatch("errorHandler", error.response.status);
            }
          }
          reject(error);
        });
    });
  },
};
