<template>
    <v-toolbar-items v-if="isLoggedIn" class="hidden-sm-and-down">
      <v-menu max-height="600" allow-overflow open-on-hover offset-y open-delay style="border-radius:7px;">

        <template v-slot:activator="scope">
        <v-btn id="dropdown" depressed text style="font-size:1.05em; letter-spacing: 2px; margin-right:1.5em; "
          slot="activator" 
          color=white
          dark
          v-on="scope.on"
        >
          Account
        </v-btn>
        </template>

        <v-list>
          <!-- <v-list-item >
            <router-link :to="{name: 'client-home'}" target=""><v-list-item-title class="menuItems">Client Home</v-list-item-title></router-link>
          </v-list-item> -->
          <!-- <v-list-item>
            <router-link :to="{name: 'os'}" target="_blank"><v-list-item-title class="menuItems">Market Insights</v-list-item-title></router-link>
          </v-list-item> -->
          <!--v-list-item>
            <router-link :to="{name: 'knowledgebase'}" target="_blank"><v-list-item-title class="menuItems">Knowledgebase</v-list-item-title></router-link>
          </v-list-item>
          <v-list-item>
            <router-link :to="{name: 'socialstandards-software-updates'}" target="_blank"><v-list-item-title class="menuItems">Software Updates</v-list-item-title></router-link>
          </v-list-item-->

          <!-- <v-list-item v-if="this.getAccess.includes('User Administrator Access')">
            <router-link :to="{name: 'userSettings'}" target=""><v-list-item-title class="menuItems">Manage Users</v-list-item-title></router-link>
          </v-list-item> -->

          <v-list-item link>
            <settingsEditor v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <v-list-item-title @click="start(open)">My Account</v-list-item-title>
              </template>
            </settingsEditor>
          </v-list-item>

          <v-list-item link>
            <router-link :to="{name: 'Home'}" target=""><v-list-item-title @click="loggingOff()" style="color:#333;">Logout</v-list-item-title></router-link>
          </v-list-item>
        </v-list>
    </v-menu>
    </v-toolbar-items>

</template>
<script>
  import {  mapState, mapGetters, mapActions } from 'vuex';
  import settingsEditor from '@/components/Navigation/SettingsEditor.vue';
  import processingMixin from '@/mixins/processingMixin';

  export default {
    name:'Client-Menu',
    mixins: [processingMixin],
    components: {
      settingsEditor
    },
    data: () => ({
      settingsWrap: '',
    }),

    methods: {

      start: function(method) {
        setTimeout(() => method());
      },

      ...mapActions([
        'logoutUser'
      ]),

      loggingOff() {
        this.logoutUser();
        this.logAction({
          event: 'logout'
        });
      },

      
    
    },
    computed: {
      ...mapState([
        'loggedUser',
      ]),
      ...mapGetters([
        'isLoggedIn',
        'isAdmin',
        'getAccess',
      ])
    }
  }
</script>

<style scoped>
#navbar {
  background-color: hsla(243, 41%, 11%, 1) ;
}
#dropdown:hover {
    color:#6ECAEB !important;
}
.menuItems {
  color:#333;
  font-size:1em !important;
}
.menuItems:hover {
  color: #6ECAEB;
}
</style>