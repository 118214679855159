<template>
  <v-container id="terms">
    <h1>Social Standards</h1>
    <h1>Terms &amp; Conditions Agreement</h1>
    <img
        class="center"
        style="position:relative; top:1em;"
        width="60px"
        height="60px"
        id="circle-logo" src="@/assets/social_standards_circle_logo.png"
    />

    <br>
    <br>
    <br>
    <p>This Terms &amp; Conditions Agreement (“Agreement”) is agreed to by Social Standards, Inc. (“Social Standards”),
       and the entity defined in the relevant Order Form (“Customer”).</p>
    <br>
    <ol>
      <li>DEFINITIONS.
        <ol style="position:relative; top:1em;" type="a">

          <li><p><u>“Account”</u> means an account allowing access to the Platform created in Customer’s name.</p></li>
          <li><p><u>“Data Taxonomies”</u> means the manner in which data is classified, organized, sorted and
                                          presented through the Services and the Technology (as defined herein).</p>
          </li>
          <li><p><u>“Confidential Information"</u> means (a) all information that relates to the provision or receipt
                                                   of the Platform or Services or either party's financial condition,
                                                   operations or business, and which is clearly
                                                   identified as confidential at the time of disclosure, (b) for
                                                   Customer as the Receiving Party: the Technology,
                                                   the Documentation, Data Taxonomies and the User IDs (all as defined
                                                   herein), and (c) for Social Standards as
                                                   the Receiving Party: means commercially sensitive or valuable
                                                   information that is disclosed by Customer to
                                                   Social Standards in the course of entering into or performing this
                                                   Agreement.</p></li>
          <li><p><u>“Documentation”</u> means the online help files and instruction manuals (whether in print or
                                        electronic form) that relate to the use of the Platform and/or the Services that
                                        have been provided or made available by Social Standards to Customer.</p>
          </li>
          <li><p><u>“Intellectual Property Rights”</u> means any and all intellectual property rights throughout the
                                                       world, including, without limitation, any and all copyrights,
                                                       trademarks, service marks, trade secrets, patents, patent
                                                       applications, moral rights, contract rights and any and all other
                                                       legal rights protecting intangible proprietary information.</p>
          </li>
          <li><p><u>“Platform”</u> means the software-as-a-service platform developed, hosted, supported, and made
                                   available by Social Standards from time to time.</p>
          </li>
          <li><p><u>“Services”</u> means the ability to use the Technology to gain insights from the data products,
                                   as enabled by Social Standards from time to time, and any other services provided by
                                   Social Standards under this Agreement.</p>
          </li>
          <li><p><u>“Technology”</u> means the Platform and other technology used by or on behalf of Social Standards
                                     to provide the Services, and all data, information and other content included on or
                                     accessible through the Service, except for any Customer Confidential Information
                                     and as otherwise stated herein.</p>
          </li>
          <li><p><u>“User”</u> means a named user accessing the Service through Customer’s Account.</p></li>
          <li><p><u>“User ID”</u> means each unique User identification name and password used for access to and use
                                  of the Platform through the Account.</p>
          </li>
        </ol>
      </li>

      <br>

      <li>SERVICES.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>Services Identified in Order Form.</u> Social Standards will make available, and Customer may
                                                         access and use, Social Standards services (the “Services”)
                                                         identified in one or more Social Standards
                                                         order forms (each, an “Order Form”) executed by Social
                                                         Standards and Customer. Each Order is incorporated
                                                         into this Agreement by reference. If the terms of an Order Form
                                                         conflict with the terms of this
                                                         Agreement, the terms in the Order Form will control.</p>
          </li>
          <li>
            <p><u>Site &amp; Applications.</u> The Services will be made available by Social Standards at its website
                                               located at <a href="http://www.socialstandards.com">www.socialstandards.com</a>
                                               (the “Site”). Additionally, an Authorized User may be given access to one
                                               or more Social Standards software applications (each, an “Application”)
                                               for the purpose of accessing the content of the Site (the “Content”)
                                               through that Application. For the purposes of this Agreement,
                                               “Content” also includes any written reports sold or otherwise delivered
                                               to Customer as well as any information Customer receives from Social
                                               Standards analysts, regardless of how that information is communicated to
                                               Customer. Except for providing access to the Site and the Content, Social
                                               Standards provides no other services pursuant to this Agreement.</p>
          </li>
          <li>
            <p><u>Updates.</u> Social Standards may modify the Services, Site, Applications, or Content at any
                               time, provided that no such modifications materially degrade the Services.</p>
          </li>
          <li>
            <p><u>Effective Date.</u> This Agreement is effective as of the date identified in the Order Form
                                      (“Effective Date”). If the Order Form does not contain an effective date, the
                                      Effective Date is the date
                                      Customer signed the Order Form.</p>
          </li>
          <li>
            <p><u>User IDs.</u> Customer will be able to create one User ID for each User authorized to access
                                the Services through the Account. Customer will be responsible for ensuring the security
                                and
                                confidentiality of all User IDs. Customer acknowledges that Customer will be fully
                                responsible for all
                                liabilities incurred through use of any User ID (whether authorized or unauthorized) and
                                that any
                                transactions completed under a User ID will be deemed to have been completed by
                                Customer, provided that
                                Social Standards shall be responsible for any liabilities resulting from a breach of
                                Social Standards’
                                security, or Social Standards’ or its agent’s negligence or willful misconduct. In no
                                event will Social
                                Standards be liable for the foregoing obligations or the failure by Customer to fulfill
                                such obligations.
            </p>
          </li>
          <li>
            <p><u>Restrictions on Use of Services.</u> Customer acknowledges that the Services and Technology and their
                                                       structure, organization, underlying source code, and Data
                                                       Taxonomies constitute valuable trade secrets of Social Standards.
                                                       Customer will not permit any User or any other person to: (a)
                                                       alter, modify, reproduce, reverse engineer, decompile,
                                                       disassemble, or otherwise attempt to derive the source code or
                                                       Data Taxonomies of the Technology, the Software or any part
                                                       thereof; (b) interfere in any manner with the operation or
                                                       hosting of the Services or Technology or attempt to gain
                                                       unauthorized access to the Services or any other services
                                                       offered by Social Standards; (c) sublicense or transfer any of
                                                       its rights under this Agreement, including, without limitation,
                                                       providing outsourcing, service bureau, commercial hosting,
                                                       application service provider or on-line services to third
                                                       parties, or otherwise make available the Services or Technology,
                                                       or access thereto, to any third party; or (d) otherwise use the
                                                       Services, Software or Technology except as explicitly permitted
                                                       by the Agreement.
            </p>
          </li>

        </ol>
      </li>

      <br>

      <li>USERS.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>Requirements for Use.</u> An “Authorized User” is an employee of Customer who is a natural
                                            person, who works within the organization listed on the Order, and who is
                                            designated by Customer as a user
                                            under this Agreement. All Authorized Users must:

                                            (a) Complete the Site registration process using a unique Site username and
                                            password or SSO;
                                            (b) Only access the Services using SSO or an email address at the Customer’s
                                            Internet domain;
                                            (c) Agree to the Site terms of use (the “Terms of Use”); and
                                            (d) Only use the Site, Applications, Content, and Services on behalf of
                                            Customer.

                                            If a term in the Terms of Use conflicts with a term of this Agreement, the
                                            term contained in this Agreement
                                            will control.</p>
          </li>
          <li>
            <p><u>Quantity of Authorized Users.</u> The Order Form identifies the number of Authorized Users who
                                                    may be granted access under this Agreement. Upon payment of the fees
                                                    specified in the Order Form, Customer
                                                    may, during the term specified in an Order Form, increase the
                                                    quantity of Authorized Users.</p>
          </li>
          <li>
            <p><u>Customer Is Responsible for Authorized Users.</u> Customer is responsible for all use of the
                                                                    Site by anyone accessing the Site or Services using
                                                                    a username or password issued to its Authorized
                                                                    Users.
                                                                    Customer must promptly notify Social Standards in
                                                                    writing if Customer becomes aware of any
                                                                    unauthorized
                                                                    access or use of the Services.</p>
          </li>
          <li>
            <p><u>Usage Limitation.</u> Social Standards may limit usage on an individual user basis. Customer
                                        acknowledges that while Social Standards’ primary intent with such usage
                                        restrictions is to maintain the
                                        technical security of its site and to limit scraping, extraordinary but
                                        legitimate usage may also be
                                        prevented by such usage restrictions. If Customer’s extraordinary but legitimate
                                        usage is limited by such
                                        restrictions, Social Standards will attempt to find a mutually acceptable
                                        solution for the usage
                                        restrictions.</p>
          </li>

        </ol>
      </li>


      <br>

      <li>ALLOWED USAGE.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>No Uses Competitive with Social Standards.</u> Customer agrees not to use the Content in
                                                                 furtherance of a Competitive Product. A “Competitive
                                                                 Product” is a product or service that provides
                                                                 customers with data that is substantially similar to
                                                                 data marketed and licensed by Social Standards as of
                                                                 the Effective Date. The Content may not be used for any
                                                                 competitive analysis of how Social Standards’
                                                                 products and services compare to a Competitive Product
                                                                 being offered or developed by Customer.</p>
          </li>
          <li>
            <p><u>Limitation on Distribution.</u> Except as explicitly allowed under Section 3 or the terms of
                                                  an Order, Customer may not transfer, sell, rent, distribute, display,
                                                  or otherwise disclose any portion
                                                  of the Services, Site, Content, or Applications to anyone.</p>
          </li>
          <li>
            <p><u>No Technological Attacks or Scraping.</u> Customer may not use or attempt to use any deep-link,
                                                            scraper, robot, bot, spider, data mining, computer code or
                                                            any other device, program, tool, algorithm,
                                                            process or methodology to systematically access, acquire,
                                                            copy, or monitor any portion of the Content or
                                                            Site. Additionally, Customer may not modify, decompile,
                                                            decrypt, disassemble or reverse engineer any
                                                            portion of the Services, Site, or Applications. Further,
                                                            Customer will not use the Content in conjunction
                                                            with any machine learning, neural network, deep learning,
                                                            predictive analytics or other artificial
                                                            intelligence computer or software program. If Customer
                                                            collects data from the Site in violation of this
                                                            Section 4.c, Customer agrees to promptly, upon receipt of
                                                            invoice, delete such data and pay 150% of the
                                                            then-current direct data pricing for such data.</p>
          </li>
          <li>
            <p><u>Unauthorized Access.</u> Customer may not—through hacking, password mining, or any other
                                           means—violate the security of the Site or any Application or attempt to gain
                                           unauthorized access to the
                                           site, Content, or Social Standards’ computer systems. Authorized Users may
                                           not share their password or
                                           other login credentials to the Services.</p>
          </li>
          <li>
            <p><u>No Violations of Third-Party Intellectual Property.</u> Customer may not use the Site,
                                                                          Services, or any Content in a manner that
                                                                          infringes or violates the intellectual
                                                                          property or proprietary
                                                                          rights of Social Standards or any third party,
                                                                          including, without limitation, the rights of
                                                                          privacy and
                                                                          publicity.</p>
          </li>
          <li>
            <p><u>No Use in Third-Party Databases.</u> Customer may not input any Content into a customer
                                                       relationship management application or any other third-party
                                                       database.</p>
          </li>
          <li>
            <p><u>No Use in Violation of Laws.</u> Customer may not use the Site or Content in any manner that
                                                   is unlawful or that harms Social Standards. Additionally, Customer
                                                   may not use the Site or Content in any
                                                   way that is fraudulent, false, or deceptive.</p>
          </li>
          <li>
            <p><u>No Offering of Securities.</u> Customer may not use the Services, Site, or Applications in
                                                 such a way as to be deemed to be engaging in the offering or
                                                 solicitation of investments in securities or
                                                 to be using the Services, Site, or Applications for any other improper
                                                 investment purposes.</p>
          </li>
          <li>
            <p><u>No Use for Credit or Employment Eligibility.</u> Customer may not use the Content as a factor
                                                                   in establishing an individual’s eligibility for
                                                                   employment, or for credit or insurance to be used
                                                                   primarily
                                                                   for personal, family, or household purposes.</p>
          </li>
          <li>
            <p><u>Social Standards Intellectual Property Marks.</u> Customer must not remove or obscure the copyright,
                                                                    trademark, service mark, or other notices contained
                                                                    in the Site, Services, Applications, or Content,
                                                                    regardless of whether such notices relate to rights
                                                                    possessed by Social Standards or any affiliate of
                                                                    Social Standards. Customer may not use the
                                                                    trademarks, service marks, logos, or other
                                                                    proprietary identifiers of Social Standards,
                                                                    affiliates of Social Standards, or Social Standards
                                                                    suppliers without prior written consent.</p>
          </li>
          <li>
            <p><u>Professional Conduct.</u> Customer must treat Social Standards employees with a reasonable
                                            level of cordiality and professionalism.</p>
          </li>

        </ol>
      </li>


      <br>

      <li>OWNERSHIP; THIRD-PARTY CONTENT; DATA LICENSE.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>Social Standards’ Ownership.</u> Social Standards retains all right, title and interest in and to, and
                                                   all
                                                   Intellectual Property Rights embodied in or related to the Services,
                                                   Technology, and any other
                                                   information or technology used or made available solely and directly
                                                   by Social Standards in connection
                                                   with the Technology and Services, including Social Standards’
                                                   proprietary data, Data Taxonomies and
                                                   algorithms provided to Customer (e.g., audience data, benchmarks,
                                                   rankings) and any and all
                                                   improvements, updates, and modifications to the Services and/or
                                                   Technology (explicitly excluding any
                                                   and all Customer Confidential Information). The Social Standards name
                                                   and logo, and the product and
                                                   service names associated with the Services are trademarks of Social
                                                   Standards or third parties, and no
                                                   right or license is granted to Customer to use them separate from
                                                   Customer’s right to access the
                                                   Services.</p>
          </li>
          <li>
            <p><u>Third Party Content.</u> Social Standards licenses content (“Third Party Content”) from various third-
                                           party providers (Third Party Providers”). Customer agrees that it will not:
                                           (i) use any Third Party Content
                                           (in aggregate form or otherwise) in connection with any online advertising
                                           network, advertising
                                           exchange, or data broker, (ii) remove or alter any proprietary notices,
                                           copyright notices or marks from
                                           the Third Party Content or (iii) display any Third Party Content that may
                                           create a false or misleading
                                           impression as to the origin or value of such Third Party Content. Customer
                                           shall defend, indemnify and
                                           hold harmless Social Standards from any claims arising from Customer’s use of
                                           the Third-Party Content
                                           or breach by Customer of Third-Party Provider terms of service, rules and
                                           policies. Social Standards
                                           may at its option immediately terminate Customer’s access to and use of
                                           Third-Party Content in the
                                           event of Customer’s breach of this Agreement.</p>
          </li>
          <li>
            <p><u>Customer Analysis.</u> Subject to Social Standards’ ownership of the Service and Third-Party
                                         Providers’ ownership of Third-Party Content, Customer retains all rights, title
                                         and interest in Customer’s
                                         own analysis of information and data generated from queries employed by
                                         Customer in its use of the
                                         Service.</p>
          </li>
          <li>
            <p><u>Feedback.</u> Social Standards may, without restriction, use, reproduce, copy, sell, license,
                                distribute, publicly display or perform, incorporate into the Services, or otherwise
                                exploit any suggestions,
                                enhancement requests, recommendations or other feedback provided by Customer, relating
                                to the
                                operation of the Services.</p>
          </li>
          <li>
            <p><u>Attribution.</u> All digital or printed copies of reports, analyses, output, other materials or
                                   information
                                   generated or produced by the Service shall include the following attribution to
                                   Social Standards: “Source:
                                   Social Standards, Inc.”</p>
          </li>

        </ol>
      </li>

      <br>

      <li>TERM.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>Term.</u> Subject to earlier termination as provided below, Customer’s subscription (“Subscription”)
                            shall commence from the Subscription Start Date and continue through the Subscription End
                            Date as set forth
                            on an applicable Order Form (“Initial Subscription Term”). Each Order Form will
                            automatically renew for a
                            period of one year each year (“Renewal Subscription Term(s)”) unless either Party provides
                            written notice
                            of their intention to cancel the Order Form thirty days prior to the end of the Initial
                            Subscription Term
                            or any subsequent Renewal Subscription Term(s).</p>
            <p>Customer may elect to upgrade its Subscription at any time. At any time at which Customer upgrades its
               Subscription, Customer shall pay any additional fees associated with such higher Subscription and, to the
               extent Customer has already pre-paid fees for its then current Subscription, Customer shall pay the
               incremental increase in fees for the upgraded Subscription over the prior Subscription in advance for the
               balance of the then-current Subscription. Social Standards will invoice Customer for any additional
               Social
               Standards fees related to a Subscription upgrade, which Social Standards fees will be due thirty (30)
               days
               from the date of invoice. Customer may not downgrade its Subscription at any time.</p>
          </li>
          <li>
            <p><u>Termination.</u> Except as otherwise stated herein, if Social Standards breaches a material term
                                   of the Agreement, and such breach is not cured within thirty (30) days after Social
                                   Standards receives
                                   written notice from Customer of such breach, Customer may terminate the Agreement
                                   upon the conclusion of
                                   such thirty (30) day period and Customer shall not be obligated to pay any sums due
                                   hereunder (except any
                                   sums due for the enjoyment of the Services prior to such termination). This Agreement
                                   will automatically
                                   terminate if Customer breaches a material term of this Agreement, and such breach is
                                   not cured within
                                   thirty (30) days after Customer receives written notice from Social Standards of such
                                   breach.</p>
          </li>
          <li>
            <p><u>Suspension.</u> Social Standards may only suspend the Services, or any portion thereof, or this
                                  Agreement for a material breach, including, but not limited to, a material breach of
                                  the payment
                                  obligations set forth in Section 7 below, with advance written notice of ten (10)
                                  business days, during
                                  which time Customer shall have the opportunity to cure such material breach (for
                                  clarity, if Customer
                                  reasonably cures such material breach, Social Standards shall not have the right to
                                  suspend the Agreement).</p>
          </li>
          <li>
            <p><u>Effect of Termination or Expiration.</u> Upon any termination or expiration of this Agreement,
                                                           (a) all rights and licenses granted to each party by the
                                                           other party in this Agreement will immediately cease
                                                           to exist (except for any irrevocable licenses); (b) Social
                                                           Standards may promptly cease performing all
                                                           obligations under this Agreement; and (c) all access by
                                                           Customer and any Users to the Services will be
                                                           immediately terminated. The provisions of Sections 1, 2.c, 5,
                                                           6, 7 through 22 of this Agreement will survive
                                                           termination of the Agreement for any reason.</p>
          </li>
        </ol>
      </li>


      <br>

      <li>FEES AND PAYMENT.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>Fees.</u> Customer will pay the Annual Subscription Fee and other payments set forth in the
                            Order Form of the Agreement (“Fees”) when due. Social Standards is not required to begin
                            performance of
                            any Services prior to receipt of any and all Fees due as per the invoicing structure set
                            forth in each
                            applicable Order Form.</p>
          </li>
          <li>
            <p><u>Payment.</u> Fees must be paid in U.S. Dollars. Customer agrees to pay invoices via electronic
                               transfer payments to the bank and account number designated by Social Standards. Fees
                               will be invoiced in
                               accordance with the payment terms set forth in each applicable Order Form. Customer will
                               notify Social
                               Standards of any good faith dispute relating to any Fees in writing within thirty (30)
                               days of Customer’s
                               receipt of the invoice. Regardless of any reasonable dispute as to any Fees on an
                               invoice, Customer will
                               pay all other non-disputed Fees on such invoice (and all other non-disputed Fees on every
                               other invoice),
                               in accordance with the terms of this Agreement. Customer’s obligation to pay Fees not
                               subject to a
                               reasonable dispute will be unconditional and not subject to abatement, set off or defense
                               of any kind.
                               All Fees are nonrefundable. Any portion of the non-disputed Fees not paid when due will
                               accrue interest at
                               one percent (1%) per month or the maximum rate permitted by applicable law, whichever is
                               less, from the
                               due date until paid.</p>
          </li>
          <li>
            <p><u>Taxes.</u> Unless otherwise stated, Social Standards’ fees do not include any taxes, levies,
                             duties or similar governmental assessments of any nature, including, but not limited to,
                             value-added,
                             sales and use, or withholding taxes, assessable by any local, state, provincial, federal or
                             foreign
                             jurisdiction (collectively, “Taxes”). Customer is responsible for paying all Taxes
                             associated with its
                             purchases hereunder. If Social Standards has the legal obligation to pay or collect Taxes
                             for which
                             Customer is responsible under this paragraph, the appropriate amount shall be invoiced to
                             and paid by
                             Customer, unless Customer provides Social Standards with a valid tax exemption certificate
                             authorized by
                             the appropriate taxing authority. For clarity, Social Standards is solely responsible for
                             taxes assessable
                             against it based on its income, property and employees.</p>
          </li>
        </ol>
      </li>

      <br>

      <li>CONFIDENTIAL INFORMATION.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>Obligations.</u> The party receiving Confidential Information (the “Receiving Party”) from the
                                   other party (the “Disclosing Party”) will not use any Confidential Information of the
                                   Disclosing Party
                                   for any purpose other than the provision and receipt of Services under this
                                   Agreement. Further, the
                                   Receiving Party will disclose the Confidential Information of the Disclosing Party
                                   only to the employees
                                   or contractors of the Receiving Party (or of the Receiving Party’s group of
                                   companies) who have a need to
                                   know such Confidential Information for purposes of this Agreement and who are under a
                                   duty of
                                   confidentiality no less restrictive than the Receiving Party’s duty hereunder. The
                                   Receiving Party will
                                   protect the Disclosing Party’s Confidential Information from unauthorized use, access
                                   or disclosure in
                                   the same manner as the Receiving Party protects its own confidential or proprietary
                                   information of a
                                   similar nature and with no less than reasonable care.</p>
          </li>
          <li>
            <p><u>Termination of Obligations.</u> The Receiving Party’s obligations under this Section 8 with
                                                  respect to any Confidential Information of the Disclosing Party will
                                                  terminate if and when the Receiving
                                                  Party can document that such information: (a) was already lawfully
                                                  known to the Receiving Party at the
                                                  time of disclosure by the Disclosing Party; (b) is disclosed to the
                                                  Receiving Party by a third party who
                                                  had the right to make such disclosure without any confidentiality
                                                  restrictions; (c) is, or through no
                                                  fault of the Receiving Party, has become, generally available to the
                                                  public; or (d) is independently
                                                  developed by the Receiving Party without access to, or use of, the
                                                  Disclosing Party’s Confidential
                                                  Information. In addition, the Receiving Party will be allowed to
                                                  disclose Confidential Information of
                                                  the Disclosing Party to the extent that such disclosure is: (i)
                                                  approved in writing by the Disclosing
                                                  Party; (ii) necessary for the Receiving Party to enforce its rights
                                                  under this Agreement in connection
                                                  with a legal proceeding; or (iii) required by law or by the order of a
                                                  court of similar judicial or
                                                  administrative body, provided that (and subject to Subsection 8.a
                                                  above) the Receiving Party notifies the
                                                  Disclosing Party of such required disclosure promptly and in writing
                                                  and cooperates with the Disclosing
                                                  Party, at the Disclosing Party’s reasonable request and expense, in
                                                  any lawful action to contest or
                                                  limit the scope of such required disclosure.</p>
          </li>
          <li>
            <p><u>Return of Confidential Information.</u> The Receiving Party will return to the Disclosing
                                                          Party or destroy all Confidential Information of the
                                                          Disclosing Party in the Receiving Party’s possession
                                                          or control and permanently erase all electronic copies of such
                                                          Confidential Information promptly upon
                                                          the written request of the Disclosing Party or the expiration
                                                          or termination of this Agreement, whichever
                                                          comes first. At the Disclosing Party’s request, the Receiving
                                                          Party will certify in writing that it has
                                                          fully complied with its obligations under this Subsection 8.c.
            </p>
          </li>
        </ol>
      </li>

      <br>

      <li>REPRESENTATIONS, WARRANTIES, DISCLAIMER.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>Representations and Warranties.</u> Each party represents and warrants to the other party
                                                      that:

                                                      (a) such party has all requisite corporate or other applicable
                                                      power and authority to execute,
                                                      deliver and perform its obligations under this Agreement;
                                                      (b) the execution, delivery and performance of this Agreement by
                                                      such party has been duly
                                                      authorized; and will not conflict with, result in a breach of, or
                                                      constitute a default under any
                                                      other agreement to which such party is a party or by which such
                                                      party is bound;
                                                      (c) such party is duly licensed, authorized or qualified to do
                                                      business and is in good standing in
                                                      every jurisdiction in which a license, authorization or
                                                      qualification is required for the transaction
                                                      of business of the character transacted by such party; and
                                                      (d) such party has obtained all applicable permits and licenses,
                                                      if any, required of such party in
                                                      connection with such party’s obligations under this Agreement and
                                                      will, in such party’s performance
                                                      of this Agreement, comply with all applicable laws, rules,
                                                      treaties, and regulations.</p>

          </li>
          <li>
            <p><u>Social Standards Representations and Warranties.</u> Social Standards represents and warrants
                                                                       that the data, information and other content
                                                                       included on or accessible through the Service
                                                                       will not
                                                                       contain any viruses or other computer programming
                                                                       routines or defects that are intended to damage,
                                                                       detrimentally interfere with or expropriate any
                                                                       system, data, or information and shall be free
                                                                       and clear
                                                                       of all liens, encumbrances and claims or demands
                                                                       of third parties.</p>
          </li>
          <li>
            <p><u>Disclaimer.</u> EXCEPT FOR THE WARRANTIES EXPLICITLY SET FORTH IN THIS AGREEMENT, SOCIAL
                                  STANDARDS PROVIDES THE TECHNOLOGY AND SERVICES “AS IS” AND TO THE MAXIMUM EXTENT
                                  PERMITTED BY LAW,
                                  SPECIFICALLY DISCLAIMS ALL OTHER WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, REGARDING
                                  THE SERVICES,
                                  INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, AND FITNESS FOR A
                                  PARTICULAR
                                  PURPOSE. CUSTOMER ACKNOWLEDGES THAT IT HAS RELIED ON NO SUCH WARRANTIES IN ENTERING
                                  INTO THIS AGREEMENT.
                                  FURTHER, SOCIAL STANDARDS DOES NOT WARRANT, GUARANTEE OR MAKE ANY REPRESENTATION
                                  REGARDING THE USE, OR
                                  THE RESULTS OF THE USE, OF THE TECHNOLOGY AND SERVICES IN TERMS OF CORRECTNESS,
                                  ACCURACY, RELIABILITY OR
                                  OTHERWISE.</p>
          </li>
        </ol>
      </li>

      <br>

      <li>INDEMNIFICATION.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>By Social Standards.</u> Social Standards will, at its own expense, indemnify, defend and hold
                                           harmless Customer and pay all costs, damages and expenses (including
                                           reasonable outside attorney’s fees)
                                           awarded against or incurred by Customer based on any third-party claim that
                                           authorized use of the Services
                                           by Customer infringes any Intellectual Property Rights, or rights of privacy
                                           or of publicity. Social
                                           Standards' obligation will not extend to a claim based on any alleged
                                           infringement arising from any
                                           (a) additions, changes or modifications to the Services by or on behalf of
                                           Customer;
                                           (b) unauthorized use or incorporation of the Services or any component
                                           thereof with or into any other
                                           service, product or process;
                                           (c) Customer Confidential Information provided to Social Standards by
                                           Customer;
                                           (d) use of the Services other than as permitted by this Agreement or
                                           (e) arising out of or in connection with Social Standard’s breach of its
                                           representations and warranties
                                           set forth in Subsection 7.a. Should the Services become, or in Social
                                           Standards' opinion be likely to
                                           become, the subject of any such suit or action for infringement or if
                                           Customer is enjoined from using the
                                           Services, then Social Standards will, at Social Standards' option and
                                           expense: (i) procure for Customer
                                           the right to continue using the Services; or (ii) replace or modify such
                                           Services so that they become
                                           non-infringing; or (iii) terminate the Agreement and refund any moneys
                                           prepaid by Customer to Social
                                           Standards for the Services that have not been performed.
            </p>
          </li>
          <li>
            <p><u>By Customer.</u> Customer will, at Customer’s own expense, indemnify, defend, hold harmless
                                   and pay all costs, damages and expenses (including reasonable outside attorney’s
                                   fees) awarded against or
                                   incurred by Social Standards based on any claims or lawsuits that may be made or
                                   filed against Social
                                   Standards by any person: (a) as a direct result of any use by Customer or any User of
                                   the Services other
                                   than as intended in the Agreement (excluding claims for which Social Standards is
                                   liable to indemnify
                                   Customer under Subsection 10.a above); (b) that use by Social Standards under this
                                   Agreement of Customer
                                   Confidential Information, Data or Confidential Information infringes or
                                   misappropriates the Intellectual
                                   Property Rights of, or has caused harm or damage to, a third party; or (c) arising
                                   out of or in
                                   connection with Customer’s breach of its representations and warranties set forth in
                                   Subsection 9.a.
            </p>
          </li>
          <li>
            <p><u>Procedure.</u> The indemnifying party's indemnification obligations under this Section 10 are
                                 conditioned upon the indemnified party: (a) giving prompt notice of the claim to the
                                 indemnifying party;
                                 (b) granting sole control of the defense or settlement of the claim or action to the
                                 indemnifying party
                                 (except that the indemnified party's prior written approval will be required for any
                                 settlement that
                                 reasonably can be expected to require a material affirmative obligation of or, result
                                 in any ongoing
                                 material liability to the indemnified party); and (c) providing reasonable cooperation
                                 to the indemnifying
                                 party and, at the indemnifying party's request and expense, assistance in the defense
                                 or settlement of
                                 the claim. In any event, the indemnified party will have the option of participating in
                                 the defense at
                                 its own expense.
            </p>
          </li>
        </ol>
      </li>

      <br>

      <li>LIMITATION OF LIABILITY.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>No Liability for Fault with Content.</u> Except as expressly agreed otherwise in this
                                                           Agreement, (a) the Content is provided “as-is” and “as
                                                           available,” and (b) Social Standards will not be liable for
                                                           any damages incurred by Subscriber that result from
                                                           Subscriber’s use of the Content.
            </p>
          </li>
          <li>
            <p><u>Categorical Limitation on Damages.</u> Except for (a) Subscriber’s indemnification obligations
                                                         under this Agreement, (b) Subscriber’s obligations to pay Fees
                                                         under this Agreement, (c) Subscriber’s liability
                                                         if it breaches the promises contained in Section 8, and (d)
                                                         Subscriber’s willful misconduct or gross negligence,
                                                         under no circumstances will either party be liable for any
                                                         indirect, incidental, special punitive, exemplary,
                                                         or consequential damages with respect to this Agreement. This
                                                         categorical limitation on damages applies even if
                                                         such damages could have been foreseen or prevented.
            </p>
          </li>
          <li>
            <p><u>Limitation on Liability.</u> Except for (a) Subscriber’s indemnification obligations under this
                                               Agreement, (b) Subscriber’s obligations to pay Fees under this Agreement,
                                               (c) Subscriber’s liability if it
                                               breaches the promises contained in Section 8, and (d) Subscriber’s
                                               willful misconduct or gross negligence, under
                                               no circumstances will either party be liable to the other party in excess
                                               of the amount of Fees actually paid
                                               by Subscriber to Social Standards under this Agreement within the 12
                                               months preceding the liability-causing
                                               events.
            </p>
          </li>
          <li>
            <p><u>Exclusions.</u> IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
                                  EXEMPLARY, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, WHETHER FOR BREACH OF CONTRACT,
                                  NEGLIGENCE OR OTHERWISE,
                                  AND REGARDLESS OF WHETHER SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF THOSE
                                  DAMAGES, INCLUDING WITHOUT
                                  LIMITATION, COSTS OF PROCUREMENT OF SUBSTITUTE GOODS, LOST PROFITS OR REVENUES,
                                  BUSINESS INTERRUPTION, LOSS OR
                                  RECONSTRUCTION OF DATA, OR LOSS OF ACCESS TO DATA. IN JURISDICTIONS WHERE LIMITATION
                                  OF LIABILITY FOR
                                  CONSEQUENTIAL OR INCIDENTAL DAMAGES IS NOT PERMITTED, EACH PARTY’S LIABILITY IS
                                  LIMITED TO THE MAXIMUM EXTENT
                                  PERMITTED BY LAW.
            </p>
          </li>
        </ol>
      </li>

      <br>

      <li>SECURITIES MATTERS.
        <ol style="position:relative; top:1em;" type="a">
          <li>
            <p><u>No Investment Advice.</u> The Site, Services, Application, and Content are for informational
                                            purposes only. Nothing in the Content constitutes, and nothing in the
                                            Content should be construed as: (a) a
                                            solicitation or offering of any investment or securities or a recommendation
                                            to acquire or dispose of any
                                            investment or security; or (b) the provision of any financial, tax, legal,
                                            or other advice.
            </p>
          </li>
          <li>
            <p><u>No Offer of Securities.</u> Nothing in the Site, Services, Application, or Content will be
                                              deemed to constitute: (1) information that specifically addresses any
                                              specific individual’s investment
                                              objectives, financial situation, or the particular needs of any specific
                                              person who may receive the Services
                                              or Content; (2) establishing an advisory relationship; or (3) a
                                              transaction in securities for the account of
                                              others.
            </p>
          </li>
          <li>
            <p><u>Independence.</u> None of Social Standards’ directors, officers, employees, or agents (a) acts
                                    on behalf of any other entity in providing information in the Content, (b) is paid
                                    to market securities to
                                    investors, (c) participates in negotiations between an entity providing information
                                    in the Content and any
                                    investor, (d) handles any money or securities in transactions between investors and
                                    any entity providing
                                    information in the Content, or (e) assists any entity providing information in the
                                    Content with the completion
                                    of any securities transactions between such entity and an investor.

            </p>
          </li>
        </ol>
      </li>
      <br>
      <li><p>
        LINKS TO THIRD-PARTY SITES. The Site may contain links to other web sites (“Linked Sites”).
        The Linked Sites are not under the control of Social Standards and
        Social Standards is not responsible for
        the contents or operation of any Linked Site. Social Standards
        provides these links only as a convenience, and
        the inclusion of any link does not imply endorsement by Social
        Standards of the Linked Sites or any
        association with its operators.
      </p></li>

      <li><p>
        ASSIGNMENT. Customer may not assign or otherwise transfer any goods or services, or licenses
        received under this Agreement to a third party without the prior written consent of
        Social Standards, not to
        be unreasonably withheld. Notwithstanding the foregoing, Customer may assign all of
        its rights and duties
        under this Agreement without Social Standards’ consent to a third party that: (a)
        directly or indirectly
        controls, is controlled by or is under common control with Customer; or (b) purchases
        or will purchase all or
        substantially all of Customer’s assets; provided that: (i) Customer gives prior
        written notice to Social
        Standards of the assignment, and (ii) the assignee agrees to be bound by all the terms
        of this Agreement.
        Unless specifically authorized in writing by Social Standards, assignment of this
        Agreement will not release
        Customer from any prior outstanding obligation under this Agreement. Social Standards
        may assign or otherwise
        transfer this Agreement to a third party; provided, however, that Social Standards
        shall remain secondarily
        liable in the event of any such assignment of this Agreement to a third party. This
        Agreement will inure to
        the benefit of each party’s successors and permitted assigns. Any assignment in
        violation of this Section 14 is
        null and void.
      </p></li>

      <li><p>
        RELATIONSHIP. The parties are and remain at all times independent contractors and no agency,
        partnership, or joint venture is created by this Agreement. Neither party has the
        authority to act for, bind,
        or incur any debts or liabilities on behalf of, the other party in any respect
        whatsoever. Each party will act
        in good faith and refrain from activities that attempt to induce the other party’s
        employee(s) to leave their
        employer, or to interfere with the other party’s relationship with its employees.
        The parties agree that
        during the Term of the Agreement, and for a period of twelve (12) months thereafter,
        neither party will, in
        any way, directly or indirectly (a) induce or attempt to persuade any employee of
        the other to quit employment,
        (b) otherwise interfere with or disrupt the other party’s relationship with its
        employees, or (c) knowingly
        solicit, entice or hire away any employees of the other party. Nothing in this
        Section 15 prohibits either
        party from hiring in response to a general solicitation for employment or if
        approached by the prospective
        employee without solicitation by the party hiring.
      </p></li>

      <li><p>
        FORCE MAJEURE. Social Standards will be excused from performance under this Agreement for any
        period and to the extent that it is prevented from performing pursuant hereto, in
        whole or in part, as a result of delays caused by Customer or third parties or an
        act of nature, war, civil disturbance, court order, labor dispute or other cause
        beyond Social Standards’ reasonable control, including without limitation,
        failures or fluctuations in electrical equipment. In addition, the Services may be
        subject to limitations, delays, and other problems inherent in the use of the
        Internet and electronic communications. Customer acknowledges that the Services may
        be affected by numerous factors outside of Social Standards’ control and that
        Social Standards is not responsible for any delays, failures, or other damage
        resulting from such problems.
      </p></li>

      <li><p>
        WAIVER OF CLASS ACTION. Except as otherwise specifically prohibited by applicable law, all
        disputes arising from or related to this Agreement will be adjudicated on
        an individual basis and not in a
        class or representative action or as a member of a class, mass,
        consolidated or representative action,
        irrespective of the forum in which such disputes are heard. Subscriber
        will not join any of its claims related
        to this Agreement with the claim or claims of any other person or entity.
      </p></li>

      <li><p>
        EXPORT CONTROL COMPLIANCE. Customer will comply with all relevant export and trade control
        laws, regulations, or requirements of the United States and other
        relevant jurisdictions, including, without
        limitation, the U.S. Export Administration Regulations (15 C.F.R Part
        730 et seq.) and U.S. economic sanctions
        and embargoes (codified in 31 C.F.R Chapter V). Without limiting the
        general nature of the previous sentence,
        Customer will comply with all relevant laws governing Customer’s
        purchase, receipt, use, disclosure, or
        re-export of any goods (including any hardware, software or technology)
        provided to it under this Agreement.
      </p></li>

      <li><p>
        NOTICES. All notices, consents and approvals under this Agreement must be delivered in
        writing by overnight delivery with a tracking system, personal delivery, or -for notices
        delivered within
        mainland USA only- certified mail, postage pre-paid, to the other party at its address
        set forth in each Order
        Form or at such other address as may be later designated in writing by such party.
        Notices will be deemed to
        have been received upon the date of receipt or, in the case of certified mailing, two (2)
        days after deposit in
        the mail. This Agreement will be governed by the laws of the State of California without
        regard to conflicts
        of law principles.
      </p></li>

      <li><p>
        GOVERNING JURISDICTION. All disputes arising under or in connection with this Agreement must
        be brought in the state and federal courts located in Los Angeles County,
        California. Each party irrevocably
        hereby consents to the jurisdiction and venue of any such court in any
        such action or proceeding.
      </p></li>

      <li><p>
          GENERAL TERMS. If any provision of this Agreement is held invalid, illegal, or unenforceable,
          including, without limitation, as a result of unconscionability or inconsistency
          with public policy, such
          provision will be construed so as to come as close as possible to its intended
          meaning, and the validity,
          legality, or enforceability of the remaining provisions will in no way be impaired.
          Under no circumstances will
          the preprinted terms of any purchase order or any other terms apply to this
          Agreement. Capitalized terms have
          the meanings given in this Agreement. No waiver of any of the terms or conditions
          of this Agreement will be
          binding for any purpose unless made in writing and signed by authorized
          representatives of both parties and any
          such waiver will be effective only in the specific instance and for the purpose
          given. No failure or delay on
          the part of either of the parties in exercising any right will operate as a waiver,
          nor will any single or
          partial exercise by either of the parties of any right preclude any other or
          further exercise thereof or the
          exercise of any other right.
      </p></li>

      <li><p>
        ENTIRE AGREEMENT. This Agreement constitutes the entire, final and exclusive agreement between
        the parties regarding the subject matter hereof, and supersede all prior or
        contemporaneous agreements,
        understandings, discussions, negotiations and communications, whether written or
        oral, express or implied. No
        amendment to or modification of this Agreement shall be binding unless in
        writing and signed by a duly
        authorized representative of both parties.
      </p>
      </li>


    </ol>

  </v-container>
</template>

<script>
export default {
  name: "terms-conditions"
}
</script>

<style scoped>
h1 {
  text-align: center;
}

p {
  text-align: justify;
}


</style>