import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"mb-7"},_vm._l((_vm.trainingList),function(product,i){return _c(VCol,{key:i,attrs:{"cols":"4"}},[_c('a',{staticClass:"grid-content",on:{"click":function($event){return _vm.viewFile(product.remote_file, product.remote_filename, product.object_uid, product.report_url)}}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"pa-1 mx-auto",staticStyle:{"border":"3px solid #f3faff","border-radius":"12px"},attrs:{"tile":"","height":"170","elevation":hover ? 6 : 1}},[_c('div',{staticClass:"vertical-center"},[_c(VContainer,{staticClass:"grid-title"},[_c('div',{staticClass:"vertical-center"},[_c('b',[_vm._v(_vm._s(product.name))])])]),_c(VContainer,{staticClass:"grid-desc"},[_c('div',{staticClass:"vertical-center",staticStyle:{"padding-top":".3em","padding-bottom":"1.5em","padding-right":"0em","padding-left":"0em"}},[_vm._v(_vm._s(product.description))])])],1)])]}}],null,true)})],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }