<template>
<div>
  <div style="height:0px;" v-if="!error404">
    <v-row justify="center" style="padding-top:26em;">
      <img class="wheel-a" width="75px" height="75px" src="@/assets/loaderA.png" alt="A">
      <img class="wheel-b" style="position:relative; right:52px; top:22.5px;" width="30px" height="30px" src="@/assets/loaderB.png" alt="B">
    </v-row>
  </div>

  <!-- <span v-else> -->
  <div v-if="!error404">
    <MainTemplate @loaded="loader" :myObject="this.myItem" :start="this.full" :filteredDomains="availableDomains" :filteredSources="availableSources" @domainChange="changeMyItem" @objectChange="changeMyItemB" :key="this.myItem" />
  </div>

  <div v-else>
    <v-app-bar absolute color="#D6E1EF" elevation="0" height="80">
      <router-link :to="{name: 'Home'}"><img src="@/assets/logo_navyL.png" width="200" style="margin: 1em 1em 0em 3em;"></router-link>
      <router-link :to="{name: 'ObjectSearch'}"><v-btn style="background-color:transparent; margin-left:2em; margin-right:1em; margin-top:.5em" elevation="0"><span style="font-size:1em; font-weight:500">CF Home</span></v-btn></router-link>
      <v-form ref="smallSearch" style="width:250px;" v-if="this.$vuetify.breakpoint.name !== 'xs'">
        <v-text-field style="padding-left:2em;" v-model="search"  append-icon="mdi-magnify" label="Search" @click:append="pushResult()" @keydown.enter="pushResult()" single-line hide-details light></v-text-field>
      </v-form>
      <v-spacer></v-spacer>
      <v-btn v-if="this.$vuetify.breakpoint.width <= 1264" @click="openI()" elevation="0" style="background-color: transparent;">
        <v-icon medium>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
  
    <v-container style="position:absolute; top:-1em; right:1em;">
    <div style="float:right; width:300px;" v-if="this.$vuetify.breakpoint.width > 1264">
      <v-select
      v-model="selectDomain"
      :placeholder="domainName"
      :items="availableDomains"
      item-value="domain_sid"
      item-text="domain_name"
      light
      :menu-props="{ bottom: true, offsetY: true, maxHeight: 450}"
      height="100%"
      hint="Switch Market Vertical"
      persistent-hint
      style="margin-right:2em; z-index:10000;"
      @change="switchVertical(selectDomain)"
      ></v-select>
    </div>
    </v-container>

    <v-container style="margin-top:10em">
      <v-row justify="center" >
        <span style="font-size:150px; color:#37474F;">404 <span style="font-size:200px; color:#37474F;">|</span></span>
        <span style="font-size:45px; color:#37474F; position:relative; top:2.7em;">
          Item not found.
        </span>
      </v-row>
      <v-row justify="center" style="padding-top:2em; padding-bottom:3em; padding-left:5em;">
      <div style="width: 60%">
        <v-row style="justify-content: space-evenly !important;">
        <a>
          <v-btn rounded elevation="0" class="back" @click="sourceSwitch=!sourceSwitch" >
            <v-icon x-small style="margin-top:.5em; margin-right:.3em;">mdi-swap-horizontal</v-icon>
            Switch Source
          </v-btn>
        </a>
        <span style="font-size: 20px; color:#37474F;">or</span>
        <a>
          <v-btn rounded elevation="0" class="back" @click="openChangeD()">
            <v-icon x-small style="margin-top:.5em; margin-right:.3em;">mdi-swap-horizontal</v-icon>
            Switch Market Vertical
          </v-btn>
        </a>
         </v-row>
      </div>
      </v-row>
      <v-row justify="center" style="padding-bottom:3em;">
        <span style="font-size:20px; color:#37474F;">
          or try another search:
        </span>
      </v-row>

      <v-container style="padding-top:2em; width:85%; align-items: center">
        <v-row justify="center">
          <div style="width: 100%">

              <v-text-field v-model="search"  append-icon="mdi-magnify" label="Search" @click:append="pushResult()" @keydown.enter="pushResult()" solo rounded hide-details light></v-text-field>

                <span style="float:right; margin-right:3.5em; padding-top:0.5em;">
                  <a class="adv" style="margin-right:1em;" @click="openAdvancedSearch()">Advanced Search</a>
                  <a class="adv" @click="sourceSwitch=!sourceSwitch">Source: {{translateSource(getCurrentSource)}}</a>
                </span>

          </div>
        </v-row>
      </v-container>
    </v-container>
  </div>

  <v-dialog v-model="advSearch" width="650" @click:outside="closeAdvancedSearch()">
      <AdvancedSearch v-on:closeDialog="closeAdvancedSearch()"></AdvancedSearch>
  </v-dialog>

  <!-- Where is this actually being used? -->
  <v-dialog v-model="sourceSwitch" width="450" @click:outside="sourceSwitch=!sourceSwitch">
      <LookupSourceSwitch
          v-on:closeSourceDialog="sourceSwitch=!sourceSwitch"
          v-on:updateSource="closeAndReload()"
          :filteredSources="availableSources"
      ></LookupSourceSwitch>
  </v-dialog>

  <!-- Where is this actually being used? -->
  <v-dialog v-model="domainD" max-width="400">
   <v-card width="400px" height="200px">
     <v-card-title>
        <v-icon @click="closeDomainD()" style="position:absolute; right:.5em; top:.5em;" sm>mdi-close</v-icon>
      </v-card-title>
      <v-card-text style="padding-top:2em;">
        <v-row>
          <span style="padding-left:1em">Switch Market Vertical</span>
        </v-row>
        <v-row justify="center" style="padding:1em;">
          <v-select
          v-model="selectDomain"
          :placeholder="this.domainName"
          :items="availableDomains"
          item-value="domain_sid" 
          item-text="domain_name"
          light
          :menu-props="{ bottom: true, offsetY: true, maxHeight: 450}"
          height="100%"
          hint="Switch Market Vertical"
          persistent-hint
          style="margin-right:2em; z-index:10000;"
          @change="switchVertical(selectDomain)"
          ></v-select>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <div style="height: 0px">
    <v-snackbar
        v-model="snackbarActive"
        :timeout="timeout"
        color="#DB5040"
        elevation="24"
    >
      {{ text }}


    </v-snackbar>
  </div>

</div>
  
</template>
<script>
import MainTemplate from '@/components/ConsumerForecast/MainTemplate.vue';
import objectFunctions from '@/mixins/objectFunctions';
import AdvancedSearch from "@/components/ConsumerForecast/Lookups/PageElements/AdvancedSearch";
import stringFunctions from '@/mixins/stringFunctions';
import { mapActions, mapGetters } from 'vuex'
import LookupSourceSwitch from "@/components/ConsumerForecast/Lookups/PageElements/LookupSourceSwitch";

export default {
  name: 'lookup-view',
  mixins: [objectFunctions, stringFunctions],
  components: {
    LookupSourceSwitch,
    MainTemplate,
    AdvancedSearch,
  },
  created() {
    window.onpopstate = function () {
      location.reload()
    };

    console.log("created, router source: " + this.$route.query.source)
    console.log("created, currentSource: " + this.getCurrentSource)

  },

  mounted() {
    //this.getFilteredDomainsAndSources()
    // this.getMyItem()
    this.init()
    this.snackbarActive = false
  },

  computed: {

    sidQuery() {
      return this.$route.query.sid
    },

    ...mapGetters([
      'getDomainsAvailable',
      'getCurrentSource',
      'getSourceList'
    ]),

    domainName() {
      let x = this.$route.query.domain
      return (x in objectFunctions.marketVerticals) ? objectFunctions.marketVerticals[x] : ''
    },
  },

  data() {
    return {
      advSearch: false,
      availableDomains: [],
      availableSources: [],
      myItem: {},
      ogType: '',
      loading: true,
      error404: false,
      search: '',
      selectDomain: '',
      sourceSwitch: false,
      domainD: false,
      // snackbar
      domainInvalid: false,
      snackbarActive: false,
      text: 'Market vertical updated',
      timeout: 9000,
    }
  },
  methods: {
    ...mapActions([
      'getObjectBlob',
      'changeCurrentSource',
      'getFullObjectInfo',
    ]),

    async init() {
      console.log('initializing LookupView')

      let currSource = this.$route.query.source
      if (currSource == undefined || currSource == '') {
        currSource = this.getCurrentSource
        await this.$router.push(this.getLookupViewPath(
            this.$route.query.sid, this.$route.query.domain, this.$route.query.type ? this.$route.query.type : '', currSource))
      }
      let payload = {
        sid: this.$route.query.sid,
        domain: this.$route.query.domain ? this.$route.query.domain: '',
        type: this.$route.query.type ? this.$route.query.type : '',
        source: currSource
      }
      let query = {
        sid: this.$route.query.sid,
        source: payload.source
      }
      await this.updateCurrentSource(currSource)

      await this.getFullObjectInfo(query)
          .then((data) => {
            // getting filtered sources
            let mySources = this.getSourceList
            console.log(mySources)
            let usableSources = []
            let objectSourceDomains = data.data.object.source_domains
            // match sources
            for (const [s, d] of Object.entries(objectSourceDomains)) {
              if (d != '' && mySources.map(item => item.value).includes(parseInt(s))) {
                usableSources.push(s)
              }
            }
            // match source domains
            let filteredSources = []
            for (const s of mySources) {
              if (usableSources.includes(s.value.toString())) {
                console.log('included: ', s.value)
                console.log(this.getDomainsAvailable[s.value].map(item => item.domain_sid))
                if (this.getDomainsAvailable[s.value].map(item => item.domain_sid).some(item => objectSourceDomains[s.value].map(item => item.domain_sid).includes(item))){
                  filteredSources.push(s)
                }
              }
            }
            this.availableSources = filteredSources

            // getting filtered domains
            let allowedDomains = this.getDomainsAvailable[this.$route.query.source]
            let filteredDomains = []

            if (data.data.object.domains != undefined) {
              let usableDomains = data.data.object.domains.map(function (d) {
                return d.domain_sid;
              })
              console.log('usable domains: ', usableDomains)

              for (const d of allowedDomains) {
                if (usableDomains.includes(d.domain_sid)) {
                  filteredDomains.push(d)
                  console.log('d: ', d)
                }
              }
            }
            this.availableDomains = filteredDomains
            let availableDomainSIDs = []
            availableDomainSIDs = this.availableDomains.map(function (d) {
              return d.domain_sid;
            })
            console.log('includes? ', availableDomainSIDs.includes(parseInt(payload.domain)))
            console.log('availableDomainSIDs: ', availableDomainSIDs)

            if (payload.domain == '' || availableDomainSIDs.includes(parseInt(payload.domain)) === false){
              console.log('current domain unavailable')
              this.noDomain()
            }
            else {
              this.getObjectBlob(payload)
                  .then((data) => {this.getData(data)})
                  .catch((err, resolve) => {
                    console.log(err);
                    this.handleErr(payload, err, resolve)
                  })
            }

          })
          .catch((err, resolve) => {
            console.log(err, resolve);
            this.handleErr(payload, err, resolve)
          })



    },


    openAdvancedSearch() {
      this.advSearch = true;
    },

    closeAdvancedSearch() {
      this.advSearch = false;
    },

    openChangeD() {
      this.domainD = true;
    },

    closeDomainD() {
      this.domainD = false;
    },

    closeAndReload() {
      this.sourceSwitch = false
      location.reload()
          .catch((err) => {
            console.log(err)
          })
    },

    switchVertical(sid) {
      console.log('switched', sid)
      this.changeMyItem(sid);
      this.error404 = false;
      this.domainD = false;
    },

    pushResult() {
      console.log(this.search)
      this.search = encodeURIComponent(this.search)
      this.$router.push({path: '/ConsumerForecast/search-result' + '?search=' + this.search})
    },

    loader(value) {
      this.loading = value
      document.getElementById('svg-loader').style.opacity = 0
    },

    determineCode(code) {
      objectFunctions.marketSpecificList.includes(code) ? this.ogType = true : this.ogType = false;
    },

    changeMyItem(value) {
      this.changeDomain(value)
    },
    changeMyItemB(value) {
      this.getMyItem(value.sid, value.domain, value.type, value.source)
    },

    changeDomain: async function (domain_sid) {
      console.log("changing domain: " + domain_sid)
      let payload = {
        sid: this.sidQuery,
        domain: domain_sid,
        type: this.$route.query.type,
        source: this.$route.query.source
      }
      console.log(payload)
      await this.getObjectBlob(payload)
          .then((data) => {
            console.log('changeDomain')
            this.getData(data)
          })
          .catch((err, resolve) => {
            console.log(err, resolve);
            this.handleErr(payload, err, resolve)
          })
    },

    // call this when URL domain is missing, user lacks permission to it, or current object has no info for source-domain
    noDomain: async function () {
      this.snackbarActive = true
      console.log('noDomain')
      console.log("Domains Available", this.availableDomains)

      // if there are no available domains, just try calling with whatever is in URL
      let d
      if (this.availableDomains.length == 0) {
        d = this.$route.query.domain
      } else {
        d = this.availableDomains[0].domain_sid
      }
      await this.callWithDomain(d)

    },

    callWithDomain: async function (match) {
      console.log("callWithDomain")

      let payload = {
        sid: this.sidQuery,
        domain: match,
        type: this.$route.query.type ? this.$route.query.type : '',
        source: this.$route.query.source ? this.$route.query.source : this.getCurrentSource
      }
      await this.getObjectBlob(payload)
          .then((data) => this.getData(data))
          .catch((err, resolve) => {
            // console.log(err);
            this.handleErr(payload, err, resolve)
          })
    },

    updateCurrentSource: async function (new_source) {
      console.log('updating source')
      await this.changeCurrentSource(new_source)
          .catch((err) => {
            console.log(err)
          })
    },


    getMyItem: async function (sid, domain, type, source) {
      console.log('getMyItem')
      console.log(sid, domain, type, source)

      if (sid) {
        console.log(sid, domain, type, source);
        let payload = {
          sid: sid,
          domain: domain,
          type: type,
          source: source,
        }
        await this.getObjectBlob(payload)
            .then((data) => this.getData(data))
            .catch((err, resolve) => {
              console.log(err);
              this.handleErr(payload, err, resolve)
            })
      }

      else if (this.$route.query.domain) {
        console.log("getMyItem - domain in query")
        let currSource = this.$route.query.source
        if (currSource == undefined) {
          currSource = this.getCurrentSource
        }
        let payload = {
          sid: this.sidQuery,
          domain: this.$route.query.domain,
          type: this.$route.query.type ? this.$route.query.type : '',
          source: currSource
        }

        // maybe check if currentSource is different from payload source before doing this.
        await this.updateCurrentSource(payload.source)

        console.log("domains available: ", this.getDomainsAvailable[payload.source])
        for (const entry of this.getDomainsAvailable[payload.source]) {
          console.log(entry.domain_sid)
        }

        if (!this.availableDomains.includes(payload.domain)){
          await this.noDomain()
        }
        else {
          await this.getObjectBlob(payload)
              .then((data) => {this.getData(data)})
              .catch((err, resolve) => {
                console.log(err);
                this.handleErr(payload, err, resolve)
              })
        }
      }
      else {
        // go here when url is missing domain or sid
        await this.noDomain()
      }
    },


    getData(data) {

      let source = data.data.source // source for which the data is returned in an API call

      this.full = data.data
      console.log(this.full)
      if (data.data.brand) {
        this.myItem = data.data.brand;
        this.determineCode(data.data.brand.type_code)
      }
      else if (data.data.product_service) {
        this.myItem = data.data.product_service;
        this.determineCode(data.data.product_service.type_code)
      }
      else if (data.data.descriptive_object) {
        this.myItem = data.data.descriptive_object;
        this.determineCode(data.data.descriptive_object.type_code)
      }
      this.$router.push(this.getLookupViewPath(
          this.myItem.object_sid, this.full.domain_sid, this.myItem.type_code, source))

    },

    handleErr(payload, err, resolve) {
      if (err.response?.status == 402 || err.response?.status == 403 || err.response?.status == 405 ) {
        this.$router.push({path: '/AppView/something-went-wrong?status=' + err.response.status})
      }
      else if (err.response?.status == 404) {
        this.$router.push(this.getLookupViewPath(payload.sid, payload.domain, payload.type, payload.source))
        this.error404 = true;
        resolve(err)
      }
      // this will be reached if err is undefined (or status is not 402/404), which should hopefully never happen
      else {
        console.log("Cannot determine error status")
        this.$router.push({path: '/AppView/something-went-wrong?status=404'})
        this.error404 = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>

@keyframes rotateleft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes rotateright {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adv {
  color: #37474F !important;
}
.adv:hover {
  color: #6387b0 !important;
}

.wheel-a {
  animation: rotateleft 4s infinite;
}
.wheel-b {
  animation: rotateright 4s infinite;
}

.back {
  background-color:#D6E1EF !important;
}

.back:hover {
  position: relative;
  bottom:.2em;
}
</style>


