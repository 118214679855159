<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        style="position:absolute; right:122px; background-color:transparent; font-size:1.1em;"
      >
        Client Tools
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="push('portal')">
        <v-list-item-title>Portal</v-list-item-title>
      </v-list-item>

      <v-list-item @click="push('ObjectSearch')" v-if="this.$vuetify.breakpoint.width > 800">
        <v-list-item-title>ConsumerForecast.</v-list-item-title>
      </v-list-item>

      <v-list-item @click="push('knowledgebase')">
        <v-list-item-title>Knowledgebase</v-list-item-title>
      </v-list-item>

      <v-list-item @click="logout()">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex';
import processingMixin from '@/mixins/processingMixin';

export default {
    name:'Client-Menu',
    mixins: [processingMixin],
    data: () => ({
      items: [
        { path: "portal", target:"", title: "Portal" },
        { path: "https://appstream2.us-west-2.aws.amazon.com/userpools#/signin?ref=MArEEMQUEH", target:"_blank", title: "Market Insights OS" },
        { path: "https://socialstandards.com/kb/AifG8kZqgoWjuMvYyYDDfu/kb_home.html", target:"_blank", title: "Knowledgebase" },
        { path: "Home", target:"", title: "Logout" },
      ],
    }),

    methods: {
      ...mapActions([
        'logoutUser'
      ]),

    push(name) {
      this.$router.push({name: name});
    },

    logout() {
      this.$router.push({name: 'Home'});
      this.logoutUser()
      this.logAction({
        event: 'logout'
      });
    }

      
    }

  }
</script>
<style>
.emp_links {
    color:#FFFF;
    font-size:1em;
    position:relative;
    right:.7em;
}
.emp_links:hover {
    color:#71C6E6;
}
</style>
