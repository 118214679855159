export default {
    methods: {
        transDateString: function (st) {
            var options = {year: 'numeric', month: 'short', day: 'numeric'};
            // replace spaces with / in the incoming string as some browsers couldn't deal with YYYY MM DD
            var dt = new Date(st.replace(/ /g, '/'));
            return ((st === null) ? '' : dt.toLocaleDateString('en-US', options))
        },
        
        transDateShort: function (st) {
            var options = {year: '2-digit', month: '2-digit', day: '2-digit'};
            // replace spaces with / in the incoming string as some browsers couldn't deal with YYYY MM DD
            var dt = new Date(st.replace(/ /g, '/'));
            return ((st === null) ? '' : dt.toLocaleDateString('en-US', options))
        },
        
        transMonthString: function (st) {
            var options = {month: 'short'};
            // replace spaces with / in the incoming string as some browsers couldn't deal with YYYY MM DD
            var dt = new Date(st.replace(/ /g, '/'));
            return ((st === null) ? '' : dt.toLocaleDateString('en-US', options))
        },
        
        transDateISO: function (st) {
            var options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            // replace spaces with / in the incoming string as some browsers couldn't deal with YYYY MM DD
            var dt = new Date(st.replace(/ /g, '/'));
            var dt_string = dt.toLocaleDateString('en-US', options)
            var date_parts = dt_string.split('/');
            
            return ((st === null) ? '' : date_parts[2] + '-' + date_parts[0] + '-' + date_parts[1])
        },
        
        shortenName(item, length, bp) {
            if (item) {
                if (item.length > length & bp >= 960) {
                    return item.substring(0, 15) + '...';
                }
                else if (item.length > 13 & bp < 960) {
                    return item.substring(0, 10) + '...';
                }
                else {
                    return item
                }
            }
        },
        
        translateSource(int) {
            if (int == 1) {return 'Instagram'}
            else if (int == 3) {return 'Twitter'}
            else if (int == 5) {return 'Reddit'}
            //else if (int == 6) {return 'News'}
            else if (int == 7) {return 'TikTok'}
        },
        
        replaceColon(string) {
            var newChar = ',';
            return string.split(';').join(newChar)
        },
        
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        
        readAsEnding(value) {
            let ending = 'th'
            if (value.toString()[1] == 1 && value != 11) {ending = 'st'}
            else if (value == 12 || value == 11 || value == 13) {ending = 'th'}
            else if (value.toString()[1] == 2) {ending = 'nd'}
            else if (value.toString()[1] == 3) {ending = 'rd'}
            return ending
        },
        
        shortenList(list) {
            let newList
            if (list.split(",").length > 3) {
                newList = list.split(",").slice(0, 3).join(",")
            }
            else {
                newList = list
            }
            return newList
        },
        
        parseDash(item) {
            if (item.includes('/')) {
                item = item.replace(' / ', '-')
            }
            return item
        },
        
    }
    
}