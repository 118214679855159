<template>
    <v-card :height="dialogHeight" @keydown.enter="searchMe">
      <v-card-title>
        <v-icon @click="closeAdvanced" style="position:absolute; right:0.5em; top:0.5em;" sm>mdi-close</v-icon>
      </v-card-title>
      <v-form ref="advForm">
        <v-card-text>
          <v-row justify="center">
            <div style="width:500px;">
              <v-text-field
                  v-model="keywordSearch"
                  :rules="[v => !!v || 'Item is required']"
                  required
                  placeholder="Keyword">
              </v-text-field>
            </div>
          </v-row>
          <v-row justify="center">
            <div style="width:500px;">
              <v-select
                  v-model="filter"
                  clearable
                  :menu-props="{bottom: true, offsetY: true}"
                  @change="clearFilter()"
                  :items="filterItems"
                  item-text="text"
                  item-value="value"
                  placeholder="Object Type">
              </v-select>
            </div>

            <div style="width:800px; margin-left:5em;" v-if="this.filter">

              <v-radio-group row v-model="radioGroup">
                <span style="padding-right:0.6em; position:relative; bottom:0.2em;">
                  <v-checkbox
                      v-model="all"
                      hide-details
                      @change="allSelected()"
                      v-for="(item, idx) in allList"
                      :key="idx"
                      :label="item.text"
                      :value="item.value">
                  </v-checkbox>
                </span>
                <v-checkbox
                    v-model="selectedFilters"
                    hide-details
                    @change="elseSelected()"
                    v-for="(item, idx) in radioItems"
                    :key="idx"
                    :label="item.text"
                    :value="item.value"

                    style="padding: 0em 0.6em 0em 0em;"
                ></v-checkbox>
              </v-radio-group>

            </div>

          </v-row>
          <v-row justify="center">
            <div style="width:500px;">
              <v-select
                  v-model="sort"
                  clearable
                  :menu-props="{bottom: true, offsetY: true}"
                  :items="sortItems"
                  item-text="text"
                  item-value="value"
                  placeholder="Sort By">
              </v-select>
            </div>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-btn class="search-btn" elevation="0" @click="searchMe">Search</v-btn>
        </v-card-text>
      </v-form>
    </v-card>


</template>

<script>

import objectFunctions from "@/mixins/objectFunctions";
import {mapGetters} from "vuex";

export default {
  name: 'advanced-search',
  mixins: [objectFunctions],

  data() {
    return {
      all: '',
      allSelect: false,
      filter: '',
      filterItems: [
        {text: objectFunctions.codeToObjectType['brand']['name'], value: 'brand'},
        {text: objectFunctions.codeToObjectType['product_type']['name'], value: 'product_type'},
        {text: objectFunctions.codeToObjectType['descriptive']['name'], value: 'descriptive'},
      ],
      keywordSearch: '',
      objGroups: ['brand', 'product_type', 'descriptive'],
      radioGroup: '',
      selectedFilters: [],
      sort: '',
      sortItems: [
        {text: 'Most Relevant', value: 'relevance'},
        {text: 'PPM High to Low', value: 'ppm'},
      ],

    }
  },

  computed: {

    ...mapGetters([
      'getCurrentSource',
    ]),

    allList() {
      return (this.objGroups.includes(this.filter)) ? [{text: 'All', value: this.filter}] : []
    },

    dialogHeight() {
      let height = 350;
      if (this.filter === 'brand' || this.filter === 'product_type') {
        height = 420
      }
      else if (this.filter === 'descriptive') {
        height = 590
      }
      return height
    },

    radioItems() {
      let list = []
      if (this.filter === 'brand') {
        list = objectFunctions.brands
      }
      else if (this.filter === 'product_type') {
        list = objectFunctions.products
      }
      else if (this.filter === 'descriptive') {
        list = objectFunctions.descriptive
      }
      return list
    },

  },


  methods: {

    allSelected() {
      if (this.allSelect === false) {
        this.allSelect = true
        this.selectedFilters = []
      }
      else {
        this.allSelect = false
      }
    },

    clearFilter() {
      this.all = ''
      this.selectedFilters = []
    },

    closeAdvanced(){
      this.$emit('closeDialog')
      this.$refs.advForm.reset();
    },

    elseSelected() {
      if (this.allSelect) {
        this.allSelect = false
        this.all = ''
      }
    },

    searchMe() {
      this.$refs.advForm.validate();
      let filter = this.filter;

      if (this.all) {
        filter = this.all
      }
      else if (this.selectedFilters) {
        filter = this.selectedFilters
      }
      if (this.sort == undefined) {
        this.sort = ''
      }
      if (this.keywordSearch) {
        this.$router.push({path: '/ConsumerForecast/search-result' + '?search=' + this.keywordSearch + '&filter=' + filter + '&order=' + this.sort + '&source=' + this.getCurrentSource})
        this.$emit('closeDialog')
        this.$emit('getAdv')
        this.$refs.advForm.reset();
      }
      else {
        console.log('needs search')
      }

    },
  }

}


</script>

<style>

.search-btn {
  float:right;
  margin-right:2em;
  color:#333;
  border-radius:25px;
  background-color:#E3F2FD !important;
}
.search-btn:hover {
  color:#333 !important;
  position:relative;
  bottom:.2em;
}

</style>
