<template>
    <v-row justify-right>
    <v-dialog v-model="dialog" max-width="340" hide-overlay>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          elevation="0"
          text
          id="logDialog"
          style="font-size:1em; position:relative; right:.2em;"
        >
          Login/Register
        </v-btn>
      </template>
      <v-card style="max-width:320px; position: absolute; top:4em; right:1em">
        <v-card-title>
          <span v-if="login" style="color:#333; margin-right:.5em; font-size:.8em;">Login </span><a v-else @click="login=true" style="margin-right:.5em; font-size:.8em;">Login </a> | <span v-if="!login" style="color:#333; margin-left:.5em; font-size:.8em;"> Register</span><a v-else @click="login=false" style="margin-left:.5em; font-size:.8em;"> Register</a>
        </v-card-title>
        <v-card-content>
          <span v-if="login"><LoginForm /></span>

          <span v-if="!login">
            <RegisterForm />
          </span>
        </v-card-content>
       
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import LoginForm from '@/components/LoginForm'
  import RegisterForm from '@/components/RegisterForm.vue'

  export default {  
    name: 'login-dialog',
    components: {
      LoginForm,
      RegisterForm
    },
    data () {
      return {
        dialog: false,
        login: true,
        register: false
      }
    },
  }
</script>

<style>
#logDialog:hover {
  color: #71C6E6;
}
  #logDialog:hover:before {
        background-color: transparent;
        display: none; 
      }
  .v-ripple__animation{ display: none; }
</style>
