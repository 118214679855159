<template>
  <div style="position:relative; top:1em;">
    <span v-if="getCurrentGap.allow_data_export">
      <span v-if="!['sm', 'xs'].includes(this.$vuetify.breakpoint.name)" style="float:right; position:relative; right:10em; top:2em; font-size:.8em; z-index:1000;"><a class="csv-hover" @click="csv()">Download CSV</a></span>
    </span>
        <v-row>
      <span style="padding-left:5em; padding-top:1em;">'{{this.compBase}}' <router-link class="percentile-link" target="_blank" tag="a" :to="{path: '/ConsumerForecast/help?page=definitions#raw'}">Penetration %</router-link> of '{{this.parentAct.name}}'<span style="font-size:.8em; padding-left:.5em;"> (In {{this.parentDomainName}})</span>
      </span>
    </v-row>
    <v-row justify="center">
      <apexchart style="margin-left:1em; position:relative; top:1em;" width="850" height="450" type="line" :options="chartOptions" :series="series"></apexchart>
    </v-row>
    <v-container style="width:80%; overflow:auto; height:170px; margin-top:.5em;">
    <v-row justify="center" @mouseover="hint=true" @mouseleave="hint=false">
      <ul v-for="item in firstHalf" :key="item" >
        <a @click="groupGraph(item)" v-on:dblclick="goTo(item)">
          <v-tooltip light left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind:style="{color: item.color}" v-bind="attrs" v-on="on">mdi-square-rounded</v-icon>
            </template>
            <span>{{item.type}}</span>
            
          </v-tooltip>
        <li style="color:#0d1026; display:inline-block;">{{shortenName(item.object_name, 18)}}</li></a>
      </ul>
    </v-row>
    <v-row v-if="hint" :class="{ 'on-hover': hover }">
      <div v-if="firstHalf.length > 15" style="position:absolute; bottom:-2em; right:2em;">
        <span style="font-size:.7em;"><b>Hint:</b> double-click on items to navigate to them in a new tab </span>
      </div>
      <div v-else style="position:absolute; bottom:5em; right:2em;">
        <span style="font-size:.7em;"><b>Hint:</b> double-click on items to navigate to them in a new tab </span>
      </div>
    </v-row>
    </v-container>
  </div>
</template>


  

<script>
import stringFunctions from '@/mixins/stringFunctions';
import processingMixin from '@/mixins/processingMixin';
import comparisons from "./Comparisons";
import objectFunctions from '@/mixins/objectFunctions';

import { mapGetters } from 'vuex';

export default {
  name: 'comparison',
  mixins: [stringFunctions, processingMixin, comparisons, objectFunctions],
  props: {
    parentAct: Object,
    parentDomain: Number,
    parentBP: Number,
    parentDomainName: String
  },
  created() {
    this.compareGroups(this.parentAct, this.parentDomain)
    this.initialize(this.parentAct, this.parentDomain)
  },
  computed: {

     ...mapGetters([
      'getCurrentGap'
    ]),

    chartWidth() {
      let bp = this.$vuetify.breakpoint.width
      if (bp < 600) { return 20} //xs
      else if (bp > 600 & bp < 800) { return 500} //xs-sm
      else if (bp > 800 & bp < 960) { return 800} //sm
      else if (bp > 960 & bp <= 1264) { return 800} //md
      else if (bp > 1264 & bp < 1704) {return 800}
      else {return 900}//lgxl
    }

  },
  
  methods: {

    compareGroups(data, domain) {
      let groups = data.volumes.domains[domain].sub_objects;
      this.addColor(groups);

      
      var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      let sep = groupBy(groups, 'type_code')
      let one = []
      for (const i in Object.keys(sep)) {
        if (!isNaN(i)) {
          one.push(sep[Object.keys(sep)[i]].sort((a,b) => b.percent[24] - a.percent[24]))
        }
      } 
      let two = []
      for (const n in one) {
        if (!isNaN(n)) {
          if (n == 0) {
            two = one[n]
          }
          else {
            for (const m in one[n]) {
              if (!isNaN(m)) {
                two.push(one[n][m])
              }
              
            }
          }
        }
      }
      this.firstHalf = two
    },


    initialize(data, domain) {
      let item = data.volumes.domains[domain].sub_objects[0]
      
      this.myIt = item.object_name

      this.compBase = item.object_name
       this.series = [ 
         {
            name: "'" + item.object_name + "'" + ' Penetration % of ' + "'" + this.parentAct.name + "'",
            type: 'line',
            data: item.percent
          },
         {
            name: "'" + this.parentAct.name + "'" + ' Volume',
            type: 'column',
            data: this.parentAct.volumes.domains[this.parentDomain].authentic_posts
          },
          
          ]
        this.chartOptions.labels = this.parentAct.volumes.month_date_set;
        
        this.chartOptions = {...this.chartOptions, ...{
          yaxis: [
            {
              title: {
                text: "'" + item.object_name + "'" + ' Penetration %'
                
              },
              labels: {
                show: true,
                formatter: function(val) { 
                  if (val < 1) { return val.toFixed(2) + '%' }
                  else { return val.toFixed(1) + '%' }
                }
              }
          },
          {
            opposite: true,
            title: {
              text: "'" + this.parentAct.name + "'" + ' Volume'
            },
            labels: {
              show: true,
              formatter: (val) => {return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            }
          }
          ]
         }}
    },

    groupGraph(item) {
       this.compBase = item.object_name
       this.series = [
         {
            name: "'" + item.object_name + "'" + ' Penetration % of ' + this.parentAct.name  ,
            type: 'line',
            data: item.percent
          },
          {
            name: "'" + this.parentAct.name + "'" + ' Volume',
            type: 'column',
            data: this.parentAct.volumes.domains[this.parentDomain].authentic_posts
          },]
        this.chartOptions.labels = this.parentAct.volumes.month_date_set;
       
        this.chartOptions = {...this.chartOptions, ...{
          yaxis: [
            {
              title: {
                text: "'" + item.object_name + "'" + ' Penetration %'
              },
              labels: {
                formatter: function(val) {
                  if (val < 1) { return val.toFixed(2) + '%' }
                  else { return val.toFixed(1) + '%' }
                  }
              }
          },
          {
            opposite: true,
            title: {
              text: "'" + this.parentAct.name + "'" + ' Volume'
              
            },
            labels: {
              show: true,
              formatter: (val) => {return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              
            }
          }
          ]
         }}

    },

    csv() {
      let data = this.series;
      let date = this.parentAct.volumes.month_date_set;
      let rows = [['Month', data[0].name, data[1].name]];
      
      for (const i in data[0].data) {
        if (!isNaN(i)) {
          rows.push([
          date[i],
          data[0].data[i], 
          data[1].data[i],
        ]) 
        }
        
      } 
  
      let csvContent = "data:text/csv;charset=utf-8," 
      + rows.map(e => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.compBase + " Penetration % of" + this.parentAct.name + ' in ' + this.parentDomainName);
      document.body.appendChild(link); // Required for FF

      link.click();

      this.logAction({
        event: 'csv-download',
        app_name: 'CF',
        page_name: 'look-up-view',
        form_name: 'sub-comparison',
        object_sid: this.myObject.object_sid,
        domain_sid: this.myDomain, 
        source: 1
      });

    },
  
  },

  data () {
    return {
      hint: false,
      compBase: '',
      firstHalf: [],
      myIt: '',
      boxColor: '',
      series: [],
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: {
                show: false,
          }
        },
        stroke: {
          width: 4,
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 'dataPoints',
          tooltip: {
            enabled: false
          },
          labels: {
            rotate: -45,
            rotateAlways: true,
            minHeight: 42,
            hideOverlappingLabels: false,
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false
          },
          offsetY: 10,
          position: 'top'
        },
        colors: ['#3f4d63', '#c6ccd8'],
        fill: {
          opacity: 1
        },
        plotOptions: {
            bar: {
              columnWidth: '80px',
              distributed: false,
              borderRadius: 2,
            }
        },
        title: {
          show:false
        },
        tooltip: {
          endabled: true,
          x: {
            format: 'MMM yyyy',
          },
        },
        dataLabels: {
          show:false,
        },
        responsive:  [{
            breakpoint: '960',
            options: {
              chart: {
                width: 650
              },
            }
          }],
        
        yaxis: [{
          labels: {
            show: true,
            formatter: (val) => { return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
          }
        }, 

        {
          opposite: true,
          title: {
            text: '%'
          },
          labels: {
            formatter: function(val) {
                if (val < 1) { return val.toFixed(2) + '%' }
                else { return val.toFixed(1) + '%' }
              }
          }
        }]
      },
          
    }
  }
}
</script>

<style scoped lang="scss">
  .percentile-link {
    color: #6387B0;
  }

  .percentile-link:hover {
    position:relative;
    bottom:.15em;
  }

  .csv-hover {
  color: #6387B0 !important;
  }
  .csv-hover:hover {
    position:relative;
    bottom:.2em;
  }

  .center-vert {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    }
    .margin {
        margin-left:0em;
      }

    @media only screen and (min-width: 1260px) {
      .margin {
        margin-left:4em;
      }
    }
</style>