<template>
  <div>
    <apexchart width="300" type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: 'geo',
  props: {
    parentObj: Object,
    parentDomain: Number,
  },

  data() {
    return {
      percent: {},
      percentile: {},

      series: [],
      chartOptions: {
        labels: [],
        colors: ['#274b69', '#3f4d63', '#85a1c1', '#B08C63', '#BDBDBD'],
        chart: {
          type: 'donut',
        },
        tooltip: {
          enabled: true,
      
          // y: {
          //   formatter: function(val, {w, dataPointIndex }) {
          //     console.log(w)
          //     console.log(val)
          //     return 'percent: '+ (val).toFixed(1) + '%' + '<br>' + 'percentile: '+ dataPointIndex},
            
          //   title: {
          //     formatter: function (seriesName) {
          //       return '  '+seriesName+':' + '<br>'
          //     }}
          // },

        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        
        dataLabels: {
            enabled: false
            },
            
        legend: {
          show: true,
          offsetY: -5,
          position: 'bottom',
          width:'50px'
        },
        annotations: {
          xaxis: [
            {
              x: 50,
              strokeDashArray: 0,
              borderColor: '#BDBDBD',
              offsetY: -5,
              label: {
                orientation: 'horizontal',
                borderColor: '#BDBDBD',
                offsetY: -15,
                style: {
                  color: '#333',
                  background: '#fff'
                },
                text: '50th Percentile'
                
              }
            }
          ]
        },
        responsive: [{
          breakpoint: '1000',
          options: {
            chart: {
              width: 300
            },
            legend: {
              show: true,
              width: 250,
              offsetX: 10,
              position: 'bottom',
            }
          }
        }]
      },
    }
  },

  created() {
    this.createVars(this.parentObj, this.parentDomain)
  },

  methods: {

    createVars(data, domain) {
      let set = data.us_region.domains[domain].percent

      let arr= []
      let labels = []
      for (const i in set) {
        if (!isNaN(i)) {
          arr.push(set[i].percent)
          labels.push(set[i].region)
        }
      }
      this.series = arr;
      this.chartOptions.labels = labels;
      this.series = arr;
      this.chartOptions.labels = labels;

      this.chartOptions.tooltip = {
     
         y: {
            formatter: function(value, opts ) {
               let item = data.us_region.domains[domain].percent[opts.dataPointIndex]
              return 'percent: '+ (value).toFixed(1) + '%' + '<br>' + 'percentile: ' + item.percentile + 'th'},
            
            title: {
              formatter: function (seriesName) {
                return '  '+seriesName+':' + '<br>'
              }}
          },

      }
    },
    },

  
}
</script>

<style lang="scss">
.tooltipA {
  min-width: 120px;
  width: 100%;
  height:80px;
  background-color: #FFF;
  opacity: .8;
  
}

.tooltip-title {
  height:30px;
  padding:.2em;
  
}

.tooltip-item {
  padding:.2em;
  //height:20px;
}
</style>
