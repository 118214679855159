import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getCurrentGap.allow_data_export)?_c('span',[(!['sm', 'xs'].includes(this.$vuetify.breakpoint.name))?_c('span',{staticStyle:{"float":"right","position":"relative","right":"10em","top":"2em","font-size":".8em"}},[_c('a',{staticClass:"csv-hover",on:{"click":function($event){return _vm.csv()}}},[_vm._v("Download CSV")])]):_vm._e()]):_vm._e(),_c(VRow,[_c('span',{staticStyle:{"padding-left":"5em","padding-top":"1em"}},[_vm._v("'"+_vm._s(this.parentAct.name)+"' "),_c('router-link',{staticClass:"percentile-link",attrs:{"target":"_blank","tag":"a","to":{path: '/ConsumerForecast/help?page=definitions#raw'}}},[_vm._v("Penetration %")]),_vm._v(" of '"+_vm._s(this.compBase)+"'"),_c('span',{staticStyle:{"font-size":".8em","padding-left":".5em"}},[_vm._v(" (In "+_vm._s(this.parentDomainName)+")")])],1)]),_c(VRow,{attrs:{"justify":"center"}},[_c('apexchart',{staticStyle:{"margin-left":"1em","position":"relative","top":"1em"},attrs:{"width":"850","height":"450","type":"line","options":_vm.chartOptions,"series":_vm.series}})],1),_c(VContainer,{staticStyle:{"width":"85%","overflow":"auto","height":"150px","padding-top":"2em"}},[_c(VRow,{attrs:{"justify":"center"},on:{"mouseover":function($event){_vm.hint=true},"mouseleave":function($event){_vm.hint=false}}},_vm._l((this.ordered),function(item){return _c('ul',{key:item},[_c('a',{on:{"click":function($event){return _vm.groupGraph(item)},"dblclick":function($event){return _vm.goTo(item)}}},[_c(VTooltip,{attrs:{"light":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({style:({color: item.color}),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-square-rounded")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.type))])]),_c('li',{staticStyle:{"color":"#0d1026","display":"inline-block"}},[_vm._v(_vm._s(_vm.shortenName(item.object_name, 18)))])],1)])}),0),(_vm.hint)?_c(VRow,{class:{ 'on-hover': _vm.hover }},[_c('div',{staticStyle:{"position":"absolute","bottom":"1em","right":"2em"}},[_c('span',{staticStyle:{"font-size":".7em"}},[_c('b',[_vm._v("Hint:")]),_vm._v(" double-click on items to navigate to them in a new tab ")])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }