<template>
  <div>
    
    <v-row justify="center" style="padding-top:4em;">
      <apexchart ref="actChart" :width="width" height="360" type="line" :options="chartOptions" :series="series"></apexchart>
    </v-row>
     <v-row>
      <v-app-bar elevation=0 style="background-color:transparent;">
        <v-spacer></v-spacer>
        <v-btn elevation=0 class="tog" :style="styleFull" @click="ret()">User Retention</v-btn>
        <v-btn elevation=0 class="tog" :style="styleVar" style="margin-right:4em;" @click="pos()">Spam %</v-btn> 
      </v-app-bar>
    </v-row>
    <div v-if="positivity" style="position:relative; bottom:.5em; z-index: 100">
      <span style="float:right; font-size:.8em;"> <v-icon style="color:#333" x-small>mdi-help-circle</v-icon>
      <router-link style="color:#333;" tag="a" target="_blank" :to="{path: '/ConsumerForecast/help?page=definitions#spam'}"> click to read more about Spam %</router-link> 
      </span>
    </div>
    <div v-if="userRet" style="position:relative; bottom:.5em; z-index: 100">
      <span style="float:right; font-size:.8em;"> <v-icon style="color:#333" x-small>mdi-help-circle</v-icon>
      <router-link style="color:#333;" tag="a" target="_blank" :to="{path: '/ConsumerForecast/help?page=definitions#retention'}"> click to read more about User Retention</router-link> 
      </span>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'activity-auth-line',
  props: {
    parAct: Object,
    parentDomain: String,
    width: Number
  },

  created() {
    this.item = this.parAct
    this.getDomainName(this.parAct)
    this.makeAuthChart(this.parAct)
    
  },

  computed: {
   styleFull : function() {
      if (this.userRet == true) { return { '--color': '#6387B0'}}
      else { return { '--color': '#333'}
      }
    },
    styleVar : function() {
      if (this.positivity == true) { return { '--color': '#6387B0'}}
      else { return { '--color': '#333'}
      }
    },
  },

  methods: {

    makeAuthChart(data) {
      let d = data.volumes.domains[this.parentDomain].authentic_posts;
      let dates = data.volumes.month_date_set;
      let dict = []

      for (const i in d ) {
        if (!isNaN(i)) {
          dict.push({
            x: dates[i],
            y: d[i]
          })
        }
      } 
      this.series = [{
        name: 'ppm',
        data: dict
      }]

      if (data.volumes.domains[this.parentDomain].domain_yoy_percent > 0) {
        this.myColor = '#274b69'
        this.chartOptions = {...this.chartOptions, ...{
       colors: ['#274b69']
      }}
      }

      else {
        this.myColor = '#274b69'
        this.chartOptions = {...this.chartOptions, ...{
          colors: ['#274b69']
      }}
      }
     

    },

    vari() {
      this.variance = true;
      this.fu = false;
        this.$refs.actChart.updateOptions({
          yaxis:{ 
            labels: { formatter: function(val) { return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}},
            title: { text: 'Posts Per Month', offsetX: -6,}
          },
        })
    },
    full() {
      this.fu = true;
      this.variance = false;
        this.$refs.actChart.updateOptions({
          yaxis:{ 
            min: 0,
            labels: { formatter: function(val) { return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}},
            title: { text: 'Posts Per Month', offsetX: -6,}
          },
        })
    },

    pos() {
      var start = this.series
      this.userRet = false;

      if (start.length > 1) {
        start.pop()
      }

      if (this.positivity == false) {

        let data = this.parAct.volumes.domains[this.parentDomain].spam_posts;
        let denom = this.parAct.volumes.domains[this.parentDomain].authentic_posts 
        let addPos = {}
        let dates = this.parAct.volumes.month_date_set;
        let dict = []

        for (const i in data ) {
          if (!isNaN(i)) {

            dict.push({
              x: dates[i],
              y: (data[i]/(denom[i]+data[i]))*100
            })
          }
        } 
        addPos = {
          name: 'Spam Post %',
          data: dict
        };

        this.chartOptions = {...this.chartOptions, ...{
          yaxis: [
            {
              title: {
                text: 'Posts Per Month'
              },
              labels: {
                show: true,
                formatter: (val) => {return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              }
          },
          {
            opposite: true,
            title: {
              text: 'Percent %'
            },
            labels: {
              formatter: function(val) { return val.toFixed(1) + '%'}
            }
          }
          ],
          colors: [this.myColor, '#FFA726']
         }}

        start.push(addPos);
        this.series = start;
        this.positivity = true;

      }
      else {
        this.chartOptions = {...this.chartOptions, ...{
           yaxis:[{
              min: 0,
              labels: { formatter: function(val) { return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}},
              title: { text: 'Posts Per Month', offsetX: -6,}
              },
              {opposite: true, labels: {show: false}} // adding an opposite y-axis is a hack for the last month to show on x-axis
           ]
        }}
        this.series = start;
        this.positivity = false;
        this.userRet = false;
      }

    },

    ret() {
      this.positivity = false
      var start = this.series
      if (start.length > 1) {
        start.pop()
      }

      if (this.userRet == false) {
        let data = this.parAct.user_retention.domains[this.parentDomain].one_month
        for (const d in data) {
          if (data[d] === 0) {
            data[d] = null
          }
        }
        let addRet = {}
        let dates = this.parAct.volumes.month_date_set;
        let dict = []
        for (const i in data ) {
          if (!isNaN(i)) {
            dict.push({
              x: dates[i],
              y: data[i]
            })
          }
        } 
        addRet = {
          name: '1 month User Retention',
          data: dict
        }

        start.push(addRet);
        this.series = start;
        this.userRet = true;

        this.chartOptions = {...this.chartOptions, ...{
          yaxis: [
            
            {
              title: {
                text: 'Posts Per Month'
              },
              labels: {
                show: true,
                formatter: (val) => {return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              }
          },
          {
            opposite: true,
            title: {
              text: 'Percent %'
            },
            labels: {
              formatter: function(val) { return val.toFixed(1) + '%'}
            }
          }
          ],
          colors: [this.myColor, '#346635'],
         }}

      }
      else {
        this.chartOptions = {...this.chartOptions, ...{
            yaxis:[{
              min: 0,
              labels: { formatter: function(val) { return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}},
              title: { text: 'Posts Per Month', offsetX: -6,}
              },
              {opposite: true, labels: {show: false}} // adding an opposite y-axis is a hack for the last month to show on x-axis
            ],
        }}
        this.series = start;
        this.userRet = false;
        this.positivity = false;
      }

    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getDomainName(data) {
      let d = data.volumes.domains[this.parentDomain].domain_name
      this.domainName = d;

      this.makeAuthChart(this.item)
    },
  },

  data () {
    return {
      fu: true,
      myColor: '',
      variance: false,
      userRet: false,
      positivity: false,
      series: [{
        name: 'ppm',
        data: []
      }],
      item: {},
      domainName: '',
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          },
          animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800,
              animateGradually: {
                  enabled: true,
                  delay: 150
              },
              dynamicAnimation: {
                  enabled: false,
                  speed: 350
              }
          },
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 2,
            blur: 4,
            opacity: .1,
          },
          background: 'transparent',
          toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          },},
        theme: {
          mode: 'light', 
      },
            dataLabels: {
              enabled: false
            },
            states: {
              active: {
                filter: {
                  type: 'none' /* none, lighten, darken */
                }
              }
            },
            color: ['#1A204C'],
            stroke: {
              curve: 'smooth',
              width: 4
            },
            tooltip: {
              endabled: true,
              x: {
                format: 'MMM yyyy',
              },
              y: {
                formatter: function formatter(val) {
                  if (val) {
                    if (val % 1 !== 0) { return val.toFixed(2)+'%'}
                    else {return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}}
                  }
                  
                  
              }
            },
            title: {
              show: false
            },

            yaxis:[{
              min: 0,
              labels: { formatter: function(val) { return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}},
              title: { text: 'Posts Per Month', offsetX: -6,}
              },
              {opposite: true, labels: {show: false}} // adding an opposite y-axis is a hack for the last month to show on x-axis
            ],
            legend: {
              offsetY: '10',
               height: '30',
            },

            xaxis: {
              type: 'datetime',
              tickAmount: 'dataPoints',
              tooltip: {
                enabled: false
              },
              labels: {
                rotate: -45,
                rotateAlways: true,
                minHeight: 42,
                hideOverlappingLabels: false,
              }
            },
          },
    }
  },
  

  
}
</script>
<style lang="scss" scoped>
.tog {
  height: 22px !important;
  color: var(--color) !important;
  font-size:12px;
  background-color:transparent !important;
  border: 1px solid var(--color);
  margin-right:.5em;
  margin-bottom:2em;
} 
.tog:hover {
  color: var(--color) !important;
  position:relative;
  bottom:.2em;
}

.tog2 {
  height: 22px !important;
  color: #333 !important;
  font-size:12px;
  background-color:transparent !important;
  // border: 1px solid #FFF;
  margin-right:.5em;
  margin-bottom:3em;
} 
.tog2:hover {
  color: #333 !important;
  position: relative;
  bottom:.2em;
}

.auth-btn {
  background-color: #3f4da0 !important;
  color:#333;
  margin-right:2em;
}
.auth-btn:hover {
  color:#333 !important;
  position:relative;
  bottom:.2em;
}

.v-tooltip__content {
  opacity: .96!important;
  background-color:#3f4d63 !important;
}
</style>
