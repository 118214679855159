<template>
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 52px;
    "
  >
    <router-link :to="{ name: 'Home' }" style="height: 32px"
      ><img
        src="@/assets/SSLogo.png"
        width="200"
        style="margin: 0em 0em 0em 3em"
    /></router-link>

    <span v-if="!isAdmin"
      ><router-link :to="{ name: 'client-home' }"
        ><v-btn
          class="btn-hover"
          style="
            background-color: transparent;
            color: white;
            margin-top: inherit;
            margin-left: 1em;
          "
          elevation="0"
          >Client Home</v-btn
        ></router-link
      ></span
    >
    <span v-else
      ><router-link :to="{ name: 'client-home' }"
        ><v-btn
          class="btn-hover"
          style="background-color: transparent; color: white; margin-left: 1em"
          elevation="0"
          >Internal Home</v-btn
        ></router-link
      ></span
    >
    <router-link
      v-if="getLoggedUser.anon"
      :to="{ path: '/ConsumerForecast/help?page=general' }"
      ><v-btn
        class="btn-hover"
        style="background-color: transparent; color: white; margin-top: 0.5em"
        elevation="0"
        >FAQ</v-btn
      ></router-link
    >
    <v-spacer></v-spacer>


    <span
      v-if="getCurrentGap.portal && !$route.path.includes('/AppView/portal')"
      ><router-link :to="{ name: 'portal' }"
        ><v-btn
          class="btn-hover"
          style="background-color: transparent; color: white"
          elevation="0"
          >Portal</v-btn
        ></router-link
      ></span
    >
    <span
      v-if="
        getCurrentGap.consumer_forecast &&
        !$route.path.includes('/ConsumerForecast/object-search')
      "
      ><router-link :to="{ name: 'ObjectSearch' }"
        ><v-btn
          class="btn-hover"
          style="background-color: transparent; color: white"
          elevation="0"
          >CF Lite</v-btn
        ></router-link
      ></span
    >

    <span
      v-if="
        getCurrentGap.dashboards && !$route.path.includes('/v2/dashboard-list')
      "
      ><router-link :to="{ name: 'dashboard-list' }"
        ><v-btn
          class="btn-hover"
          style="background-color: transparent; color: white"
          elevation="0"
          >Lenses</v-btn
        ></router-link
      ></span
    >

    <router-link
      v-if="!getLoggedUser.anon"
      :to="{ path: '/ConsumerForecast/help?page=general' }"
      ><v-btn
        class="btn-hover"
        style="background-color: transparent; color: white"
        elevation="0"
        >FAQ</v-btn
      ></router-link
    >
    <v-menu
      v-if="!getLoggedUser.anon"
      v-model="accountMenu"
      absolute
      offset-y
      style="max-width: 600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="no-background-hover"
          class="btn-hover"
          v-bind="attrs"
          v-on="on"
          style="
            background-color: transparent;
            margin: 0em 10em 0em 0em;
            color: white;
          "
          elevation="0"
          >Account</v-btn
        >
      </template>

      <v-list>
        <v-list-item link v-if="!getLoggedUser.anon">
          <settingsEditor v-model="settingsWrap">
            <template v-slot:activator="{ open }">
              <a @click="start(open)">My Account</a>
            </template>
          </settingsEditor>
        </v-list-item>
        <v-list-item>
          <a @click="loggingOff()">Logout</a>
        </v-list-item>
      </v-list>
    </v-menu>
    <span
      ><v-icon color="#FFFF" @click="sourceSwitch=true"
        >mdi-cog-transfer-outline</v-icon
      ></span
    >

    <v-dialog v-model="sourceSwitch" width="450" @click:outside="sourceSwitch=!sourceSwitch">
      <SourceSwitch
          v-on:closeSourceDialog="sourceSwitch=!sourceSwitch"
          v-on:updateSource="closeAndReload()"
      ></SourceSwitch>
    </v-dialog>


  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import settingsEditor from "@/components/Navigation/SettingsEditor.vue";
import SourceSwitch from "../ConsumerForecast/Lookups/PageElements/SourceSwitch";

export default Vue.extend({
  name: "forecast-content",
  props: {
    isSticky: {
      type: Boolean,
      required: true,
    },
    bookmarksWrap: {
      type: String,
      required: true,
    },
    settingsWrap: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      sourceSwitch: false,
      accountMenu: "",
    };
  },
  components: {
    settingsEditor,
    SourceSwitch,
  },
  computed: {
    ...mapGetters(["isAdmin", 'getLoggedUser', "getCurrentGap"]),
    btnMargin() {
      return !this.isSticky ? "margin-right: 12px" : "";
    },
  },
  methods: {
    ...mapActions(["logoutUser", "getQueryMatch", "changeCurrentSource"]),
    start: function (method) {
      setTimeout(() => method());
    },
    markDialog: function (method) {
      setTimeout(() => method());
    },
    loggingOff() {
      this.logoutUser();
      this.$router.push({ name: "Home" });
      this.logAction({
        event: "logout",
      });
    },
    closeAndReload() {
      this.sourceSwitch = false
      location.reload()
          .catch((err) => {
            console.log(err)
          })
    },
  },
});
</script>
