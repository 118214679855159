<template>
  <v-container id="career">

  <br>
  <p>
    <strong>Attention job seekers</strong> - we’ve recently been alerted to a scam using our company name to extort recent applicants out of money.
  </p>

  <p>
    Claiming to be employees of Social Standards, the scammers ask applicants to download Microsoft Teams for a text interview.
    Applicants are then sent a PDF of a fake check to buy equipment and pressured to send $500 via Zelle or asked to fill
    out a W-2 in order to gain personal information.
  </p>

  <p>
    Social Standards will never ask a job candidate or employee to send money as part of the recruiting or onboarding process.
     Any request for personal information will be made and handled as part of a transparent, professionally operated onboarding process only after we've established a formal relationship.
  </p>

  <p>
    To help protect future applicants we are sharing the fake identities used, but please note that these could change:
  </p>

  <ul>
    <li>Michelle Arsenault</li>
    <li>Caroline Parsons - (716) 923-7625</li>
    <li>Jennifer Roland - 'CPA VP, Finance & Business Ops'</li>
    <li>Sherry Iris Bolinger - sherryblngr@gmail.com</li>
    <li>Brad Sullivan - tasooshrwa@hotmail.com</li>
    <li>Emir Avdic - dwbzqlbqpqsoaxc@hotmail.com</li>
  </ul>
  <br>

  <p>We urge applicants to look out for this scam and to report any suspicious activity to us at contact@socialstandards.com.</p>

  <p>Thank you, stay safe and please reach out with any questions.</p>

  <br>

  <p>
    <strong>Open Positions</strong> - Please check for open positions on <a href="https://www.linkedin.com/company/social-standards/jobs" target="_blank">Social Standrads LinkedIn</a> profile.
  </p>

  </v-container>


</template>

<script>
import processingMixin from '@/mixins/processingMixin';
export default {
    name: 'career-page',
    mixins: [processingMixin],
    created() {
      this.logPageLoad({
			app_name: 'S',
			page_name: this.$options.name,
      })
    }
}
</script>

<style>
.career {
    width:65%; padding-top:2.5em;
}
</style>