<template>
  <div fluid style="padding-top:3em;" v-if="myloadingvariable">
    <v-container style="padding:2em; position:relative; bottom:3em;">
    
      <v-data-table 
      style="width:100% "
      :items="tableArray"
      :headers="headers"
      :footer-props="{
        'items-per-page-options': [20]
      }"
      hide-default-footer
      >
      <template v-slot:item.view="{ item }">
        <v-icon  @click="view(item)" small>mdi-format-list-text</v-icon>
      </template>
      <!-- <template v-slot:item.is_auto_assigned="{ item }">
        <a v-if="item.is_auto_assigned" @click="autoR(item)"><v-icon style="color:#FFB300;" small>mdi-star</v-icon></a>
        <a v-else @click="autoA(item)"><v-icon style="color:#BDBDBD;" small>mdi-star</v-icon></a>
      </template> -->
    </v-data-table>
    

    
      <div v-if="getAccess.includes('internal_admin')" style="padding-top:2em; padding-bottom:1em;">
        <v-btn class="create-btn" elevation="0" style="background-color:#E3F2FD;  float:right; border-radius:25px; margin-left:0em;" @click="switchAdd()">Add New Profile <v-icon small>mdi-plus</v-icon></v-btn>
      </div>
   
    </v-container>

    <v-container v-if="viewItem.name" style="width:80%; padding-top:1em; padding-bottom:6em;">
      <v-card v-if="!editing" style="padding-left:2em; padding-top:1em;">
        <v-card-title>
          <span style="font-size:.7em;"><b>{{viewItem.name}}</b></span>
          <!-- <span v-if="getAccess.includes('internal_admin')" style="position:relative; left:.5em; bottom:.2em;"><v-icon @click="edit(item)" small>mdi-pencil-outline</v-icon></span> -->
          <v-icon @click="closeOptions()" style="position:absolute; right:.5em; top:.5em;">mdi-close</v-icon>
        </v-card-title>
        <v-card-text style="text-align:left;">
          <b>Date created: </b><span>{{viewItem.created}}</span><br>
          <b>Span: </b><span>{{viewItem.duration}}</span>
          <v-icon v-if="getAccess.includes('internal_admin') && !EdittingSpan" right style="position:relative; bottom:.2em;" @click="EdittingSpan=true" small>mdi-marker</v-icon>
          <v-icon v-if="getAccess.includes('internal_admin') && EdittingSpan" right style="position:relative; bottom:.2em;" @click="EdittingSpan=false" small>mdi-marker-cancel</v-icon>
        </v-card-text>
        <v-card-text v-if="EdittingSpan" style="position:relative; bottom: 1em;">
          <v-row>
            <v-col cols="2" sm="4">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate" dense label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" required :rules="dateRules" style="width:225px;">
                  </v-text-field>
                </template>
                <v-date-picker v-model="startDate" @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2" sm="4">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate" dense label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" required :rules="dateRules" style="width:225px;">
                  </v-text-field>
                </template>
                <v-date-picker v-model="endDate" @input="menu2 = false"></v-date-picker>
              </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-btn @click="pushSpanEdit(), EdittingSpan=false" style="position:absolute; right:2em; background-color:#E3F2FD;" rounded elevation="0">done<v-icon small>mdi-check</v-icon></v-btn>
        </v-row>

      </v-card-text>
      
        <v-card-text style="text-align:left;">
          <b>Email Domains:</b>
          <v-icon v-if="getAccess.includes('internal_admin') && !EdittingDomains" right style="position:relative; bottom:.2em;" @click="EdittingDomains=true" small>mdi-marker</v-icon>
          <v-icon v-if="getAccess.includes('internal_admin') && EdittingDomains" right style="position:relative; bottom:.2em;" @click="EdittingDomains=false" small>mdi-marker-cancel</v-icon>
          <span v-if="viewItem.profile_email_domains">
            <ul v-for="(email,idx) in viewItem.profile_email_domains" :key="idx" style="list-style-type:none;">
              <li v-if="!EdittingDomains">{{email.email_domain}}</li>
              <li v-else>{{email.email_domain}} <a @click="deleteDomain(email.email_domain)"><v-icon small>mdi-delete</v-icon></a></li>
            </ul>
          </span>
          <span v-else><br>No email domains</span>
          <span v-if="EdittingDomains" style="position:relative; bottom:1em;"><br>
            <v-text-field v-model="AddingDomain" style="width:200px; margin-left:2em;" placeholder="new email domain"></v-text-field>
            <a style="margin-left:2em;" @click="addDomain()">Add <v-icon small color="primary">mdi-email-plus</v-icon></a>
          </span>
        </v-card-text>
        
        <v-card-text style="text-align:left;">
          <div style="padding-bottom:.5em;">
            <span><b>Entitlements:</b></span>
            <v-icon v-if="getAccess.includes('internal_admin') && !EdittingEntitlements" right style="position:relative; bottom:.2em;" @click="EdittingEntitlements=true" small>mdi-marker</v-icon>
            <v-icon v-if="getAccess.includes('internal_admin') && EdittingEntitlements" right style="position:relative; bottom:.2em;" @click="EdittingEntitlements=false" small>mdi-marker-cancel</v-icon>
            <br>
          </div>
          
          <span v-if="!EdittingEntitlements">
            <div>
              <span v-if="viewItem.market_insights == true">
                <v-icon small>mdi-check-underline</v-icon><b> Market Insights Access: </b><span>Yes</span><br>
              </span>
              <span v-else>
                <v-icon small>mdi-window-close</v-icon><b> Market Insights Access: </b><span>No</span><br>
              </span>
              <span v-if="viewItem.influencer_insights == true">
                <v-icon small>mdi-check-underline</v-icon><b> Follower Insights Access: </b><span>Yes</span><br>
              </span>
              <span v-else>
                <v-icon small>mdi-window-close</v-icon><b> Follower Insights Access: </b><span>No</span><br>
              </span>
            </div>
            <div style="padding-top:.5em;">
              <span v-if="viewItem.portal == true">
                <v-icon small>mdi-check-underline</v-icon><b> Portal Access: </b><span>Yes</span><br>
              </span>
              <span v-else>
                <v-icon small>mdi-window-close</v-icon><b> Portal Access: </b><span>No</span><br>
              </span>
              <span v-if="viewItem.consumer_forecast == true">
                <v-icon small>mdi-check-underline</v-icon><b> CF Lite Access: </b><span>Yes</span><br>
              </span>
              <span v-else>
                <v-icon small>mdi-window-close</v-icon><b> CF Lite Access: </b><span>No</span><br>
              </span>
              <span v-if="viewItem.exports == true">
                <v-icon small>mdi-check-underline</v-icon><b> CF Lite Exports: </b><span>Yes</span><br>
              </span>
              <span v-else>
                <v-icon small>mdi-window-close</v-icon><b> CF Lite Exports: </b><span>No</span><br>
              </span>
              <span v-if="viewItem.dashboards == true">
                <v-icon small>mdi-check-underline</v-icon><b> Lenses: </b><span>Yes</span><br>
              </span>
              <span v-else>
                <v-icon small>mdi-window-close</v-icon><b> Lenses: </b><span>No</span><br>
              </span>
              <span v-if="viewItem.brand_pulse == true">
                <v-icon small>mdi-check-underline</v-icon><b> Brand Maps: </b><span>Yes</span><br>
              </span>
              <span v-else>
                <v-icon small>mdi-window-close</v-icon><b> Brand Maps: </b><span>No</span><br>
              </span>
            </div>
          </span>

          <span v-else>
            <v-checkbox v-model="mi" color="#283593" dense  :label="'Market Insights Access'" :value="viewItem.market_insights" hide-details></v-checkbox>
            <v-checkbox v-model="fi" color="#26A69A" dense  :label="'Follower Insights Access'" :value="viewItem.influencer_insights" hide-details></v-checkbox>
            
            <v-checkbox v-model="portal" color="#283593" dense  :label="'Portal Access'" :value="viewItem.portal" hide-details></v-checkbox>
            <v-checkbox v-model="cFL" color="#283593" dense  :label="'CF Lite Access'" :value="viewItem.consumer_forecast" hide-details></v-checkbox>
            <v-checkbox v-if="cFL" v-model="cFExports" color="#283593" dense  :label="'CF Lite data exports'" :value="viewItem.exports" hide-details></v-checkbox>
            <v-checkbox v-model="dashboard" color="#283593" dense  :label="'Lenses'" :value="viewItem.dashboards" hide-details></v-checkbox>
            <v-checkbox v-model="bpm" color="#283593" dense  :label="'Brand Maps'" :value="viewItem.brand_pulse" hide-details></v-checkbox>

            <v-btn @click="sendNewEntitlements(), EdittingEntitlements=false" style="float:right; background-color:#E3F2FD;" elevation="0" rounded>done <v-icon small>mdi-check</v-icon></v-btn> 
          </span>
        </v-card-text>

        <v-card-text style="text-align:left;">
          <div style="padding-bottom:.5em;">
            <span><b>Sources:</b></span>
            <v-icon v-if="getAccess.includes('internal_admin') && !EdittingSources" right style="position:relative; bottom:.2em;" @click="EdittingSources=true" small>mdi-marker</v-icon>
            <v-icon v-if="getAccess.includes('internal_admin') && EdittingSources" right style="position:relative; bottom:.2em;" @click="EdittingSources=false, showSources=false,AddSpecificSource=false,upForAdditionSource=[], upForRemoval=[], editSpecific=false" small>mdi-marker-cancel</v-icon>
            <br>
            
            <span>
            <span v-for="(item, idx) in viewItem.source_values" :key="idx">
              <div style="margin-left:1em; padding-top:1em;">
                <span v-if="item!=6">{{translateSource(item)}}</span>   <!-- Exclude News -->
                <v-icon v-if="getAccess.includes('internal_admin') && EdittingSources && !editSpecific" @click="editSpecific=item, specificList(item)" right style="position:relative; bottom:.2em;" small>mdi-marker</v-icon>
                <v-icon v-if="getAccess.includes('internal_admin') && EdittingSources && editSpecific" right style="position:relative; bottom:.2em;" @click="editSpecific=false" small>mdi-marker-cancel</v-icon>
              </div>
              
              <div style="margin-left:2em;">
                <span v-for="(vert, idx) in viewItem.sources[item]" :key="idx">
                  <ul>
                    <li v-if="!upForRemoval.includes(vert.domain_sid)">{{vert.domain_name}}
                      <v-icon v-if="editSpecific && editSpecific ==item" @click="removeVertical(vert)" right small>mdi-delete</v-icon>
                    </li>
                  </ul>
                </span>

                <span v-if="editSpecific==item && Object.keys(viewItem.sources[item]).length < domains.length">
                  <ul v-for="(additem, idx) in domains" :key="idx">
                    <li v-if="!alreadyHave.includes(additem.sid)">{{additem.name}} <a @click="addVertical(additem)"><v-icon color="" v-if="!upForAddition.includes(additem.sid)" small>mdi-plus-circle</v-icon></a> <v-icon v-if="upForAddition.includes(additem.sid)" small>mdi-check</v-icon></li>
                  </ul>
                </span>
              </div>

              <span v-if="editSpecific && editSpecific ==item">
                <v-btn @click="sendNewVerticals(item)" style="float:right; background-color:#E3F2FD;" elevation="0" rounded>done <v-icon small>mdi-check</v-icon></v-btn>
              </span>
            </span>
            <span style="position:relative; top:1em;" v-if="EdittingSources && !editSpecific && Object.keys(viewItem.sources).length < source.length">
              <a v-if="!showSources" @click="showSources=true" style="margin-left:1em;">Add Source<v-icon color="primary" right small>mdi-plus-circle</v-icon></a>
              <a v-if="showSources" @click="showSources=false" style="margin-left:1em;">cancel<v-icon color="primary" right small>mdi-close-circle</v-icon></a>
            </span>
            <span v-if="showSources" style="position:relative; top:1.5em;">
              <ul v-for="(s,idx) in source" :key="idx" style="margin-left:1em; list-style-type:none;">
                <li v-if="!Object.keys(viewItem.sources).includes(String(s.value)) && !AddSpecificSource"><b>{{s.name}}</b> <a @click="AddSpecificSource=s.value"><v-icon small>mdi-plus-circle</v-icon></a> </li>
                <li v-else-if="AddSpecificSource == s.value"><b>{{s.name}}</b> <v-icon small>mdi-check-circle</v-icon></li>
                
              </ul>
               <span v-if="AddSpecificSource" style="position:relative; top:0em;">
              <ul v-for="(v, idx) in domains" :key="idx" style="list-style-type:none; margin-left:2em;">
                <li>{{v.name}} <a v-if="!upForAdditionSource.includes(v.sid)" @click="upForAdditionSource.push(v.sid)"><v-icon small>mdi-plus-circle</v-icon></a><v-icon v-else small>mdi-check-circle-outline</v-icon></li>
              </ul>
              <v-btn @click="sendNewSource(),EdittingSources=false; showSources=false" style="float:right; background-color:#E3F2FD;" elevation="0" rounded>done <v-icon small>mdi-check</v-icon></v-btn> 
            </span>
            </span>
           
          </span>

          </div>
        </v-card-text>


        <v-container justify="center" >
          
        </v-container>
        <v-card-text style="text-align:left; padding-left:2em;">
          <div style="padding-bottom:.5em; color:#333;">
            <span><b>Anonymous Tokens</b></span><span v-if="getAccess.includes('internal_admin')" style="position:relative; left:.5em; bottom:.2em;"><v-icon @click="editTokens(item)" small>mdi-pencil-outline</v-icon></span><br>
          </div>
          <div v-if="tokenList.length">
            <v-simple-table dense ref="tokenTable">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Note
                    </th>
                    <th class="text-left">
                      Expiration Date
                    </th>
                    <th class="text-left">
                      Created On
                    </th>
                    <th class="text-center">
                      View Stats
                    </th>
                    <th class="text-center">
                      Copy Access URL
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in tokenList"
                    :key="idx"
                  >
                    <td>{{ item.note }}</td>
                    <td>{{ transDateString(item.expiration_date) }}</td>
                    <td>{{ transDateString(item.created_at) }}</td>
                    <td class="text-center"><v-icon @click="openUserStats(item)" small>mdi-chart-box</v-icon></td>

                    <td class="text-center">
                    <button @click="copy(item.token)"><v-icon style="margin-left:1em;" small>mdi-content-copy</v-icon></button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="editingTokens" max-width="700">

      <v-card v-if="!addToken && !changeExpiry" style="width:700px; min-height:150px;">
        <v-card-text v-if="tokenList.length">
          <div style="padding-top:1em;">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Note
                    </th>
                    <th class="text-left">
                      Expiration Date
                    </th>
                    <th class="text-left">
                      Created On
                    </th>
                    <th class="text-center">
                      Copy Access URL
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, idx) in tokenList"
                    :key="idx"
                  >
                    <td>{{ item.note }}</td>
                    <td>{{ transDateString(item.expiration_date) }} <button><v-icon @click="openNewExpirty(item)" style="margin-left:0em; margin-bottom:.3em;" small>mdi-pencil</v-icon></button></td>
                    <td>{{ transDateString(item.created_at) }}</td>
                    <td class="text-center">
                    <button @click="copy(item.token)"><v-icon style="margin-left:1em;" small>mdi-content-copy</v-icon></button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div>
            <v-btn @click="addToken=true" class="bounce" style="background-color:#E3F2FD; border-radius:25px; position:absolute; bottom:1em; right: 1em;" elevation="0">Add Token</v-btn>
          </div>
        </v-card-text>
        <v-card-text v-else style="padding:2em;">
          <div>
            No anonymous access tokens
          </div>

          <div>
            <v-btn @click="addToken=true" class="bounce" style="background-color:#E3F2FD; border-radius:25px; position:absolute; bottom:1em; right: 1em;" elevation="0">Add Token</v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card v-if="addToken && !changeExpiry" style="width:700px; min-height:150px;">
        <v-card-text style="padding-top:2em;">
        <v-form lazy-validation ref="anonForm">
          <v-text-field style="width:400px;" v-model="anonName" label="Add name/description (Internal Only)"></v-text-field>
          <v-radio-group row
            v-model="defaultEx"
            mandatory
          >
            <v-radio
              label="Default Expiry (7 days)"
              value="default"
            ></v-radio>
            <v-radio
              label="Other"
              value="other"
            ></v-radio>
          </v-radio-group>

          <span v-if="defaultEx == 'other'" style="position:relative; bottom:1em;">
            <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="expiryDate"
                    label="Expiry Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :rules="dateRules"
                    style="width:225px;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="expiryDate"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
          </span>
          <v-btn @click="createAnonToken()" class="create-btn" elevation="0" style="background-color:#E3F2FD; border-radius:25px; position:absolute; bottom:2em; right:2em;">Create <v-icon small>mdi-plus</v-icon></v-btn>
        </v-form>
        </v-card-text>
      </v-card>
      <v-card v-if="changeExpiry" style="height:200px;">
        <v-card-text style="padding:2em; width:50%; margin-left:3em;">
          <v-form ref="newE" lazy-validation>
        <v-row justify="center">
        <v-menu
          v-model="menu4"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          style="margin-bottom:3em;"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="newExpiry"
              label="Expiry Date"
              prepend-icon="mdi-calendar"
              readonly
              :placeholder="transDateString(expiryEditItem.expiration_date)"
              v-bind="attrs"
              v-on="on"
              required
              :rules="dateRules"
              style="width:225px;"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newExpiry"
            @input="menu4 = false"
          ></v-date-picker>
        </v-menu>
         
        </v-row>

        <div style="padding:1.5em">
        <v-text-field style="width:300px;" label="Edit Note" :placeholder="expiryEditItem.note" dense v-model="newExpiryNote">
        </v-text-field>

        </div>
        <v-row>
          <v-btn elevation="0" style="background-color:#E3F2FD; border-radius:25px; position:absolute; right:1em; bottom:1em;" @click="editExpiry()">Set</v-btn>
        </v-row>
        </v-form>
        </v-card-text>
        
      </v-card>
    </v-dialog>

    <v-dialog v-model="userStats" max-width="800">
      <v-card class="mx-auto" style="height:100%; width:800px; padding: 1em 1em 4em 1em; ">
        <v-card-title>
          <v-icon
            small
            class="mr-2"
            @click="userStats=false"
            style="position:absolute; top:1em; right:.5em;">
            mdi-close
          </v-icon>
        </v-card-title>
        <span v-if="userStatsLoaded">
        <v-card-text>
          <v-select style="width:300px; float:right;" @change="updateActivity" dense :items="eventTypes" item-text="name" item-value="value" v-model="activityFilter"></v-select>
        </v-card-text>
        <v-card-text>
          <div style="padding-left:1em; padding-right:1em;">
            <UserActivity :parentData="monthAnon" :title="'Total Monthly User Activity'" :key="monthAnon" @monthEmit="monthPush" />
          </div>
        </v-card-text>

        <v-card-text style="margin-top:2em;">
          <UserLogDetails :parentData="monthAnon" :parentMonth="userDetailsMonth" :key="tokenStats" :parentUser="tokenStats"   />
        </v-card-text>
        </span>
      </v-card>
    </v-dialog>

    <v-dialog v-model="autoDialog" width="600">
      <v-card style="width:600px; height:200px;">
        <v-card-text></v-card-text>
        <span v-if="dialogItem.is_auto_assigned === false || dialogItem.is_auto_assigned === null">
          <v-card-text style="text-align:center;" >
            Auto assign this profile to all registering users?
          </v-card-text>
          <v-card-text style="text-align:center;">
            <v-btn elevation="0" class="bounce2" rounded style="margin:1em; background-color:#E0E0E0;" @click="autoDialog=false">Cancel</v-btn>
            <v-btn elevation="0" class="bounce2" rounded style="margin:1em; background-color:#BBDEFB;" @click="aY">Yes</v-btn>
          </v-card-text>
        </span>
        <span v-else>
          <v-card-text style="text-align:center;">
            Remove auto assign from this profile?
          </v-card-text>
          <v-card-text style="text-align:center;">
            <v-btn elevation="0" class="bounce2" rounded style="margin:1em; background-color:#E0E0E0;" @click="autoDialog=false">Cancel</v-btn>
            <v-btn elevation="0" class="bounce2" rounded style="margin:1em; background-color:#BBDEFB;" @click="bY">Yes</v-btn>
          </v-card-text>
        </span>
      </v-card>
    </v-dialog>
    
    
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import stringFunctions            from '@/mixins/stringFunctions';
  import objectFunctions            from "@/mixins/objectFunctions";
  import logQueries                 from '@/mixins/logQueries';
  import UserActivity               from '@/components/AdminMgmt/UserActivity.vue';
  import UserLogDetails             from '@/components/AdminMgmt/UserLogDetails.vue';

  export default {
    name: 'gap-manager',
    components: {
      UserActivity,
      UserLogDetails
    },
    mixins: [
      stringFunctions,
      logQueries
    ],
    data() {
      return {
        EdittingSpan: false,
        EdittingDomains: false,
        EdittingEntitlements: false,
        EdittingSources: false,
        editSpecific: false,
        upForRemoval: [],
        upForAddition: [],
        alreadyHave: [],
        AddingDomain: '',
        showSources: false,
        AddSpecificSource: false,
        upForAdditionSource: [],

        userDetailsMonth: 'first',
        myloadingvariable: false,
        org: '',
        activityFilter: '',
        userStatsLoaded: false,
        startDate: '',
        endDate: '',
        tableArray: [],
        addToken: false,
        viewItem: {},
        editing: false,
        spanEdit: false,
        spanDone: false,
        editingTokens: false,
        doneChecking: false,
        anonName: '',
        expiryDate: '',
        fullVerticals: [],
        newExpiry: '',
        expiryEditItem: '',
        defaultEx: '',
        otherEx: '',
        editingSources:false,
        sourcesCheckbox: [],
        yColor: 'transparent',
        nColor: 'transparent',
        same: false,
        answered: false,
        cFL: false,
        mi: true,
        fi: false,
        portal: '',
        dashboard: false,
        bpm: false,
        tokenList: [],
        monthAnon: {},
        monthDetails: {},
        userStats: false,
        changeExpiry: false,
        newExpiryNote: '',
        cFExports: false,
        tokenStats: {},
        cFDomains: [],
        autoDialog: false,
        dialogItem: '',
        info: '',
        headers: [
          {text: 'Name', align: 'start', sortable: false, value: 'name'},
          // {text: '', align: 'start', sortable: false, value: 'is_auto_assigned'},
          {text: 'Date Created', align: 'start', sortable: false, value: 'created'},
          {text: 'Span', align: 'start', sortable: false, value: 'duration'},
          {text: 'View', align: 'start', sortable: false, value: 'view'},
        ],
        source: objectFunctions.dataSources,
        domains: objectFunctions.domains,
        eventTypes: [
          {name: 'All Event Types', value: 'all'},
          {name: 'Page Loads', value: 'page-load'},
          {name: 'Preview Logins', value: 'anon-login'},
          {name: 'Report Views', value: 'view-report'},
          {name: 'CF Object Loads', value: 'object-load'},
          {name: 'Logins', value: 'login'},
          {name: 'Logouts', value: 'logout'},
          {name: 'CF Exports', value: 'csv-download'},
          {name: 'Button Clicks', value: 'click'},
        ],
      }
    },
    props: {
      parentData: String
    },
    created() {
      this.getViewOrg()
    },

    computed: {
      ...mapGetters([
        'getAccess'
      ])
    },

    methods: {

      ...mapActions([
        'assumeOrg',
        'editGap',
        'getAnonToken',
        'postAnonToken',
        'editAnonToken',
        'getOrgInfo',
        'queryLogs'

      ]),

      view(item) {
        console.log(item)
        this.viewItem = {};
        this.viewItem = item;
        this.EdittingSpan = false;
        this.EdittingDomains = false;
        this.clearEntilements();
        this.AddingDomain = '';
        this.editSpecific = false;
        this.upForAddition = [];
        this.upForRemoval = [];
        this.alreadyHave = [];
        this.getTokensList(item);
      },

      edit() {
        let item = this.viewItem
        console.log(item)
       
        for (const i in this.source) {
          console.log(this.source[i].value)
          console.log(this.viewItem.source_values)
          if ( this.viewItem.source_values.includes(String(this.source[i].value))) {
            this.source[i].prev = true;
          }
          console.log(this.source)
        }
        if (this.viewItem.market_insights) {
          this.mi = true;
        }
        if (item.influencer_insights) {
          this.fi = true;
        }
        if (item.consumer_forecast) {
          this.cFL = true;
        }
        if (item.portal) {
          this.portal = true;
        }
        if (item.exports) {
          this.cFExports= true;
        }
        if (item.dashboards) {
          this.dashboard= true;
        }
        if (item.brand_pulse) {
          this.bpm= true;
        }
         this.editing = true; 
         this.doneChecking = true;
      },

      editSpan() {
        this.spanEdit = true;
        this.span
      },

      copy(token) {
        let text = 'https://socialstandards.com/AppView/client-home?jwt='+ token
        navigator.clipboard.writeText(text)
        .then(() => {
         console.log('success')
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
      },

      otherSources(source, item) {
        this.fullVerticals.push({source: source, vert: item})
        //console.log(array)
      },

      switchAdd() {
        this.$emit('newGap', true)
      },

       yesNoClick() {
        this.same = true;
        this.yColor = '#C8E6C9';
        this.nColor = 'transparent';
        this.answered = true;
      },

      noYesClick() {
        this.same = false;
        this.yColor = 'transparent';
        this.nColor = '#FFE0B2';
        this.answered = true;
        this.cFDomains = []
        
      },

      closeOptions() {
        this.EdittingSpan = false;
        this.EdittingDomains = false;
        this.clearEntilements();
        this.editSpecific = false;
        this.upForAddition = [];
        this.upForRemoval = [];
        this.alreadyHave = [];
        this.viewItem = {};
      },

      clearEntilements() {
        this.EdittingEntitlements = false;
        this.mi = this.viewItem.market_insights;
        this.fi = this.viewItem.follower_insights;
        this.cFL = this.viewItem.consumer_forecast;
        this.portal = this.viewItem.portal;
        this.cFExports = this.viewItem.exports;
        this.dashboard = this.viewItem.dashboards;
        this.bpm = this.viewItem.brand_pulse;
      },

     
      

      monthPush(value) {
        this.userDetailsMonth = value
      },

      openUserStats: async function(item) {
        this.userStats = true;
        this.tokenStats = item;
        this.activityFilter = 'all',
        console.log(item);
        this.activity(item);
        //this.secondTable(item)

      },

      activity: async function(item) {
        let result
        result = this.monthlyEventCount(item)
        console.log(result)

        await this.queryLogs(result).then((data)=> {
          console.log(data)
          this.monthAnon = data.data.log_data;
          this.userStatsLoaded = true
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },

      updateActivity: async function() {
        let item = this.tokenStats
        console.log(item)
        console.log(this.activityFilter)
        let payload = this.monthlyFilteredCount(item, this.activityFilter)
        console.log(payload)

        await this.queryLogs(payload).then((data)=> {
          console.log(data)
          this.monthAnon = data.data.log_data;
        }) 
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },

      secondTable: async function(item) {
        let result = this.eventsOutput(item)
        console.log(result)
        await this.queryLogs(result).then((data)=> {
          console.log(data)
          this.monthDetails = data.data.log_data;
        }) 
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
        
      },

      removeEmailDomain(item) {
        console.log(item)
        console.log(this.viewItem);
        this.removeDomain = item.email_domain;
        this.viewItem.profile_email_domains.filter(x => x.email_domain != item.email_domain)
      },

      getTokensList: async function(item) {
        console.log(item)
        let payload = {
          org: this.parentData,
          gap_uid: this.viewItem.uid,
        }
        console.log(payload)
        await this.getAnonToken(payload).then((data) => {
          console.log(data)
          this.tokenList = data.data.anonymous_tokens
        })
        .catch((err) => { console.log(err)} )
      },

      editTokens: async function() {
        this.editingTokens = true;
      },

      openNewExpirty(item) {
        this.changeExpiry = true;
        this.expiryEditItem = item
        console.log(item)
        this.menu4 = item.expiration_date
      },

      editExpiry: async function() {
        this.$refs.newE.validate()
        let payload
        if (this.newExpiry) {
          payload = {
          org_uid: this.expiryEditItem.organization_uid,
          gap_uid: this.expiryEditItem.gap_uid,
          anon_token: this.expiryEditItem.token,
          new_exp: this.newExpiry,
          note: this.newExpiryNote || this.expiryEditItem.note,
          }
        }
        console.log(this.expiryEditItem);
        
        console.log(payload)
        await this.editAnonToken(payload).then((data) => {console.log(data)})
        .then(() => {
          this.changeExpiry = false;
          this.editingTokens = false;
          this.getTokensList()
          })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },

      makeNull(item) {
        let it = item
        if (item == '') { it = null}
        return it
      },

      pushSpanEdit: async function() {
        let payload = {
          org_uid: this.parentData,
          gap_uid: this.viewItem.uid, 
          data: {
            time_start: this.makeNull(this.startDate),
            time_end: this.makeNull(this.endDate)
          }
        }
        await this.editGap(payload).then((data) => {
          console.log(data)
          this.viewItem = {};
          this.EdittingSpan = false;
        })
        .catch((e) => {
          console.log(e);
          console.log(e.response)
        })
      },

      deleteDomain: async function(domain) {
        let payload = {
          org_uid: this.parentData,
          gap_uid: this.viewItem.uid, 
          data: {
            profile_email_domains: [{
              email_domain: domain,
              remove: true
            }]
          }
        }
        await this.editGap(payload).then((data) => {
          console.log(data);
          this.getViewOrg();
          this.viewItem = {};
          this.EdittingDomains = false;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },

      addDomain: async function() {
        if (this.AddingDomain) {
            let payload = {
            org_uid: this.parentData,
            gap_uid: this.viewItem.uid, 
            data: {
              profile_email_domains: [
                { email_domain: this.AddingDomain }
              ]
            }
          }
          await this.editGap(payload).then((data) => {
            console.log(data);
            this.getViewOrg();
            this.viewItem = {};
            this.AddingDomain = ''
            this.EdittingDomains = false;
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response)
         })
        }
      },

      sendNewEntitlements: async function() {
        let payload = {
          org_uid: this.parentData,
          gap_uid: this.viewItem.uid,
          data: {
            consumer_forecast: this.cFL || false,
            market_insights: this.mi || false,
            portal: this.portal || false,
            exports: this.cFExports || false,
            influencer_insights: this.fi || false,
            dashboards: this.dashboard || false,
            brand_pulse: this.bpm || false
          }
        }
        console.log(payload)
        await this.editGap(payload).then((data) => {
          console.log(data);
          this.getViewOrg();
          this.viewItem = {};
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })

      },

      sendNewSource: async function() {
        let set = {}
        let domains = {}
        this.upForAdditionSource.map(x => {
          domains[x] = { domain_sid: x}
        })
        set[this.AddSpecificSource] = domains
        let payload = {
          org_uid: this.parentData,
          gap_uid: this.viewItem.uid,
          data: {
            profile_sources: set
          }
        }
        console.log(payload)
        await this.editGap(payload).then((data) => {
          console.log(data);
          this.getViewOrg();
          this.upForAdditionSource=[];
          this.AddSpecificSource=false;
          this.viewItem = {};
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },
      

     removeVertical(vert) {
       console.log(vert)
       this.upForRemoval.push(vert.domain_sid)
     },

     addVertical(vert) {
       console.log(vert)
       this.upForAddition.push(vert.sid);
       console.log(this.upForAddition)
     },

     specificList(source) {
       this.alreadyHave = Object.keys(this.viewItem.sources[source])
       this.alreadyHave = this.alreadyHave.map(x => {return parseInt(x)})
       console.log(this.alreadyHave)
     },
      sendNewVerticals: async function(source) {
      console.log(source)
      let items = {}
      this.upForRemoval.map(x => {
        items[x] = { domain_sid: x, remove: true}
      })
      this.upForAddition.map(x => {
        items[x] = {domain_sid: x}
      })
      let data = {}
      data[source] = items
      let payload = {
        org_uid: this.parentData,
        gap_uid: this.viewItem.uid,
        data: {
          profile_sources: data
        }
      }
      console.log(payload)
      await this.editGap(payload).then((data) => {
          console.log(data);
          this.getViewOrg();
          this.EdittingSources = false;
          this.editSpecific = false;
          this.upForAddition = [];
          this.upForRemoval = [];
          this.alreadyHave = [];
          this.viewItem = {};
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
     },

    clearEditing() {
      this.editing = false;
      this.spanEdit = false;
      this.spanDone = false;
      this.editingSources = false;
      this.editing = false;
      this.viewItem = {};
      this.mi = '',
      this.fi = '',
      this.cFL = '';
      this.portal = '';
    },

    csAssume: async function () {
      if (this.parentData != '') {
        let payload = this.parentData;
        await this.assumeOrg(payload)
        .then((data) => {
          console.log(data)
          })
        .catch((err) => {
          console.log(err)
        })
      }
    },

    createAnonToken: async function() {
      console.log(this.expiryDate)
        console.log(this.defaultEx)
        console.log(this.otherEx)
        console.log(this.anonName)
        console.log(this.viewItem)

        if (this.expiryDate == '') {
          this.expiryDate = null
        }

        if (this.defaultEx == 'default') {
          this.expiryDate = null
        }

        let payload = {
          org_uid: this.parentData,
          gap_uid: this.viewItem.uid,
          data: {
            note: this.anonName,
            expiry: this.expiryDate
          }
        }
        console.log(payload)
        await this.postAnonToken(payload).then((data) => {
          console.log(data);
          this.editingTokens = false;
          this.getTokensList()
        }) 
        .catch((err)=> {
          console.log(err)
        }) 
    },

    createTable(set) {
      console.log(set)
      let gaps = set.org_profiles
      console.log(gaps)
      let list = []
      let duration
      
      for (const i in gaps) {
        console.log('gaps', gaps[i])
        let source_values = Object.keys(gaps[i].profile_sources)
       
        if (gaps[i].time_start && gaps[i].time_end) {
          duration = this.transDateString(gaps[i].time_start) + '-' + this.transDateString(gaps[i].time_end)
        }
        else if (gaps[i].time_start && !gaps[i].time_end) {
          duration = this.transDateString(gaps[i].time_start) + ' +'
        }
        else if (!gaps[i].time_start && gaps[i].time_end) {
          duration = '-' + this.transDateString(gaps[i].time_end) 
        }
        else {
          duration = 'Not set'
        }
        list.push(
          {uid: gaps[i].gap_uid,
          name: gaps[i].profile_name,
          start_date: gaps[i].time_start,
          end_date: gaps[i].time_end,
          duration: duration,
          created: this.transDateString(gaps[i].created_at),
          consumer_forecast: gaps[i].consumer_forecast,
          influencer_insights: gaps[i].influencer_insights,
          market_insights: gaps[i].market_insights,
          sources: gaps[i].profile_sources,
          source_values: source_values,
          exports: gaps[i].allow_data_export,
          is_auto_assigned: gaps[i].is_auto_assigned,
          profile_email_domains: gaps[i].profile_email_domains,
          portal: gaps[i].portal,
          dashboards: gaps[i].dashboards,
          brand_pulse: gaps[i].brand_pulse,
          })
      }
      this.tableArray = list
      console.log(this.tableArray)
      this.myloadingvariable = true;

    },

    getViewOrg: async function() {
      let payload = this.parentData
      console.log(payload)
      await this.getOrgInfo(payload).then((data) => {
        console.log(data)
        if (data.data.organization) {
          this.info = data.data.organization
          this.createTable(data.data.organization);
        }
      }).catch((err) => {
        console.log(err)
      })
    },

    autoR(item) {
      console.log(item)
      this.autoDialog = true
      this.dialogItem = item
    },
    autoA(item) {
      console.log(item)
      this.autoDialog = true
      this.dialogItem = item
    },

      // related to auto-assign
    aY: async function() {
      console.log(this.dialogItem)

      let data = {
        is_auto_assigned: true,
        market_insights: this.dialogItem.market_insights,
        influencer_insights: this.dialogItem.influencer_insights,
        consumer_forecast: this.dialogItem.consumer_forecast,
        allow_data_export: this.dialogItem.exports,
        dashboards: this.dialogItem.dashboards,
        brand_pulse: this.dialogItem.brand_pulse,
        time_start: this.dialogItem.start_date,
        time_end: this.dialogItem.end_data || null,
      }
      console.log(data)
      let payload = {
        gap_uid: this.dialogItem.uid,
        org_uid: this.info.organization_uid,
        data: data
      }
      console.log(payload)
      await this.editGap(payload).then((data) => {console.log(data); this.getViewOrg()})
      .catch((err) => {
        console.log(err);
        console.log(err.response)
      })
      this.autoDialog = false
   },

      // related to auto-assign
      bY: async function() {
      console.log(this.dialogItem)
      let data = {
        is_auto_assigned: false,
        market_insights: this.dialogItem.market_insights,
        influencer_insights: this.dialogItem.influencer_insights,
        consumer_forecast: this.dialogItem.consumer_forecast,
        allow_data_export: this.dialogItem.exports,
        dashboards: this.dialogItem.dashboards,
        brand_pulse: this.dialogItem.brand_pulse,
        time_start: this.dialogItem.start_date,
        time_end: this.dialogItem.end_data || null,
      }
  
      let payload = {
        gap_uid: this.dialogItem.uid,
        org_uid: this.info.organization_uid,
        data: data
      }
      console.log(payload)
      await this.editGap(payload).then((data) => {console.log(data); this.getViewOrg()})
      .catch((err) => {
        console.log(err);
        console.log(err.response)
      })
      this.autoDialog = false
    },
    }
  }
</script>

<style lang="scss" scoped>
.bounce:hover {
  position: absolute;
  bottom:1.2em !important;
}
.bounce2:hover {
  position:relative;
  bottom:.2em;
}
.create-btn:hover {
  position:relative;
  bottom:.2em;
}
.yesNo:hover {
background-color: #C8E6C9 !important;
}

.noYes:hover {
  background-color: #FFE0B2 !important;
}
</style>
