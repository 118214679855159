<template>
  <v-card
    class="mx-auto lighten-5"  
    style="background-color:#f3faff;"
    elevation="0"    
  >
    <v-card-text>
      <v-btn-toggle rounded mandatory>
        <v-btn id="toggle" elevation='0' @click="activeState = false">
            All
          </v-btn>
          <v-btn id="toggle" elevation='0' @click="toggle()">
            Active Only
          </v-btn>
      </v-btn-toggle>
      
      <span v-if="activeState == false">
      <v-autocomplete
        :items="companies"
        v-model="selectedCompany"
        label="Company"
        item-text="customerCompany"
        item-value="uid"
        @change="handleClick"
        bottom
      ></v-autocomplete>
      </span>
      <span v-else>
      <v-autocomplete
        :items="this.activeCompanies"
        v-model="selectedCompany"
        label="Company"
        item-text="customerCompany"
        item-value="uid"
        @change="handleClick"
        bottom
      ></v-autocomplete>
      </span>

    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState, mapActions} from 'vuex';
  export default {
    name: 'Admin',
     props: ['companies'],
    data () {
      return {
        selectedCompany: null,
        activeState: false,
      }
    },
    created () {
      this.getCompanies();
    },
    methods: {
      handleClick () {
        this.getWorkProducts(this.selectedCompany);
        this.getCompany(this.selectedCompany);
      },
      ...mapActions([
        'getCompanies',
        'getWorkProducts',
        'getCompany'
      ]),
      toggle: function() {
        this.activeState = !this.activeState
      },
    },
    computed: {
      ...mapState([
        'companies'
      ]),
      activeCompanies: function() {
        let filtered = this.companies;
        filtered = filtered.filter(c => c.weight >= 9)
        return filtered
      }
    }
  }
</script>

<style>
#toggle {
  height:20px; 
  width:80px; 
  font-size:.7em; 
  float:right; 
  
  background-color:white;
}
</style>