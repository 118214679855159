<template>
  <v-container style="width: 400px;">
   
    <v-form
      ref="form"
      v-model="passReset"
      lazy-validation
      style="padding-top:1em;"
      @keydown.native.enter="submit()"
    >
      <v-text-field
        v-if="isLoggedIn"
        v-model="oldPassword"
        label="Current Password"
        type="password"
        required
        outlined
        dense        
      ></v-text-field>

      <v-text-field
        v-model="newPassword1"
        :rules="newPass"
        label="New Password"
        type="password"
        required
        outlined
        dense
      ></v-text-field>

      <v-text-field
        v-model="newPassword2"
        :rules="newPass"
        label="Confirm New Password"
        type="password"
        required
        outlined
        dense
      ></v-text-field>

      <v-btn
        :disabled="!passReset"
        color="success"
        class="mr-4"
        @click="submit()"
      >
        Submit
      </v-btn>

      <div style="line-height:9px;"><br></div>

      <!--If passwords don't match -->  
      <v-alert 
        type='error' 
        v-if="notMatched" 
        style="font-size:1em;"
        id='matchError'
        >Submitted new passwords do not match. Try Again.
      </v-alert>

      <v-alert 
        v-if="resetFailedMessage"
        type="error"
        >{{ resetFailedMessage }}
      </v-alert>

    </v-form>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex';
  export default {
    name: 'password-reset',
    props: {
      parentData: Object
    },
    data () {
      return {
        valid: true,
        oldPassword: null,
        newPassword1: null,
        newPassword2: null,
        notMatched: false,
        passReset: true,
        error: {},
        resetFailedMessage: null,
        newPass: [
          v => !!v || 'New Password is required',
        ],
      }
    },
    created () {
      console.log(this.parentData)
      /* 
      when this is a 'forgot password' reset the user will not be logged in so we 
      auto fill the password with the supplied token from the api
      when this is a voluntary reset, the user is logged in, so we do not
      auto fill the old password and make them supply it 
      */
      if (!this.isLoggedIn) {
        this.oldPassword = this.$route.query.token;
      }
    },
    computed: {
      ...mapGetters([
        'isLoggedIn',
      ]),
      ...mapState([
        'tempPassword',
        'token'
      ])
    },
    methods: {
      ...mapActions([
        'resetPassword',
        'setPassword',
        'getAccount',
        'setUser'
      ]),

      loginUser: async function(data) {
        console.log(data)
        await this.setUser(data.data)
        .then(() => {
          console.log(this.isLoggedIn);
          this.$router.push({name: 'client-home'});
        })
        .catch((error) => {console.log(error)})
      },

       submit: async function () {
        console.log('in submit')
        this.$refs.form.validate();
        if (this.newPassword1 !== this.newPassword2) {
          this.notMatched = true;
        }
        else {
          if (this.$route.query.full_name) {
            console.log('query.ful_name is true')
            let payload = {
              oldPassword: this.$route.query.token,
              newPassword: this.newPassword1
            }

            console.log(payload)

            await this.resetPassword(payload)

            .then((data) => {
              console.log(data)
              this.loginUser(data)

              })
            .catch((error) => {
              console.log(error)
              if (error.response) {
                this.error = error.response
                  console.log(error.response)
                  console.log(error.response.data.status)

              }
              
              })
          }
          else {
            let payload = {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword1
          }
           console.log(payload)
          this.resetPassword(payload)
          .then(response => {
            if (response.status === 200) {
              this.$router.push({name: 'client-home'});
            }
          })
          .catch(error => {
            switch (error.response.status) {
              case 403:
              case 404:
                this.resetFailedMessage = 'Submitted Current Password is incorrect. Please try again'
                break;
              default:
                this.resetFailedMessage = 'We encountered and error. Please try again or contact us.'
            }
          })
        }
          }
      },
    },
  }
</script>
