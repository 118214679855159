<template>
  <div>
    <span>
      <v-row justify="center">
      <v-progress-circular
        v-if="load"
        :size="48"
        color="primary"
        indeterminate
        style="z-index:100000; position:relative; top:.8em; left:1.5em;">
      </v-progress-circular>
      </v-row>
    </span>
    <apexchart v-if="!load" ref="stackChart" min-width="640" height="330" type="area" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name:'domain-percent',
  props: {
    parentObj: Object,
    parentDomain: Number
  },
  data() {
    return {
      name: 'percent of posts',
      load: false,
      series: [],
      chartOptions: {
        chart: {
          type: 'area',
          background: 'transparent',
           zoom: {
             enabled: false
           },
          toolbar: {
            show: false
          },
        },
        colors: ['#274b69', '#85a1c1', '#B08C63'],
        dataLabels: {
          enabled: false
        },
        yaxis: {
        labels: {
            formatter: function(val) {
              if (val) {return val.toFixed(0)+'%'}
            }}
      },
      xaxis: {
        categories: [],
        type: 'datetime',
      },
      legend: {
        position: 'top',
      },
        stroke: {
          curve: 'smooth',
        }
      },
      
    }
  },
  created() {
    this.create(this.parentObj) 
  },
  methods: {

    create(data) {
      this.load = true;
      
      let mySeries = [];

      for (const a in data.domain_percents.domains) {
        mySeries.push(data.domain_percents.domains[a])
      }
      mySeries.sort((a, b) => b.latest - a.latest)
      mySeries = mySeries.splice(0,3)
      //console.log(mySeries)

    let final = []
    let n  = mySeries.length < 3 ? mySeries.length : 3
    for (let i = 0; i < n; i++) {
      final.push({
        name: mySeries[i].domain_name, 
        data: mySeries[i].post_percents
      })
    } 
    this.series = final

      let months = data.domain_percents.month_date_set;

        this.chartOptions = {...this.chartOptions, ...{
          xaxis: {
            categories: months,
            type: 'datetime',
          }
        }}

        //colors: ['#20246f', '#6c2d6a', '#B83e5D', '#DC722F','#FFd032', '#BDBDBD'],
        // if (mySeries[0].latest < 50) {
        //   this.chartOptions = {...this.chartOptions, ...{
        //   yaxis: {
        //     min: 0,
        //     labels: {
        //     formatter: function(val) {
        //       if (val) {return val.toFixed(0)+'%'}
        //     }
        //   }
        //   }
        // }}
        // }
        // else {
        //   this.chartOptions = {...this.chartOptions, ...{
        //   yaxis: {
        //     min: 0,
        //     max: 100,
        //     labels: {
        //     formatter: function(val) {
        //       if (val) {return val.toFixed(0)+'%'}
        //     }
        //   }
        //   }
        // }
        // }
        // }
        this.load = false;
    },

  }
}
</script>
