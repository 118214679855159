import Vue from "vue";
import Vuex from "vuex";
import VueHead from "vue-head";

import axios from "@/plugins/axios";
import VueCookies from "vue-cookies";
import VueAxios from "vue-axios";
import createPersistedState from "vuex-persistedstate";

import getters from "@/store/getters.js";
import mutations from "@/store/mutations.js";
import actions from "@/store/actions.js";
import objectFunctions from "@/mixins/objectFunctions";

Vue.use(VueAxios, axios);
Vue.use(VueHead);
Vue.use(VueCookies);
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: [
        "loggedUser",
        "account",
        "company",
        "token",
        "publicWorkProducts",
        "workProducts",
        "userGuides",
        "nodeEnv",
        "access",
        "currentOrg",
        "myOrgs",
        "assumeToken",
        "assumedOrg",
        "availableDomains",
        "availableSourceDomains",
        "gap",
        "sourceList",
        "currentSource",
      ],
    }),
  ],

  state: {
    api_uri: process.env.VUE_APP_API_URI,
    testAPIURI: process.env.VUE_APP_API_URI_DEV_TEST,
    nodeEnv: process.env.NODE_ENV,
    cookieUrl: process.env.VUE_APP_COOKIE_DOMAIN,
    loggedUser: {},
    account: {},
    access: [],
    gap: {},
    availableDomains: [],
    availableSourceDomains: {},
    sourceList: [],
    dashboardList: [],
    currentSource: "",
    assumedOrg: {},
    assumeToken: "",
    tempPassword: null,
    invitedOrg: {},
    token: null,
    currentOrg: {},
    myOrgs: [],
    defaultOrg: "",
    workProducts: {},
    publicWorkProducts: {},
    viewLoading: false,
    company: {},
    companies: {},
    orgList: [],
    personnel: {},
    verticals: objectFunctions.domains,
    portalTabEmit: "company",
    userSettings: {},
    userGuides: {},
    dashboardBlobData: {},
    demoShowPercentiles: true,
    geoShowPercentiles: true,
    kebabMenuState: {},
  },

  getters,
  mutations,
  actions,
});
