<template>
  <div>
    <apexchart width="260" height="250" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: 'geo',
  props: {
    parentObj: Object,
    parentDomain: Number,
  },

  data() {
    return {
      percentile: {},
      tcolor: '#FFF',
      series: [],
          chartOptions: {

            responsive: [{
              breakpoint: '1000',
              options: {
                chart: {
                  width: 240,
                  height:265,
                },
                legend: {
                  show: true,
                  offsetX: 2,
                  position: 'bottom',
                },
                annotations: {
                  xaxis:[{
                    show:false,
                  }],
                  yaxis: [
                    {
                      y: 50,
                      strokeDashArray: 1,
                      width:'100%',
                      borderColor: '#BDBDBD',
                      offsetY: 0,
                    }
                  ]
                },
                xaxis: {
                  labels: {
                    show: false,
                  }
                },
                plotOptions: {
                bar: {
                  horizontal: false
                }
              },
                
              }
            }],

            chart: {
              type: 'bar',
              height: 350,
              yaxis: {
                type: 'numeric',
                tickAmount: 6,
                max: 100,
                min: 0,
              },
              toolbar: {
                show: false
              },
            },
            tooltip: {
              enabled: true,
            },
            annotations: {
              xaxis: [
                {
                  x: 50,
                  strokeDashArray: 0,
                  borderColor: '#BDBDBD',
                  offsetY: -5,
                  label: {
                    orientation: 'horizontal',
                    borderColor: '#BDBDBD',
                    offsetY: -15,
                    style: {
                      color: '#333',
                      background: '#fff'
                    },
                    text: '50th Percentile'
                    
                  }
                }
              ]
            },
            legend: {
              show: false
            },
            fill: {
              colors: [function({ value}) {
                if (value < 50) {
                    return '#3f4d63'
                } else {
                    return '#274b69'
                }
              }],
              opacity:1,
            },
            states: {
              active: {
                filter: {
                  type: 'none' /* none, lighten, darken */
                }
              }
            },
            plotOptions: {
            bar: {
              borderRadius: 2,
              horizontal: true,
              distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              type: 'category',
              categories: [],
            }
          },
        }
  },

  created() {
   this.createVars(this.parentObj, this.parentDomain)
  },

  methods: {
    createVars(data, domain) {
      this.percentile = data.us_msa.domains[domain].top_percentile
      let series = []
      let categories = []
      for (const i in this.percentile) {
        if (!isNaN(i)) {
          series.push(this.percentile[i].percentile)
          console.log(this.percentile[i].msa.split('-')[0])
          categories.push(this.percentile[i].msa.split('-')[0])
        }
        
      }
      this.series = [{
        data: series,
        name: 'percentile'
      }]

      this.chartOptions.yaxis = {
        type: 'numeric',
        max: 100,
        min: 0, 
      }

      this.chartOptions.xaxis = {
              type: 'category',
              categories: categories}
      
      this.chartOptions.tooltip.x = {
        formatter: function(val) {
          // let set = data.country.domains[domain].top_percentile
          // let item = set.filter(x => x.iso3 == val)
          return val
        }
      }
      
      this.chartOptions.tooltip = {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          let set = data.us_msa.domains[domain].top_percentile
          let val = w.config.xaxis.categories[dataPointIndex]
          let ending = 'th'

          if (series[seriesIndex][dataPointIndex].toString()[1] == 1) {ending = 'st'}
          else if (series[seriesIndex][dataPointIndex].toString()[1] == 2) {ending = 'nd'}
          else if (series[seriesIndex][dataPointIndex].toString()[1] == 3) {ending = 'rd'}
          let item = set.filter(x => x.msa.includes(val))
          return '<v-card class="tooltipA">' +
      '<div class="tooltip-title">' + item[0].msa + '</div>' +
      '<div class="tooltip-item">'+ 'percentile: ' + series[seriesIndex][dataPointIndex] + ending+ '<br>' +
      'raw: ' + item[0].percent.toFixed(1)+'%' +
      
      '</div>' +
      '</v-card>'
        }
      }
    },

    setColor(i) {
        let colors = ['#20246f', '#352d6a', '#6c2d6a', '#B83e5D', '#DC722F', '#CFD8DC'];
           this.tcolor = colors[i]
            console.log(this.tcolor)

    }
      
    
  }
}
</script>
<style lang="scss">
.tooltipA {
  min-width: 120px;
  width: 100%;
  height:80px;
  background-color: #FFF;
  opacity: .8;
  
}

.tooltip-title {
  height:30px;
  padding:.2em;
  
}

.tooltip-item {
  padding:.2em;
  //height:20px;
}
</style>
