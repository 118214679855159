<template>
  <v-container fluid fill-height fill-width >
    <v-layout row wrap class="text-center">
      <v-flex xs12 sm12 md8 lg9 xl9>
        <v-container style="padding-left:2em; padding-top:1.5em;">

          <v-tabs
            v-model="tab"
            background-color="#f3faff"
            color="#131737">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#company-reports" @click="tabEmitter('company')">Your Reports</v-tab>
            <v-tab href="#syndicated-reports" @click="tabEmitter('public')">Syndicated Reports</v-tab>
            <v-tab href="#more" v-if="getUserGuides.length !== 0" @click="tabEmitter('more')">More</v-tab>
          </v-tabs>

          <div style="height:52px; position:relative; bottom:1em;"> 
            <div style="float:right; height:10px; ">
              <p v-if="selectedVertical" class="filter-items">Active Filter: <b>{{ currentVerticalName }}</b>
                <v-btn class="filter-btn" text @click="clearFilter"><v-icon small>mdi-close-circle-outline</v-icon></v-btn>
              </p>
            </div>
            <br>
            <div style="float:right; height:10px; margin-top:.3em;">
              <p v-if="searchedReports" class="filter-items">Active Search: <b>{{ searchedReports }}
                </b> <v-btn class="filter-btn" text @click="clearSearch"><v-icon small>mdi-close-circle-outline</v-icon></v-btn>
              </p>
            </div>
          </div>
              
          <v-tabs-items v-model="tab" :value="tab">
            <v-tab-item value="company-reports">
              <v-card v-if="workProducts != ''" class="d-flex flex-wrap align-content-center pa-4 cardsalign" flat title>

                <v-card v-if="callFilteredPriv !== ''" class="d-flex flex-wrap align-content-center pa-4 cardsalign" flat title>
                <ReportCard
                  
                  class='mx-auto pa-2 ma-2 align-content-center'
                  v-for="product in callFilteredPriv"
                  v-bind:key="product.uid"
                  v-bind:product="product"
                  @clicked="menuTold"
                ></ReportCard>
                </v-card>
               <v-card v-else class="d-flex flex-wrap align-content-center pa-4" flat title>
                <span style="font-size:1.1em;">No reports for the current selection! Try changing your filters. </span>
              </v-card>

                
              </v-card>
              
              <v-card v-else class="d-flex flex-wrap align-content-center pa-4" flat title>
                <span style="font-size:1.1em;">We haven't prepared any reports for you yet. Check out our Syndicated Reports! </span>
              </v-card>
            </v-tab-item>

            <v-tab-item value="syndicated-reports"> 

              <v-card v-if="callFilteredPub !== ''" class="d-flex flex-wrap align-content-center cardsalign"  tile flat >
              <ReportCard
                class='mx-auto pa-2 ma-2 align-content-center'
                v-for="product in callFilteredPub"
                v-bind:key="product.object_uid"
                v-bind:product="product"
                @clicked="menuTold"
              ></ReportCard>
              </v-card>
              <v-card v-else class="d-flex flex-wrap align-content-center pa-4" flat title>
                <span style="font-size:1.1em;">No reports for the current selection! Try changing your filters. </span>
              </v-card>

            </v-tab-item>

            <v-tab-item value="more">
              <v-container style="width:100%; padding-top:0;">                     
                <div style="float:left; font-size:1.4em; padding-left:1em; font-weight:600;">Guides</div>
                <br>
                <div style="padding-top:1em;"><TrainingGrid v-bind:trainingList="getUserGuides" /></div>                      
              </v-container>
            </v-tab-item>
          </v-tabs-items>

        </v-container>
      </v-flex>

      <!-- company / admin -->
      <v-flex xs12 sm12 md4 lg3 xl3 >
        <v-container style="padding-right:2em; padding-top:1.5em;">

          <CompanyAdmin />
          
          <!-- Search and Filter-->
          <v-container  class="filter-container" v-if="this.portalTabEmit != 'more'" elevation="0">
            <v-form>
              <v-select 
                v-model="selectedVertical" 
                label="Select Market Vertical"
                :items="verticals"
                item-value="sid"
                item-text="name"
                ref="sel"
                :menu-props="{ bottom: true, offsetY: true, maxHeight: 450}"
                height="100%"
                clearable>
              </v-select>
              
              <v-text-field 
                v-model="searchedReports" 
                label="Search" 
                clearable 
                ref="search">
              </v-text-field>
            </v-form>
          </v-container>

        </v-container> 
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
  import reportFilteringFunctions from '@/mixins/reportFilteringFunctions';
  import processingMixin from '@/mixins/processingMixin';
  import CompanyAdmin from '@/components/Portal/CompanyAdmin';
  import ReportCard from '@/components/Portal/ReportCard';
  import TrainingGrid from '@/components/Portal/TrainingGrid.vue';
  import objectFunctions from '@/mixins/objectFunctions';
  import ResourcesView from '@/views/static-site-views/ResourcesView.vue';

  export default {
    name: 'portal-page',
    mixins: [
      processingMixin, 
      reportFilteringFunctions,
      objectFunctions,
      ResourcesView
    ],
    components: {
      ReportCard,
      CompanyAdmin,
      TrainingGrid
    },
    data () {
      return {
        form: this.$options.name,
        searchedReports: '',
        selectedVertical: '',
        loadPubWorkProducts: []
      }
    },
    created () {
      this.init()

      if (this.access.length == 0) {
        this.$router.push({name: 'disabled'})
      }
      else {
     //   this.init()
        this.logPageLoad({
          app_name: 'P',
          page_name: this.$options.name,
        })
      }
    },
    computed: {
      ...mapState([
        'loggedUser',
        'workProducts',
        'publicWorkProducts',
        'downloadLoading',
        'viewLoading',
        'company',
        'verticals',
        'portalTabEmit',
        'access'
      ]),
      ...mapGetters([
        'isLoggedIn',
        'isAdmin',
        'getUserGuides'
      ]),

      currentVerticalName(){
        return this.selectedVertical ? objectFunctions.marketVerticals[this.selectedVertical] : ''
      },

      callFilteredPub() {
        //difference between this one and the one below is the data object: publicWorkProducts/workProducts
        return this.filteredWorkProducts(this.publicWorkProducts, this.selectedVertical, this.searchedReports)
      },
      callFilteredPriv() {
        return this.filteredWorkProducts(this.workProducts, this.selectedVertical, this.searchedReports)
      },
      tab: {
        set (tab) {
          this.$router.replace({ query: { ...this.$route.query, tab } })
        },
        get () {
          return this.$route.query.tab
        }
      }
    },
    methods: {
      ...mapActions([
        'getWorkProducts',
        'downloadAs',
        'downloadItem',
        'setTabs',
        'setWorkProducts',
        'setPublicWorkProducts',
      ]),
      ...mapMutations([
        'SET_USER'
      ]),
      init: async function() {
        await this.getWorkProducts('EzmmIA_wjlinFAZBA_nHRs')
        .then((data)=> {
          this.setPublicWorkProducts(data.workProducts);
          //this.publicWorkProducts = data.workProducts
        })
        // .catch((err)=>{console.log(err)})
        await this.getWorkProducts(this.loggedUser.company_uid)
        .then((data)=> {
          this.setWorkProducts(data.workProducts);
        })
        // .catch((err)=>{console.log(err)})
      },
      clearFilter() {
        this.selectedVertical = null
      },
      clearSearch() {
        this.searchedReports = null
      },
      menuTold(value) {
        //takes the emitted value from clicking the tag -- and equals that value to selectedVertical 
        //-- triggering the filter to change, and the v-select component to update
        this.selectedVertical = value
      },
      tabEmitter(str) {
        //sets store.state.tabEmit to current tab value (either 'company' / 'public') -- can later be used to for v-ifs
        this.setTabs(str);
      },
    }
  }
</script>

<style>
  .tab-btn {
    border:solid; 
    padding:.7em; 
    border-width:2px; 
    border-radius: 15px; 
    margin-bottom:.5em;
    margin-right:1em;
  }
  .v-tab:hover:before {
        background-color: transparent;
      }
  .filter-items {
    padding-top:1em; 
    color:#333 !important;
  }
  .filter-btn {
    position:relative; 
    right:1.4em;
  }
  .filter-container{
    margin-top:.5em;
    background-color:#f3faff;
  }
</style>
