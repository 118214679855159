<template>
  <div ref="psychocard">
    <v-row  v-if="this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs'" justify="center" style="padding-top:1em;">
      <v-card  width='85%'  elevation="0">
        <v-data-table
            v-model="psychographicsTable"
            :items="first.concat(second)"
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [20]
            }"
            dense
            style="color:#333;"
                 
            >
            <template v-slot:item="{ item }">
              <tr style="height:10px; overflow: visible;">
                <td>{{item.name}}</td>
                <td style="width:20px;">{{item.percentile}}{{readAsEnding(item.percentile)}}</td>
                <td>
                  
                      <div class="rectangle">
                          <div class="rectangle2" :style="{ width:item.percentile+'%', backgroundColor:item.color, borderRadius: item.border_radius}">
                            <div class="vl">
                          </div>
                        </div>
                      </div>        
                </td>
              </tr>
            </template>
          </v-data-table> 
      </v-card>
    </v-row>

    <v-row v-else justify="center" style="padding-top:2em; position:relative; left:3em;">
      <v-flex sm6 style="position:relative; left:2em;">
        <v-card  width='85%'  elevation="0">
        <v-data-table
            v-model="psychographicsTable"
            :items="first"
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [14]
            }"
            :hide-default-footer="true"
            dense
            style="color:#333;"
                 
            >
            <template v-slot:item="{ item }">
              <tr style="height:10px; overflow: visible;">
                <td>{{item.name}}</td>
                <td style="width:20px;">{{item.percentile}}{{readAsEnding(item.percentile)}}</td>
                <td>
                      <div class="rectangle">
                          <div class="rectangle2" :style="{ width:item.percentile+'%', backgroundColor:item.color, borderRadius: item.border_radius}">
                            <div class="vl">
                          </div>
                        </div>
                      </div>
                </td>
              </tr>
            </template>
          </v-data-table> 
      </v-card>
      </v-flex>

      <v-flex sm6 style="position:relative; right:2em;">
        <v-card  width='85%'  elevation="0">
        <v-data-table
            v-model="psychographicsTable"
            :items="second"
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-options': [14]
            }"
            :hide-default-footer="true"
            dense
            style="color:#333;"
                 
            >
            <template v-slot:item="{ item }">
              <tr style="height:10px; overflow: visible;">
                <td>{{item.name}}</td>
                <td style="width:20px;">{{item.percentile}}{{readAsEnding(item.percentile)}}</td>
                <td>
                  
                      <div class="rectangle" >
                          <div class="rectangle2" :style="{ width:item.percentile+'%', backgroundColor:item.color, borderRadius: item.border_radius}">
                            <div class="vl">
                          </div>
                        </div>
                      </div>
                   
                </td>
              </tr>
            </template>
          </v-data-table> 
      </v-card>
      </v-flex>
      
    </v-row>
  </div>
</template>

<script>
import stringFunctions from '@/mixins/stringFunctions.js';
import objectFunctions from '@/mixins/objectFunctions.js';

export default {
  name: 'psychographics',
  props: {
    parentObj: Object,
    parentDomain: String,
    parentBP: String
  },
  mixins: [stringFunctions, objectFunctions],
  data() {
    return {
      psychographicsInterests: [],
      psychographicsHobbies: [],
      first: [],
      second: [],
      psychographicsTable: '',
      headers: [
        {text: 'Name', value: 'name', align:'left', sortable: false},
        {text: '', value: '', align:'right', sortable: false},
        {text: 'Percentile', value: 'percentile', align:'left', sortable: false,},
      ],
      sortBy: 'percentile',
      sortDesc: true,
    }
  },
  created()  {
    this.create(this.parentObj)
  },
  mounted() {
    this.$emit('height', this.$refs.psychocard.clientHeight)
  },
  methods: {

    create(data) {
      let psycho = data.psychographics.domains[this.parentDomain].data;
      this.filterPsychographics(psycho)
      let psychographics = this.filterPsychographics(psycho) 
      for (const i in psychographics) {
        if (!isNaN(i)) {
        if (psychographics[i].percentile > 50) {
          psychographics[i].color = '#20246F';
        }
        else {
          psychographics[i].color = '#9FA8DA';
        }
        if (psychographics[i].percentile == 100) {
            psychographics[i].border_radius = '4px 4px 4px 4px';
          }
        else {psychographics[i].border_radius = '4px 0px 0px 4px';}

        }
      }
      psychographics = psychographics.sort((a,b) => b.percentile - a.percentile)
      
      this.$emit('realLen', psychographics.length)
      let half = Math.ceil(psychographics.length / 2);
        this.first = psychographics.splice(0, half)
        this.second = psychographics.splice(-half)
    },
  }
}
</script>

<style scoped lang="scss">
  .rectangle {
  height: 15px;
  width: 100px;
  background-color:#E0E0E0;
  border-radius: 4px 4px 4px 4px;
}
.rectangle2 {
  height: 15px;
  z-index: 90;
}
.vl {
  border-left: 2px solid  #BDBDBD;
  height: 10px;
  position:relative;
  top:5px;
  left:50px;
  z-index: 900 !important;
}
</style>