<template>
  <div>
    <div>
      <objectToolbar
        @itemLoad="pageItemLoad"
        :parentSource="translateSource(getCurrentSource)"
        :breakpoint="bp"
        :parentDomain="myDomain"
        @monthDialog="monthD()"
        @sourceDialog="openSourceSwitch()"
        @infoDialog="infoD"
        :parentMonth="currentMonth"
        :filteredSources="filteredSources"
        @pushB="pageItemLoad"
      />

      <v-app-bar
        app
        style="
          background-image: linear-gradient(#3f4d63, #3f4d63);
          z-index: 10001;
        "
        dark
        inverted-scroll
        scroll-threshold="20"
      >
        <span style="padding-left: 1em"
          >'{{ this.myObject.name }}' in
          <a class="bar-domain" @click="domainDialog()" style="color: #fff"
            >{{ this.domainName }} <v-icon small>mdi-swap-horizontal</v-icon></a
          ></span
        >

        <v-spacer></v-spacer>

        <span v-if="!getLoggedUser.anon">
          <span
            v-if="!isBookmarked"
            style="
              position: absolute;
              right: 2em;
              bottom: 1.5em;
              padding-right: 1em;
            "
            ><a @click="bookmark()"
              ><v-icon class="bookmark" medium>mdi-bookmark-outline</v-icon></a
            ></span
          >
          <span
            v-else
            style="
              position: absolute;
              right: 2em;
              bottom: 1.5em;
              padding-right: 1em;
            "
            ><a @click="removeBookmark()"
              ><v-icon class="isbookmark" medium>mdi-bookmark</v-icon></a
            ></span
          >
        </span>
      </v-app-bar>

      <div id="whole">
        <div>
          <v-container
            style="position: absolute; top: 0em; right: 3em; padding-top: 0em"
          >
            <div style="float: right; width: 300px" v-if="bp > 1264">
              <v-select
                v-model="selectDomain"
                :placeholder="this.domainName"
                :items="filteredDomains"
                item-value="domain_sid"
                item-text="domain_name"
                light
                :menu-props="{ bottom: true, offsetY: true, maxHeight: 450 }"
                height="100%"
                hint="Switch Market Vertical"
                persistent-hint
                style="margin-right: 2em; z-index: 10000"
                @change="domainChange(selectDomain)"
              ></v-select>
            </div>
          </v-container>

          <v-container fluid id="blue-banner" style="z-index: 900">
            <v-container id="inner-blue">
              <v-row id="objectTitle">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind:style="{ color: myObject.color }"
                      style="
                        position: relative;
                        bottom: 0.3em;
                        padding-right: 0.3em;
                      "
                      md
                      v-bind="attrs"
                      v-on="on"
                      >mdi-square-rounded</v-icon
                    >
                  </template>
                  <span v-if="myObject.desc_name">{{
                    myObject.desc_name
                  }}</span>
                  <span v-else>{{ myObject.type }}</span>
                </v-tooltip>

                <span
                  v-if="['BE', 'BR', 'OR'].includes(myObject.type_code)"
                  style="margin-left: 0.2em; margin-bottom: 1em"
                  ><a class="brand-name" :href="myObject.web" target="_blank">{{
                    myObject.name
                  }}</a></span
                >
                <span v-else style="margin-left: 0.2em; margin-bottom: 1em"
                  ><a class="nonBrand-name">{{ myObject.name }}</a></span
                >

                <span
                  style="position: relative; top: 2.2em; margin-left: 0.3em"
                >
                  <v-tooltip
                    light
                    right
                    max-width="600px"
                    opacity=".8"
                    v-if="myObject.description.length"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#37474F" v-bind="attrs" v-on="on"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ myObject.description }}</span
                    ><br />
                  </v-tooltip>
                </span>

                <v-progress-circular
                  v-if="this.load"
                  :size="48"
                  color="#37474F"
                  indeterminate
                  style="
                    z-index: 100000;
                    position: relative;
                    top: 0.8em;
                    left: 1.5em;
                  "
                >
                </v-progress-circular>
              </v-row>

              <v-row>
                <v-flex xs12 sm12 md12 lg7>
                  <v-row>
                    <v-card class="auth-activity" elevation="2">
                      <v-app-bar
                        dense
                        color="#A6B7CD"
                        style="
                          padding-left: 1em;
                          border-radius: 3px 3px 0px 0px;
                        "
                        elevation="0"
                      >
                        <span style="color: #202022"
                          >Monthly Activity in {{ domainName }}</span
                        >
                      </v-app-bar>

                      <div style="position: relative; bottom: 3em; left: 2em">
                        <span v-if="getCurrentGap.allow_data_export">
                          <span
                            style="
                              font-size: 0.8em;
                              float: right;
                              position: relative;
                              top: 5em;
                              right: 4em;
                            "
                            ><a @click="downloadAuth()" class="csv-hover"
                              >Download CSV</a
                            ></span
                          >
                        </span>
                        <ActivityAuthLine
                          :width="this.authWidth"
                          :parAct="myObject"
                          :parentDomain="myDomain"
                        />
                      </div>
                    </v-card>
                  </v-row>
                </v-flex>

                <v-flex xs12 sm12 md4 lg5 class="all-sparks" v-if="bp >= 1264">
                  <v-row>
                    <v-flex xs12 sm5 md8 lg5 class="margin-sparks">
                      <v-card
                        class="spark1 transform"
                        v-bind:style="{ backgroundImage: absSpark }"
                        @click="changeD()"
                        elevation="2"
                      >
                        <v-card-text
                          class="key-metrics-titles"
                          style="color: #202022"
                        >
                          YoY Abs Change in<br />{{ domainName }}<br />
                          <div
                            style="font-size: 2em; padding-top: 0.5em"
                            v-bind:style="{ color: absColor }"
                          >
                            {{ this.numberWithCommas(absYoy)
                            }}<span
                              v-if="this.numberWithCommas(absYoy).length <= 7"
                              style="font-size: 14px"
                            >
                              ppm</span
                            >
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-flex>

                    <v-flex sm5 md8 lg5 class="no-margin-sparks">
                      <div>
                        <span v-if="!getLoggedUser.anon">
                          <span
                            v-if="!isBookmarked"
                            style="
                              float: right;
                              position: relative;
                              bottom: 3em;
                              right: 1em;
                            "
                            ><a @click="bookmark()"
                              ><v-icon class="bookmark" large
                                >mdi-bookmark-outline</v-icon
                              ></a
                            ></span
                          >
                          <span
                            v-else
                            style="
                              float: right;
                              position: relative;
                              bottom: 3em;
                              right: 1em;
                            "
                            ><a @click="removeBookmark()"
                              ><v-icon class="isbookmark" large
                                >mdi-bookmark</v-icon
                              ></a
                            ></span
                          >
                        </span>
                      </div>
                      <v-card
                        class="spark1"
                        v-bind:style="{ backgroundImage: relSpark }"
                        @click="changeD()"
                        elevation="2"
                      >
                        <v-card-text style="color: #202022">
                          YoY Rel Change<br />{{ domainName }}<br />
                          <div
                            style="font-size: 2em; padding-top: 0.5em"
                            v-bind:style="{ color: relColor }"
                          >
                            {{ relYoy }}%
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-row>

                  <v-row>
                    <v-flex sm5 md8 lg5 class="margin-sparks">
                      <v-card class="spark1" @click="pvD()" elevation="2">
                        <v-card-text class="center-vert" style="color: #202022">
                          Activity in<br />{{ domainName }}<br />
                          <div
                            style="
                              font-size: 2em;
                              padding-top: 0.5em;
                              color: #202022;
                            "
                          >
                            {{ ppm }}<span style="font-size: 14px"> ppm</span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-flex>

                    <v-flex sm5 md8 lg5 class="no-margin-sparks">
                      <v-card
                        class="spark1"
                        @click="openD()"
                        elevation="2"
                        style=""
                      >
                        <v-card-text style="color: #202022">
                          Current 1 Month<br />User Retention<br />
                          <div
                            v-if="userRet"
                            style="
                              font-size: 2em;
                              padding-top: 0.2em;
                              color: #202022;
                            "
                          >
                            {{ userRet.toFixed(1) }}%
                          </div>
                          <div
                            v-else
                            style="
                              font-size: 1em;
                              padding-top: 0.5em;
                              color: #37474f;
                            "
                          >
                            N/A
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-row>

                  <v-row>
                    <v-flex sm10 style="margin-left: 3em">
                      <v-tooltip bottom max-width="500" style="z-index: 2000">
                        <template v-slot:activator="{ on, attrs }">
                          <v-card
                            @click="domainPercentD()"
                            v-bind="attrs"
                            v-on="on"
                            class="spark2"
                            elevation="2"
                          >
                            <div
                              style="
                                color: #202022;
                                position: relative;
                                top: 1.5em;
                              "
                            >
                              '{{ myObject.name }}' Posts Vertical Distribution
                            </div>
                            <div>
                              <DomainPercentA
                                style="
                                  position: relative;
                                  left: 2em;
                                  top: 1.5em;
                                "
                                :parentObj="myObject"
                                :parentDomain="myDomain"
                                :parentMonth="currentMonth"
                                :key="myDomain"
                              />
                            </div>
                          </v-card>
                        </template>
                        <span
                          >Here we can see the percent of posts that fall under
                          each Market Vertical in the month of
                          {{ this.currentMonth }}.</span
                        >
                      </v-tooltip>
                    </v-flex>
                  </v-row>
                </v-flex>

                <v-row
                  v-if="bp < 1264"
                  justify="center"
                  style="padding-top: 1em"
                >
                  <v-flex xs12 sm12>
                    <v-row justify="center">
                      <v-card
                        @click="domainPercentD()"
                        class="spark2B"
                        style="width: 95%; padding-top: 1em"
                      >
                        <div
                          style="color: #202022; position: relative; top: 1em"
                        >
                          '{{ myObject.name }}' Posts Vertical Distribution
                        </div>
                        <v-row justify="center" style="margin-top: 0.5em">
                          <DomainPercentA
                            style="position: relative; left: 2em"
                            :parentObj="myObject"
                            :parentDomain="myDomain"
                            :parentMonth="currentMonth"
                            :parentBP="bp"
                            :key="myDomain"
                          />
                        </v-row>
                      </v-card>
                    </v-row>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    md6
                    class="all-sparks"
                    style="padding-top: 1em"
                  >
                    <v-card
                      class="spark1"
                      v-bind:style="{ backgroundImage: absSpark }"
                      @click="changeD()"
                    >
                      <v-card-text
                        class="key-metrics-titles"
                        style="color: #202022"
                      >
                        YoY Abs Change in<br />{{ domainName }}<br />
                        <div
                          style="font-size: 2em; padding-top: 0.5em"
                          v-bind:style="{ color: absColor }"
                        >
                          {{ this.numberWithCommas(absYoy)
                          }}<span style="font-size: 14px"> ppm</span>
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-card class="spark1" @click="pvD()">
                      <v-card-text class="center-vert" style="color: #202022">
                        Activity in<br />{{ domainName }}<br />
                        <div
                          style="
                            font-size: 2em;
                            padding-top: 0.5em;
                            color: #202022;
                          "
                        >
                          {{ ppm }}<span style="font-size: 14px"> ppm</span>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    md6
                    class="all-sparks"
                    style="padding-top: 1em"
                  >
                    <v-card
                      class="spark1"
                      v-bind:style="{ backgroundImage: relSpark }"
                      @click="changeD()"
                    >
                      <v-card-text style="color: #202022">
                        YoY Rel Change<br />{{ domainName }}<br />
                        <div
                          style="font-size: 2em; padding-top: 0.5em"
                          v-bind:style="{ color: relColor }"
                        >
                          {{ relYoy }}%
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-card class="spark1" @click="openD()">
                      <v-card-text style="color: #202022">
                        Current Month<br />User Retention<br />
                        <div
                          v-if="userRet"
                          style="
                            font-size: 2em;
                            padding-top: 0.5em;
                            color: #202022;
                          "
                        >
                          {{ userRet.toFixed(1) }}%
                        </div>
                        <div
                          v-else
                          style="
                            font-size: 1em;
                            padding-top: 0.5em;
                            color: #202022;
                          "
                        >
                          N/A
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-row>
              </v-row>
            </v-container>
          </v-container>

          <v-container fluid style="width: 1600px">
            <div class="scroller"></div>

            <v-container
              fluid
              id="components"
              v-if="this.$vuetify.breakpoint.width >= 800"
            >
              <v-row row justify="center">
                <div style="width: 1250px">
                  <div style="width: 100%; height: 100%">
                    <grid-layout
                      :layout.sync="layout"
                      :col-num="12"
                      :row-height="30"
                      :margin="[40, 40]"
                      :is-draggable="false"
                      :is-resizable="false"
                      :responsive="true"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                      <grid-item
                        v-for="item in layout"
                        :key="item"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                      >
                        <v-card
                          class="text transform"
                          v-if="item.i == 1 && has_demo"
                        >
                          <v-app-bar
                            elevation="0"
                            class="transform"
                            color="#A6B7CD"
                          >
                            <router-link
                              style="color: #202022"
                              class="card-titles"
                              target="_blank"
                              tag="a"
                              :to="{
                                path: '/ConsumerForecast/help?page=general#demos',
                              }"
                              >Demographics</router-link
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                              class="demoButton"
                              rounded
                              :style="styleGender"
                              id="g"
                              @click="changeDemoCat('G')"
                              >Gender</v-btn
                            >
                            <v-btn
                              class="demoButton"
                              rounded
                              :style="styleAge"
                              id="a"
                              @click="changeDemoCat('A')"
                              >Age</v-btn
                            >
                            <v-btn
                              class="demoButton"
                              rounded
                              id="r"
                              :style="styleRace"
                              @click="changeDemoCat('R')"
                              >Race (US Only)</v-btn
                            >
                            <v-btn
                              class="demoButton"
                              rounded
                              id="i"
                              :style="styleIncome"
                              @click="changeDemoCat('I')"
                              >Income</v-btn
                            >
                          </v-app-bar>

                          <v-row
                            row
                            style="
                              padding: 1em;
                              height: 340px;
                              padding-top: 2em;
                            "
                            v-if="demoCat == 'G' && has_gender"
                            justify="center"
                          >
                            <v-flex sm6 xl5>
                              <span style="margin-left: 3em"
                                >Gender by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> 'Males' are in
                                    the
                                    {{
                                      myObject.demo.domains[myDomain]
                                        .data[200100].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.demo.domains[myDomain]
                                          .data[200100].percentile
                                      )
                                    }}
                                    percentile in terms of their percent
                                    contribution. This means that only
                                    {{
                                      100 -
                                      myObject.demo.domains[myDomain]
                                        .data[200100].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this
                                    demographic.</span
                                  >
                                </v-tooltip>
                              </span>
                              <PercentileGender
                                :parentDemo="myObject"
                                :parentDomain="myDomain"
                                :key="myDomain"
                              />
                            </v-flex>

                            <v-flex sm6 xl5>
                              <span style="margin-left: 1em"
                                >Gender By Raw Percent</span
                              >
                              <div>
                                <DonutGender
                                  :parentDemo="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                                />
                              </div>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="demoCat == 'G' && !has_gender"
                            justify="center"
                            class="center-vert"
                            style="padding-bottom: 3em"
                          >
                            No Gender Data Available
                          </v-row>

                          <v-row
                            row
                            style="
                              padding: 1em;
                              height: 340px;
                              padding-top: 2em;
                            "
                            v-if="demoCat == 'A' && has_age"
                            justify="center"
                          >
                            <v-flex sm6>
                              <span style="margin-left: 3em"
                                >Age by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> Those aged '20
                                    and under' are in the
                                    {{
                                      myObject.demo.domains[myDomain]
                                        .data[401099].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.demo.domains[myDomain]
                                          .data[800099].percentile
                                      )
                                    }}
                                    percentile in terms of their percent
                                    contribution. This means that only
                                    {{
                                      100 -
                                      myObject.demo.domains[myDomain]
                                        .data[401099].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this
                                    demographic.</span
                                  >
                                </v-tooltip>
                              </span>
                              <PercentileAge
                                :parentDemo="myObject"
                                :parentDomain="myDomain"
                                :breakpoint="bp"
                                :key="myDomain"
                              />
                            </v-flex>
                            <v-flex sm6>
                              <span style="margin-left: 1em"
                                >Age By Raw Percent</span
                              >
                              <div>
                                <DonutAge
                                  :parentDemo="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                                />
                              </div>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="demoCat == 'A' && !has_age"
                            justify="center"
                            class="center-vert"
                            style="padding-bottom: 3em"
                          >
                            No Age Data Available
                          </v-row>

                          <v-row
                            row
                            style="
                              padding: 1em;
                              height: 340px;
                              padding-top: 2em;
                            "
                            v-if="demoCat == 'R' && has_race"
                            justify="center"
                          >
                            <v-flex sm6>
                              <span style="margin-left: 3em"
                                >Race and Income by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> 'White/Caucasian'
                                    is in the
                                    {{
                                      myObject.demo.domains[myDomain]
                                        .data[300100].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.demo.domains[myDomain]
                                          .data[800099].percentile
                                      )
                                    }}
                                    percentile in terms of percent contribution.
                                    This means that only
                                    {{
                                      100 -
                                      myObject.demo.domains[myDomain]
                                        .data[300100].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this
                                    demographic.</span
                                  >
                                </v-tooltip>
                              </span>
                              <PercentileRace
                                :parentDemo="myObject"
                                :parentDomain="myDomain"
                                :key="myDomain"
                              />
                            </v-flex>
                            <v-flex sm6>
                              <span style="margin-left: 0em"
                                >Race By Raw Percent</span
                              >
                              <span
                                ><DonutRace
                                  :parentDemo="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="demoCat == 'R' && !has_race"
                            justify="center"
                            class="center-vert"
                            style="padding-bottom: 3em"
                          >
                            No Race Data Available
                          </v-row>

                          <v-row
                            row
                            style="
                              padding: 1em;
                              height: 340px;
                              padding-top: 2em;
                            "
                            v-if="demoCat == 'I' && has_income"
                            justify="center"
                          >
                            <v-flex sm6>
                              <span style="margin-left: 3em"
                                >Income by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> 'Low Income' is
                                    in the
                                    {{
                                      myObject.demo.domains[myDomain]
                                        .data[800099].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.demo.domains[myDomain]
                                          .data[800099].percentile
                                      )
                                    }}
                                    percentile in terms of percent contribution.
                                    This means that only
                                    {{
                                      100 -
                                      myObject.demo.domains[myDomain]
                                        .data[800099].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this
                                    demographic.</span
                                  >
                                </v-tooltip>
                              </span>
                              <PercentileIncome
                                :parentDemo="myObject"
                                :parentDomain="myDomain"
                                :key="myDomain"
                              />
                            </v-flex>
                            <v-flex sm6>
                              <div width="100">
                                <span style="margin-left: 1em"
                                  >Income By Raw Percent</span
                                >
                                <DonutIncome
                                  :parentDemo="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                                />
                              </div>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="demoCat == 'I' && !has_income"
                            justify="center"
                            class="center-vert"
                            style="padding-bottom: 3em"
                          >
                            No Income Data Available
                          </v-row>
                        </v-card>

                        <v-card class="text" v-if="item.i == 2">
                          <v-app-bar elevation="0" color="#A6B7CD">
                            <router-link
                              style="color: #202022"
                              class="card-titles"
                              target="_blank"
                              tag="a"
                              :to="{
                                path: '/ConsumerForecast/help?page=general#geos',
                              }"
                              >Geographies
                            </router-link>
                            <v-spacer></v-spacer>
                            <v-btn
                              class="geoButton"
                              rounded
                              :style="styleGlobal"
                              @click="geo_type('Global')"
                              >Global</v-btn
                            >
                            <v-btn
                              class="geoButton"
                              rounded
                              :style="styleUSA"
                              @click="geo_type('USA')"
                              >USA</v-btn
                            >
                          </v-app-bar>
                          <v-app-bar
                            dense
                            elevation="0"
                            style="background-color: transparent"
                          >
                            <v-btn-toggle
                              rounded
                              mandatory
                              style="position: absolute; right: 3em"
                            >
                              <v-btn
                                id="toggle"
                                elevation="0"
                                @click="geoToggle('Percentile')"
                              >
                                Percentile
                              </v-btn>
                              <v-btn
                                id="toggle"
                                elevation="0"
                                @click="geoToggle('Raw')"
                              >
                                Raw %
                              </v-btn>
                            </v-btn-toggle>
                          </v-app-bar>

                          <!-- global percentile -->
                          <v-row
                            row
                            style="padding: 2em; height: 345px"
                            v-if="geoType == 'Global' && geoT == 'Percentile'"
                            justify="center"
                          >
                            <v-flex
                              sm6
                              style="position: relative; bottom: 1.5em"
                              v-if="has_gr"
                            >
                              <span style="margin-left: 2em"
                                >Global Regions by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> '{{
                                      myObject.global_region.domains[myDomain]
                                        .percent[0].region
                                    }}' is in the
                                    {{
                                      myObject.global_region.domains[myDomain]
                                        .percent[0].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.global_region.domains[myDomain]
                                          .percent[0].percentile
                                      )
                                    }}
                                    percentile in terms of its percent
                                    contribution. This means that only
                                    {{
                                      100 -
                                      myObject.global_region.domains[myDomain]
                                        .percent[0].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this region.</span
                                  >
                                </v-tooltip>
                              </span>
                              <span class="g-regions"
                                ><GRegionsPercentile
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                            <v-flex v-else>
                              <v-row justify="center">
                                Global regions by percentile are not available.
                              </v-row>
                            </v-flex>

                            <v-flex
                              sm6
                              style="position: relative; bottom: 1.5em"
                              v-if="has_c"
                            >
                              <span style="margin-left: 1em"
                                >Top 5 Countries by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> '{{
                                      myObject.country.domains[myDomain]
                                        .top_percentile[0].country
                                    }}' is in the
                                    {{
                                      myObject.country.domains[myDomain]
                                        .top_percentile[0].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.country.domains[myDomain]
                                          .top_percentile[0].percentile
                                      )
                                    }}
                                    percentile in terms of its percent
                                    contribution. This means that only
                                    {{
                                      100 -
                                      myObject.country.domains[myDomain]
                                        .top_percentile[0].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this country.</span
                                  >
                                </v-tooltip>
                              </span>
                              <span style="position: relative; left: 2em"
                                ><CountriesPercentile
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                            <v-flex v-else>
                              <v-row justify="center">
                                Countries by percentile are not available.
                              </v-row>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="
                              (geoType == 'Global') & (geoT == 'Percentile') &&
                              !has_gr &&
                              !has_c
                            "
                            justify="center"
                            style="padding-top: 6em"
                          >
                            No global gercentile data available.
                          </v-row>

                          <!-- global raw -->
                          <v-row
                            row
                            style="padding: 2em; height: 345px"
                            v-if="geoType == 'Global' && geoT == 'Raw'"
                            justify="center"
                          >
                            <v-flex
                              sm6
                              style="position: relative; bottom: 1.5em"
                              v-if="has_gr"
                            >
                              <span style="margin-left: 2em"
                                >Global Regions by Raw %</span
                              >
                              <span
                                style="position: relative; left: 2em; top: 1em"
                                ><GlobalRegions
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                            <v-flex v-else>
                              <v-row justify="center">
                                Global regions by raw % are not available.
                              </v-row>
                            </v-flex>

                            <v-flex
                              sm6
                              style="position: relative; bottom: 1.5em"
                              v-if="has_c"
                            >
                              <span style="margin-left: 1em"
                                >Top 5 Countries By Raw %</span
                              >
                              <span style="position: relative; top: 1em"
                                ><Countries
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                            <v-flex v-else>
                              <v-row justify="center">
                                Countries by Raw % are not available.
                              </v-row>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="
                              (geoType == 'Global') & (geoT == 'Raw') &&
                              !has_gr &&
                              !has_c
                            "
                            justify="center"
                            style="padding-top: 6em"
                          >
                            Global raw % data is not available.
                          </v-row>

                          <!-- usa percentile -->
                          <v-row
                            row
                            class="usa-raw"
                            v-if="
                              (geoType == 'USA') & (geoT == 'Percentile') &&
                              has_usState &&
                              has_usMsa
                            "
                          >
                            <v-flex
                              sm4
                              style="position: relative; bottom: 1.5em"
                            >
                              <span style="margin-left: 0em"
                                >US Regions by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> The '{{
                                      myObject.us_region.domains[myDomain]
                                        .percent[0].region
                                    }}ern' region is in the
                                    {{
                                      myObject.us_region.domains[myDomain]
                                        .percent[0].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.us_region.domains[myDomain]
                                          .percent[0].percentile
                                      )
                                    }}
                                    percentile in terms of its percent
                                    contribution. This means that only
                                    {{
                                      100 -
                                      myObject.us_region.domains[myDomain]
                                        .percent[0].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this region.</span
                                  >
                                </v-tooltip>
                              </span>
                              <span
                                ><USRegionsPercentile
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>

                            <v-flex
                              sm4
                              style="
                                position: relative;
                                bottom: 1.5em;
                                right: 1.5em;
                              "
                            >
                              <span
                                >Top 5 US States by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> '{{
                                      myObject.us_state.domains[myDomain]
                                        .top_percent[0].state
                                    }}' state is in the
                                    {{
                                      myObject.us_state.domains[myDomain]
                                        .top_percent[0].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.us_state.domains[myDomain]
                                          .top_percent[0].percentile
                                      )
                                    }}
                                    percentile in terms of its percent
                                    contribution. This means that only
                                    {{
                                      100 -
                                      myObject.us_state.domains[myDomain]
                                        .top_percent[0].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this state.</span
                                  >
                                </v-tooltip>
                              </span>
                              <span style="position: relative; left: 1em"
                                ><StatesPercentile
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>

                            <v-flex
                              sm4
                              style="
                                position: relative;
                                bottom: 1.5em;
                                right: 0em;
                              "
                            >
                              <span style="margin-left: 2em"
                                >Top 5 MSAs by
                                <router-link
                                  class="percentile-link"
                                  target="_blank"
                                  tag="a"
                                  :to="{
                                    path: '/ConsumerForecast/help?page=definitions#percentile',
                                  }"
                                  >Percentile
                                </router-link>
                                <v-tooltip light right max-width="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      x-small
                                      width="10px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information</v-icon
                                    >
                                  </template>
                                  <span
                                    ><strong>Read As:</strong> The '{{
                                      myObject.us_msa.domains[myDomain]
                                        .top_percent[0].msa
                                    }}' MSA is in the
                                    {{
                                      myObject.us_msa.domains[myDomain]
                                        .top_percent[0].percentile
                                    }}{{
                                      readAsEnding(
                                        myObject.us_msa.domains[myDomain]
                                          .top_percent[0].percentile
                                      )
                                    }}
                                    percentile in terms of its percent
                                    contribution. This means that only
                                    {{
                                      100 -
                                      myObject.us_msa.domains[myDomain]
                                        .top_percent[0].percentile
                                    }}% of similarly categorized objects in
                                    {{ domainName }} have a higher percent
                                    contribution coming from this MSA.</span
                                  >
                                </v-tooltip>
                              </span>
                              <span
                                style="
                                  position: relative;
                                  top: 0.2em;
                                  right: 0.1em;
                                "
                                ><MSA
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="
                              geoType == 'USA' &&
                              geoT == 'Percentile' &&
                              !has_usState &&
                              !has_usMsa
                            "
                            justify="center"
                            style="padding-top: 6em"
                          >
                            No Percentile USA data available
                          </v-row>

                          <!-- usa raw -->
                          <v-row
                            row
                            class="usa-raw"
                            v-if="
                              (geoType == 'USA') & (geoT == 'Raw') &&
                              has_usState &&
                              has_usMsa
                            "
                          >
                            <v-flex
                              sm4
                              style="position: relative; bottom: 1.5em"
                            >
                              <span style="margin-left: 0em"
                                >US Regions by Raw %</span
                              >
                              <span
                                ><USRegion
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                            <v-flex
                              sm4
                              style="
                                position: relative;
                                bottom: 1.5em;
                                position: relative;
                                right: 2em;
                              "
                              v-if="has_usState"
                            >
                              <span>US States by Raw %</span>
                              <States
                                :parentObj="myObject"
                                :parentDomain="myDomain"
                                :key="myDomain"
                              />
                            </v-flex>
                            <v-flex
                              sm4
                              style="position: relative; bottom: 1.5em"
                            >
                              <span style="margin-left: 2em"
                                >Top 5 MSAs by Raw %</span
                              >
                              <span
                                style="
                                  position: relative;
                                  top: 0.2em;
                                  right: 2em;
                                "
                                ><MSARaw
                                  :parentObj="myObject"
                                  :parentDomain="myDomain"
                                  :key="myDomain"
                              /></span>
                            </v-flex>
                          </v-row>

                          <v-row
                            v-if="
                              geoType == 'USA' &&
                              geoT == 'Raw' &&
                              !has_usState &&
                              !has_usMsa
                            "
                            justify="center"
                            style="padding-top: 6em"
                          >
                            No Raw USA data available
                          </v-row>
                        </v-card>

                        <v-card v-if="item.i == 5" class="text">
                          <v-app-bar elevation="0" color="#A6B7CD">
                            <router-link
                              class="card-titles"
                              style="color: #202022"
                              target="_blank"
                              tag="a"
                              :to="{
                                path: '/ConsumerForecast/help?page=general#see-also',
                              }"
                              >See Also</router-link
                            >
                          </v-app-bar>
                          <div class="center-vert" style="height: 100%">
                            <SideBar
                              @alsolength="seeAlsoLen"
                              @itemLoad="pageItemLoad"
                              :parentItem="myObject"
                              :breakpoint="bp"
                              :parentDomain="myDomain"
                            />
                          </div>
                        </v-card>

                        <v-card id="relational" class="text" v-if="item.i == 0">
                          <v-app-bar elevation="0" color="#A6B7CD">
                            <span style="color: #fff"
                              ><router-link
                                class="card-titles"
                                style="color: #202022"
                                target="_blank"
                                tag="a"
                                :to="{
                                  path: '/ConsumerForecast/help?page=general#psychographics',
                                }"
                                >Psychographics
                              </router-link>
                              <v-tooltip right max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    x-small
                                    width="10px"
                                    style="color: #fff; padding-top: 0.2em"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span
                                  >Psychographics show the affinities and
                                  interests of those who talk about
                                  {{ myObject.name }}.</span
                                >
                              </v-tooltip>
                            </span>
                          </v-app-bar>
                          <v-card-text>
                            <span
                              v-if="
                                $vuetify.breakpoint.name == 'sm' ||
                                $vuetify.breakpoint.name == 'xs'
                              "
                              style="
                                position: relative;
                                top: 1em;
                                left: 2em;
                                color: #333;
                              "
                              >'{{ myObject.name }}' Audience Psychographics
                              <v-tooltip light right max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    x-small
                                    width="10px"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span
                                  ><strong>Read As:</strong>The
                                  {{ myObject.name }} audience
                                  {{
                                    myObject.psychographics.domains[myDomain]
                                      .data[6370].name
                                  }}
                                  conversation frequency is in the
                                  {{
                                    myObject.psychographics.domains[myDomain]
                                      .data[6370].percentile
                                  }}{{
                                    readAsEnding(
                                      myObject.psychographics.domains[myDomain]
                                        .data[6370].percentile
                                    )
                                  }}. This means only 18% of other audiences
                                  talk more about
                                  {{
                                    myObject.psychographics.domains[myDomain]
                                      .data[6370].name
                                  }}.</span
                                >
                              </v-tooltip>
                            </span>
                            <span
                              v-else
                              style="
                                position: relative;
                                top: 2em;
                                left: 4em;
                                color: #333;
                              "
                              >'{{ myObject.name }}' Audience Psychographics
                              <v-tooltip light right max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    x-small
                                    width="10px"
                                    style="z-index: 20000"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span
                                  ><strong>Read As:</strong> The
                                  {{ myObject.name }} audience
                                  {{
                                    myObject.psychographics.domains[myDomain]
                                      .data[6370].name
                                  }}
                                  conversation frequency is in the
                                  {{
                                    myObject.psychographics.domains[myDomain]
                                      .data[6370].percentile
                                  }}{{
                                    readAsEnding(
                                      myObject.psychographics.domains[myDomain]
                                        .data[6370].percentile
                                    )
                                  }}
                                  percentile. This means only
                                  {{
                                    100 -
                                    myObject.psychographics.domains[myDomain]
                                      .data[6370].percentile
                                  }}% of other audiences talk more about
                                  {{
                                    myObject.psychographics.domains[myDomain]
                                      .data[6370].name
                                  }}.</span
                                >
                              </v-tooltip>
                            </span>
                          </v-card-text>
                          <Psychographics
                            :parentObj="myObject"
                            :parentDomain="myDomain"
                            :parentBP="$vuetify.breakpoint.name"
                            @realLen="psychoLen"
                            @height="psychoHeight"
                          />
                        </v-card>

                        <v-card class="text" v-if="item.i == 7" elevation="2">
                          <v-app-bar
                            elevation="0"
                            color="#A6B7CD"
                            style="
                              padding-left: 1em;
                              border-radius: 7px 7px 0px 0px;
                            "
                          >
                            <span
                              ><router-link
                                class="card-titles"
                                style="color: #202022"
                                target="_blank"
                                tag="a"
                                :to="{
                                  path:
                                    '/ConsumerForecast/help?page=general&dom=' +
                                    domainName +
                                    '#thme',
                                }"
                                >Themes
                              </router-link>
                              <v-tooltip right max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    width="10px"
                                    style="
                                      color: #37474f;
                                      padding-bottom: 0.2em;
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span
                                  >Themes are combined relational objects that
                                  have a unifying theme or topic.</span
                                >
                              </v-tooltip>
                            </span>
                          </v-app-bar>
                          <div class="center-vert" style="padding-bottom: 8em">
                            <Context
                              :parentObject="myObject"
                              :parentDomain="myDomain"
                              :key="myDomain"
                              :parentBP="$vuetify.breakpoint.name"
                              @height="themesHeight"
                              @themeLoad="pageItemLoad"
                            />
                          </div>
                        </v-card>

                        <v-card
                          id="relation-timelines"
                          class="text"
                          v-if="item.i == 4"
                        >
                          <v-app-bar elevation="0" color="#A6B7CD">
                            <span v-if="timelineType == 'linked'"
                              ><router-link
                                style="color: #202022"
                                class="card-titles"
                                target="_blank"
                                tag="a"
                                :to="{
                                  path: '/ConsumerForecast/help?page=general#rel-time',
                                }"
                                >Relations Timeline of Linked Objects
                              </router-link>
                              <v-tooltip right max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    style="
                                      color: #37474f;
                                      padding-bottom: 0.2em;
                                    "
                                    small
                                    width="10px"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span
                                  >Linked Objects are objects that are linked in
                                  our system to {{ myObject.name }}.</span
                                >
                              </v-tooltip>
                            </span>

                            <span
                              v-if="timelineType == 'sub'"
                              style="color: #202022"
                              ><router-link
                                style="color: #202022"
                                class="card-titles"
                                target="_blank"
                                tag="a"
                                :to="{
                                  path: '/ConsumerForecast/help?page=general#rel-time',
                                }"
                                >Relations Timeline of Sub Objects
                              </router-link>
                              <v-tooltip right max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    style="
                                      color: #37474f;
                                      padding-bottom: 0.2em;
                                    "
                                    small
                                    width="10px"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-information</v-icon
                                  >
                                </template>
                                <span
                                  >'Sub Objects' are those that are children of
                                  {{ myObject.name }}, allowing them to be
                                  compared against {{ myObject.name }}.</span
                                >
                              </v-tooltip>
                            </span>
                            <v-spacer></v-spacer>
                            <span v-if="has_subObjects && has_linkedObjects">
                              <v-btn
                                rounded
                                class="geoButton"
                                :style="styleLinked"
                                @click="changeTimeline('linked')"
                                >Linked</v-btn
                              >
                              <v-btn
                                rounded
                                class="geoButton"
                                :style="styleSub"
                                @click="changeTimeline('sub')"
                                >Sub</v-btn
                              >
                            </span>
                          </v-app-bar>

                          <v-container v-if="timelineType == 'sub'">
                            <ComparisonsSub
                              @csv="timelinesCSV"
                              :parentAct="myObject"
                              :parentDomain="myDomain"
                              :parentBP="bp"
                              :parentDomainName="domainName"
                              :key="myDomain"
                            />
                          </v-container>

                          <v-container v-if="timelineType == 'linked'">
                            <Comparisons
                              @csv="timelinesCSV"
                              :parentAct="myObject"
                              :parentDomain="myDomain"
                              :parentBP="bp"
                              :parentDomainName="domainName"
                              :key="myDomain"
                            />
                          </v-container>
                        </v-card>
                      </grid-item>
                    </grid-layout>
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-container>

          <div class="q-footer" style="height: 10px">
            <Footer @pushB="pageItemLoad" />
          </div>
        </div>
      </div>

      <v-dialog
        v-model="retDialog"
        v-if="userRet"
        max-width="750"
        style="z-index: 30000"
      >
        <v-card width="750px" height="600px">
          <v-card-title>
            User Retention
            <v-icon
              @click="closeD()"
              style="position: absolute; right: 0.5em; top: 0.5em"
              sm
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            User retention is Social Standards’ metric for understanding if an
            object is retaining its loyalists.
          </v-card-text>
          <v-card-text>
            {{ currentMonth }}'s one-month's user retention is
            <strong>{{ userRet.toFixed(1) }}%</strong>. This means that
            {{ userRet.toFixed(1) }}% of users that talked about
            {{ myObject.name }} in {{ prevMonth }} are still talking about it in
            {{ currentMonth }}. You can also see this visualized with the 'User
            Retention' Activity Chart overlay. <br /><br />1 month user
            retention is a great way to measure ongoing loyalty.
            <br /><br />Long term loyalty is better understood by the User
            Retention Curve:
          </v-card-text>
          <br />
          <v-card-text>
            <b>{{ myObject.name }}'s User Retention Curve:</b>
            <div style="position: relative; bottom: 1em">
              <v-row justify="center">
                <DecaySpark
                  :parentObj="myObject"
                  v-bind:parentDomain="myDomain"
                  :key="myDomain"
                />
              </v-row>
            </div>
            The User Retention Curve shows us whether the people talking about
            an object last month (or 2, 3, 4, 5, or 6 months ago) still talking
            about the same object this month.
            <br /><br />The slope of this curve shows us {{ myObject.name }}'s
            ability to retain consumer interest over time.
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="pvDialog" max-width="600">
        <v-card width="600px" height="280px">
          <v-card-title>
            {{ this.myObject.name }} Activity
            <v-icon
              @click="closePv()"
              style="position: absolute; right: 0.5em; top: 0.5em"
              sm
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            Activity is measured in Posts Per Month.
            <br /><br />{{ myObject.name }}'s average 3m* activity is
            <b>{{ ppm }}</b> posts. <br /><br />These posts are public posts
            that have been collected, and cleaned from spam and bots.
            <br /><br />*3 month average is calculated from the
            {{ currentMonth }} and the two months before it.
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="changeDialog" max-width="600">
        <v-card width="600px" height="235px">
          <v-card-title>
            {{ this.myObject.name }} YoY Change
            <v-icon
              @click="closeChange()"
              style="position: absolute; right: 0.5em; top: 0.5em"
              sm
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            Post per month volume changed by, on average* by
            <b>{{ this.numberWithCommas(absYoy) }} posts per month</b> year over
            year.<br /><br />This is a <b>{{ relYoy }}%</b> change.
            <br /><br />*3 month average is calculated from the
            {{ currentMonth }} and the two months before it.
          </v-card-text>
        </v-card>
      </v-dialog>



      <v-dialog v-model="infoMobile" max-width="700">
        <v-card width="700" height="100%">
          <v-card-title>
            Settings Menu
            <v-icon
              @click="closeI()"
              style="position: absolute; right: 0.5em; top: 0.5em"
              sm
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text style="position: relative; right: 1.5em">
            <ul
              style="
                position: relative;
                top: 0.3em;
                bottom: 0.3em;
                list-style-type: none;
              "
            >
              <li style=""><b>Market Vertical:</b></li>
            </ul>
            <v-select
              v-model="selectDomain"
              :placeholder="this.domainName"
              :items="filteredDomains"
              item-value="domain_sid"
              item-text="domain_name"
              light
              :menu-props="{ bottom: true, offsetY: true, maxHeight: 450 }"
              height="100%"
              hint="switch market vertical"
              persistent-hint
              style="padding: 0.5em 1em 0em 2em; z-index: 10000"
              @change="domainChange(selectDomain)"
            ></v-select>
          </v-card-text>
          <v-card-text style="position: relative; right: 1.5em">
            <ul style="position: relative; list-style-type: none">
              <li style=""><b>Data Source:</b></li>
            </ul>
            <v-select
              style="padding: 0.5em 1em 0em 2em"
              :menu-props="{ bottom: true, offsetY: true }"
              @change="updateCurrentSource()"
              hint="switch data source"
              persistent-hint
              v-model="sourceUpdater"
              :items="filteredSources"
              item-text="name"
              item-value="value"
              :placeholder="translateSource(getCurrentSource)"
            ></v-select>
            <ul
              style="
                position: relative;
                top: 0.3em;
                bottom: 0.3em;
                list-style-type: none;
              "
            >
              <li><br /></li>
              <li><b>Data Information</b></li>
              <li style="">
                Data End Month: {{ this.currentMonth }} -- Data is a three month
                average of the two months before {{ this.currentMonth }}, plus
                {{ this.currentMonth }}.
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            <v-row style="padding: 1em">
              <router-link
                class="bar-domain"
                target="_blank"
                tag="a"
                :to="{ path: '/ConsumerForecast/help?page=general' }"
                ><v-icon small style="padding-bottom: 0.1em"
                  >mdi-help-circle</v-icon
                ><span style="margin-left: 0.1em; color: #333">
                  Click here to visit the FAQ page.</span
                ></router-link
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="domainPercentDialog"
        max-width="900"
        style="z-index: 30000"
      >
        <v-card width="900px" height="650px">
          <v-card-title>
            '{{ myObject.name }}' Market Vertical Distributions
            <v-icon
              @click="closedomainPercent()"
              style="position: absolute; right: 0.5em; top: 0.5em"
              sm
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            Understanding market vertical distribution allows you to understand
            what percent of posts are coming from a particular vertical, and how
            is this changing over time.<br /><br />
            *Vertical allocation percent across verticals
            <b>does not sum up to 100%</b> -- this is because a single post can
            simultaneously fall into multiple market verticals.
          </v-card-text>
          <v-card-text>
            Here we can see <b>{{ myObject.name }}'s vertical allocation</b> for
            its top 3 most relevant market verticals, over time.
          </v-card-text>
          <v-card-text>
            <DomainPercents
              :parentObj="myObject"
              :parentDomain="myDomain"
              :key="myDomain"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="domainD" max-width="600">
        <v-card width="630px" height="200px">
          <v-card-title>
            <v-icon
              @click="domainDialog()"
              style="position: absolute; right: 0.5em; top: 0.5em"
              sm
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text style="padding-top: 2em">
            <v-row>
              <span style="padding-left: 1em">Switch Market Vertical</span>
            </v-row>
            <v-row justify="center">
              <v-select
                v-model="selectDomain"
                :placeholder="this.domainName"
                :items="filteredDomains"
                item-value="domain_sid"
                item-text="domain_name"
                light
                :menu-props="{ bottom: true, offsetY: true, maxHeight: 450 }"
                height="100%"
                persistent-hint
                style="padding: 1em; z-index: 10000"
                @change="domainChange(selectDomain)"
              ></v-select>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import stringFunctions from "@/mixins/stringFunctions";
import processingMixin from "@/mixins/processingMixin";
import objectFunctions from "@/mixins/objectFunctions";
import { GridLayout, GridItem } from "vue-grid-layout";
import { mapGetters, mapActions } from "vuex";
import ObjectToolbar from "@/components/ConsumerForecast/Lookups/PageElements/objectToolbar.vue";
import SideBar from "@/components/ConsumerForecast/Lookups/PageElements/SideBar.vue";
import ActivityAuthLine from "@/components/ConsumerForecast/Lookups/Charts/ActivityAuthLine.vue";
import PercentileGender from "@/components/ConsumerForecast/Lookups/Charts/PercentileGender.vue";
import PercentileRace from "@/components/ConsumerForecast/Lookups/Charts/PercentileRace.vue";
import PercentileAge from "@/components/ConsumerForecast/Lookups/Charts/PercentileAge.vue";
import PercentileIncome from "@/components/ConsumerForecast/Lookups/Charts/PercentileIncome.vue";
import DonutGender from "@/components/ConsumerForecast/Lookups/Charts/DonutGender.vue";
import DonutAge from "@/components/ConsumerForecast/Lookups/Charts/DonutAge.vue";
import DonutRace from "@/components/ConsumerForecast/Lookups/Charts/DonutRace.vue";
import DonutIncome from "@/components/ConsumerForecast/Lookups/Charts/DonutIncome.vue";
import CountriesPercentile from "@/components/ConsumerForecast/Lookups/Charts/CountriesPercentile.vue";
import GRegionsPercentile from "@/components/ConsumerForecast/Lookups/Charts/GRegionsPercentile.vue";
import Countries from "@/components/ConsumerForecast/Lookups/Charts/Countries.vue";
import GlobalRegions from "@/components/ConsumerForecast/Lookups/Charts/GlobalRegions.vue";
import MSA from "@/components/ConsumerForecast/Lookups/Charts/MSA.vue";
import MSARaw from "@/components/ConsumerForecast/Lookups/Charts/MSARaw.vue";
import USRegion from "@/components/ConsumerForecast/Lookups/Charts/USRegion.vue";
import States from "@/components/ConsumerForecast/Lookups/Charts/States.vue";
import USRegionsPercentile from "@/components/ConsumerForecast/Lookups/Charts/USRegionsPercentile.vue";
import StatesPercentile from "@/components/ConsumerForecast/Lookups/Charts/StatesPercentile.vue";
import Psychographics from "@/components/ConsumerForecast/Lookups/PageElements/Psychographics.vue";
import Context from "@/components/ConsumerForecast/Lookups/PageElements/Context.vue";
import Comparisons from "@/components/ConsumerForecast/Lookups/PageElements/Comparisons.vue";
import ComparisonsSub from "@/components/ConsumerForecast/Lookups/PageElements/ComparisonsSub.vue";
import Footer from "@/components/ConsumerForecast/Footer.vue";
import DomainPercentA from "@/components/ConsumerForecast/Lookups/PageElements/DomainPercentA.vue";
import DomainPercents from "@/components/ConsumerForecast/Lookups/Charts/DomainPercents.vue";
import DecaySpark from "@/components/ConsumerForecast/Lookups/Charts/DecaySpark.vue";
import GeneralMethods from "@/components/V2/Mixins/GeneralMethods";

export default {
  name: "MainTemplate",
  props: ["myObject", "start", "filteredSources", "filteredDomains"],

  mixins: [stringFunctions, processingMixin, objectFunctions, GeneralMethods],
  components: {
    GridLayout,
    GridItem,
    ObjectToolbar,
    SideBar,
    ActivityAuthLine,
    PercentileGender,
    PercentileAge,
    PercentileRace,
    PercentileIncome,
    DonutGender,
    DonutAge,
    DonutRace,
    DonutIncome,
    CountriesPercentile,
    GRegionsPercentile,
    Countries,
    GlobalRegions,
    MSA,
    MSARaw,
    USRegion,
    States,
    USRegionsPercentile,
    StatesPercentile,
    Psychographics,
    Context,
    Comparisons,
    ComparisonsSub,
    Footer,
    DomainPercentA,
    DomainPercents,
    DecaySpark,
    //SourceSwitch
  },
  data() {
    return {
      currentMonth: "",
      sourceUpdater: "",
      prevMonth: "",
      mDialog: false,
      sourceSwitch: false,
      changeDialog: false,
      domainD: false,
      pvDialog: false,
      domainPercentDialog: false,
      retDialog: false,
      selectDomain: "",
      domainName: "",
      color: "#ad4f22",
      sendSeries: {},
      absYoy: null,
      load: false,
      absSpark: "",
      absColor: "",
      relSpark: "",
      relColor: "",
      demoCat: "G",
      geoType: "Global",
      geoT: "Percentile",
      has_subObjects: null,
      timelineType: "",
      linkedColor: null,
      subColor: null,
      has_linkedObjects: null,
      has_demo: null,
      has_gender: null,
      has_age: null,
      has_race: null,
      has_income: null,
      has_geo: true,
      has_usState: true,
      has_usMsa: true,
      has_psycho: true,
      has_gr: true,
      has_c: true,
      has_themes: true,
      alsoLen: "",
      tH: 12,
      pH: 9,
      pl: "",
      layout: [],
      loaded: false,
      infoMobile: false,
      availableSources: [],
      //availableDomains: [],
    };
  },
  created() {
    this.bp = this.$vuetify.breakpoint.width;
    this.init(this.myObject);
    console.log("filtered domains ", this.filteredDomains);
    this.log();
  },

  computed: {
    ...mapGetters([
      "getAccount",
      "getDomainsAvailable",
      "getLoggedUser",
      "getCurrentGap",
      "getCurrentSource",
      "getSourceList",
    ]),
    isBookmarked() {
      let current = [];
      if (this.getAccount.account_settings) {
        for (const i in this.getAccount.account_settings.bookmarks) {
          if (!isNaN(i)) {
            current.push(
              this.getAccount.account_settings.bookmarks[i].object_sid
            );
          }
        }
        return current.includes(this.myObject.object_sid);
      } else {
        return false;
      }
    },

    myDomain() {
      let domain = this.$route.query.domain;
      return domain;
    },
    bp() {
      this.breakpointChangedEvent(this.$vuetify.breakpoint.name);
      return this.$vuetify.breakpoint.width;
    },
    authWidth() {
      let bp = this.$vuetify.breakpoint.width;
      let width;
      if (bp >= 1264) {
        width = 640;
      } else if (bp >= 960 && bp < 1264) {
        width = 780;
      } //md
      else if (bp < 960 && bp >= 786) {
        width = 700;
      } else if (bp < 785 && bp >= 650) {
        width = 550;
      } else if (bp < 649 && bp >= 550) {
        width = 490;
      } else {
        width = 400;
      }
      return width;
    },
    styleGender() {
      return this.buttonColor(this.demoCat === "G");
    },
    styleAge() {
      return this.buttonColor(this.demoCat === "A");
    },
    styleRace() {
      return this.buttonColor(this.demoCat === "R");
    },
    styleIncome() {
      return this.buttonColor(this.demoCat === "I");
    },
    styleGlobal() {
      return this.buttonColor(this.geoType === "Global");
    },
    styleUSA() {
      return this.buttonColor(this.geoType === "USA");
    },
    styleLinked() {
      return this.buttonColor(this.timelineType === "linked");
    },
    styleSub() {
      return this.buttonColor(this.timelineType === "sub");
    },
  },
  methods: {
    ...mapActions([
      "getFullObjectInfo",
      "changeCurrentSource",
      "updateSettings",
    ]),

    init(data) {
      this.missingData(data);
      this.missingGeos(data);
      this.getDomainName(this.myDomain);
      this.dateMonth(data.data_latest_date);
      this.setColorName(data);
      this.metricKeys(data);
      this.urlCheck(data);
      this.setTimelineType();
    },

    breakpointChangedEvent(newBreakpoint) {
      let regLayout = [
        { x: 0, y: 0, w: 12, h: this.tH, i: "7", title: "themes" }, //themes
        { x: 0, y: 0, w: 12, h: 12.5, i: "4", title: "timelines" }, //timelines
        { x: 0, y: 0, w: 12, h: 6.625, i: "1", title: "demo" }, //demo
        { x: 0, y: 0, w: 12, h: 7, i: "2", title: "geo" }, //geo
        //{"x":0,"y":0,"w":12,"h":this.pH,"i":"0", "title": 'psycho'}, //psychographics
        { x: 0, y: 0, w: 12, h: 6, i: "5", title: "also" }, //see also
      ];
      let layout;
      if (newBreakpoint == "sm") {
        if (!this.has_linkedObjects && !this.has_subObjects) {
          layout = layout.filter((x) => x.title != "timelines");
        }
        if (!this.has_themes) {
          layout = layout.filter((x) => x.title != "themes");
        }
        if (!this.has_demo) {
          layout = layout.filter((x) => x.title != "demo");
        }
        if (
          !this.has_race &&
          !this.has_age &&
          !this.has_gender &&
          !this.has_income
        ) {
          layout = layout.filter((x) => x.title != "demo");
        }
        if (!this.has_psycho) {
          layout = layout.filter((x) => x.title != "psycho");
        }
      } else {
        layout = regLayout;
        //check for themes
        if (!this.has_themes) {
          //no themes, then remove section
          layout = layout.filter((x) => x.title != "themes");
        }

        //check for demographics
        if (!this.has_demo) {
          layout = layout.filter((x) => x.title != "demo");
        } else if (
          !this.has_race &&
          !this.has_age &&
          !this.has_gender &&
          !this.has_income
        ) {
          layout = layout.filter((x) => x.title != "demo");
        }
        //if all geos are missing
        if (!this.has_geo) {
          layout = layout.filter((x) => x.title != "geo");
        }
        //check for psychographics
        if (!this.has_psycho) {
          layout = layout.filter((x) => x.title != "psycho");
        }

        //see also height depending on length
        // if (!this.alsoLen) {
        //   console.log('this.alsoLen is null')
        //   //layout = layout.filter(x => x.title != 'also');
        // }
        if (this.alsoLen) {
          if (this.alsoLen > 40) {
            for (const i in layout) {
              if (layout[i].title == "also") {
                layout[i].h = 7.5;
              }
            }
          }
          if (this.alsoLen <= 9) {
            for (const i in layout) {
              if (layout[i].title == "also") {
                layout[i].h = 4;
              }
            }
          }
          if (this.alsoLen <= 6) {
            for (const i in layout) {
              if (layout[i].title == "also") {
                layout[i].h = 3;
              }
            }
          }
        }
        //check for linked objects & sub objects
        if (!this.has_linkedObjects && !this.has_subObjects) {
          layout = layout.filter((x) => x.title != "timelines");
        }
        let x;

        if (this.has_linkedObjects && this.has_subObjects) {
          //console.log('has both')
          let subLarger =
            this.myObject.volumes.domains[this.myDomain].linked_objects.length <
            this.myObject.volumes.domains[this.myDomain].sub_objects.length;

          if (subLarger) {
            //console.log('sublarger true')
            x = this.myObject.volumes.domains[this.myDomain].sub_objects.length;
          } else {
            //console.log('sublarger false')
            x =
              this.myObject.volumes.domains[this.myDomain].linked_objects
                .length;
          }
        } else if (this.has_linkedObjects) {
          //console.log('has linked only')
          x =
            this.myObject.volumes.domains[this.myDomain].linked_objects.length;
        } else if (this.has_subObjects) {
          //console.log('has sub only')
          x = this.myObject.volumes.domains[this.myDomain].sub_objects.length;
        } else {
          layout = layout.filter((x) => x.title != "timelines");
        }

        if (x <= 20) {
          for (const i in layout) {
            if (layout[i].title == "timelines") {
              layout[i].h = 11;
            }
          }
        }
        if (x < 10) {
          for (const i in layout) {
            if (layout[i].title == "timelines") {
              layout[i].h = 10.5;
            }
          }
        }
      }

      this.layout = layout;
      this.$emit("loaded", false);
    },
    seeAlsoLen(value) {
      this.alsoLen = value;
    },

    themesHeight(value) {
      let len = this.myObject.theme.domains[this.myDomain].objects.length;
      let d;
      if (this.$vuetify.breakpoint.width <= 1180) {
        if (len <= 20) {
          d = 400;
        } else {
          d = 480;
        }
      } else {
        if (len <= 5) {
          d = 300;
        } else if (len <= 20) {
          d = 400;
        } else {
          d = 500;
        }
      }
      this.tH = (10 * value) / d;
    },
    psychoLen(value) {
      this.pL = value;
    },
    psychoHeight(value) {
      let len = this.pL;
      let d;
      if (this.$vuetify.breakpoint.width <= 1180) {
        if (len <= 20) {
          d = 400;
        } else {
          d = 480;
        }
      } else {
        if (len <= 20) {
          d = 400;
        } else {
          d = 460;
        }
      }
      this.pH = (10 * value) / d;
    },
    missingData(data) {
      //check for prescence of demo
      console.log(data.demo);
      if (data.demo) {
        console.log("data.demo");
        console.log(data.demo.domains);
        if (data.demo.domains) {
          this.has_demo = true;
          //has_demo is true -- now check for individual demographics
          let demo = data.demo.domains[this.myDomain].data;
          console.log(demo);
          //checking for gender
          if (demo[200100] && demo[200200]) {
            this.has_gender = true;
          } else {
            this.has_gender = false;
          }
          //checking for age
          let age_counter = 0;
          for (const i in demo) {
            if (
              demo[i].demo_id == 401099 ||
              demo[i].demo_id == 401299 ||
              demo[i].demo_id == 401499 ||
              demo[i].demo_id == 401699
            ) {
              age_counter += 1;
            }
          }
          if (age_counter > 3) {
            this.has_age = true;
          } else {
            this.has_age = false;
          }
          //checking for race
          let race_counter = 0;
          for (const i in demo) {
            if (demo[i].demo_id.toString()[0] == 3) {
              race_counter += 1;
            }
          }
          if (race_counter > 2) {
            this.has_race = true;
          } else {
            this.has_race = false;
          }
          //checking for income
          let income_counter = 0;
          for (const i in demo) {
            if (demo[i].demo_id.toString()[0] == 8) {
              income_counter += 1;
            }
          }
          if (income_counter > 2) {
            this.has_income = true;
          } else {
            this.has_income = false;
          }
          //check return values
          // console.log(this.has_demo)
          // console.log(this.has_gender)
          // console.log(this.has_age)
          // console.log(this.has_income)
        } else {
          // console.log('no data.demo.data')
          this.has_demo = false;
          this.has_demo = false;
          this.has_gender = false;
          this.has_age = false;
          this.has_race = false;
          this.has_income = false;
        }
      } else {
        // console.log('no data.demo')
        this.has_demo = false;
        this.has_gender = false;
        this.has_age = false;
        this.has_race = false;
        this.has_income = false;
      }
      //check for sub objects
      if (data.volumes.domains[this.myDomain].sub_objects) {
        if (data.volumes.domains[this.myDomain].sub_objects.length) {
          this.has_subObjects = true;
        } else {
          //console.log('sub_objects has no length')
          this.has_subObjects = false;
        }
      } else {
        //console.log("sub_objects don't exists")
        this.has_subObjects = false;
      }
      //check for linked
      if (data.volumes.domains[this.myDomain].linked_objects) {
        if (data.volumes.domains[this.myDomain].linked_objects.length) {
          this.has_linkedObjects = true;
        } else {
          //console.log('linked_objects has no length')
          this.has_linkedObjects = false;
        }
      } else {
        //console.log("linked_objects don't exists")
        this.has_linkedObjects = false;
      }
      // console.log(this.has_linkedObjects)
      // console.log(this.has_subObjects)
    },
    missingGeos() {
      //check for us_state
      if (this.myObject.us_state) {
        if (this.myObject.us_state.domains) {
          this.has_usState = true;
        } else {
          this.has_usState = false;
        }
      } else {
        this.has_usState = false;
      }
      //console.log('has_usState', this.has_usState)
      //check for us_msa
      if (this.myObject.us_msa) {
        if (this.myObject.us_msa.domains) {
          this.has_usMsa = true;
        } else {
          this.has_usMsa = false;
        }
      } else {
        this.has_usMsa = false;
      }
      //console.log('has_usMsa', this.has_usMsa)
      //check for global region
      if (this.myObject.global_region) {
        if (this.myObject.global_region.domains) {
          this.has_gr = true;
        } else {
          this.has_gr = false;
        }
      } else {
        this.has_gr = false;
      }
      //console.log('global_region', this.has_gr)
      //check for country
      if (this.myObject.country) {
        if (this.myObject.country.domains) {
          this.has_c = true;
        } else {
          this.has_c = false;
        }
      } else {
        this.has_c = false;
      }
      //console.log('country', this.has_c)
      //check for overall geo prescence
      if (!this.has_gr && !this.has_c && !this.has_usMsa && !this.has_usState) {
        this.has_geo = false;
      }
      //console.log('has_geo', this.has_geo)
      //check for psychographics
      if (this.myObject.psychographics) {
        //has object
        if (!this.myObject.psychographics.domains) {
          //console.log('has_pysycho has no length')
          this.has_psycho = false;
        } else {
          this.has_psycho = true;
        }
      } else {
        //console.log("has_pysycho doesn't exist")
        this.has_psycho = false;
      }

      //check for themes
      if (this.myObject.theme) {
        //has object
        if (this.myObject.theme.domains) {
          this.has_themes = true;
        } else {
          //console.log('has_themes has no domains')
          this.has_themes = false;
        }
      } else {
        //console.log("has_themes doesn't exist")
        this.has_themes = false;
      }
    },
    log() {
      this.logAction({
        event: "object-load",
        app_name: "CF",
        page_name: "look-up-view",
        object_sid: this.myObject.object_sid,
        domain_sid: this.myDomain,
        source: this.getCurrentSource,
      });
    },

    urlCheck(data) {
      if (data.web) {
        if (!data.web.includes("https://")) {
          this.myObject.web = "https://" + this.myObject.web;
        }
      }
    },
    getDomainName(domain) {
      this.domainName = objectFunctions.marketVerticals[domain];
    },
    domainChange(sid) {
      this.load = true;
      this.domainD = false;
      this.logAction({
        event: "domain-change",
        app_name: "CF",
        page_name: "look-up-view",
        form_name: "activity",
        object_sid: this.myObject.object_sid,
        details: { from: this.myDomain, to: sid },
        source: this.getCurrentSource,
      });
      this.$router.push(
        this.getLookupViewPath(
          this.myObject.object_sid,
          sid,
          this.$route.query.type,
          this.$route.query.source
        )
      );
      this.$emit("domainChange", sid);
    },
    updateCurrentSource: async function () {
      let payload = {
        sid: this.$route.query.sid,
        domain: this.$route.query.domain,
        type: this.$route.query.type ? this.$route.query.type : "",
        source: this.sourceUpdater,
      };
      let path = this.getLookupViewPath(
        payload.sid,
        payload.domain,
        payload.type,
        payload.source
      );
      this.$router.push(path);
      location.reload();
    },
    pageItemLoad(value) {
      this.$emit("objectChange", value);
      this.load = true;
    },
    buttonColor(selcted) {
      return selcted ? { "--color": "#536582" } : { "--color": "#B6C2D3" };
    },
    setTimelineType() {
      if (this.has_linkedObjects) {
        this.timelineType = "linked";
      } else if (this.has_subObjects) {
        this.timelineType = "sub";
      } else {
        this.timelineType = null;
      }
      //console.log(this.timelineType)
    },
    changeTimeline(type) {
      this.timelineType = type;
    },

    bookmark: async function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let settings = this.getAccount.account_settings;
      console.log(!this.getAccount.account_settings.bookmarks);
      if (settings) {
        if (settings.bookmarks) {
          //console.log('not first bookmark')

          if (this.isBookmarked === false) {
            //console.log('object not yet bookmarked')
            settings.bookmarks.push({
              object_name: this.myObject.name,
              object_sid: this.myObject.object_sid,
              date_added: date,
              ts: Date.now(),
              source: this.getCurrentSource,
              domain: this.myDomain,
              type_code: this.myObject.type_code,
            });

            let payload = {
              token: this.getLoggedUser.token,
              settings: settings,
            };
            this.logAction({
              event: "add-bookmark",
              app_name: "CF",
              page_name: "look-up-view",
              object_sid: this.myObject.object_sid,
              object_domain: this.myDomain,
              object_type: this.myObject.type_code,
            });
            await this.updateSettings(payload)
              .then((data) => {
                console.log(data);
              })
              .catch((err) => {
                console.log(err);
                console.log(err.response);
              });
          }
        } else {
          //console.log('first bookmark')
          settings["bookmarks"] = [
            {
              object_name: this.myObject.name,
              object_sid: this.myObject.object_sid,
              date_added: date,
              ts: Date.now(),
              source: this.getCurrentSource,
              type_code: this.myObject.type_code,
              domain: this.myDomain,
            },
          ];

          console.log(settings);
          let payload = {
            token: this.getLoggedUser.token,
            settings: settings,
          };
          await this.updateSettings(payload)
            .then((data) => {
              console.log(data);
              console.log(this.getAccount);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);
            });
        }
      }
    },
    removeBookmark: async function () {
      let settings = this.getAccount.account_settings;
      //console.log('remove bookmark')
      settings.bookmarks = settings.bookmarks.filter(
        (x) => x.object_sid != this.myObject.object_sid
      );
      this.logAction({
        event: "remove-bookmark",
        app_name: "CF",
        page_name: "look-up-view",
        object_sid: this.myObject.object_sid,
        object_domain: this.myDomain,
        object_type: this.myObject.type_code,
      });
      let payload = {
        token: this.getLoggedUser.token,
        settings: settings,
      };
      await this.updateSettings(payload)
        .then((data) => {
          console.log(data);
          console.log(this.getAccount);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },
    metricKeys(data) {
      if (data.volumes) {
        let d = data.volumes.domains[this.myDomain];
        this.relYoy = (d.domain_yoy_percent * 100).toFixed(2);

        if (this.relYoy > 0) {
          this.relYoy = "+" + this.relYoy;
        }
        if (d.authentic_yoy > 0) {
          this.absYoy = "+" + d.authentic_yoy;
        } else {
          this.absYoy = d.authentic_yoy;
        }

        this.ppm = this.numberWithCommas(d.authentic_ppm);
        if (data.user_retention) {
          if (data.user_retention.domains) {
            this.userRet =
              data.user_retention.domains[this.myDomain].one_month[23];
          }
        }
      }
      this.setKeyColors();
    },
    setKeyColors() {
      if (this.absYoy <= 0) {
        this.absColor = "#C62828";
        //this.absSpark = 'linear-gradient(to top right, #F7E85C, #E57F23)';
        this.absSpark = "linear-gradient(to top right, #ffff, #ffff)";
      } else {
        this.absColor = "#388E3C";
        //this.absSpark = 'linear-gradient(to top right,#C7F464,#2B908F)';
        this.absSpark = "linear-gradient(to top right, #ffff, #ffff)";
      }
      if (this.relYoy <= 0 || this.relYoy == "-0.00%") {
        this.relColor = "#C62828";
        //this.relSpark = 'linear-gradient(to top right, #F7E85C, #E57F23)';
        this.relSpark = "linear-gradient(to top right, #ffff, #ffff)";
      } else {
        this.relColor = "#388E3C";
        //this.relSpark = 'linear-gradient(to top right,#C7F464,#2B908F)';
        this.relSpark = "linear-gradient(to top right, #ffff, #ffff)";
      }
    },
    changeDemoCat(btn) {
      this.demoCat = btn;
    },
    geoToggle(value) {
      console.log(value);
      console.log(this.geoType);
      this.geoT = value;
    },
    geo_type(type) {
      this.geoType = type;
      if (type == "Global") {
        this.glBtn = "#71C6E6";
        this.usBtn = "#FFF";
      } else {
        this.glBtn = "#FFF";
        this.usBtn = "#71C6E6";
      }
    },
    downloadAuth() {
      let d = this.myObject.volumes.domains[this.myDomain].authentic_posts;
      let dates = this.myObject.volumes.month_date_set;
      let rows = [["Month", "PPM"]];

      for (const i in d) {
        if (!isNaN(i)) {
          rows.push([dates[i], d[i]]);
        }
      }
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.myObject.name + "_Activity");
      document.body.appendChild(link); // Required for FF
      link.click();
      this.logAction({
        event: "csv-download",
        app_name: "CF",
        page_name: "look-up-view",
        form_name: "activity",
        object_sid: this.myObject.object_sid,
        domain_sid: this.myDomain,
        source: this.getCurrentSource,
      });
    },
    onScroll(e) {
      console.log(e.target.scrollTop);
    },
    closem() {
      this.mDialog = false;
    },
    openSourceSwitch() {
      this.sourceSwitch = true;
    },
    closeAndReload() {
      this.sourceSwitch = false;
      location.reload().catch((err) => {
        console.log(err);
      });
    },
    closeI() {
      this.infoMobile = false;
    },
    closeChange() {
      this.changeDialog = false;
    },
    closePv() {
      this.pvDialog = false;
    },
    closedomainPercent() {
      this.domainPercentDialog = false;
    },
    closeD() {
      this.retDialog = false;
    },
    monthD() {
      this.mDialog = true;
    },
    infoD() {
      this.infoMobile = true;
    },
    changeD() {
      this.changeDialog = true;
    },
    pvD() {
      this.pvDialog = true;
    },
    domainPercentD() {
      this.domainPercentDialog = true;
    },
    openD() {
      this.retDialog = true;
    },
    domainDialog() {
      this.domainD = !this.domainD;
    },
  },
};
</script>

<style scoped lang="scss">
.bookmark {
  color: #ffff;
}
.bookmark:hover {
  color: #6387b0;
}
.isbookmark {
  color: #6387b0;
}
.isbookmark:hover {
  color: #ffff;
}
.bar-domain:hover {
  position: relative;
  bottom: 0.2em;
}
.card-titles:hover {
  position: relative;
  bottom: 0.1em;
}
.card-titles {
  font-size: 1.1em;
}
.q-footer {
  position: absolute;
  bottom: 0em;
  width: 100%;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-6%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes bounce2 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
  }
}
#objectTitle {
  animation: bounce 0.7s 0.2s;
  animation-direction: alternate;
}
.vue-grid-item {
  /* transition: all 200ms ease; */
  transition: none;
}
.vue-grid-layout {
  /* transition: height 200ms ease; */
  transition: none;
}
.full {
  padding: 1em;
  max-width: 1420px;
  margin-left: 3em;
}
#toggle {
  height: 20px;
  width: 80px;
  font-size: 0.7em;
  float: right;
  background-color: white;
}
%dG-btn {
  background-color: transparent !important;
  margin: 1em;
}
.demoButton {
  @extend %dG-btn;
  //border: solid 2px var(--color);
  background-color: var(--color) !important;
  color: #ffff;
}
.demoButton:hover {
  position: relative;
  bottom: 0.2em;
}
.geoButton {
  @extend %dG-btn;
  background-color: var(--color) !important;
  color: #ffff;
}
.geoButton:hover {
  position: relative;
  bottom: 0.2em;
}
.timelineButton {
  @extend %dG-btn;
  background-color: var(--color) !important;
  color: #ffff;
}
.auth-activity {
  border-radius: 7px !important;
  height: 480px;
  //border: 2px solid #627792;
  //background-image: linear-gradient(#273072,#20246F);
  background-color: #ffff;
  //background-image: linear-gradient(#273072,#20246F);
  //background-image: linear-gradient(to top right, #5F6695,#6A70A0);
  width: 98%;
}
.transform {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.v-tooltip__content {
  opacity: 0.96 !important;
  background-color: #3f4d63 !important;
}
#whole {
  background-color: #d6e1ef;
  //background-image: linear-gradient(#273072,#1a204c);
  //background-image: linear-gradient(to top right, #0d1026, #1a204c, #273072);;
  padding-bottom: 6em;
  height: 100%;
}
#logo-img {
  border-radius: 12px;
  background-size: 120px 90px;
  float: right;
  margin-right: 640px;
}
#logo-img:hover {
  position: relative;
  bottom: 0.2em;
}
#blue-banner {
  //background-image: linear-gradient(to top right, #0d1026, #1a204c, #273072);
  color: #333;
  height: 685px;
  margin-top: 5em;
}
#inner-blue {
  position: relative;
  left: 2em;
  width: 1200px;
  padding-top: 2em;
  animation: fadein 1s ease-in;
}
#components {
  animation: fadein 0.5s ease-in;
  position: relative;
  bottom: 7em;
}
.center-vert {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.brand-name {
  font-size: 3.5em;
  color: #37474f !important;
  font-weight: 400;
}
.nonBrand-name {
  font-size: 3.5em;
  color: #37474f !important;
  font-weight: 400;
  cursor: default;
}
.brand-name:hover {
  position: relative;
  bottom: 0.1em;
}
%sparks {
  height: 115px;
  text-align: center;
  border-radius: 3px 3px 7px 7px !important;
  color: #333;
  margin: 1em;
  border-top: solid 6px #a6b7cd;
}
.margin-sparks {
  margin-left: 3em;
}
.spark1:hover {
  position: relative;
  bottom: 0.3em;
}
.percentile-link {
  color: #6387b0;
}
.percentile-link:hover {
  position: relative;
  bottom: 0.15em;
}
.spark1 {
  @extend %sparks;
  //background-image: linear-gradient(to top right, #4ECDC4, #3F51B5);
  //background-image: linear-gradient(#273072,#20246F);
  background-color: #ffff;
  //border: 3px solid #607D8B;
}
.spark2 {
  @extend %sparks;
  height: 195px;
  background-color: #ffff;
  //border: 3px solid #607D8B;
  //background-image: linear-gradient(#273072,#20246F);
}
.spark2:hover {
  position: relative;
  bottom: 0.2em;
}
.spark2B {
  @extend %sparks;
  height: 195px;
  background-color: #ffff;
}
.all-sparks {
  position: relative;
  bottom: 1em;
}
.hor-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
//grid
.vue-grid-layout {
  background: transparent;
}
.vue-grid-item .text {
  margin: auto;
  height: 100%;
  width: 100%;
  border-radius: 7px;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .add {
  cursor: pointer;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.g-regions {
  position: relative;
  left: 2em;
}
.usa-raw {
  padding: 2em;
  height: 345px;
  position: relative;
  left: 2em;
}
.csv-hover {
  color: #6387b0 !important;
}
.csv-hover:hover {
  position: relative;
  bottom: 0.2em;
}
//xs
@media only screen and (min-width: 0px) and (max-width: 600px) {
  #blue-banner {
    height: 1450px;
  }
  #inner-blue {
    width: 100%;
  }
  .auth-activity {
    justify-self: center;
    padding-right: 1em;
    position: relative;
    right: 1.5em;
  }
  .no-margin-sparks {
    margin-left: 3em !important;
  }
  .spark2B {
    position: relative;
    right: 1.5em;
    justify-self: center;
    padding-right: 1em;
  }
  .all-sparks {
    position: relative;
    bottom: 0em;
    right: 1.5em;
    padding-top: 2em;
  }
}
//sm
@media only screen and (min-width: 600px) and (max-width: 960px) {
  #blue-banner {
    height: 1250px;
  }
  #inner-blue {
    width: 100%;
    position: relative;
    left: 0em;
  }
  .usa-raw {
    position: relative;
    left: 0em;
  }
  .auth-activity {
    justify-self: center;
    margin-left: 2em;
    margin-right: 2em;
  }
  .no-margin-sparks {
    margin-left: 3em !important;
  }
  .all-sparks {
    position: relative;
    bottom: 0em;
    padding-top: 1em;
    padding-bottom: 2em;
  }
  .spark2B {
    margin-left: 3em;
    margin-right: 3em;
  }
  .spark1 {
    width: 80%;
    margin-left: 3em;
    margin-bottom: 3em;
  }
  .g-regions {
    position: relative;
    left: 0em;
  }
}
//md
@media only screen and (min-width: 960px) and (max-width: 1263px) {
  .no-margin-sparks {
    margin-left: 3em !important;
  }
  #blue-banner {
    height: 1250px;
  }
  #inner-blue {
    position: relative;
    left: 0em;
  }
  .all-sparks {
    position: relative;
    bottom: 1em;
  }
  .auth-activity {
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
  }
  .full {
    margin-left: 0;
  }
  .usa-raw {
    position: relative;
    left: em;
  }
  .spark2 {
    height: 245px;
    width: 90%;
    position: relative;
    left: 1em;
    padding-top: 2em;
  }
}
</style>
