<template>
    <div>
<!--  AD BANNER
      <a  :href="'https://informaconnect.com/tmre/sponsors/social-standards/'" target="_blank">
      <img class="center" style="margin-bottom:3em; position:relative; top:3em; border-radius: 12px 12px 12px 12px;"
           width=75% src="@/assets/banners/TMRE_banner.png" alt="" />
      </a>-->
    <v-layout row wrap flex-lg-row-reverse flex-md-row-reverse
              style="padding-top:6em; padding-bottom:8em;">
      <v-flex xs12 sm12 md8 lg10 xl10
              style="text-align:center; margin-left:10em; margin-right:10em;"
              class="rep">
        <v-card
          class="d-flex flex-wrap align-content-center cardsalign"
          flat
          style="text-align:center; "
          tile>
          <MediaCard
            class='mx-auto pa-2 ma-4 align-content-center'
            v-for="video in videosList"
            :key="video.id"
            :video="video"
          ></MediaCard>
        </v-card>

      </v-flex>

    </v-layout>
  </div>
</template>

<script>
import processingMixin from '@/mixins/processingMixin';
import MediaCard from '@/components/Portal/MediaCard';

export default {
  name: 'videos-page',
  mixins: [processingMixin],
  components: {MediaCard},
  data() {
    return {
       videosList: [
         {id:'https://www.prnewswire.com/news-releases/social-standards-adds-tiktok-consumer-intelligence-301735323.html', type: 'link', image: "tiktok.png", title: 'Social Standards Adds TikTok Consumer Intelligence', subtitle: 'Social Standards Press Release', summary: "Social Standards has Structured, Indexed, and Benchmarked the Billions of Daily Authentic Consumer Conversations on TikTok to Create Actionable Insights at the Category, Product, Feature, and Trend Levels.", description: "", date: 'January 31, 2023'},
         {id:'arPe1XRrQCw', type: 'video', image: "future_of_xyz.png", title: 'Future of Consumer Analytics', subtitle: 'Future of XYZ podcast', summary: "Devon Bergman, CEO and Co-Founder of Social Standards, joins Lisa Gralnek on the Future of XYZ Podcast to discuss the evolution of consumer analytics in driving business decisions around supply chain, innovation, promotions, and so much more – and why it matters to consumers.", description: "", date: 'November 3, 2022'},
         {id:'zdw85QaQwvM', type: 'video', image: "careerclub.png", title: 'Career Club\'s Cool Companies You Should Know podcast', subtitle: 'Bob Goodwin of Career Club "Cool Companies You Should Know" podcast', summary: "Discover more from Devon Bergman, CEO and Co-Founder of Social Standards, as he speaks with Bob Goodwin, President and Founder of Career Club, and shares Social Standards' unique value proposition, what drives the company culture, and what he looks for in the talent he brings into the company.", description: "", date: 'August 25, 2022'},
         {id:'https://www.bcg.com/press/14april2022-bcg-social-standards-join-forces-uncover-new-growth-insights-opportunities', type: 'link', image: "bcg.png", title: 'BCG and Social Standards Join Forces to Uncover New Growth Insights and Opportunities Powered by Consumers', subtitle: 'BCG Press Release', summary: "BCG announces multiyear partnership with Social Standards. Led by BCG, Social Standards data will be integrated to augment the company’s strategic, operational and digital insights, becoming part of BCG’s default toolkit for all things consumer.", description: "", date: 'April 14, 2022'},
         {id:'Srl7G0eTKwc', type: 'video', image: "food_institute.png", title: 'Probiotics: Consumer Perceptions and Opportunities', subtitle: 'Food Institute Webinar', summary: "In this webinar from Food Institute, consumer analytics Social Standards VP of Data Analytics & Customer Experience, Jordan Breslauer, teams up with Dr. Gregory Leyer, Sr. Director – Scientific Affairs at Chr. Hansen, a bioscience company, to uncover unique insights into the probiotic consumer.", description: "More than ever, consumers are prioritizing health and finding ways to make positive changes to their lifestyle. To address wellness needs in this health-focused consumer landscape, food processors turn to functional ingredients like probiotics to provide documented health benefits in food and drink.\n" +
               "• The importance of understanding what a probiotic is \n" +
               "• Consumers' perception of probiotics and their benefits \n" +
               "• Motivations consumers have for taking probiotics \n" +
               "• What consumers are saying about probiotics on social media", date: 'March 10, 2022'},
        {id:'sB4vA8kL8Ds', type: 'video', image: "baml.png", title: '2022 Food & Beverage Health Trends', subtitle: 'At the Bank of America Merrill Lynch Consumer & Retail Tech Conference', summary: "Social Standards' very own Jordan Breslauer takes the stage at the Bank of America 2022 Consumer & Retail Technology Conference to discuss evolving food and beverage health trends and the impact on plant based categories and beverage alcohol.", description: "", date: 'March 8, 2022'},
        {id:'https://foodinstitute.com/focus/monitoring-plant-based-food-converstations-on-instagram/', type: 'link', image: "food_institute.png", title: 'Monitoring Plant-Based Food Conversations on Instagram', subtitle: 'The Food Institute Article', summary: "In this article for the Food Institute, Social Standards analysed public consumer posts on Instagram to identify trends within plant-based food conversations from November 2020 to January 2020.", description: "", date: 'February 17, 2022'},
        {id:'jBfxwBU9_gI', type: 'video', image: "raymond_james.png", title: '2021 Beauty Trends', subtitle: 'At the Raymond James Consumer Conference', summary: 'Social Standards CEO and Co-Founder Devon Bergman speaks about beauty trends and celebrity influencer brands with Olivia Tong, a Managing Director and Senior Analyst within Consumer at Raymond James 2021 Consumer Conference.', description: "Devon talks about consumer forecasting and how consumers' conversations about brands are changing over time within social media. He discusses how celebrity founded makeup brands can thrive or suffer given the drive of social conversation. Coty, Kylie Cosmetics, KKW Beauty and Bobbi Brown are examples that are mentioned. He also chats about overall cosmetics and skincare trends, with The Ordinary and Estee Lauder as examples here.", date: 'September 14, 2021'},
        {id:'upoMtvmm2Tk', type: 'video', image: "baml.png", title: '2021 Hard Seltzer & RTD Cocktails', subtitle: 'At the Bank of America Merrill Lynch Consumer & Retail Tech Conference', summary: "Social Standards VP of Data Analytics & Customer Experience Jordan Breslauer speaks at Bank of America Merrill Lynch's 2021 Consumer and Retail Tech Conference, introduced by Bryan Spillane, a Managing Director within Equity Research in the Food and Beverage space at Bank of America Merrill Lynch.", description: "Jordan speaks about the current state of the hard seltzer category within Beverage Alcohol. He dives into how the category is trending and the potential threat that other ready to drink (RTD) products like hard teas, lemonade and premixed cocktails present.", date: 'March 11, 2021'},

       ]
    }
  },
  created() {
    this.logPageLoad({
      app_name: 'S',
      page_name: this.$options.name,
    })
  },
}
</script>