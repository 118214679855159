import Vue from "vue";
import VueRouter from "vue-router";
import VueGtm from "@gtm-support/vue2-gtm";
import vueScrollBehavior from "vue-scroll-behavior";

import WhatWeDoView from "@/views/static-site-views/WhatWeDoView.vue";
import ProductsView from "@/views/static-site-views/ProductsView.vue";
import AboutView from "@/views/static-site-views/AboutView.vue";
import Policy from "@/views/static-site-views/Policy.vue";
import ResourcesView from "@/views/static-site-views/ResourcesView.vue";
import Videos from "@/views/static-site-views/Videos.vue";
import VideoPlayer from "@/views/static-site-views/VideoPlayer.vue";
import TermsAndConditions from "@/views/static-site-views/TermsAndConditions.vue";
import WorkProducts from "@/views/AppViews/WorkProducts.vue";
import CompanyManagement from "@/views/AppViews/CompanyManagement.vue";
import ContactView from "@/views/static-site-views/ContactView.vue";
import AdminView from "@/views/AppViews/AdminView.vue";
import ErrorView from "@/views/ErrorView.vue";
import Home from "@/views/static-site-views/Home.vue";
import Login from "@/views/Login.vue";
import Store from "@/store/index.js";
import ClientHome from "@/views/AppViews/ClientHome.vue";
import Installer from "@/views/static-site-views/Installer.vue";
import ManageUsers from "@/views/auth-mgmt/ManageUsers.vue";
import ResetPassView from "@/views/auth-mgmt/ResetPassView.vue";
import AcceptInvite from "@/views/auth-mgmt/AcceptInvite.vue";
import AccountDisabled from "@/views/auth-mgmt/AccountDisabled.vue";
import LookupView from "@/views/ConsumerForecast/LookupView.vue";
import ObjectSearch from "@/views/ConsumerForecast/ObjectSearch.vue";
import WorklistView from "@/views/ConsumerForecast/WorklistView.vue";
import searchResult from "@/views/ConsumerForecast/searchResult.vue";
/*
import Dashboard from "@/views/ConsumerForecast/DynamicReport.vue";
import DashboardHome from "@/views/ConsumerForecast/DashHome.vue";
*/
import Help from "@/views/ConsumerForecast/Help.vue";
import DomainAccess from "@/views/ConsumerForecast/DomainAccess.vue";
import Errors from "@/views/AppViews/Errors.vue";
/*
import DashboardList from "@/views/V2/DynamicReportListView.vue";
import DynamicDashboardView from "@/views/V2/DynamicReportView";
 */
import CareerView from "@/views/static-site-views/Career.vue";

const os = { template: "<div>os</div>" };
const knowledgebase = { template: "<div>knowledegbase</div>" };
const harnessingCI_TL = { template: "<div>harnessingCI_TL</div>" };
const process_TL = { template: "<div>process_TL</div>" };
const softwareUpdates = { template: "<div>softwareUpdates</div>" };

const routes = [
  {
    path: "/AppView/client-home",
    name: "client-home",
    component: ClientHome,
  },
  {
    path: "/ConsumerForecast/lookup-view",
    name: "lookup-view",
    component: LookupView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ConsumerForecast/worklist",
    name: "worklist",
    component: WorklistView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/AppView/something-went-wrong",
    name: "error",
    component: Errors,
  },
  {
    path: "/ConsumerForecast/domain-access",
    name: "domain-access-402",
    component: DomainAccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/disabled-login",
    name: "disabled",
    component: AccountDisabled,
  },

  {
    path: "/ConsumerForecast/search-result",
    name: "searchResult",
    component: searchResult,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/ConsumerForecast/help",
    name: "help",
    component: Help,
    meta: {
      requiresAuth: true,
    },
  },
/* -- not used anymore
  {
    path: "/ConsumerForecast/my-dashboards",
    name: "myDashboards",
    component: DashboardHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ConsumerForecast/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },*/

  {
    path: "/ConsumerForecast/object-search",
    name: "ObjectSearch",
    component: ObjectSearch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-settings",
    name: "userSettings",
    component: ManageUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/accept",
    name: "accept-invite",
    component: AcceptInvite,
  },
  {
    path: "/reset-password",
    name: "reset-pass-view",
    component: ResetPassView,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/error/:statusCode",
    name: "error-page",
    component: ErrorView,
  },
  {
    path: "/what-we-do",
    name: "what-we-do",
    hash: ["#investment", "#strategy", "#process"],
    component: WhatWeDoView,
  },
  {
    path: "/products",
    name: "products",
    hash: ["#contact_form", "#products-btn", "#Workshops", "#osproduct"],
    component: ProductsView,
  },
  {
    path: "/about",
    name: "about",
    hash: ["#partnerships"],
    component: AboutView,
  },
  {
    path: "/policy",
    name: "policy",
    hash: ["#cookies"],
    component: Policy,
  },
  {
    path: "/resources",
    name: "resources",
    component: ResourcesView,
  },
  {
    path: "/career",
    name: "career-page",
    component: CareerView,
  },
  {
    path: "/press",
    name: "videos",
    component: Videos,
  },
  {
    path: "/player",
    name: "video-player",
    component: VideoPlayer,
  },
  {
    path: "/terms-and-conditions",
    name: "terms-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/AppView/admin",
    name: "admin-view",
    component: AdminView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/installer",
    name: "installer",
    component: Installer,
    meta: {
      title: "installer",
      metaTags: [
        {
          name: "robot",
          content: "noindex",
        },
        {
          name: "googlebot",
          content: "noindex",
        },
      ],
    },
  },
  {
    path: "/os",
    name: "os",
    beforeEnter() {
      window.location =
        "https://console.nutanix.com/socialstandards46com/social-standards/social-standards-h2ubzk/launchpad/social-standards-insights";
    },
    component: os,
  },
  {
    path: "/collectivearticle",
    beforeEnter() {
      window.location =
        "https://socialstandards.com/page/8mivQDXsjzKMfwr4tBTkcn.html?Harnessing_Collective_Intelligence";
    },
    component: harnessingCI_TL,
  },
  {
    path: "/processarticle",
    beforeEnter() {
      window.location =
        "https://socialstandards.com/page/sdvUxSbBi5WBBqm1_ujLHD.html?Our_Process";
    },
    component: process_TL,
  },
  {
    path: "/AppView/portal",
    name: "portal",
    component: WorkProducts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/AppView/company-management",
    name: "company-management",
    component: CompanyManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/knowledgebase",
    name: "knowledgebase",
    beforeEnter() {
      window.location =
        "https://socialstandards.com/kb/AifG8kZqgoWjuMvYyYDDfu/kb_home.html";
    },
    component: knowledgebase,
  },
  {
    path: "/socialstandards-software-updates",
    beforeEnter() {
      window.location =
        "https://www.socialstandards.com/page/HZyU_zTxg0_hDZQYiJyqqo.html";
    },
    component: softwareUpdates,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
/* -- not used anymore
  {
    path: "/v2/dashboard-list",
    name: "dashboard-list",
    component: DashboardList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/v2/dashboard",
    name: "dynamic-dashboard",
    component: DynamicDashboardView,
    meta: {
      requiresAuth: true,
    },
  },
*/
  {
    // this catch-all handles essentially 404 requests back to home
    path: "*",
    component: ErrorView,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (from.path === to.path) {
      const y = window.scrollY;
      setTimeout(() => {
        window.scrollTo(0, y);
      }, 0);
      return { x: 0, y: y };
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(vueScrollBehavior, { router: router });
Vue.use(VueRouter);

// this is the google analytics tracker. logs each view of the above routes.
if (process.env.NODE_ENV === "production") {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
}

// this manages the login requirement for requiresAuth: true routes
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {    
    if (!Store.getters.isLoggedIn) {
      // if the user is not logged in, send them to the login after storing their dest
      sessionStorage.setItem("redirectPath", to.fullPath);
      next({ name: "login", params: { login: true } });
    } else {
      // otherwise send them to where they were going
      next();
    }
  } else {
    // auth not needed, just send them to where they were going
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  next();
});

export default router;
