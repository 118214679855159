<template>
<v-container style="padding-top:2em;">
   <ContactForm />
</v-container>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import processingMixin from '@/mixins/processingMixin';

  export default {
    name:'contact-view',
    mixins: [processingMixin],
    components: {
      ContactForm
    },
    created() {
		this.logPageLoad({
			app_name: 'S',
			page_name: this.$options.name,
			})
	},
  }
</script>
