<template>
<div>
  <v-dialog 
   width="480px"
   overlay-opacity=".85"
   v-model="dialog" 
   v-bind="$attrs" 
   v-on="$listeners"
   >

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" :open="open"/>
    </template>

    <v-card style="height:100%;">
      <v-card-title>Access Details</v-card-title>
      <v-icon
        small
        class="mr-2"
        @click="dialog=false"
        style="position:absolute; top:1em; right:.5em;">
        mdi-close
      </v-icon>
      <v-card-text style="text-align:left;">
        <b>Organization Information:</b><br>
        Org Name: {{getCurrentOrg.company_name}}<br>
        Access Profile: {{getCurrentGap.profile_name}}
      </v-card-text>
      <v-card-text style="text-align:left;">
        <b>Entitlements:</b>
      </v-card-text>
      <v-card-text style="text-align:left;">

        <span v-if="getCurrentGap.market_insights">
         <v-icon small>mdi-check-underline</v-icon><b> CF Pro Access: </b><span>Yes</span><br>
        </span>
        <span v-else>
         <v-icon small>mdi-window-close</v-icon><b> CF Pro: </b><span>No</span><br>
        </span>

        <span v-if="getCurrentGap.consumer_forecast">
         <v-icon small>mdi-check-underline</v-icon><b> CF Lite Access: </b><span>Yes</span><br>
        </span>
        <span v-else>
         <v-icon small>mdi-window-close</v-icon><b> CF Lite Access: </b><span>No</span><br>
        </span>

        <span v-if="getCurrentGap.allow_data_export">
         <v-icon small>mdi-check-underline</v-icon><b> CF Lite Data Export Access: </b><span>Yes</span><br>
        </span>
        <span v-else>
         <v-icon small>mdi-window-close</v-icon><b> CF Lite Data Export Access: </b><span>No</span><br>
        </span>

      </v-card-text> 

      
      <v-card-text style="text-align:left;">
          <div style="padding-bottom:.5em;">
            <span><b>Sources:</b></span><br>

            <span v-for="(item, idx) in Object.keys(getCurrentGap.profile_sources)" :key="idx">
              <div style="margin-left:1em; padding-top:1em;">
                <span v-if="item == 1">Instagram</span>
                <span v-else-if="item == 3">Twitter</span>
                <span v-else-if="item == 5">Reddit</span>
                <!-- <span v-else-if="item == 6">News</span> -->
                <span v-else-if="item == 7">TikTok</span>
              </div>

              <div v-if="item!=6" style="margin-left:2em;">
                 <span v-for="(vert, idx) in getCurrentGap.profile_sources[item]"  :key="idx">
                   <span style="margin-right:1em;">{{vert.domain_name}} </span>
                  <!-- <span v-else>, {{vert.domain_name}}</span> -->
                 </span>
              </div>
            </span>
          </div>
        </v-card-text>
      


      
    </v-card>
  </v-dialog>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "settingsEditor",
  props: {
    value: String
  },

  data() {
    return {
      dialog: false,
      switchOrg: '',
    };
  },
  created() {
  },

  computed: {

    ...mapGetters([
      'getAccount',
      'getLoggedUser',
      'getMyOrgs',
      'getCurrentGap',
      'getCurrentOrg',
      'getCurrentSource',
      'getSourceList'
    ])
  },

  methods: {

    open() {
      this.dialog = true;
    },

    closed() {
      console.log('close')
      this.dialog = false;
    },

  }
};
</script>
