<template> 

<div style="height:100%;">
    <v-container style="text-align:center;" >

        <v-card v-if="isLoggedIn && this.loggedUser.legacy === false && !this.sameUser && dataStatus !== 200"
                elevation="0" justify="center" style="padding-top:6em;">
          You were already logged in as {{loggedUser.name}} within {{loggedUser.company_name}} organization. <br><br>

          If you wish to accept this invite, click on the "Accept" button, in which case you'll be automatically
          logged out from the current account and will be prompted to create password for a new account. <br><br>

          Or you can click on the "Home" button to continue with the current account.<br><br>
          <v-row justify="center">

            <v-btn @click="logout_accept()" rounded elevation="0" class="back"
                   style="background-color:#ecedf8; margin-right: 1em;">Accept Invite</v-btn>

            <v-btn @click="redirect()" rounded elevation="0" class="back"
                   style="background-color:#ecedf8; margin-left: 1em;">
              <span v-if="isAdmin">Internal Home</span>
              <span v-else>Client Home</span>
            </v-btn>

          </v-row>

        </v-card>
      

        <v-card v-if="acceptError === 401" elevation="0" justify="center" style="padding-top:6em;">
          Invitation link has expired. Please contact Social Standards for help.
        </v-card>

        <v-card v-if="acceptError === 403" elevation="0" justify="center" style="padding-top:6em;">
          Your account information cannot be found. Please contact Social Standards for help.
        </v-card>

        <v-card v-if="dataStatus === 204 || setPswdAcceptError === 401"  elevation="0" justify="center" style="padding-top:6em;" >
          This invite has already been accepted. Please <router-link :to="{name: 'client-home'}">login</router-link>.
        </v-card>

        <v-card v-else-if="dataStatus === 200 && pswdHash !== null && pswdSetAt === null"
                elevation="0" justify="center" style="padding-top:6em;">
          You are trying to accept the invite to the platform while you have an active reset password request. <br>
          Please check your inbox for the reset password email and follow instructions from there. <br>
          If you can't find the email, you can go to <router-link :to="{name: 'client-home'}">Forgot Password.</router-link>
        </v-card>


         <v-card v-else-if="dataStatus === 200" style="padding:2em; background-color:#f3faff" elevation="0">
            <div v-if="this.queryType==='register'" style="font-size:1.1em;">
                Hi {{invitedAccount.full_name}}!
                <div style="line-height:10px;"><br></div>
                Welcome to the Social Standards Platform.
                <div style="line-height:10px;"><br></div>
                You have been assigned the following access: {{invitedRoles}}
            </div>

            <div v-else style="font-size:1.1em;">
                Hi {{invitedAccount.full_name}}!
                <div style="line-height:10px;"><br></div>
                {{invitedOrg}} is inviting you to the Social Standards Platform.
                <div style="line-height:10px;"><br></div>
                You have been assigned the following access: {{invitedRoles}}
            </div>
            
            <div style="line-height:40px;"><br></div>
            <!--if password_set_at is not populated, need to create:-->
            <div>
                <v-container style="width:400px;" v-if="!isLoggedIn & dataStatus === 200">
                    <div style="text-align:left; padding-left:1em; padding-bottom:1em;">Please create a password:</div>          
										<v-form ref="passForm">
											<v-text-field type="password" v-model="passwordOne" :rules="firstRule" required dense outlined placeholder="Password"></v-text-field>
											<v-text-field type="password" v-model="passwordTwo" :rules="secondRule" required dense outlined placeholder="Retype Password"></v-text-field>
											<v-btn @click="setPass()">Create Account</v-btn>
										</v-form>
										<div style="padding-top:1em; color:red;">
											{{Matchmessage}}
										</div>
                </v-container>
            </div>
      </v-card>
    </v-container>
</div>
</template>

<script>
	import {  mapActions, mapGetters, mapState } from 'vuex';
	//import PassReset from '@/components/PasswordReset';
	import roleFunctions from '@/mixins/roleFunctions.js';
//import ContactViewVue from '../static-site-views/ContactView.vue';
	export default {
		name: 'accept-invite',
		components: {
				//PassReset
		},
		mixins: [roleFunctions],
		data () {
			return {
				parent: this.$route.query.token,
				acceptedData: {},
        queryType: '',
				queryToken: '',
        parsedToken: {},
				dataStatus: '',
				acceptError: null,
        setPswdAcceptError: null,
        pswdSetAt: null,
        pswdHash: null,
				passSetResponse: {},
				invitedAccount: {},
				invitedInvite: {},
				invitedOrg: '',
				invitedRoles: [],
				payloadUser: {},
        sameUser: false,
				doneLoading: false,
				passwordOne: '',
				passwordTwo: '',
				Matchmessage: '',
				firstRule: [
					v => !!v || 'Field is required',
				],
				secondRule: [
					v => !!v || 'Field is required',
				]
			}
		},
		created() {
      this.queryToken = this.$route.query.token;
      this.queryType = this.$route.query.type;
      this.parsedToken = this.parseJwt(this.queryToken)

			console.log(this.loggedUser)
			if (!this.isLoggedIn) {this.invite(this.$route.query.token)}
      // same account loggedIn and invited
          // non-legacy => go to Home
      else if (this.isLoggedIn && this.loggedUser.company_uid === this.parsedToken.org_id
          && this.loggedUser.uid === this.parsedToken.id && !this.loggedUser.legacy){
        this.sameUser = true
        this.redirect()
      }   // legacy => logout & accept invite
      else if (this.isLoggedIn && this.loggedUser.legacy){this.logout_accept()}
		},

		computed: {
			...mapGetters([
				'isLoggedIn',
				'getAccount',
        'isAdmin'
			]),
			...mapState([
				'loggedUser',
				'account'
			])
		},
		
		methods: {
			...mapActions([
					'readInvite',
					'putAccountSettings',
					'acceptInviteFinal',
          'setUser',
					'setInviteOrg',
					'getUser',
					'invitePassword',
          'logoutUser'
      ]),
      
			invite: async function (token) {
					let payload = {'token': this.$route.query.token} 
					console.log(token)
					await this.readInvite(payload)
					.then((data) => {
						console.log(data)
						if (data.status === 200) {
							this.dataStatus = 200;
							let accounted = data;
							this.invitedAccount = accounted.data.account;
							this.pswdSetAt = this.invitedAccount.password_set_at;
              this.pswdHash = this.invitedAccount.password_hash;
							this.invitedInvite = data.data.invite;
							this.invitedRoles = this.presentRoleNames(this.unpackRoleNames(data.data.invite.roles));
							this.invitedOrg = data.data.organization.name;
							this.parent = {token: this.$route.query.token, name: data.data.account.full_name};
						}
						if (data.status === 204) {
							this.dataStatus = 204;
						}
					
					})
					.catch((error) => {
						console.log(error)
						if (error.response) {
							console.log('error', error.response)
							console.log('status', error.response.status)
							this.acceptError = error.response.status
              }
					})
					console.log(this.acceptError)
			},

			setPass: async function(){
				this.$refs.passForm.validate()
				if (this.passwordOne && this.passwordTwo) {
					if (this.passwordOne !== this.passwordTwo) {
						this.Matchmessage = 'Passwords do not match.'
					}
					else {
						this.Matchmessage = ''
						let payload = {
							password: this.passwordTwo,
							token: this.queryToken,
							full_name: this.invitedAccount.full_name,
						}
						console.log(payload)
						await this.invitePassword(payload).then((data) => {
							console.log(data)
							let payload = {
								invite_token: this.queryToken,
								jwt: data.data.jwt,
								org_uid: this.invitedInvite.organization_uid,
							}
							this.accept(payload)
						}) 
						.catch((err) => {
							console.log(err)
							console.log(err.response)
              if (err.response) {
                console.log('error', err.response)
                console.log('status', err.response.status)
                this.setPswdAcceptError = err.response.status
              }
						})
					} 
				}
			},

			accept: async function (payload) {
				await this.acceptInviteFinal(payload).then((data) => {
					console.log(data)
					this.setAll(data.data)
				})
				.catch((err) => {console.log(err)})
			},

			setAll: async function (load) {
				console.log(load)
				await this.setUser(load)
				.then(()=> {
					this.addSettingsToTable() 
					})
				.catch((err)=> { 
					console.log(err);
					console.log(err.response) })
			},

			addSettingsToTable: async function()  {
				let payload = {
					full_name: this.invitedAccount.full_name,
					token: this.loggedUser.token,
					settings: this.getAccount.account_settings,
				} 
				await this.putAccountSettings(payload).then((data) => {console.log(data)})
				.then(() => {
					this.redirect()
				}) 
				.catch((err) => {console.log(err)})
			},


			redirect() {
				this.$router.push({name: 'client-home'});
			},

      logout_accept(){
        this.logoutUser();
        this.invite(this.queryToken)

      },

      parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let parsed = JSON.parse(jsonPayload)
        console.log('parsed jwt', parsed)
        return parsed;
      }
		}
}
	
</script>

<style lang="scss" scoped>
</style>