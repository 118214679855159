<template>
  <div>
    <apexchart width="400" height="270" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: 'geo',
  props: {
    parentObj: Object,
    parentDomain: String,
  },

  data() {
    return {
      percentile: {},

      series: [],
          chartOptions: {

            responsive: [{
            breakpoint: '960',
            options: {
              chart: {
                width: 340
              },
            }
          }],
            chart: {
              type: 'bar',
              yaxis: {
                type: 'numeric',
                tickAmount: 6,
                max: 100,
                min: 0,
              },
              toolbar: {
                show: false
              },
            },
            tooltip: {
              enabled: true,
            },
            annotations: {
              xaxis: [
                {
                  x: 50,
                  strokeDashArray: 0,
                  borderColor: '#BDBDBD',
                  offsetY: -5,
                  label: {
                    orientation: 'horizontal',
                    borderColor: '#BDBDBD',
                    offsetY: -15,
                    style: {
                      color: '#333',
                      background: '#fff'
                    },
                    text: '50th Percentile'
                    
                  }
                }
              ]
            },
            
            legend: {
              show: false
            },
            states: {
              active: {
                filter: {
                  type: 'none' /* none, lighten, darken */
                }
              }
            },
            fill: {
              //colors: ['#20246F', '#40458A', '#9FA8DA', '#C28BB5', '#BD4B80', '#AD1457'],
              //colors: ['#40458A'],
              colors: [function({ value}) {
                if (value < 50) {
                    return '#85a1c1'
                } else {
                    return '#274b69'
                }
              }],
              opacity:1,
            },
            plotOptions: {
            bar: {
              borderRadius: 2,
              horizontal: true,
              distributed: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              type: 'category',
            }
          },
          
        }
  },

  created() {
    this.createVars(this.parentObj, this.parentDomain)
  },

  methods: {
    createVars(data, domain) {
      this.percentile = data.global_region.domains[domain].percent
      let series = []
      let categories = []
      for (const i in this.percentile) {
        if (!isNaN(i)) {
          series.push({
              x: this.percentile[i].region,
              y: this.percentile[i].percentile,
              per: this.percentile[i].percent
            })
        }
        
      }
      series.sort((a,b) => b.y - a.y)
      let set 
      
      set = [{
        data: series,
        name: 'percentile'
      }]
      
      this.series = set

       this.chartOptions = {...this.chartOptions, ...{
        yaxis: {
          type: 'numeric',
          tickAmount: 6,
          max: 100,
          min: 0,
        },
      }}

      this.chartOptions.xaxis = {
              type: 'category',
              categories: categories}
      
      this.chartOptions.tooltip = {
        custom: function({dataPointIndex, w}) {
            let val = w.config.series[0].data[dataPointIndex]
            let ending = 'th'
              if (val.y.toString()[1] == 1) {ending = 'st'}
              else if (val.y.toString()[1] == 2) {ending = 'nd'}
              else if (val.y.toString()[1] == 3) {ending = 'rd'}
              
          return '<v-card class="tooltipA">' +
      '<div class="tooltip-title">' + val.x + '</div>' +
      '<div class="tooltip-item">'+ 'percentile: ' + val.y + ending+ '<br>' +
      'raw: ' + val.per.toFixed(1)+'%' +
      
      '</div>' +
      '</v-card>'
        }
      }
    },

    
  }
}
</script>
<style lang="scss">
.tooltipA {
  min-width: 120px;
  width: 100%;
  height:80px;
  background-color: #FFF;
  opacity: .8;
  
}

.tooltip-title {
  height:30px;
  background-color:#E0E0E0;
  padding:.2em;
  
}

.tooltip-item {
  padding:.2em;
  //height:20px;
}
</style>
