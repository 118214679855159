import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFooter,{staticClass:"object-footer"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c('router-link',{attrs:{"to":{name: 'ObjectSearch'}}},[_c(VBtn,{staticStyle:{"background-color":"transparent","color":"#FFFF"},attrs:{"elevation":"0"}},[_vm._v("Home")])],1),_c('Bookmarks',{on:{"bookmarkPush":_vm.markPush},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('a',{staticStyle:{"background-color":"transparent","position":"relative","top":".5em","padding":"0em 1em 0em 1em","color":"#FFFF"},on:{"click":function($event){return _vm.start(open)}}},[_vm._v("Bookmarks")])]}}]),model:{value:(_vm.bookmarksWrap),callback:function ($$v) {_vm.bookmarksWrap=$$v},expression:"bookmarksWrap"}}),(!_vm.getLoggedUser.anon)?_c('a',{attrs:{"href":_vm.lensesUrl,"target":"_blank"}},[_c(VBtn,{staticStyle:{"background-color":"transparent","color":"#ffff"},attrs:{"elevation":"0"}},[_vm._v("Lenses")])],1):_vm._e(),_c('router-link',{attrs:{"to":{path: 'help?page=general'}}},[_c(VBtn,{staticStyle:{"background-color":"transparent","color":"#FFFF"},attrs:{"elevation":"0"}},[_vm._v("FAQ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }