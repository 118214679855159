<template>
  <div>
     <apexchart type=bar height="155" :width="width" :options="chartOptions" :series="series" />
  </div>
</template>

<script>

export default {
  name: 'decay-spark',
  props: {
    parentObj: Object,
    parentDomain: Number,
    width: Number,
  },
  created() {
    this.setArray() 
    
  },

  methods: {
    setArray() {
      let d = this.parentObj.user_retention.domains[this.parentDomain].data;
      let shiftArr = []
      for (const i in d) {
        if (!isNaN(i)) {
          shiftArr.unshift(d[i])
        }
      }
      this.series = [{
        data: shiftArr,
      }];
      }
  },
  data() {
    return {
      w: '',
      domain: '',
      series: [{
      data: []
      }],
      chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
              toolbar: {
            show: false
          },
            },
            grid: {
              show: false,   
            },
            fill: {
              colors: [ '#3f4d63'],
              },
              states: {
              active: {
                filter: {
                  type: 'none' /* none, lighten, darken */
                }
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 6,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: false,
              formatter: function (val) {
                return val.toFixed(1) + "%";
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#333"]
              }
            },
            tooltip: {
                enabled: true,
                theme: 'light',
                y: {
                  formatter: function formatter(val) {
                    return (val).toFixed(1) + '%'},
                  title: {
                    formatter: function formatter() {
                      return '';}
                  },  
                }
              
            },
            
            xaxis: {
              categories: ['1m', '2m', '3m', '4m', '5m', '6m'],
              position: 'bottom',
              labels: {
                show: true,
                 offsetY: -5,
                style: {
                  colors: ['#757575', '#757575', '#757575', '#757575', '#757575', '#757575'],
                }
              },
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                width:1,
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val + "%";
                }
              }
            
            },

          },
        
  
    }
  }      
}
</script>
