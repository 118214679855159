<template>
  <div style="height:100vh; background-color:#131737 ">
    <v-container style="padding-top:6em;">
      <v-row justify="center">
        <div id="my-animation" style="width:300px; z-index:0;">
        </div>
      </v-row>
      <v-row justify="center" style="position:relative; bottom:2em;">
        <v-card
            v-if="isLoggedIn"
            id="nav-card"
            class="mx-auto"
            width="320"
            tile
            style="border-radius: 12px;"
        >
          <v-list rounded>
            <span v-if="!loggedUser.anon">
            <v-subheader v-if="getAccount.full_name" class="text-uppercase">Hi {{getAccount.full_name}}!</v-subheader>
            <v-subheader v-else class="text-uppercase">Hi {{getAccount.name}}!</v-subheader>
            </span>
            <span v-else>
            <v-subheader class="text-uppercase">Welcome!</v-subheader>
            </span>
            <v-list-item-group color="primary">


              <span v-if="!loggedUser.anon">
                <v-list-item link v-if="this.getCurrentGap.portal || this.loggedUser.legacy">
                 <v-icon>mdi-account-circle</v-icon>
                 <v-list-item-content>
                  <router-link :to="{name:'portal'}">
                    <v-list-item-title style="color:#333; margin-left:2em;">Portal</v-list-item-title>
                  </router-link>
                 </v-list-item-content>
                </v-list-item>
              </span>

              <v-list-item link v-if="this.loggedUser.legacy == false && getCurrentGap.consumer_forecast">
                <v-icon>mdi-chart-line</v-icon>
                <v-list-item-content>
                  <router-link :to="{name:'ObjectSearch'}">
                    <v-list-item-title style="color:#333; margin-left:2em;">ConsumerForecast Lite <span
                        style="font-size:.5em; position:relative; bottom:1em; right:.5em;">*Beta</span>
                    </v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>


              <v-list-item link v-if="getCurrentGap.dashboards" :href="lensesUrl" target="_blank">
                <v-icon>mdi-view-dashboard</v-icon>
                <v-list-item-content>
                    <v-list-item-title style="color:#333; margin-left:2em;">Lenses</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link v-if="this.loggedUser.legacy == false && getCurrentGap.brand_pulse"
                           :href="brandMapsUrl" target="_blank">
                <v-icon>mdi-map-legend</v-icon>
                <v-list-item-content>
                    <v-list-item-title style="color:#333; margin-left:2em;">Brand Maps</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link v-if="!loggedUser.anon">
                <v-icon>mdi-chart-multiple</v-icon>
                <v-list-item-content>
                  <router-link :to="{name:'os'}" target="_blank">
                    <v-list-item-title style="color:#333; margin-left:2em;">Market Insights</v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>

             <v-list-item link v-if="!loggedUser.anon">
                <v-icon>mdi-book-open-page-variant</v-icon>
                <v-list-item-content>
                  <router-link :to="{name:'knowledgebase'}" target="_blank">
                    <v-list-item-title style="color:#333; margin-left:2em;">Knowledgebase</v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link v-if="loggedUser.anon">
                <v-icon>mdi-help-circle</v-icon>
                <v-list-item-content>
                  <router-link :to="{path:'/ConsumerForecast/help?page=general'}" target="_blank">
                    <v-list-item-title style="color:#333; margin-left:2em;">ConsumerForecast Lite FAQ
                    </v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link v-if="loggedUser.anon">
                <v-icon>mdi-account-cog</v-icon>
                <v-list-item-content>
                  <GapSettings v-model="settingsWrap">
                    <template v-slot:activator="{ open }">
                      <span>
                        <v-list-item-title @click="start(open)"
                                           style="color:#333; margin-left:2em;">Account Settings</v-list-item-title>
                      </span>
                    </template>
                  </GapSettings>
                </v-list-item-content>

              </v-list-item>

              <v-list-item link v-if="this.getAccess.includes('User Administrator Access')">
                <v-icon>mdi-account-group</v-icon>
                <v-list-item-content>
                  <router-link :to="{name:'userSettings'}">
                    <v-list-item-title style="color:#333; margin-left:2em;">Manage Users</v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link v-if="isAdmin">
                <v-icon>mdi-account-group</v-icon>
                <v-list-item-content>
                  <router-link :to="{name:'admin-view'}">
                    <v-list-item-title style="color:#333; margin-left:2em;">Admin</v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link v-if="loggedUser.legacy == false & !loggedUser.anon">
                <v-icon>mdi-account</v-icon>
                <v-list-item-content>
                  <span>
                    <settingsEditor v-model="settingsWrap">
                      <template v-slot:activator="{ open }">
                        <v-list-item-title style="color:#333; margin-left:2em;"
                                           @click="start(open)">My Account</v-list-item-title>
                      </template>
                    </settingsEditor>
                  </span>
                </v-list-item-content>
              </v-list-item>


              <v-list-item link v-if="!loggedUser.anon">
                <v-icon>mdi-logout</v-icon>
                <v-list-item-content>
                  <a @click="loggingOff()">
                    <v-list-item-title style="color:#333; margin-left:2em;">Logout</v-list-item-title>
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link v-else>
                <v-icon>mdi-logout</v-icon>
                <v-list-item-content>
                  <a @click="loggingOff()">
                    <v-list-item-title style="color:#333; margin-left:2em;">Exit Preview Mode</v-list-item-title>
                  </a>
                </v-list-item-content>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
        <v-card v-else style="width:320px; height:100%">
          <LoginForm style="padding:1em;"/>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import lottie from "lottie-web";
import logoData from '@/assets/ss_slide.json'
import { mapGetters, mapActions, mapState } from 'vuex';
import settingsEditor from '@/components/Navigation/SettingsEditor.vue'
import GapSettings from '@/components/AdminMgmt/GapSettings.vue'
import LoginForm from '@/components/LoginForm'
import processingMixin from '@/mixins/processingMixin';

export default {
  name: 'client-home',
  mixins: [processingMixin],
  components: {
    settingsEditor,
    GapSettings,
    LoginForm
  },
  data: () => ({
      selectedItem: 1,
      items: [
        { text: 'Portal', icon: 'mdi-account-circle', path: 'portal'},
        { text: 'CF Lite', icon: 'mdi-chart-line', path: 'ObjectSearch'},
        { text: 'CF Pro', icon: 'mdi-chart-multiple', path: 'os' },
        { text: 'Account', icon: 'mdi-account', path:'dialog' },
        { text: 'Logout', icon: 'mdi-logout' },
      ],
    }),
  computed: {
    ...mapGetters([
      'getAccount',
      'isAdmin',
      'getAccess',
      'isLoggedIn',
      'getCurrentGap'
    ]),
    ...mapState([
      'loggedUser',
    ]),
    brandMapsUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/brand-maps' // Prod URL
        : 'https://app.socialstandards.net/brand-maps'; // Dev URL
    },
    lensesUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/reports-list' // Prod URL
        : 'https://app.socialstandards.net/reports-list'; // Dev URL
    },
  },
    
  created() {

    //this.getloggeduserinfo(),

    this.logPageLoad({
      app_name: 'P',
      page_name: this.$options.name,
    })
    
    setTimeout(function () {
      let ele = document.getElementById('nav-card');
      ele.classList.add('appear');
      }, 1350);
      setTimeout(function () {
      document.getElementById('nav-card').style.opacity = 1;
      }, 1350);

      if (this.$route.query.jwt) {
        this.logoutUser();
        this.logAction({
          event: 'logout'
        });
        this.initAnon(this.$route.query.jwt)
      }

      if (this.loggedUser.legacy) {
        if (this.isLoggedIn && !this.getAccount.name) {
           this.logoutUser();
           this.logAction({
            event: 'logout'
          });
        }
      }
      else {
        if (this.isAdmin) {
          if (this.isLoggedIn && !this.getAccount.full_name) {
           this.logoutUser();
           this.logAction({
            event: 'logout'
          });
        }
      }
        
      }
  },
  methods: {
    ...mapActions([
      'logoutUser',
      'joinAnon'
    ]),

    getloggeduserinfo(){
      console.log("loggedUser")
      console.log(this.loggedUser)
      console.log("getCurrentGap")
      console.log(this.getCurrentGap)

    },

    loggingOff() {
      this.$router.push({name: 'Home'});
      this.logoutUser()
    },

    start: function(method) {
      setTimeout(() => method());
    },

    initAnon: async function(jwt) {
      console.log(jwt)
      await this.joinAnon(jwt).then((data) => {
        console.log(data)
        this.logAction({
          event: 'anon-login',
          params: jwt,
        });
      })
      .catch((err) => {
        console.log(err.response)
      })
    },

  },
  mounted() {
    lottie.loadAnimation({
      container: document.getElementById("my-animation"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: logoData
    });
  }
  

}
</script>

<style lang="scss" scoped>
  #nav-card {
    opacity: 0;
  }
  @keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  }
  .appear {
    animation: fadein 3s;
    animation-timing-function: ease-out;
  }
</style>
