<template>
  <div>
    <v-container>
      <v-row justify="center">
        <apexchart
          style="margin-right: 1em"
          width="420"
          type="donut"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "demo-pie",
  props: {
    parentDemo: Object,
    parentDomain: String,
    breakpoint: Number,
  },

  created() {
    this.deconstruct(this.parentDemo);
  },

  computed: {
    // myBreakpoint() {
    //   let bp = this.$vuetify.breakpoint.width;
    //   console.log(bp)
    //   if (bp < 960) {return 'bottom'}
    //   return 'right'
    // }
  },

  data() {
    return {
      series: [],
      chartOptions: {
        labels: [],
        //colors: ['#20246f', '#6c2d6a', '#B83e5D', '#DC722F', '#EE8C18', '#FFA600', '#FFd032'],
        //colors: ['#20246F', '#9FA8DA', '#C28BB5', '#BD4B80', '#AD1457', '#800F40'],
        colors: ["#274b69", "#85a1c1", "#c6ccd8", "#3f4d63", "#202022"],
        chart: {
          type: "donut",
          // events: {
          //   legendHovered: function() {
          //     console.log('hovere')
          //   }
          // }
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          y: {
            formatter: function formatter(val) {
              return val.toFixed(1) + "%";
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          offsetY: 0,
          position: "right",
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        states: {
          active: {
            filter: {
              type: "none" /* none, lighten, darken */,
            },
          },
        },
        responsive: [
          {
            breakpoint: "960",
            options: {
              chart: {
                width: 340,
              },
              legend: {
                show: true,
                offsetY: 0,
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  methods: {
    deconstruct(data) {
      let d = data.demo.domains[this.parentDomain].data;
      let percent = [];
      let cat = [];
      for (const i in d) {
        if (i[0] == 4) {
          if (
            d[i].demo_id == 401099 ||
            d[i].demo_id == 401299 ||
            d[i].demo_id == 401499 ||
            d[i].demo_id == 401699
          ) {
            percent.push(d[i].percent);
            cat.push(d[i].name);
          }
        }
      }
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: cat,
        },
      };
      this.series = percent;
    },
  },
};
</script>
