import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mx-auto lighten-4",staticStyle:{"background-color":"#f3faff"},attrs:{"elevation":"0"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline",staticStyle:{"color":"#131737"}},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.loggedUser.name)+" ")])],1)],1),_c(VCardText,[_c(VList,{attrs:{"flat":"","three-line":""}},[_c(VSubheader,[_vm._v("CONTACTS")]),_c(VListItemGroup,{attrs:{"color":"black lighten-5"}},_vm._l((_vm.company.reps),function(rep,i){return _c(VListItem,{key:i,attrs:{"inactive":true},on:{"click":function($event){return _vm.sendMail(rep.email)}}},[_c(VListItemAvatar,{attrs:{"size":"60px"}},[_c(VImg,{attrs:{"src":_vm.getImageURL(rep.imageUID)}})],1),_c(VListItemContent,{staticClass:"pl-2"},[_c(VListItemTitle,{staticClass:"text-left",staticStyle:{"padding-bottom":"0em"}},[_vm._v(" "+_vm._s(rep.full_name)+" ")]),_c(VListItemSubtitle,{staticClass:"text-left",staticStyle:{"position":"relative","bottom":".5em"}},[_vm._v(" "+_vm._s(rep.jobTitle)+" ")])],1),_c(VIcon,{attrs:{"medium":"","color":"blue-grey lighten-3"}},[_vm._v("mdi-email")])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }