<template>
  <div>
    <v-form
      v-if="!inviteSent"
      id="register_input"
      ref='regForm' 
      class="pl-6 pr-6 pb-6" >
      <v-text-field label="Full Name" :rules=nameRules v-model="fullName"></v-text-field>
      <v-text-field label="Email" :rules=emailRules @keydown.native.enter="getInvite" v-model="email">
      </v-text-field>
      <v-btn primary large block class="mt-4" @click="getInvite">
        Get Invite
      </v-btn>
    </v-form>

    <div class="pl-6 pr-6 pb-6" v-if="inviteSent">
    <v-alert
      dense
      text
      type="success"
    >
      Please follow the invite instructions sent to your email.
    </v-alert>
    </div>

    <div class="pl-6 pr-6 pb-6" v-if="emailExists">
      <v-alert
      dense
      text
      type="info"
      >
        Account with this email already exists! Please try to Login.
      </v-alert>
    </div>

    <div class="pl-6 pr-6 pb-6" v-if="notFound">
    <v-alert
      dense
      text
      type="error"
    >
     Email domain not found. 
    </v-alert>
    </div>

     <div class="pl-6 pr-6 pb-6" v-if="wrongFormat">
    <v-alert
      dense
      text
      type="error"
    >
     Email has wrong format.
    </v-alert>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'register',
  data() {
    return {
      email: '',
      fullName: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => /[a-zA-Z]{2,}/.test(v) || 'Name must be valid',
      ],
      inviteSent: false,
      notFound: false,
      wrongFormat: false,
      emailExists: false,

    }
  },
  methods: {
    ...mapActions([
      'register'
    ]),

    getInvite: async function() {
      this.notFound = false;
      this.inviteSent = false;
      this.wrongFormat = false;
      this.emailExists = false;

      if (this.email && this.fullName) {
        console.log(this.email)
        let payload = {
          email: this.email.trim(),
          fullName: this.fullName.trim(),
        }
        await this.register(payload).then((response) => {
          console.log(response)
          console.log(response.status)
          if (response.status === 200) {
            console.log('sent')
            this.inviteSent = true;
          }
          else if (response.status === 204) {
            this.emailExists = true;
          }
          
        })
        .catch((err) => {
          console.log(err)
          console.log(err.response.status)
          if (err.response.status === 404) {
            console.log('404')
            this.notFound = true;
          }
          else if (err.response.status === 400) {
            console.log('400')
            this.wrongFormat = true;
          }
        })
      }
    }
    
  },
}
</script>

<style lang="scss" scoped>

</style>