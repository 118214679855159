<template>
  <div>
    <apexchart width="340" type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: 'geo',
  props: {
    parentObj: Object,
    parentDomain: Number,
  },

  data() {
    return {
      percent: {},
      percentile: {},

      series: [],
      chartOptions: {
        
        responsive: [{
            breakpoint: '960',
            options: {
              chart: {
                width: 313
              },
            }
          }],

        labels: [],
        //colors: ['#20246f', '#352d6a', '#6c2d6a', '#B83e5D', '#DC722F', '#BDBDBD'],
        colors: ['#274b69', '#3f4d63', '#85a1c1', '#95724B', '#C1A585', '#BDBDBD'],
        chart: {
          type: 'donut',
        },
        dataLabels: {
            enabled: false
            },
            tooltip: {
          enabled: true,
          theme: 'dark',
          y: {
            formatter: function formatter(val) {
              return (val).toFixed(1) + '%'},
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        legend: {
          show: true,
          offsetY: -5,
          position: 'bottom',
        },
      },
    }
  },

  created() {
    this.createVars(this.parentObj, this.parentDomain)
  },

  methods: {

    createVars(data, domain) {
      let set = data.country.domains[domain].top_percent
      let total = 0;
      let arr= []
      let labels = []
      for (const i in set) {
        if (!isNaN(i)) {
          arr.push(set[i].percent)
          labels.push(set[i].iso3)
          total += set[i].percent
        }
      }
        arr.push(100-total);
        labels.push('Other')
        this.series = arr
        this.chartOptions.labels = labels
        
      this.chartOptions.tooltip = {
     
         y: {
            formatter: function(value, opts ) {
              if (value != 100-total) {

                let item = data.country.domains[domain].top_percent[opts.dataPointIndex]
              return 'raw: '+ (value).toFixed(1) + '%' + '<br>' + 'percentile: ' + item.percentile + 'th'
              }
              else {
                return 'raw: '+ (value).toFixed(1) + '%'
              }
              },
            
            title: {
              formatter: function (seriesName) {
                let set = data.country.domains[domain].top_percent
                if (seriesName != "Other") {
                  let item = set.filter(x => x.iso3 == seriesName)
                  return '  '+item[0].country+': ' + '<br>'
                  }
                else {
                   return ' ' + 'Other' + '<br>'
                }
                
              }}
         }}
    },
  }
}
</script>
