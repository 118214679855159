<template>
  <div>
    <v-container>
      <v-row justify="center">
        <apexchart width="380" height="250" type="bar" :options="chartOptions" :series="series"></apexchart>
      </v-row>
    </v-container>
        
    </div>
</template>

<script>
export default {
  name:'demo-pie', 
  props: {
    parentDemo: Object,
    parentDomain: String,
  },

  created() {
    this.deconstruct(this.parentDemo)
  },

  data() {
    return {
      series: [{
        data: [400, 430]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          },
          yaxis: {
              type: 'numeric',
              tickAmount: 6,
              max: 100,
              min: 0,
            },
          },
          legend: {
            show: false
          },
          states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        tooltip: {
            enabled: true,
            y: {
              formatter: function formatter(val) {
                let ending = 'th'
                if (val.toString()[1] == 1) {ending = 'st'}
                else if (val.toString()[1] == 2) {ending = 'nd'}
                else if (val.toString()[1] == 3) {ending = 'rd'}
                return val + ending
                },
            }
          },
          colors: ['#274b69', '#3f4d63', '#85a1c1', '#95724B', '#B08C63', '#C1A585', '#BDBDBD'],
            fill: {
              opacity:1, 
            //   colors: [function({ value }) {
            //     if(value > 50) {
            //       return '#FFA600'
            //   } else {
            //       return '#352d6a'
            //   }
            // }]
         },
         
          annotations: {
              xaxis: [
                {
                  x: 50,
                  strokeDashArray: 0,
                  borderColor: '#BDBDBD',
                  offsetY: -5,
                  label: {
                    orientation: 'horizontal',
                    offsetY: -15,
                    borderColor: '#BDBDBD',
                    style: {
                      color: '#333',
                      background: '#fff'
                    },
                    text: '50th Percentile'
                    
                  }
                }
              ]
            },
          
          responsive: [{
          breakpoint: '960',
          options: {
            chart: {
              width: 315
            },
          }
        }],
          
          plotOptions: {
            
            bar: {
              borderRadius: 2,
              horizontal: true,
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
          }
      },
    }
  },

  methods: {

    deconstruct(data) {
      let d = data.demo.domains[this.parentDomain].data
        let percent = [];
        let percentile = [];
        let cat = [];
        for (const i in d) {
            if (i[0] == 8 ) {
              if (d[i].demo_id == 800099 || d[i].demo_id == 800699 || d[i].demo_id == 800399) {
                percent.push(d[i].percent);
                percentile.push(d[i].percentile);
                cat.push(d[i].name);
              }
          }
        }
        this.chartOptions.xaxis = {
          categories: cat
        }
        this.series = [{
          name: 'percentile',
          data: percentile
        }]
        this.chartOptions.yaxis = {
              type: 'numeric',
              tickAmount: 6,
              max: 100,
              min: 0,
            }
    },
  },
}
</script>
