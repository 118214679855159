import { mapActions } from "vuex";
export default {
  computed: {
    timePeriodOptionFromQuery() {
      const query = Object.assign({}, this.$route.query);
      const DEFAULT_TIME_PERIOD = "1m"; // Only used while loading rawData

      return query.time_period
        ? query.time_period
        : this.timePeriodSelectOptions?.options?.[0]?.value
        ? this.timePeriodSelectOptions.options[0].value
        : DEFAULT_TIME_PERIOD;
    },
  },
  methods: {
    ...mapActions(["changeCurrentSource"]),
    getObjectKey(obj, value) {
      return Object.keys(obj).find((key) => obj[key] === value);
    },
    getFormattedDate(date) {
      return `${this.getObjectKey(this.months, date.getMonth()).slice(
        0,
        3
      )} ${this.getShortYear(date.getFullYear())}`;
    },
    getShortYear(year) {
      return `${year}`.slice(2);
    },
    async handleSocialNetworkChange(value) {
      const orgSource = this.rawData.source;
      this.changeSocialNetworkSelectOptionsSource(value);
      let payload = `${value}`;
      await this.changeCurrentSource(payload).catch((err) => {
        console.log(err);
      });
      // We want to log the source when new source is loaded
      const interval = setInterval(() => {
        if (this.rawData.source !== orgSource) {
          this.logEvent("dashboard-source-change");
          clearInterval(interval);
        }
      }, 300);
    },
    dateMonth(date) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let split = date.split("-");
      let lookup;
      if (split[1][0] == 0) {
        lookup = split[1][1];
        this.currentMonth = monthNames[lookup - 1];
        this.prevMonth = monthNames[lookup - 2];
      } else {
        lookup = split[1];
        this.currentMonth = monthNames[lookup - 1];
        this.prevMonth = monthNames[lookup - 2];
      }
    },
    getQueryParam(paramName, paramType = "string") {
      const query = Object.assign({}, this.$route.query);
      switch (paramType) {
        case "number":
          return Number(query[paramName]);
        default:
          return query[paramName];
      }
    },
    getDomainValueByPropName(propName) {
      const routeQuery = Object.assign({}, this.$route.query);
      return this.rawData.volumes
        ? this.rawData.volumes.domains[routeQuery.domain][propName]
        : "";
    },
    getUserRetentionValueByPropName(propName, idx) {
      const query = Object.assign({}, this.$route.query);
      return this.rawData.user_retention
        ? this.rawData.user_retention.domains[query.domain][propName][
            idx
          ].toFixed(1)
        : "";
    },
  },
};
