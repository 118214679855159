<template>
  <div>
    <v-container style="width:45%; padding-top:5em; padding-bottom:8em;">
    <!-- <LazyYoutubeVideo :src="id" preview-image-size="maxresdefault" /> -->
    <v-card elevation="0">
      <v-responsive :aspect-ratio="16/9">
          <iframe id="ytplayer" :src="id" frameborder="0"></iframe>
      </v-responsive>
    </v-card>
    

    <v-row justify="left">
      <span style="padding:1em 0em .3em .7em; font-size:1.5em; color:#333;">{{item.title}}</span>
    </v-row>
    <v-row justify="left">
      <span style="padding-left:1em; font-size:1.2em; color:#333;">{{item.subtitle}}</span>
    </v-row>
    <v-row justify="right">
      <span style="padding:1em 0em .6em 1em; font-size:1em; color:#616161;">{{item.summary}}</span>
    </v-row>
    <v-row justify="right">
      <span style="padding:1em 0em .6em 1em; font-size:1em; color:#616161; white-space: pre-wrap;">{{item.description}}</span>
    </v-row>
    <v-row justify="right">
      <span style="padding:1em 0em .6em 1em; font-size:.9em; color:#616161;">{{item.date}}</span>
    </v-row>
    </v-container>

    
  </div>
  
</template>

<script>
import processingMixin from '@/mixins/processingMixin';
import videos from '@/views/static-site-views/Videos.vue'

export default {
  name: 'videoplayer',
  mixins: [processingMixin, videos],
  data() {
    return {
      id: '',
      item: '',
    }
  },
  created() {
    this.logPageLoad({
			app_name: 'S',
      page_name: this.$options.name,
      params: this.$route.query.video
    })
    this.id = 'https://www.youtube.com/embed/' + this.$route.query.video + '?modestbranding=1&rel=0'
    this.item = this.videosList.filter(x => x.id === this.$route.query.video)[0]
  },
  methods: {
  },
}
</script>

<style scoped>
#ytplayer {
  width:100% !important;
  height:100% !important;
}
</style>