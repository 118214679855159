<template>

  <div>
    <v-container style="text-align:center;">
      <div style="font-size:1.1em;">
        <b style="font-size:1.3em;">Reset Password</b>
        <div style="line-height:10px;"><br></div>
        Hi {{this.username}}!
        <br>
        <div style="line-height:10px;"><br></div>
        Please create your new password.
        <br>
      </div>
      <PassReset/>
    </v-container>
  </div>
</template>

<script>
  import PassReset  from '@/components/PasswordReset';
  import {mapState} from "vuex";

  export default {
    components: {
      PassReset
    },
    data (){
      return {
        username: ''
      }
    },
    created() {

      this.username = this.loggedUser.name ? this.loggedUser.name : this.$route.query.full_name;

      // need to add name if user is resting pswd (not from the foget pswd email)
    },
    computed: {
      ...mapState([
        'loggedUser'
      ]),
    }
  }
</script>

