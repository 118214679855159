<template>
<v-container  fluid class="contact-container">
  <p class="contact-title">Let's Talk. </p>
  <img src="@/assets/images/tele2.png" class="center" width="180px;" style="position:relative; right:1em; padding-bottom:3em;" />
  
  <form lazy-validation ref="form" @submit.prevent="handleSubmit" id="contactForm">
    <v-layout row wrap class="form-layout">
      <v-flex xs12 sm12 md6>
        <v-text-field
          v-model="firstName"
          :rules="nameRules"
          label="First Name*"
          required
          dense
          outlined
          class="first-name"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm12 md6>
        <v-text-field
          v-model="lastName"
          :rules="nameRules"
          label="Last Name*"
          required
          dense
          outlined
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm12>
        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          label="Phone Number*"
          required
          dense
          outlined
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm12>
        <v-text-field
          v-model="company"
          label="Company"
          dense
          outlined
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm12>
        <v-text-field
          v-model="email"
          label="Email Address*"
          :rules="emailRules"
          dense
          outlined
          required
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm12>
        <v-textarea
          v-model="message"
          label="Message*"
          :rules="messageRules"
          dense
          :counter="500"
          outlined
          required
          class="box-input"
        ></v-textarea>
      </v-flex>

      <v-flex xs12 sm12>
        <v-text-field
          v-model="hearAbout"
          label="How did you hear about us?"
          :counter="300"
          dense
          outlined
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm12 style="text-align:center; padding-top:1em; padding-bottom:.5em;">
        <v-btn text id="submit-form" type="submit"> Submit</v-btn>
      </v-flex>
    </v-layout>
  </form>

  <!-- email success! -->
  <v-alert v-if="submitted == true" width="77%" type="success" class="center message">Message successfully sent.</v-alert>

  <!-- email fail! -->
  <v-alert v-if="submitted == false" width="77%" type="error" class="center message">Message send failed. Please contact site administor.</v-alert>
  
</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name:'contact-form',
  data() {
    return {
      submitted: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      company: null,
      message: null,
      hearAbout: null,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      phoneRules: [
        v => !!v || 'A 10 digit phone number is required',
        v => this.returnNumOnly(v).length == 10 || 'Phone number must be 10 digits',
      ],
      messageRules: [
         v => !!v || 'A message number is required',
         v => (v && v.length <= 500) || 'Name must be less than 500 characters',
      ],
    }
  },
  methods: {
    ...mapActions(['postContactForm']),

    returnNumOnly(input) {
      let res = input.replace(/\D/g, "");
      return res
    },

    handleSubmit() {
      let payload = {
        source: 'webapp', 
        type: 'contact request',
        firstName: this.firstName, 
        lastName: this.lastName, 
        phone: this.phone, 
        company:this.company, 
        email: this.email, 
        message: this.message, 
        hearAbout: this.hearAbout 
      };

      if (process.env.NODE_ENV === 'production') {
        this.postContactForm(payload)
          .then(response => {
            if (response.status == 200) this.submitted = true;
          })
          .catch(error => {
            console.log(error);
            this.submitted = false;
          })
      } else { this.submitted = true; }

      this.$refs.form.reset();
      
    },
  }
}
</script>

<style scoped>
#contactForm {
  padding-top:1em;
  padding-bottom:1em;
}
.form-layout {
  margin-left:auto;
  margin-right:auto; 
  max-width:80%;
}
.first-name {
  margin-right:1em;
}
.box-input {
  resize: none;
  box-sizing: border-box;
  height:200px;
}
#submit-form {
  background-color: transparent;
  color:#757575;
  font-weight:500;
  border:solid 1px#757575;
  border-radius: 7px;
  font-size:1.1em;
}
#submit-form:hover {
  border: solid 1px #71c6e6;
  color:#71c6e6;
}
.contact-container {
width:900px;
border-radius:12px;
}
.contact-title {
  text-align:center;
  font-size:3em;
}
@media only screen and (max-width: 600px) {
  .first-name {
    margin-right:0em;
  }
}
</style>