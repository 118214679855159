import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"122px","background-color":"transparent","font-size":"1.1em"},attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Client Tools ")])]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.push('portal')}}},[_c(VListItemTitle,[_vm._v("Portal")])],1),(this.$vuetify.breakpoint.width > 800)?_c(VListItem,{on:{"click":function($event){return _vm.push('ObjectSearch')}}},[_c(VListItemTitle,[_vm._v("ConsumerForecast.")])],1):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.push('knowledgebase')}}},[_c(VListItemTitle,[_vm._v("Knowledgebase")])],1),_c(VListItem,{on:{"click":function($event){return _vm.logout()}}},[_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }