<template>
  <div>


    <v-app-bar elevation="0" style="background-color:transparent">
      <router-link :to="{name: 'Home'}"><img src="@/assets/logo_navyL.png" width="190" id="logo"
                                             style="margin-top:.6em;"></router-link>
      <v-spacer></v-spacer>
      <router-link :to="{name: 'ObjectSearch'}">
        <v-btn class="btn-hover" style="background-color:transparent; color:#333;" elevation="0">CF Home</v-btn>
      </router-link>
      <Bookmarks v-if="!getLoggedUser.anon" v-model="bookmarksWrap">
        <template v-slot:activator="{ open }">
          <v-btn @click="begin(open)" class="btn-hover" style="background-color:transparent;  color:#333;"
                 elevation="0">Bookmarks
          </v-btn>
        </template>
      </Bookmarks>
      <router-link :to="{path: 'help?page=general'}">
        <v-btn class="btn-hover" style="background-color:transparent; color:#333;" elevation="0">FAQ</v-btn>
      </router-link>
      <v-menu
          v-model="accountMenu"
          absolute
          offset-y
          style="max-width: 600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="btn-hover" id="no-background-hover" v-bind="attrs" v-on="on"
                 style="background-color:transparent; margin: 0em 10em 0em 0em; color:#333;" elevation="0">Account
          </v-btn>
        </template>

        <v-list>
          <v-list-item link v-if="!getLoggedUser.anon">
            <settingsEditor v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">My Account</a>
              </template>
            </settingsEditor>
          </v-list-item>
          <v-list-item v-else>
            <GapSettings v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">Account Settings</a>
              </template>
            </GapSettings>
          </v-list-item>
          <v-list-item>
            <a @click="loggingOff()" style="color:#333;">Logout</a>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <span v-if="!loaded">
     <v-row justify="center" style="padding-top:3em;">
       <v-progress-circular
           :size="50"
           color="primary"
           indeterminate
       ></v-progress-circular>
     </v-row>

    </span>

    <span v-else>
      <v-container style="padding-top:2em;">
        <div v-if="errorMsg===''">
        <div style="float:right;">
          <v-btn rounded elevation="0" class="back" @click="uidsToClipboard()">{{copyMessage}}</v-btn>
        </div>

        <v-data-table
            :items="listItems"
            :headers="headers"
            :hide-default-footer="true"
            disable-pagination
            style="width:75vw; padding-top:2em;"
        >
        <template v-slot:item.name="{ item }">
          <router-link style="color:#333;" tag="a" target="_blank" :to="getObjectPath(item)">
            <v-tooltip light left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind:style="{color: item.color}" v-bind="attrs" v-on="on"
                        style="position:relative; bottom:.1em; padding-right:.3em;" small>mdi-square-rounded</v-icon>
              </template>
              <span v-if="item.desc_name">{{item.desc_name}}</span>
              <span v-else>{{item.type}}</span>
            </v-tooltip>
            {{item.name}}
            <span v-if="item.opacity == .4" style="font-size:.8em;"> (Insufficient Data)</span>
          </router-link>
        </template>

        <template v-slot:item.logo="{ item }">
          <div class="p-2">
            <v-img max-width="50px" max-height="60px" style="margin:.4em;" :style="{opacity: item.opacity}"
                   :src="item.logo" :alt="item.name"></v-img>
          </div>
        </template>

        <template v-slot:item.object_uid="{ item }">
          <div class="p-2">
            <v-icon small @click="uidsToClipboard(item.object_uid)">mdi-content-copy</v-icon>
          </div>
        </template>

        </v-data-table>
         </div>
        <div v-else>
           <v-row justify="center">
             <span style="font-size:20px; color:#37474F; position:relative; top:5em;">
               {{this.errorMsg}}
             </span>
           </v-row>
           <v-row justify="center">
             <span style="font-size:20px; color:#37474F; position:relative; top:8em;">
               You can try searching for a new object.
            </span>
           </v-row>
           <v-row justify="center" style="position:relative; top:13em;">
            <router-link :to="{name: 'ObjectSearch'}">
              <v-btn rounded elevation="0" class="back">Go to Search</v-btn>
            </router-link>
           </v-row>
       </div>
      </v-container>
    </span>
  </div>
  
</template>

<script>

import objectFunctions from '@/mixins/objectFunctions';
import settingsEditor from '@/components/Navigation/SettingsEditor.vue';
import GapSettings from '@/components/AdminMgmt/GapSettings.vue';
import Bookmarks from '@/components/ConsumerForecast/Bookmarks.vue';
// import searchResult from '@/views/ConsumerForecast/searchResult.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'worklists',
  mixins: [
      objectFunctions,
    ],
  components: {
    Bookmarks,
    settingsEditor,
    GapSettings,
  },
  data() {
    return {
      errorMsg: '',
      listItems: [],
      loaded: false,
      copyMessage: 'Copy Uids',
      headers: [
        {text: 'Object', align: 'start', sortable: false, value: 'name'},
        {text: '', value: 'logo', align: 'center', sortable: false},
        {text: 'Type', align: 'start', sortable: false, value: 'type'},
        {text: 'Category', align: 'start', sortable: false, value: 'category'},
        {text: '', align: 'start', sortable: false, value: 'object_uid'},
      ]
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters([
      'getLoggedUser',
        'getCurrentSource',
        'getDomainsAvailable',
        'getSourceList'
    ])
  },
  methods: {

    ...mapActions([
      'getFullObjectInfo'
    ]),

    getObjectPath(item){
      return this.getLookupViewPath(item.object_sid, item.push_domain, item.type_code, this.$route.query.source)
    },

    init: async function() {
      let sidList = this.$route.query.objects

      console.log(sidList)
      let payload = { sid: sidList, source: this.$route.query.source }
      await this.getFullObjectInfo(payload)
          .then((data) => {
            let objectsAll = []
            if (data.data.objects != undefined) {
              objectsAll = data.data.objects
            }
            else {
              objectsAll.push(data.data)
            }

            console.log("objectsAll ", objectsAll)
            this.listItems = objectsAll.filter(item => item.object !== null).map(item => item.object)
            if (this.listItems.length === 0){
              this.errorMsg = 'Oops, none of the objects are available.';
              this.loaded = true;
            }
            else this.addColorFontDomain(this.listItems)

          })
         .catch((err) => {
           console.log(err)
           if (err.response.status === 404) {
             this.errorMsg = 'Oops, the object isn\'t available.';
             this.loaded = true;
           }
           else this.$router.push({path: '/AppView/something-went-wrong' + '?status='+ err.response.status})
         })
    },

    begin: function(method) {
      setTimeout(() => method());
    },

    start: function(method) {
      setTimeout(() => method());
    },

    uidsToClipboard(uid) {
      if (uid) {
        navigator.clipboard.writeText(uid);
      }
      else {
        let copy = []
        this.listItems.forEach(x => {
          copy.push(x.object_uid);
        })
        navigator.clipboard.writeText(copy.join("\n"));
        this.timerCopy();
      }
    },

    timerCopy() {
      this.copyMessage = 'Copied!';
      setTimeout(() => {this.copyMessage = 'Copy Uids'}, 3000);
    },

    addDomain(data) {
      console.log('Adding Push Domains')
      console.log(data)
      console.log("DomainsAvailable", this.getDomainsAvailable)
      console.log("currentSource", this.getCurrentSource)
      let available = []
      if (this.getDomainsAvailable !== '') {
        available = this.getDomainsAvailable[this.getCurrentSource].map(x => x.domain_sid)
        console.log("available", available)
      }
      for (let object of data) {
        //console.log('object', object)
        if (object.domains) {
          if (available.length > 0) {
            console.log("object domains", object.domains)
            object.domainMatches = object.domains.filter(x => available.includes(x.domain_sid))
            console.log("domain matches", object.domainMatches)
            if (object.domainMatches.length > 0) {
              object.push_domain = object.domainMatches[0].domain_sid  // domain with the highest total_posts (object.domains is sorted by highest total_posts)
            }
            else {
              console.log('no domains match')
              /* ??? why is this part needed?
              if none of domains match, that means that none of the available domains for a specific account
              have enough data for an object. And when we assign the first available domain, it won't have enough data,
              which will throw 404 error
              */
              object.push_domain = available[0] // first available domain
            }
          }
          else {
            object.push_domain = object.domains[0].domain_sid
          }
        }
      }
      //console.log(data)
    },

    addColorFontDomain(data){
      for (const i in data) {
        console.log('object', data[i])
        this.setColorName(data[i])
        if (!data[i].domains) {
          data[i].color = '#E0E0E0';
          data[i].font = '#9E9E9E';
          data[i].opacity = .4
        }
        else {
          data[i].font = '#333';
          data[i].opacity = 1
        }
        //console.log(this.listItems);
        this.addDomain(data)
        this.loaded = true;
      }
    },


  }
} 
</script>

<style>
.back {
  background-color:#D6E1EF !important;
}
</style>