<template>
  <div>
    <v-app-bar absolute color="#1a204c" elevation="0" style="margin: 0em 0em 0em 0em;">
      <router-link :to="{name: 'Home'}"><img src="@/assets/SSLogo.png" width="200" style="margin: 1em 0em 0em 3em;"></router-link>
      <router-link :to="{name: 'ObjectSearch'}"><v-btn style="background-color:transparent; margin-left:1em; margin-top:.5em" elevation="0"><span style="font-size:1em; font-weight:500; color:#ffff">Home</span></v-btn></router-link>
      <v-spacer></v-spacer>
      <span id="toolbar">
      <!-- <router-link :to="{name: 'portal'}"><v-btn style="background-color:transparent; color:white;" elevation="0">Worklists</v-btn></router-link>
      <router-link :to="{name: 'portal'}"><v-btn style="background-color:transparent;  color:white;" elevation="0">Bookmarks</v-btn></router-link> -->
      <v-btn @click="markDialog()" class="btn-hover" style="background-color:transparent; color:white;" elevation="0">Bookmarks</v-btn>
      <a v-if="!loggedUser.anon" :href="lensesUrl" target="_blank">
        <v-btn style="background-color:transparent; color:#ffff;" elevation="0">Lenses</v-btn>
      </a>
      <router-link :to="{path: 'help?page=general'}"><v-btn  class="btn-hover" style="background-color:transparent; color:white;" elevation="0">FAQ</v-btn></router-link>
       <v-menu
        v-model="accountMenu"
        absolute
        offset-y
        style="max-width: 600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn id="no-background-hover" class="btn-hover" v-bind="attrs" v-on="on" style="background-color:transparent; margin: 0em 10em 0em 0em; color:white;" elevation="0">Account</v-btn>
        </template>

        <v-list>
          <v-list-item link v-if="!loggedUser.anon">
            <settingsEditor v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">My Account</a>
              </template>
            </settingsEditor>
          </v-list-item>
          <v-list-item v-else>
            <GapSettings v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">Account Settings</a>
              </template>
            </GapSettings>
          </v-list-item>
          <v-list-item>
            <a @click="loggingOff()" style="color:#333;">Logout</a>
          </v-list-item>
        </v-list>
       </v-menu>
      </span>
    </v-app-bar>
    <v-container style="margin-top:8em;">
      <v-row justify="center">
        You do not have access to this domain.
      </v-row>
      <v-row justify="center">
        <router-link :to="{name: 'ObjectSearch'}">Return to search</router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import settingsEditor         from '@/components/Navigation/SettingsEditor.vue';
import GapSettings from '@/components/AdminMgmt/GapSettings.vue';

export default {
  name: 'domain-access-402',

  components: {
    settingsEditor,
    GapSettings,
  },

  computed: {
    ...mapState([
      'loggedUser',
    ]),
    lensesUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/reports-list' // Prod URL
        : 'https://app.socialstandards.net/reports-list'; // Dev URL
    },
  },

  methods:{

    ...mapActions([
      'logoutUser',
    ]),

    start: function(method) {
        setTimeout(() => method());
    },

    loggingOff() {
      this.logoutUser()
      this.$router.push({name: 'Home'});
      this.logAction({
        event: 'logout'
      });
    },
  }
}
</script>
