<template>
  <div fluid style="padding-top:2em;" v-if="this.started">
    <v-container  style="padding:1em; position:relative; bottom:3em;">

      <v-row justify="space-between" >
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            style="padding-left:3em;"
        ></v-text-field>

        <div style="padding:1em;" v-if="getAccess.includes('internal_admin')">
          <v-btn class="create-btn" elevation="0" @click="edit('new')">Add User
            <v-icon small class="mr-1"> mdi-plus </v-icon>
          </v-btn>
        </div>
      </v-row>

      <v-data-table
          :headers="userHeaders"
          :items="this.userTable"
          :search="search"
          fixed-header
          height="65vh"
          width="100em"
          hide-default-footer
          disable-pagination
          class="elevation-0"
          style="padding-top:1em;"
        >
        <template v-slot:no-data>
          No Users
        </template>
        <template  v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="userStats(item)"
          >
            mdi-chart-box
          </v-icon>

          <v-icon v-if="getAccess.includes('internal_admin')"
            small
            class="mr-2"
            @click="edit(item)">
            mdi-format-list-bulleted-square
          </v-icon>

        </template>
      </v-data-table>

    </v-container>

    <v-dialog v-model="editDialog" max-width="600" overlay-opacity=".8">
      <v-card>
        <v-card-title>{{this.dialogTitle}} 
          <a id="ex" @click="close()">
            <v-icon
              small
              class="mr-2"
              @click="close()">
              mdi-close
            </v-icon>
          </a>
        </v-card-title>
        <div style="line-height:10px"><br></div>
        
        <v-card-text v-if="this.dialogTitle === 'View User'">
          <span v-if="this.editName===false">
            <b>Name:</b> {{this.chosenItem.full_name}} <v-icon right style="position:relative; bottom:.2em;" @click="editName=true" small>mdi-marker</v-icon><br>
          </span>
          <span v-else-if="this.editName===true">
            <div class="d-flex" style="width: 60%; align-items: baseline;">
              <b>Name:</b> <v-text-field style="padding:1em;"
                            :value="this.chosenItem.full_name"
                            dense>
              </v-text-field>
              <v-btn small @click="setFullName" style="left:2em;" rounded elevation="0"><v-icon small>mdi-check</v-icon></v-btn>
              <v-btn small @click="editName=false" style="left:3em;" rounded elevation="0"><v-icon small>mdi-close</v-icon></v-btn>
            </div>
          </span>
          <span>
<!--            <b>Name:</b> {{this.chosenItem.full_name}}<br>-->
            <b>Email:</b> {{this.chosenItem.email}}<br><br>
            <b>Roles:</b>
            <a @click="EdittingRoles=true" v-if="!EdittingRoles"><v-icon class="icon-bump" small right>mdi-marker</v-icon></a>
            <a @click="EdittingRoles=false, upForRemovalRole=[], upForAdditionRole=[]" v-else><v-icon small class="icon-bump" right>mdi-marker-cancel</v-icon></a>


            <span v-if="!EdittingRoles">
              <ul v-for="(role, idx) in chosenItem.org_roles" :key="idx">
                <li>{{role.role_name}}</li>
              </ul>
            </span>
            <span v-else>
              <ul v-for="(role, idx) in chosenItem.org_roles" :key="idx">
                <li v-if="!upForRemovalRole.includes(role.role)">{{role.role_name}} <a @click="upForRemovalRole.push(role.role)"><v-icon small class="icon-bump">mdi-delete</v-icon></a></li>
              </ul>
              <ul v-for="(role,idx) in rolesList" :key="idx">
                <li v-if="!chosenItem.roles.includes(role.str)">{{role.role_name}} <a @click="upForAdditionRole.push(role.role_value)" v-if="!upForAdditionRole.includes(role.role_value)"><v-icon small>mdi-plus-circle</v-icon></a><v-icon class="icon-bump" small v-else>mdi-check-circle</v-icon></li>
              </ul>
              <v-btn class="save-changes" rounded elevation="0" @click="sendNewRoles()">Save Changes</v-btn>
            </span>

            <span>
            <br>
            <b>Access Profiles:</b>
            <a @click="EdittingGap=true" v-if="!EdittingGap"><v-icon style="margin-bottom:.3em;" small right>mdi-marker</v-icon></a>
            <a @click="EdittingGap=false, upForRemovalGap=[], upForAdditionGap=[]" v-else><v-icon small style="margin-bottom:.3em;" right>mdi-marker-cancel</v-icon></a>
            
            <span v-if="chosenItem.profiles && !EdittingGap">
            <ul v-for="(gap, idx) in org_gaps" :key="idx">
              <li v-if="chosenItem.profiles.includes(gap.gap_uid)">{{gap.profile_name}}</li>
            </ul>
            </span>
            <span v-if="chosenItem.profiles && EdittingGap">
              <ul v-for="(gap, idx) in org_gaps" :key="idx">
                <li v-if="chosenItem.profiles.includes(gap.gap_uid) && !upForRemovalGap.includes(gap.gap_uid)">{{gap.profile_name}}<a @click="upForRemovalGap.push(gap.gap_uid)"><v-icon right small class="icon-bump">mdi-delete</v-icon></a></li>
              </ul>
              <ul v-for="(gap, idx) in org_gaps" :key="idx">
                <li v-if="!chosenItem.profiles.includes(gap.gap_uid)">{{gap.profile_name}}<a @click="upForAdditionGap.push(gap.gap_uid)" v-if="!upForAdditionGap.includes(gap.gap_uid)"><v-icon right small>mdi-plus-circle</v-icon></a><v-icon right class="icon-bump" small v-else>mdi-check-circle</v-icon></li>
              </ul>
              <v-btn class="save-changes" rounded elevation="0" @click="sendNewGaps()">Save Changes</v-btn>
            </span>


            <br>
            <div v-if="acceptPending || inviteExpired">
            <span>Invite Creation Date: {{transDateString(this.chosenItem.invite_sent_at)}}</span><br>
            <span>Invite Expiry Date: {{transDateString(this.chosenItem.invite_expire_at)}}</span>
            </div>
            <div v-if="!acceptPending && !inviteExpired">
              <b>Last Login:</b> {{transDateString(this.lastLogin)}}
            </div>
          </span>
          </span>

        </v-card-text>

        <v-card-text v-if="this.dialogTitle === 'Add User'">
          <v-form ref="addForm">
            <v-text-field style="padding:1em;" :rules="[ v => !!v || 'Name is required']" label="Add full name" v-model="name" dense></v-text-field>
            <v-text-field style="padding:1em;" :rules="emailRules" label="Add email" v-model="email" dense></v-text-field>
            <v-select style="padding:1em;" required :rules="selectRules" ref="roleRef" label="Add access role" v-model="selectedRoles" multiple item-text="role_name" item-value="role_value" :items="rolesList" dense></v-select>
            <v-select style="padding:1em;" required :rules="selectRules" ref="gapRef" label="Add Profile (GAP)" v-model="selectedGap" multiple item-text="profile_name" item-value="gap_uid" :items="org_gaps" dense></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions v-if="EdittingUser">
            <div style="line-height:40px"><br></div>
            <div >
              <v-btn v-if="this.dialogTitle === 'View User'" class="right-btn" @click="deleteOpen()" rounded elevation="0">
                <v-icon small left>mdi-account-remove</v-icon> Remove User</v-btn>
            </div>
            <div >
               <v-btn v-if="(acceptPending === true || inviteExpired) & this.dialogTitle === 'View User'"
                      rounded elevation="0" class="left-btn"  @click="inviteUser()">
                  <v-icon small left>mdi-email-sync</v-icon>Resend Invite
                </v-btn>
                <v-btn v-if="acceptPending === true & this.dialogTitle === 'View User'"
                       rounded elevation="0" class="share-btn"  @click="shareLink()" >
                  <v-icon small left>mdi-share</v-icon>Share Invite
                </v-btn>
                <span  v-if="this.copyMessage" 
                       style="font-size:.85em; position:absolute; bottom:0.1em; left:20em;">{{copyMessage}}</span>
            </div>

        </v-card-actions>

        <v-card-actions v-if="this.dialogTitle === 'Add User'">
          <div class="right-btn">
            <v-btn @click="validateInvite()" rounded elevation="0" color="#E3F2FD">Invite User <v-icon right small>mdi-email-send-outline</v-icon></v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteWarning" max-width="300">
      <v-card style="text-align:center; padding:3em 0em 3em 0em;">
        <v-card-text>Are you sure you want to remove this user?</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="deleteUser()" class="remove-btn">Remove</v-btn>
          <v-btn @click="closeDelete()" class="cancel-btn">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selfEdit" max-width="300">
      <v-card style="text-align:center; padding:3em 0em 3em 0em;">
        <v-card-text>You cannot edit your own access. Please reach out to your contacts found in the <router-link :to="{name: 'portal'}"><a @click="closeSelf()">Portal</a></router-link></v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="closeSelf()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userStatistics" max-width="700">
      <v-card style="width:700px; height:100%">
         <v-card-title>
          <v-icon
            small
            class="mr-2"
            @click="userStatistics=false"
            style="position:absolute; top:1em; right:.5em;">
            mdi-close
          </v-icon>
        </v-card-title>
        <span v-if="userStatsLoaded">
        <v-card-text>
          <v-select style="width:300px; float:right;" @change="updateActivity" dense :items="eventTypes" item-text="name" item-value="value" v-model="activityFilter"></v-select>
        </v-card-text>
        <v-card-text>
          <div style="padding-left:1em; padding-right:1em;">
            <UserActivity :parentData="monthUser" :title="'Total Monthly User Activity'" :key="monthUser" @monthEmit="monthPush" />
          </div>
        </v-card-text>

        <v-card-text style="margin-top:2em;">
          <UserLogDetails :parentData="monthUser" :parentMonth="userDetailsMonth" :key="openStat" :parentUser="openStat" />
        </v-card-text>
        </span>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import roleFunctions from '@/mixins/roleFunctions';
  import stringFunctions from '@/mixins/stringFunctions';
  import logQueries from '@/mixins/logQueries';
  import UserActivity from '@/components/AdminMgmt/UserActivity.vue';
  import UserLogDetails from '@/components/AdminMgmt/UserLogDetails.vue';


export default {
  name:'user-mgmt',
  props: {
    parentAssume: String,
    userAdmin: String,
  },
  mixins: [roleFunctions, stringFunctions, logQueries],
  components: {
    UserActivity, 
    UserLogDetails
  },
  data () {
    return {
      EdittingRoles: false,
      EdittingGap: false,
      upForRemovalRole: [],
      upForAdditionRole: [],
      upForRemovalGap: [],
      upForAdditionGap: [],

      search:'',
      userHeaders: [
        {text: 'Name', align: 'start', sortable: true, value: 'full_name', width:'10em'},
        {text: 'Email', value: 'email', sortable: false, width:'12em'},
        {text: 'Access', value: 'roles', sortable: false, width:'9em'},
        {text: 'Invite Status', value: 'invite_accepted_at', sortable: true, width:'10em'},
        {text: 'Last Login', value: 'last_login', sortable: true, width:'10em'},
       // {text: '', value: 'stats', sortable: false, width:'2em'},
        {text: '', value: 'actions', sortable: false, width:'7em'},
      ],
      rolesList: [ 
        {"role_name": "Web User Access", "role_value": "portal_user", str: 'Web User'}, 
        //{"role_name": "Web-based App Access", "role_value": "web_based_app"}, 
        {"role_name": "User Administrator", "role_value": "user_admin", str: 'User Admin' },
        {"role_name": "Billing Administrator", "role_value": "org_admin", str: 'Billing Admin' },
      ],
      eventTypes: [
        {name: 'All Event Types', value: 'all'},
        {name: 'Page Loads', value: 'page-load'},
        {name: 'Preview Logins', value: 'anon-login'},
        {name: 'Report Views', value: 'view-report'},
        {name: 'CF Object Loads', value: 'object-load'},
        {name: 'Logins', value: 'login'},
        {name: 'Logouts', value: 'logout'},
        {name: 'CF Exports', value: 'csv-download'},
        {name: 'Button Clicks', value: 'click'},
      ],
      userDetailsMonth: 'first',
      userTable: [],
      userSearch: '',
      addUserBtn: false,
      started: false,
      inviteForm: '',
      accessDisabled: false,    
      disableAccess:'', 
      userStatistics: false,
      editDialog: false,
      deleteWarning: false,
      req: false,
      dialogTitle: 'View User',
      EdittingUser: false,
      rolesPlaceholder: '',
      selectedRoles: '',
      selectedGap: '',
      name: '',
      email: '',
      disable: '',
      editName: false,
      copyMessage: null,
      nameMessage: null,
      emailMessage: null,
      roleMessage: null,
      openStat: '',
      activityFilter: {},
      acceptPending: null,
      inviteExpired: null,
      lastLogin: '',
      userStatsLoaded: false,
      monthUser: {},
      org_gaps: [],
      chosenItem: {
        full_name: '',
        editName: false,
        email: '',
        roles: '',
        invite_accepted_at: 'pending',
        invite_expire_at: '',
        invite_sent_at: '',
        invite_token: ''
      },
      selfEdit: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-mail must be valid',
      ],
      selectRules: [(v) => !!v || "Item is required"],
    }
  },

  created () {
    //console.log(this.parentAssume)
    this.getViewOrg()

    if (this.getAccess.includes('internal_admin')) {
      this.csAssume()
    }

  },

  computed: {
    ...mapGetters([
      'getAssumedOrg',
      'getAssumeToken',
      'isAdmin',
      'getAccount',
      'getCurrentOrg',
      'getAccess'
    ])
  },

  methods: {

    ...mapActions([
      'editUserRoles',
      'postAccount',
      'assumeOrg',
      'getOrgUsers',
      'deleteOrgUser',
      'getOrgs',
      'adminGetUsers',
      'getAssumedOrgUsers',
      'getOrgInfo',
      'editUserGaps'
    ]),

    getViewOrg: async function() {
      let payload = this.parentAssume
      await this.getOrgInfo(payload).then((data) => {
        this.arrangeTable(data)
      }).catch((err) => {
        console.log(err)
      })
    },

    monthPush(value) {
        this.userDetailsMonth = value
      },

    userStats(item) {
      this.userStatistics = true;
      this.openStat = item;
      console.log(item);
      this.activity(item);
      this.objectsData(item)
      this.activityFilter = 'all';
    },

    activity: async function(item) {
        let result
        result = this.monthlyEventCount(item)
        console.log(result)

        await this.queryLogs(result).then((data)=> {
          console.log(data)
          this.monthUser = data.data.log_data;
          this.userStatsLoaded = true
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },

    objectsData: async function(item) {
      let result = this.objectLoadAggregates(item, 'Dec')
      console.log(result)

        await this.queryLogs(result).then((data) => {
          console.log(data)
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
    },

    updateActivity: async function() {
        let item = this.openStat
        console.log(item)
        console.log(this.activityFilter)
        let payload = this.monthlyFilteredCount(item, this.activityFilter)
        console.log(payload)

        await this.queryLogs(payload).then((data)=> {
          console.log(data)
          this.monthUser = data.data.log_data;
        }) 
        .catch((err) => {
          console.log(err);
          console.log(err.response)
        })
      },


    //open edit/invite user dialog
    edit(item) {
      console.log("edit item",item)
      this.editDialog = true;
      this.disableAccess = false;
      this.acceptPending = false;
      this.inviteExpired = false;
      this.editName = false;
      if (item === 'new') {
        this.req = true;
        this.dialogTitle = 'Add User';
        this.rolesPlaceholder = '';
        this.selectedRoles = null;
        this.name = null;
        this.email = null;
      }
      else {
        this.chosenItem = item;
        //this.getProfileInfo(item)
        item.profiles = []
        item.org_profiles.forEach(x => { item.profiles.push(x.gap_uid) })
        
        this.req = false;
        this.rolesPlaceholder = item.roles;
        if (item.last_login_at) { this.lastLogin = item.last_login_at }
        if (item.invite_expire_at === null) { item.invite_expire_at = '2021-05-05T17:16:49.482887' }

        if (item.invite_accepted_at === 'pending') { this.acceptPending = true; }
        if (item.roles === 'Access Disabled') { this.accessDisabled = true }
        if (item.inviteExpired === true){this.inviteExpired = true}

        this.dialogTitle = 'View User';
        this.EdittingUser = true;
      }
    },

    //close edit/add dialog
    close() {
      this.editDialog = false;
      this.EdittingRoles = false;
      this.upForRemovalRole = [];
      this.upForAdditionRole = [];
      this.EdittingGap = false;
      this.upForRemovalGap = [];
      this.upForAdditionGap = [];
    },

    //save changes on user who has accepted
    saveChanges: async function() {
      let roles;
      let idToken;
      let sendOrg;

      if (this.selectedRoles) { roles = this.repackRoleValues(this.selectedRoles) }
      if (this.disableAccess) {roles = '';  console.log(roles)}
      if (!this.selectedRoles & !this.disableAccess) { roles =  this.translateChosenItem(this.chosenItem.roles)}
      if (this.disableAccess) {this.disabledAccess = true}
      
      if (this.isAdmin) {idToken = this.getAssumeToken; sendOrg = this.parentAssume }
      else {idToken = this.getAccount.token; sendOrg = this.getCurrentOrg.company_uid}
      
      const payload = {
        uid: this.chosenItem.account_uid,
        roles: roles,
        org: sendOrg,
        token: idToken,
        profiles: this.chosenItem.org_profiles
      }
      await this.editUserRoles(payload)
      .then((data) => {
        console.log(data)
        })
      .catch((err) => {
        if (err.response) {
          console.log(err.response)
          if (err.response.data.status == 400) {
            this.selfEdit = true
          }
        }
      })
      if (this.isAdmin) { this.getViewOrg() }
      else { this.static() }
      this.close()
    },

    sendNewRoles:async function() {
      console.log('send new roles')
      let roles = []
      console.log(this.chosenItem)
      for (const i in this.chosenItem.org_roles) {
        if (!this.upForRemovalRole.includes(this.chosenItem.org_roles[i].role)) {
          roles.push({role: this.chosenItem.org_roles[i].role})
        }
      }
      this.upForAdditionRole.forEach(x => {
        if (!roles.includes(x)) {
          roles.push({role: x})
        }
      })

      let gaps = []
      if (this.chosenItem.profiles.length) {
        this.chosenItem.org_profiles.forEach(x => {
        gaps.push({gap_uid: x.gap_uid})
      })
      }
      

      let idToken
      let sendOrg

      if (this.isAdmin) {idToken = this.getAssumeToken; sendOrg = this.parentAssume }
      else {idToken = this.getAccount.token; sendOrg = this.getCurrentOrg.company_uid}
      
      let payload = {
        uid: this.chosenItem.account_uid,
        roles: roles,
        org: sendOrg,
        token: idToken,
        profiles: gaps
      }
      console.log(payload)

      await this.editUserRoles(payload).then((res) => {
        console.log(res);
        this.EdittingRoles = false;
        this.EdittingGap= false;
        this.editDialog =false;
        this.upForRemovalRole = [];
        this.upForAdditionRole = [];
        this.getViewOrg()

      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status == 400) {
            this.selfEdit = true
          }
        })
    },

    sendNewGaps: async function() {
      let gaps = []
      for (const i in this.chosenItem.org_profiles) {
        console.log(this.chosenItem.org_profiles[i])
        if (!this.upForRemovalGap.includes(this.chosenItem.org_profiles[i].gap_uid)) {
          gaps.push({gap_uid: this.chosenItem.org_profiles[i].gap_uid})
        }
      }
      this.upForAdditionGap.forEach(x => {
        if (!gaps.includes(x)) {
          gaps.push({gap_uid: x})
        }
      })

      let idToken
      let sendOrg

      let roles = []
      if (this.chosenItem.org_roles) {
        this.chosenItem.org_roles.forEach(x => {
          roles.push({role: x.role})
        })
      }
      if (this.isAdmin) {idToken = this.getAssumeToken; sendOrg = this.parentAssume }
      else {idToken = this.getAccount.token; sendOrg = this.getCurrentOrg.company_uid}
      
      let payload = {
        uid: this.chosenItem.account_uid,
        profiles: gaps,
        org: sendOrg,
        token: idToken,
        roles: roles

      }
      console.log(payload)

      await this.editUserGaps(payload).then((res) => {
        console.log(res);
        this.EdittingGap = false;
        this.editDialog =false;
        this.upForRemovalGap = [];
        this.upForAdditionGap = [];
        this.getViewOrg()

      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status == 400) {
            this.selfEdit = true
          }
        })
    },


    validateInvite() {
      if (this.name === 'Add Name' || !this.name) {this.nameMessage = 'Name is Required.'} else {this.nameMessage = null}
      if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email))) {this.emailMessage = 'Input a Valid Email.'} else {this.emailMessage = null}
      if (!this.selectedRoles) {this.roleMessage = 'Must Select At Lease One Role.'} else {this.roleMessage = null}
      if (this.nameMessage === null & this.emailMessage === null & this.roleMessage === null) {
        this.emailMessage = ' '
        this.nameMessage = ' '
        this.roleMessage = ' '
        this.inviteUser()
      }
    },

    inviteUser: async function () {
      let roles_arr = [];
      let gap_arr = []
      let inviteEmail;
      let inviteName;
      let sendToken;
      let sendOrg;

      // need to check for length > 0, otherwise if condition passes as true
      if (this.selectedRoles.length > 0) { roles_arr = this.repackRoleValues(this.selectedRoles) }
      else { roles_arr = this.translateChosenItem(this.chosenItem.roles) }

      if (this.selectedGap.length > 0) { gap_arr = this.sendPackGaps(this.selectedGap) }
      else { gap_arr = this.sendPackGaps(this.chosenItem.profiles) }

      if (this.name) { inviteName = this.name }
      else { inviteName = this.chosenItem.full_name }

      if (this.email) { inviteEmail = this.email }
      else { inviteEmail = this.chosenItem.email }

      if (this.isAdmin) { sendToken = this.getAssumeToken; sendOrg = this.getAssumedOrg }
      else { sendToken = this.getAccount.token; sendOrg = this.getCurrentOrg.company_uid }
    
      const payload = {
        email: inviteEmail,
        name: inviteName,
        roles: roles_arr,
        profiles: gap_arr,
        uid: sendOrg,
        token: sendToken
      }
      console.log(payload)
      await this.postAccount(payload)
      .then((data) => { console.log(data) })
      .catch((err) => {
        console.log(err);
        if (err.response) { 
          console.log(err.response)
        }
      })
      this.close()
      if (this.isAdmin) { this.getViewOrg() }
      else { this.static() }
      this.$refs.addForm.reset()
    },

    //open delete dialog
    deleteOpen() {
      this.deleteWarning = true;
    },

    //close delete dialog
    closeDelete() {
      this.deleteWarning = false;
    },

    //close edit-self warning dialog
    closeSelf() {
      this.selfEdit = false;
    },

    deleteUser: async function() {
      let sendToken;

      if (this.isAdmin) { sendToken = this.getAssumeToken; }
      else { sendToken = this.getAccount.token; }
  
      const payload = {
        uid: this.chosenItem.account_uid,
        org_uid: this.chosenItem.organization_uid,
        token: sendToken
      }
      await this.deleteOrgUser(payload)
      .then((data) => { console.log(data) })
      .catch((err) => { console.log(err) })

      if (this.isAdmin) { this.getViewOrg() }
      else { this.static() }
      this.closeDelete()
      this.close()
    },

    csAssume: async function () {
      if (this.parentAssume != '') {
        let payload = this.parentAssume;
        await this.assumeOrg(payload)
        .then((data) => {
          console.log(data)
          })
        .catch((err) => {
          console.log(err.response.status)
          if (err.response.status == 400) {
            this.$emit('error', 400);
          }
        })
      }
    },

      
    arrangeTable(data) {
      console.log(data)
      if (data.data.organization) {

        this.org_gaps = []
        for (const g in data.data.organization.org_profiles) {
          this.org_gaps.push(data.data.organization.org_profiles[g])
        }
        console.log(this.org_gaps)
        this.userTable = data.data.organization.accounts
        console.log(this.userTable)
        this.$emit('loaded', false);
        for (let item in this.userTable) {
          if (isNaN(item) == false) {
            this.userTable[item].roles = this.presentRoleNames(this.unpackRoleNames(this.userTable[item].org_roles))
            if (this.userTable[item].roles == '') {
              this.userTable[item].roles = 'Access Disabled'
            }
            if (!this.userTable[item].org_profiles) {
              this.userTable[item].roles = 'No Entitlements'
            }
            if (this.userTable[item].invite_accepted_at !== null) {
              this.userTable[item].last_login = this.transDateISO(this.userTable[item].last_login_at)
            }
            // check if invite is expired and set invite_accepted_at in ISO date format
            if (this.userTable[item].invite_accepted_at == null && this.userTable[item].invite_expire_at !== null) {
              this.userTable[item].invite_accepted_at = (Date.parse(this.userTable[item].invite_expire_at) > Date.now()) ? 'pending' : 'expired'
              this.userTable[item].inviteExpired = true
            }
            else {
              this.userTable[item].invite_accepted_at = this.transDateISO(this.userTable[item].invite_accepted_at)
            }
          }
        }
        this.started = true;
      }
    },
    shareLink(){
      navigator.clipboard.writeText(this.chosenItem.invite_token);
      this.copyMessage = 'Link copied to clipboard!';
      setTimeout(() => {this.copyMessage = null}, 1600);

    }
    
  }
}
</script>

<style scoped>
.icon-bump {
  margin-bottom:.2em;
}

.create-btn {
  position:absolute; 
  right:3em; 
  background-color:#E3F2FD !important; 
  border-radius:25px;
}

.save-changes {
  float:right; 
  background-color:#E3F2FD !important;
  bottom:1.5em;
}
.save-changes:hover {
  position:relative;
  bottom:1.7em;
}

.remove-btn {
  position: relative;
  bottom:1.5em;
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}

.remove-btn:hover {
  bottom:1.7em;
}

.share-btn {
  position: absolute;
  bottom:1.5em;
  left: 13em;
  background-color:#ECEFF1;
}

.share-btn:hover {
  bottom:1.7em;
}

.cancel-btn {
  position: relative;
  bottom:1.5em;
}

.cancel-btn:hover {
  bottom:1.7em;
}

#ex {
    position: absolute;
    top:.3em;
    right:.6em;
    color: black;
  }

  .red-text {
    color:red;
  }

  .inviteFormItems {
    position: relative;
    bottom:2em;
  }

  .right-btn {
    position:absolute;
    right:1.5em; 
    bottom:1.5em;
    background-color:#ECEFF1;
  }

  .right-btn:hover {
    bottom:1.7em;
  }
  
  .left-btn {
    position:absolute;
    left:1.5em; 
    bottom:1.5em;
    background-color:#ECEFF1;
  }
  .left-btn:hover {
    bottom:1.7em;
  }

  table {
    border-spacing: 2em;
  }
</style>
