<template>
  <div style="padding: 2em">
    <v-card v-if="info" elevation="0">
      <v-card-text style="text-align: left">
        <span><b>Name:</b> {{ info.name }}</span
        ><br />
        <span><b>Date Created:</b> {{ transDateString(info.created_at) }}</span
        ><br />
        <span><b>UID:</b> {{ info.organization_uid }}</span>
      </v-card-text>
      <v-card-text style="text-align: left">
        <b>Available Roles:</b>
        <v-icon
          v-if="getAccess.includes('internal_admin')"
          @click="edit(info)"
          style="margin-bottom: 0.3em"
          small
          >mdi-pencil</v-icon
        ><br /><br />
        <!-- {{presentRoleNames(Object.keys(info.org_roles))}} -->
        <span v-for="(item, idx) in info.org_roles" :key="idx">
          <b>{{ item.role_name }}</b>
          <a @click="autoR(item)"
            ><v-icon
              v-if="item.is_auto_assigned"
              right
              small
              style="margin-bottom: 0.2em; color: #ffb300"
              >mdi-star</v-icon
            ></a
          >
          <a @click="autoA(item)"
            ><v-icon
              v-if="!item.is_auto_assigned"
              right
              small
              style="margin-bottom: 0.2em; color: #bdbdbd"
              >mdi-star</v-icon
            ></a
          >
          <br />
          {{ item.role_description }}<br /><br />
        </span>
      </v-card-text>
    </v-card>

    <v-dialog v-model="editRoles" max-width="700">
      <v-card style="width: 700px; height: 200px; padding: 4em">
        <v-card-text>
          <v-select
            v-model="orgRoles"
            :items="rolesArray"
            item-text="name"
            item-value="role"
            :rules="nameRules"
            label="Available roles"
            required
            multiple
          >
          </v-select>
          <div>
            <v-btn
              class="create-btn"
              elevation="0"
              style="
                background-color: #e3f2fd;
                float: right;
                border-radius: 25px;
                margin-left: 0em;
              "
              @click="confirm(info)"
              >Save Changes</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="autoDialog" width="600">
      <v-card style="width: 600px; height: 200px">
        <v-card-text></v-card-text>
        <span
          v-if="
            dialogItem.is_auto_assigned === false ||
            dialogItem.is_auto_assigned === null
          "
        >
          <v-card-text style="text-align: center">
            Auto assign this role to all registering users?
          </v-card-text>
          <v-card-text style="text-align: center">
            <v-btn
              elevation="0"
              class="bounce"
              rounded
              style="margin: 1em; background-color: #e0e0e0"
              @click="autoDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              elevation="0"
              class="bounce"
              rounded
              style="margin: 1em; background-color: #bbdefb"
              @click="aY"
              >Yes</v-btn
            >
          </v-card-text>
        </span>
        <span v-else>
          <v-card-text style="text-align: center">
            Remove auto assign from this role?
          </v-card-text>
          <v-card-text style="text-align: center">
            <v-btn
              elevation="0"
              class="bounce"
              rounded
              style="margin: 1em; background-color: #e0e0e0"
              @click="autoDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              elevation="0"
              class="bounce"
              rounded
              style="margin: 1em; background-color: #bbdefb"
              @click="bY"
              >Yes</v-btn
            >
          </v-card-text>
        </span>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import stringFunctions from "@/mixins/stringFunctions";
import roleFunctions from "@/mixins/roleFunctions";

export default {
  name: "general-org-settings",
  mixins: [stringFunctions, roleFunctions],
  data() {
    return {
      info: {},
      editRoles: false,
      orgRoles: "",
      dialogItem: "",
      autoDialog: false,
      rolesArray: [
        {
          role: "portal_user",
          name: "Web Access",
          description: "Able to access web properties.",
        },
        {
          role: "org_admin",
          name: "Billing Administrator Access",
          description:
            "External user with ability to manage organization settings and access the web properites.",
        },
        {
          role: "user_admin",
          name: "User Administrator Access",
          description:
            "External user with ability to manage org users and access the web properites.",
        },
      ],
      nameRules: [(v) => !!v || "Field is required"],
    };
  },
  props: {
    parentData: String,
  },
  created() {
    console.log(this.parentData);
    this.getViewOrg();
    //this.logs()
  },
  computed: {
    ...mapGetters(["getAccess"]),
  },
  methods: {
    ...mapActions(["getOrgInfo", "editOrganization"]),

    getViewOrg: async function () {
      let payload = this.parentData;
      //console.log(payload)
      await this.getOrgInfo(payload)
        .then((data) => {
          console.log(data);
          this.info = data.data.organization;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    edit(info) {
      this.editRoles = true;
      this.orgRoles = Object.keys(info.org_roles);
    },

    confirm: async function (info) {
      console.log("new", this.orgRoles);
      console.log("old", info);

      let array = [];

      if (!info.orgRoles){ // org doesn't have any roles
         // all new roles should be added
         for (const a in this.orgRoles) {
           array.push({ role: this.orgRoles[a] });
        }
      }

      else {
         for (const i in Object.keys(info.org_roles)) {
          if (this.orgRoles.includes(Object.keys(info.org_roles)[i])) {
            console.log("match", Object.keys(info.org_roles)[i]);
          } else {
            //means it needs to be removed
            console.log("no-match", Object.keys(info.org_roles)[i]);
            array.push({
              role: Object.keys(info.org_roles)[i],
              remove: true,
            });
          }
        }

        for (const a in this.orgRoles) {
          if (Object.keys(info.org_roles).includes(this.orgRoles[a])) {
            console.log("have", this.orgRoles[a]);
          } else {
            //means needs to add
            console.log("no have", this.orgRoles[a]);
            array.push({ role: this.orgRoles[a] });
          }
        }
      }

      let payload = {
        name: info.name,
        organization_uid: info.organization_uid,
        roles: array,
      };
      console.log(payload);

      await this.editOrganization(payload)
        .then((data) => {
          console.log(data);
          this.info = data.data.organization;
          this.editRoles = false;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },

    autoR(item) {
      console.log(item);
      this.autoDialog = true;
      this.dialogItem = item;
    },
    autoA(item) {
      console.log(item);
      this.autoDialog = true;
      this.dialogItem = item;
    },

    aY: async function () {
      let roles = [];
      let auto;
      for (const i in this.info.org_roles) {
        if (this.info.org_roles[i].role == this.dialogItem.role) {
          auto = true;
        } else {
          auto = this.info.org_roles[i].is_auto_assigned;
        }
        roles.push({
          role: this.info.org_roles[i].role,
          is_auto_assigned: auto,
        });
      }

      let payload = {
        name: this.info.name,
        organization_uid: this.info.organization_uid,
        roles: roles,
      };
      await this.editOrganization(payload)
        .then((data) => {
          this.info = data.data.organization;
        })
        .catch((err) => {
          console.log(err);
        });
      this.autoDialog = false;
    },

    bY: async function () {
      let roles = [];
      let auto;

      for (const i in this.info.org_roles) {
        if (this.info.org_roles[i].role == this.dialogItem.role) {
          auto = false;
        } else {
          auto = this.info.org_roles[i].is_auto_assigned;
        }
        roles.push({
          role: this.info.org_roles[i].role,
          is_auto_assigned: auto,
        });
      }

      let payload = {
        name: this.info.name,
        organization_uid: this.info.organization_uid,
        roles: roles,
      };
      await this.editOrganization(payload)
        .then((data) => {
          this.info = data.data.organization;
        })
        .catch((err) => {
          console.log(err);
        });
      this.autoDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-btn:hover {
  position: relative;
  bottom: 0.2em;
}

.bounce:hover {
  position: relative;
  bottom: 0.2em;
}
</style>
