import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticStyle:{"background-color":"transparent"},attrs:{"elevation":"0"}},[_c('router-link',{attrs:{"to":{name: 'Home'}}},[_c('img',{staticStyle:{"margin-top":".6em"},attrs:{"src":require("@/assets/logo_navyL.png"),"width":"190","id":"logo"}})]),_c(VSpacer),_c('router-link',{attrs:{"to":{name: 'ObjectSearch'}}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"#333"},attrs:{"elevation":"0"}},[_vm._v("CF Home")])],1),(!_vm.getLoggedUser.anon)?_c('Bookmarks',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"#333"},attrs:{"elevation":"0"},on:{"click":function($event){return _vm.begin(open)}}},[_vm._v("Bookmarks ")])]}}],null,false,3114827847),model:{value:(_vm.bookmarksWrap),callback:function ($$v) {_vm.bookmarksWrap=$$v},expression:"bookmarksWrap"}}):_vm._e(),_c('router-link',{attrs:{"to":{path: 'help?page=general'}}},[_c(VBtn,{staticClass:"btn-hover",staticStyle:{"background-color":"transparent","color":"#333"},attrs:{"elevation":"0"}},[_vm._v("FAQ")])],1),_c(VMenu,{staticStyle:{"max-width":"600px"},attrs:{"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"btn-hover",staticStyle:{"background-color":"transparent","margin":"0em 10em 0em 0em","color":"#333"},attrs:{"id":"no-background-hover","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v("Account ")])]}}]),model:{value:(_vm.accountMenu),callback:function ($$v) {_vm.accountMenu=$$v},expression:"accountMenu"}},[_c(VList,[(!_vm.getLoggedUser.anon)?_c(VListItem,{attrs:{"link":""}},[_c('settingsEditor',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('a',{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.start(open)}}},[_vm._v("My Account")])]}}],null,false,3995101080),model:{value:(_vm.settingsWrap),callback:function ($$v) {_vm.settingsWrap=$$v},expression:"settingsWrap"}})],1):_c(VListItem,[_c('GapSettings',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('a',{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.start(open)}}},[_vm._v("Account Settings")])]}}]),model:{value:(_vm.settingsWrap),callback:function ($$v) {_vm.settingsWrap=$$v},expression:"settingsWrap"}})],1),_c(VListItem,[_c('a',{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.loggingOff()}}},[_vm._v("Logout")])])],1)],1)],1),(!_vm.loaded)?_c('span',[_c(VRow,{staticStyle:{"padding-top":"3em"},attrs:{"justify":"center"}},[_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1):_c('span',[_c(VContainer,{staticStyle:{"padding-top":"2em"}},[(_vm.errorMsg==='')?_c('div',[_c('div',{staticStyle:{"float":"right"}},[_c(VBtn,{staticClass:"back",attrs:{"rounded":"","elevation":"0"},on:{"click":function($event){return _vm.uidsToClipboard()}}},[_vm._v(_vm._s(_vm.copyMessage))])],1),_c(VDataTable,{staticStyle:{"width":"75vw","padding-top":"2em"},attrs:{"items":_vm.listItems,"headers":_vm.headers,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"color":"#333"},attrs:{"tag":"a","target":"_blank","to":_vm.getObjectPath(item)}},[_c(VTooltip,{attrs:{"light":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticStyle:{"position":"relative","bottom":".1em","padding-right":".3em"},style:({color: item.color}),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-square-rounded")])]}}],null,true)},[(item.desc_name)?_c('span',[_vm._v(_vm._s(item.desc_name))]):_c('span',[_vm._v(_vm._s(item.type))])]),_vm._v(" "+_vm._s(item.name)+" "),(item.opacity == .4)?_c('span',{staticStyle:{"font-size":".8em"}},[_vm._v(" (Insufficient Data)")]):_vm._e()],1)]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_c(VImg,{staticStyle:{"margin":".4em"},style:({opacity: item.opacity}),attrs:{"max-width":"50px","max-height":"60px","src":item.logo,"alt":item.name}})],1)]}},{key:"item.object_uid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.uidsToClipboard(item.object_uid)}}},[_vm._v("mdi-content-copy")])],1)]}}],null,false,4256209405)})],1):_c('div',[_c(VRow,{attrs:{"justify":"center"}},[_c('span',{staticStyle:{"font-size":"20px","color":"#37474F","position":"relative","top":"5em"}},[_vm._v(" "+_vm._s(this.errorMsg)+" ")])]),_c(VRow,{attrs:{"justify":"center"}},[_c('span',{staticStyle:{"font-size":"20px","color":"#37474F","position":"relative","top":"8em"}},[_vm._v(" You can try searching for a new object. ")])]),_c(VRow,{staticStyle:{"position":"relative","top":"13em"},attrs:{"justify":"center"}},[_c('router-link',{attrs:{"to":{name: 'ObjectSearch'}}},[_c(VBtn,{staticClass:"back",attrs:{"rounded":"","elevation":"0"}},[_vm._v("Go to Search")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }