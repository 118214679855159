<template>
    <div>
      <v-container fluid class="banner2">
        <div style="width:80%;">
          <h3 class="banner-text">Social Standards' goal is to unlock the full potential of <span class="ss_blue">collective social intelligence.</span></h3>
        </div>
      </v-container> 

      <v-container class="aboutusfull">
        <v-layout row wrap>
          <v-flex xs12 sm12 class="flex_col" hidden-md-and-up>
              <v-img max-width="200" class="center" src="@/assets/images/lap.png" alt="laptop graphic" />
          </v-flex>

          <v-flex xs12 sm12 md8 lg8 style="padding:1em;">
              <h2 class="passion">Consumer data is our passion.</h2>
              <p class="item-text-left">Social Standards aims to revolutionize market analytics by transforming social data into strategic insights, enabling agile decision-making for CPG companies and investors.</p>
              <p class="item-text-left">We are a tech company headquartered in Oakland, CA with an office in NYC, made up of a diverse, multi-national staff of professionals with backgrounds in tech, consulting, strategy, investment banking and market research.</p>
              <p class="item-text-left">We were founded in late-2015 and are most recently backed by TPG Growth and e.l.f. Cosmetics, along with early investments from the Band of Angels and the NY Angels.</p>
          </v-flex>
          <v-flex sm4 md4 lg4 class="flex_col" style="padding-left:4em;" hidden-sm-and-down>
              <v-img style="width:80%;" src="@/assets/images/lap.png" alt="laptop graphic" />
          </v-flex>

          <v-container class="partners">
            <v-flex sm12 md12 lg12>
                <h2 id="partnerships" class="item_headings">Partnerships</h2>
                <p class="item-text-center">Social Standards is a proud member of Nielsen's connected partner program. We also partner with a variety of consultancy and market research firms to provide data to clients. We know strong data analytics practices depend on multiple data sources and are always looking to partner with companies in our field.</p>
            </v-flex>

            <v-flex sm12 md12 lg12 >
                <router-link :to="{name:'contact'}"><p class="btn center ss_blue orange_hover btn-txt">Partner with us</p></router-link>
            </v-flex>

            <v-flex sm12 md12 lg12>
                <h2 class="item_headings">Our Culture</h2>
                <p class="item-text-center">At Social Standards, every member of the team is encouraged to wear multiple hats. We operate in a lean startup environment where creativity and experimentation is encouraged. </p>
            </v-flex>

            <v-flex sm12 md12 lg12 >
                <a href="https://www.linkedin.com/company/social-standards/" target="_blank"><p class="btn center ss_blue info-btn orange_hover btn-txt">Work with us</p></a>
            </v-flex>
          </v-container>

        </v-layout>
    </v-container>
  </div>
</template>

<script>
import processingMixin from '@/mixins/processingMixin';

export default {
	name:'about-page',
	mixins: [processingMixin],
	created() {
		this.logPageLoad({
			app_name: 'S',
			page_name: this.$options.name,
			})
	},
	methods: {
		validate () {
			if (this.$refs.form.validate()) {
				this.snackbar = true
			}
		},
		}
	}
</script>

<style lang="scss" scoped>
	.page_title {
		font-size:2.5em;
		font-weight:400;
		text-align:center;
		color:white;
		padding-bottom:.2em;
	}

	.item_headings {
		font-size:2em;
		text-align:center;
		padding-top:1.5em;
		padding-bottom:.5em;
		text-align:center;
	}

	.passion {
		font-size:2em;
		text-align:center;
		padding-top:1.5em;
		padding-bottom:.5em;
		text-align:left;
	}

	%item-text {
		font-size:1.3em;
		padding-bottom:.8em;
	}

	.item-text-center {
		@extend %item-text;
		text-align: center;
	}

	.item-text-left {
		@extend %item-text;
		text-align: left;
	}

	.partners {
		width:60%;
	}

	.btn-txt {
		font-size:1em;
	}

	.aboutusfull {
		padding-top:2em;
	}

	@media only screen and (min-width: 1904px){
		.aboutusfull {
			width:80%;
		}
	}

	@media only screen and (max-width: 960px) {
		.banner3 {
			height:20vh;
		}
		.page_title {
			text-align:center;
		}
		.item_text {
			text-align:center;
		}
		.partners {
			width:90%;
		}
		.passion {
		text-align:center;
		}
	}
</style>