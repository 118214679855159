export default {
    methods: {
        filteredWorkProducts: function (data, selector, search) {
            //either this.workProducts or this.publicWorkProducts
            let works = data


            // function to de-duplicate the .concat()
            Array.prototype.unique = function () {
                var a = this.concat();
                for (var i = 0; i < a.length; ++i) {
                    for (var j = i + 1; j < a.length; ++j) {
                        if (a[i] === a[j])
                            a.splice(j--, 1);
                    }
                }
                return a;
            };

            // filter works out where description is null
            let notNullDes
            if (works.constructor === Array){
                notNullDes = works.filter(x => {
                    return x.description !== null
                });
            }
            //else {notNullDes=[]}
            else {return 'not initiated'}

            // set up the return value
            var filtered = notNullDes;

            //if both
            if (selector && search) {

                let preSearch = searchForVertical()

                //take list of reports with filtered verticals (preSearched) and search through their name and description
                let descFiltered = preSearch.filter(p => p.description.toLowerCase().includes(search.toLowerCase()));
                let nameFiltered = preSearch.filter(p => p.name.toLowerCase().includes(search.toLowerCase()));

                //return results
                filtered = descFiltered.concat(nameFiltered).unique();
            }
            else if (selector) { filtered = searchForVertical() } //if selecting vertical
            else if (search) { filtered = searchForText() } //if using search

            let filtered1 = filtered.filter(x => x.category_name !== 'Drafts')

            return filtered1.length > 0 ? filtered1 : ''


            function hasSelectedDomain(product) {
                for (let i = 0; i < product.subjects.length; i++) {
                    if (product.subjects[i].subjectSID === selector) return true
                }
                return false
            }

            function searchForVertical(){
                // reports with subjects
                let notNullSub = notNullDes.filter(x => { return x.subjects !== null })
                return notNullSub.filter(hasSelectedDomain).unique()
            }

            function searchForText(){
                // set up two filters (one for name, and one for description)
                let descFiltered = notNullDes.filter(p => p.description.toLowerCase().includes(search.toLowerCase()));
                let nameFiltered = notNullDes.filter(p => p.name.toLowerCase().includes(search.toLowerCase()));
                // combine them
                return descFiltered.concat(nameFiltered).unique();
            }

        },
    }
}
