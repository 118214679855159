<template>
  <div>
    <apexchart width="320" type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: 'geo',
  props: {
    parentObj: Object,
    parentDomain: Number,
  },

  data() {
    return {
      percent: {},
      percentile: {},

      series: [],
      chartOptions: {
        labels: [],
        colors: ['#274b69', '#3f4d63', '#85a1c1', '#95724B', '#C1A585', '#BDBDBD'],
        chart: {
          type: 'donut',
        },
        dataLabels: {
            enabled: false
            },
            tooltip: {
          enabled: true,
          theme: 'dark',
          y: {
            formatter: function formatter(val) {
              return (val).toFixed(1) + '%'},
          }
        },
        legend: {
          show: true,
          offsetY: -5,
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
            
        responsive: [{
          breakpoint: '1000',
          options: {
            chart: {
              width: 320
            },
            legend: {
              show: true,
              width: 200,
              offsetX: 40,
              position: 'bottom',
              colors: ['#20246F', '#40458A', '#9FA8DA', '#C28BB5', '#BD4B80', '#BDBDBD'],
            }
          }
        }]
      },
    }
  },

  created() {
    this.createVars(this.parentObj, this.parentDomain)
  },

  methods: {

    createVars(data, domain) {
      let set = data.us_state.domains[domain].top_percent
      let total = 0;
      let arr= []
      let labels = []
      for (const i in set) {
        if (!isNaN(i)) {
          arr.push(set[i].percent)
          labels.push(set[i].state)
          total += set[i].percent
        }
      }
        arr.push(100-total);
        labels.push('Other')
      this.series = arr
      this.chartOptions.labels = labels

      this.chartOptions.tooltip = {
         y: {
            formatter: function(value, opts ) {
              if (value != 100-total) {
                let item = data.global_region.domains[domain].percent[opts.dataPointIndex]
              return 'raw: '+ (value).toFixed(1) + '%' + '<br>' + 'percentile: ' + item.percentile + 'th'
              }
              else {
                return 'raw: '+ (value).toFixed(1) + '%'
              }
              },
            
            title: {
              formatter: function (seriesName) {
                return '  '+seriesName+':' + '<br>'
              }}
         }
      }
         
    },
  }
}
</script>
