<template>
  <v-container style="text-align:center;">
    <v-img class="robo-img" src="@/assets/images/robotA.png" width=300px /><br><br>

    <h1>{{ errorMsg }}.</h1>

    <h1>Click <router-link :to="{name:'Home'}" style="color:#71c6e6">here</router-link> to return to the Home page.</h1><br>
    <h2>Need help?<a style="color:#71c6e6" @click="sendMail('product@socialstandards.com')"> Contact us.</a></h2>
    
  </v-container>
</template>

<script>
  import processingMixin from '@/mixins/processingMixin';

  export default {
    name:'error-page',
    mixins: [processingMixin],
    data () {
      return {
        errorMsg: null,
      }
    },


    mounted () {
      this.determineErrorMsg();

      this.logPageLoad({
        app_name: 'P',
        page_name: this.$options.name,
        params: this.$route.params.statusCode
      })
    },

    methods: {
      sendMail (email) {
        window.open('mailto:'+ email, '_self')
      },

      determineErrorMsg () {
        // check the statusCode. particular messages for a status code can be defined here.
        // or it will default to the 404 message if no param is supplied.
        
        switch (this.$route.params.statusCode) {
          case '403':
            this.errorMsg = 'Access to the requested page has been declined';
            break;

          case undefined:
          case '404':
            this.errorMsg = 'Oops! We can\'t seem to find the page you\'re looking for';
            break;
        }
      }
    }

  }
</script>

<style>
  .four {
    font-size:140px;
  }
  .robo-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
</style>>