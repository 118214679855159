<template>
    <v-footer class="footer">
    <v-layout justify-space-around col wrap style="padding-top:1em; padding-bottom:1em;">

        <v-flex md2 lg2>
        </v-flex>

        <v-flex md4 lg3 class="flex_col">
            <ul style="list-style-type:none">
              <router-link :to="{name:'contact'}"><li><a class="list-items">Contact</a></li></router-link>
              <router-link :to="{name:'career-page'}"><li><a class="list-items">Careers</a></li></router-link>
              <router-link :to="{name:'about', hash:'#partnerships' }"><li><a class="list-items">Partnerships</a></li></router-link>
              <router-link to="Policy"><li><a class="list-items">Privacy Policy</a></li></router-link>
              <router-link to="/Policy#cookies"><li><a class="list-items">Cookies</a></li></router-link>
            </ul>
        </v-flex>

        <v-flex md2 lg1 >
        </v-flex>

        <v-flex md3 lg3 class="flex_col" style="color:white; font-size:1.1em; text-align: center; padding-top:1em;">
            <p>New York | Oakland | Los Angeles<br>
            </p>

             &copy; {{ new Date().getFullYear() }} Social Standards
        </v-flex>

        <v-flex md1 lg2>
        </v-flex>

    </v-layout>

    </v-footer>
</template>
<script>

export default {
    name:'Footer',
    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
  },
}
</script>
<style>
.footer {
    background-color: #131737 !important;
    font-weight:300;
    height:100%;
    
}
.list-items {
    color:white !important;
    font-size:1.2em;
    
}
.list-items:hover {
     color: #71C6E6 !important;
}
li {
    padding:.2em;
}
</style>