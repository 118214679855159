<template>
  <div>
    <!-- 403 error -->
    <v-container v-if="status == 403">
      <v-row justify="center">
        <span style="font-size:100px; color:#1a204c;">403 <span style="font-size:200px; color:#1a204c;">|</span></span>
        <span style="font-size:30px; color:#1a204c; position:relative; top:4.5em;">
          You do not have<br> rights to this page.
        </span>
      </v-row>
      <v-row justify="center" style="padding-top:4em; text-align:center;">
         <span style="font-size:20px; color:#1a204c; position:relative; top:0em;">
           Please reach out to your organization manager, <br>
           or contact Social Standards for help.
         </span>
      </v-row>
      <v-row justify="center" style="padding-top:3em; padding-bottom:3em;">
        <!-- <router-link :to="{name: 'Home'}"> -->
        <v-btn @click="back()" rounded elevation="0" class="back" style="background-color:#ecedf8;"><v-icon x-small style="margin-top:.5em; margin-right:.3em;">mdi-keyboard-return</v-icon>Go back</v-btn>
        <!-- </router-link> -->
      </v-row>
    </v-container>

    <!-- 429 error -->
    <v-container v-else-if="status == 429">
      <v-row justify="center" >
        <span style="font-size:100px; color:#1a204c;">429 <span style="font-size:200px; color:#1a204c;">|</span></span>
        <span style="font-size:30px; color:#1a204c; position:relative; top:4.5em;">
          You have exceeded your<br> API call limit.
        </span>
      </v-row>
      <v-row justify="center" style="padding-top:4em; text-align:center;">
         <span style="font-size:20px; color:#1a204c; position:relative; top:0em;">Please wait until your access has been reinstated.</span>
      </v-row>
      <v-row justify="center" style="padding-top:3em; padding-bottom:3em;">
        <router-link :to="{name: 'client-home'}"><v-btn rounded elevation="0" class="back" style="background-color:#ecedf8;"><v-icon x-small style="margin-top:.5em; margin-right:.3em;">mdi-keyboard-return</v-icon>Return Home</v-btn></router-link>
      </v-row>
    </v-container>

    <!-- error 402 -->
    <v-container v-else-if="status == 402">
      <v-row justify="center">
        <span style="font-size:100px; color:#1a204c;">402 <span style="font-size:200px; color:#1a204c;">|</span></span>
        <span style="font-size:30px; color:#1a204c; position:relative; top:4.5em;">
          You do not have access to this domain. 
        </span>
      </v-row>
      <v-row justify="center" style="padding-top:3em; padding-bottom:3em;">
        <router-link :to="{name: 'ObjectSearch'}"><v-btn rounded elevation="0" class="back" style="background-color:#ecedf8;"><v-icon x-small style="margin-top:.5em; margin-right:.3em;">mdi-keyboard-return</v-icon>return to search</v-btn></router-link>
      </v-row>
    </v-container>

    <!-- error 405 -->
    <v-container v-else-if="status == 405">
      <v-row justify="center">
        <span style="font-size:100px; color:#1a204c;">405 <span style="font-size:200px; color:#1a204c;">|</span></span>
        <span style="font-size:30px; color:#1a204c; position:relative; top:4.5em;">
         You haven't been given <br> any entitlements yet. <br>
        </span>
      </v-row>

      <v-row justify="center" style="padding-top:4em; text-align:center;">
         <span style="font-size:20px; color:#1a204c; position:relative; top:0em;">
           Please reach out to your organization manager, <br>
           or contact Social Standards for help.
         </span>
      </v-row>
      <v-row justify="center" style="padding-top:3em; padding-bottom:3em;">
        <!-- <router-link :to="{name: 'Home'}"> -->
        <v-btn @click="back()" rounded elevation="0" class="back" style="background-color:#ecedf8;"><v-icon x-small style="margin-top:.5em; margin-right:.3em;">mdi-keyboard-return</v-icon>Go back</v-btn>
        <!-- </router-link> -->
      </v-row>

    </v-container>

    <v-container v-else>
      <v-row justify="center" style="padding-top:3em; padding-bottom:3em;">
        <router-link :to="{name: 'client-home'}"><v-btn rounded elevation="0" class="back" style="background-color:#ecedf8;"><v-icon x-small style="margin-top:.5em; margin-right:.3em;">mdi-keyboard-return</v-icon>Return Home</v-btn></router-link>
      </v-row>
    </v-container>

    
    
  </div>
</template>

<script>
import processingMixin from '@/mixins/processingMixin';
export default {
  name: 'in-app-error-page',
  mixins: [processingMixin],
  data() {
    return {
      status: '',
    }
  },
  methods: {
    back() {
      console.log('hello');
      this.$router.go(-2);
      
    },
  },
  created() {
    this.status = this.$route.query.status

    this.logPageLoad({
      app_name: 'P',
      page_name: this.$options.name,
      params: this.status
    })

    console.log(this.status)
  }
}
</script>

<style lang="scss" scoped>
.back:hover {
  position: relative;
  bottom:.2em;
}
</style>