<template>
  <div>
    <Company          
      max-width="400"          
      v-bind:loggedUser="loggedUser">
    </Company>

    <Admin 
      style="margin-bottom:.5em; margin-top:.5em;"
      v-if="isAdmin()"
      max-width="400"
      v-bind:loggedUser="loggedUser">          
    </Admin>
  </div>
</template>

<script>
import Admin from '@/components/Portal/Admin';
import Company from '@/components/Portal/Company';
import { mapState, mapGetters } from 'vuex';

export default {
  name:'company-admin',
  components: {
    Admin,
    Company
  },
  computed: {
    ...mapState([
      'loggedUser'
    ])
  },
  methods: {
    ...mapGetters([
      'isAdmin'
    ])
  }
}
</script>