<template>
<div>
    <v-card       
      tile
      class="card"
      style="border-radius:7px;"      
    > <a v-on:click="viewFile(product.remote_file, product.remote_filename, product.object_uid, product.report_url)">   
      <v-img              
        height="200px"
        style="border-bottom: 1px solid #ddd; border-radius: 7px 7px 0px 0px;"
        :src="placeImage(product.image_file)"        
      >              
      </v-img></a>

      <a v-on:click="viewFile(product.remote_file, product.remote_filename, product.object_uid, product.report_url)">  <v-card-title style="padding-left:1em; padding-right:1em;">
        <v-flex xs12 sm12 md12 lg12 xl12 text-xs-center><p class="pre-formatted" style="color:black; font-size:.9em;">{{ indent(product.name) }}</p></v-flex>
      </v-card-title></a>

      <v-card-subtitle style="padding-bottom:0em; padding-top:0em; position:relative; bottom:1.8em;">
        <span v-for="(desc, i) in product.subjects" :key="i">

          <!--tellVertical emits name of vertical tag, which then triggers menuTold() in portal(WorkProducts.vue) to update filter -->
          <a @click="tellVertical(desc.subjectSID)" text >{{ desc.subjectName }}</a><span v-if="i + 1 < product.subjects.length">, </span>

        </span>
      </v-card-subtitle>

      <div style="position:relative; bottom:2.2em;" v-if="product.description">
        <v-card-text>
          <div class="black--text font-italic">{{ product.description }}</div>
        </v-card-text>
      </div>

      <v-card-actions class="pr-4" style="position: absolute; bottom: 0; left: 0; width:100%; justify-content: space-between;">
        <v-btn          
          color="primary"
          text
          :loading="viewLoading"
          v-on:click="viewFile(product.remote_file, product.remote_filename, product.object_uid, product.report_url)"
        >view                
        </v-btn>


        <div v-if="product.partnership_company_name">
          <v-icon style="color: #1976d2">mdi-handshake-outline</v-icon> In Partnership with {{ product.partnership_company_name }}
        </div>
        
        <!-- transform pub_date to MMM DD, YYYY via mixin stringFunctions.transDateString() -->        
        <div class="caption" >{{ transDateString(product.pub_date) }}</div>

      </v-card-actions>

    </v-card>
</div>
</template>

<script>
  import { mapState } from 'vuex';
  import processingMixin from '@/mixins/processingMixin';
  import stringFunctions from '@/mixins/stringFunctions';
  import placeholderImage from '@/assets/images/placeholder.png';
  import reportFilteringFunctions from '@/mixins/reportFilteringFunctions'; 
  export default {  
    name: 'portal-reports',
    props: ['product'],
    mixins: [
      processingMixin, 
      stringFunctions,
      reportFilteringFunctions
    ],
    data () {
      return {
        viewLoading: false,
        downloadLoading: false,
        form: this.$options.name,
        tellVert:'',
      }
    },
    computed: {
      ...mapState([
        'loggedUser'    
      ]),
    },
    methods: {
      placeImage (image_file) {      
        return image_file === null ? placeholderImage : image_file
      },
      tellVertical(str) {
        this.$emit('clicked', str);
      },
      indent(str) {
        //str.length= 46 fits best into the box size
        if (str.length > 46) {
          let sp = str.split(" ");
          sp.splice(7, 0, '\n');
          return sp.join(" ");
        }
        return str;
      },
      async viewFile (fileName, remote_filename, uid, report_url) {
        this.viewLoading = true;
        let showFile = report_url || fileName
        if (this.loggedUser) {
          showFile = report_url + '?id=' + this.loggedUser.uid || fileName
        }
        if (showFile) {
          window.open(showFile)
        }     
        this.viewLoading = false;

        this.logAction({
          event: 'view-report',
          app_name: 'P',
          page_name: this.$options.name,
          form_name: 'view',
          report_uid: uid,
          url: report_url
        });

      },
    },
  } 
</script>

<style lang="scss" scoped>
.pre-formatted {
  white-space: pre;
  hyphens: auto;
}
  .card {
    min-width:520px;       
    max-width:520px;
    height:430px;
  }
  .card:hover {
    position:relative;
    bottom:.2em;
  }
@media only screen and (max-width: 600px) {
    .card {
      min-width:400px;
      max-width:400px;
      height:400px;
    }
}
</style>