let codeToObjectType = {
    brand: {name: 'Brands', color: '#ad4f22'},
    product_type: {name: 'Products', color: '#cf4784'},
    descriptive: {name: 'Descriptive Objects', color: '#8b8b8b'},
    BR: {name: 'Brand', color: '#ad4f22'},
    BE: {name: 'Brand Extension', color: '#dd8535'},
    OR: {name: 'Organization', color: '#000000'},
    PH: {name: 'Prod/Srvc Type High', color: '#cf4784'},
    PL: {name: 'Prod/Srvc Type Low', color: '#bf8da7'},
    AH: {name: 'Activities and Hobbies', color: '#8a8dc3'},
    AP: {name: 'Appearance', color: '#002bf6'},
    BC: {name: 'Benefits and Concerns', color: '#ee3e8a'},
    EM: {name: 'Emoji', color: '#8a8dc3'},
    GC: {name: 'General Concept', color: '#8b8b8b'},
    IN: {name: 'Ingredient', color: '#8b8b8b'},
    LT: {name: 'Location Type', color: '#2e6b18'},
    PT: {name: 'Person Type', color: '#8b8b8b'},
    PC: {name: 'Person/Character', color: '#801748'},
    PR: {name: 'Preparation', color: '#5fa4cf'},
    QU: {name: 'Quality', color: '#e0c649'},
    TE: {name: 'Time and Event', color: '#c2b5d9'},
    TH: {name: 'Theme/Meta-Topic', color: '#8B00FE'}
};

let brandList = ['BR', 'BE', 'OR'];
let productTypeList = ['PH', 'PL'];
let descriptiveList = ['AH', 'AP', 'BC', 'EM', 'GC', 'IN', 'LT', 'PT', 'PC', 'PR', 'QU', 'TE', 'TH'];
let marketSpecificList = brandList.concat(productTypeList);
let allObjectTypesList = marketSpecificList.concat(descriptiveList);

//
function getListOfObjectGroup(objectGroupType) {
    let objectGroup = []
    for (let code_type of objectGroupType) {
        objectGroup.push({text: codeToObjectType[code_type]['name'], value: code_type})
    }
    return objectGroup
}

let brands = getListOfObjectGroup(brandList)
let products = getListOfObjectGroup(productTypeList)
let descriptive = getListOfObjectGroup(descriptiveList)

function getObjectTypesColors(objectGroupType) {
    let objectGroup = []
    for (let code_type of objectGroupType) {
        objectGroup.push(codeToObjectType[code_type])
    }
    return objectGroup
}
// a list; subset of CodeToObjectType
let allObjectTypes = getObjectTypesColors(allObjectTypesList)

let marketVerticals = {
    1215: 'Food/Beverage',
    1225: 'Beverage Alcohol',
    7959: 'Travel',
    8981: 'Health/Wellness',
    8982: 'Fashion',
    9022: 'Beauty',
    34581: 'Personal Care',
    50596: 'Home Goods',
    // 77059: 'Cannabis',
};

let sources = {
    1: "Instagram",
    3: "Twitter",
    5: "Reddit",
     // 6: 'News',
    7: "TikTok"
};

function getNameIdList(myDictionary, key1, key2) {
    let myList = []
    for (let id in myDictionary) {
        myList.push({ [key1]: myDictionary[id], [key2]: parseInt(id)})
    }
    myList.sort((a, b) => (a[key1] > b[key1]) ? 1 : -1)
    return myList
}

let domains = getNameIdList(marketVerticals, 'name', 'sid')

let dataSources = getNameIdList(sources, 'name', 'value')



export default {
    codeToObjectType,
    brands, products, descriptive,
    brandList, productTypeList, descriptiveList, marketSpecificList, allObjectTypes,
    marketVerticals, domains, dataSources,
    
    methods: {
        filterPsychographics(data) {
            let wanted = [6178, 6180, 6185, 6352, 6353, 6354, 6355, 6356, 6358, 6359, 6360, 6361, 6362, 6363, 6365, 6369, 6370, 6372, 6373, 6347, 6375, 6376, 6412, 6413, 6419, 6424, 6426, 6430, 6431];
            let filtered = []
            for (const [key] of Object.entries(data)) {
                if (wanted.includes((data[`${key}`].psychographics_id))) {
                    filtered.push(data[`${key}`])
                }
                
            }
            for (const i in filtered) {
                if (filtered[i].name == 'Dancing') {
                    console.log(filtered[i])
                }
            }
            return filtered
        },
        
        topDomains(data) {
            console.log(data)
            let mySeries = [];
            let set = data.domain_percents.domains
            console.log(set)
            let news = []
            for (const n in set) {
                news.push(set[n])
            }
            let sorted = news.sort((a, b) => b.latest - a.latest)
            console.log(sorted)
            // for (const n in Object.keys(set)) {
            //   set[Object.keys(set)[n]].latest = set[Object.keys(set)[n]].post_percents[set[Object.keys(set)[n]].post_percents.length-1];
            // }
            sorted = sorted.splice(0, 3)
            let c = 0
            for (const i in sorted) {
                
                if (c < 3) {
                    mySeries.push({
                        name: sorted[i].domain_name,
                        data: sorted[i].post_percents,
                        latest: sorted[i].latest
                    })
                }
                c += 1
            }
            return mySeries
        },

        allSources() {
          return sources
        },
        
        // just color
        addColor(data) {
            for (const i in data) {
                let x = data[i].type_code
                data[i].color = x in codeToObjectType ? codeToObjectType[x]['color'] : '#8b8b8b' // default
            }
        },
        
        // color and name for one object
        setColorName(item) {
            let x = item.type_code;
            
            if (typeof (x) == 'string') { // without this condition x might be undefined and brake the code "See Also" then doesn't show any objects
                item.color = codeToObjectType[x]['color'];
                
                if (marketSpecificList.includes(x)) {
                    item.type = codeToObjectType[x]['name'];
                }
                else if (descriptiveList.includes(x)) {
                    item.desc_name = codeToObjectType[x]['name'];
                }
            }
        },
        
        // colors and names for multiple objects
        setColorsAndNames(data) {
            for (const i in data) {
                this.setColorName(data[i])
            }
        },
        
        getLookupViewPath(sid, domain, type, source){
//            console.log(sid, domain, type, source)
            let objectPath = '/ConsumerForecast/lookup-view' + '?sid=' + sid + '&domain=' + domain + '&type=' + type + '&source=' + source
            return {path: objectPath}
        },

        getSearchViewPath(payload) {
            var fullPath = '/ConsumerForecast/search-result' + '?search=' + payload.item
            if (payload.filter != undefined) {
               fullPath += '&filter=' + payload.filter
            }
            if (payload.order != undefined) {
                fullPath += '&order=' + payload.order
            }
            fullPath += '&source=' + payload.source
            //let searchPath = '/ConsumerForecast/search-result' + '?search=' + item + '&source=' + source
            return fullPath
        }
    }
}

