<template>
  <div>
    <v-data-table
    v-model="domainDist"
    :items="mySeries"
    :headers="headers"
    hide-default-footer
    dense
    light
    :style="tableStyle"
    class="myTable"
    >
    <template v-slot:item="{ item }">
      
    <tr class="trs">
      <td>
        <div style="text-align:left;">
          {{item.domain_name}}
        </div>
      </td>
      <td>
         <div style="text-align:left;">
        {{item.latest.toFixed(1)}}%
         </div>
      </td>
    </tr>
    </template>
    </v-data-table>
  </div>
</template>

<style scoped lang='scss'>
  .trs:hover {
  background-color: #ECEFF1 !important;
  }
</style>

<script>
import objectFunctions from '@/mixins/objectFunctions.js'

export default {
  name: 'domain-percent-currentMonth', 
  props: {
    parentObj: Object,
    parentDomain: String,
    parentMonth: String,
    parentBP: Number
  },
  mixins: [
    objectFunctions
  ],
  data() {
    return {
      mySeries: [],
      domainDist: '',
      headers: [
      {text: 'Market Vertical', value: 'name', align: 'left', sortable: false},
      {text: 'Post % ('+this.parentMonth+')' , value: 'name', align: 'left', sortable: false},
      ]
    }
  },
  created() {
   this.topDomains(this.parentObj.domain_percents.domains)

  },
  methods: {

    topDomains(data) {
  
    for (const i in data) {
      data[i].latest = data[i].post_percents[data[i].post_percents.length -1]
    }

    var mySeries = []
    for (const i in data) {
      mySeries.push(data[i])
    }

    mySeries.sort((a, b) => b.latest - a.latest)
    mySeries = mySeries.splice(0,3)
    this.mySeries = mySeries
  },
  },

  computed: {
    array() {
      return this.parentSeries
    },

    tableStyle() {
      if (this.parentBP >= 1264) return { '--width': '300px'};
      else return { '--width': '600px'};
      
    }
  }
  
}
</script>

<style lang="scss" scoped>
  .myTable {
    color:#333; 
    background-color:transparent; 
    padding-top:1em; 
    //width: var(--width) !important;
    width: 350px;
  }
@media only screen and (min-width: 960px) and (max-width: 1263px) {
  .myTable {
    width:600px;
  }
}
</style>>
