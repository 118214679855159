<template>
<div> 
  <v-container>
      <v-row
      v-if="colNum > 1"
        align="start"
        no-gutters
        style="height: 100%; margin-left:2em;"
      >
        <v-col
          v-for="(list, idx) in lists" :key="idx"
        >
        <v-card class="pa-2" elevation="0" tile>
          <ul v-for="(item, idx) in lists[idx]" :key="idx">
            <router-link tag="a" :to="getLookupViewPath(item.object_sid, parentDomain, item.type_code, getCurrentSource)">
              <span @click="push(item)">
                <v-tooltip light left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind:style="{color: item.color}" small v-bind="attrs" v-on="on" style="padding-bottom:.2em;">mdi-square-rounded</v-icon>
                  </template>
                  <span v-if="item.desc_name">{{item.desc_name}}</span>
                  <span v-else>{{item.type}}</span>
                  </v-tooltip>

                  <span v-if="item.object_name">
                    <span v-if="item.object_name.length > 19">
                      <v-tooltip light right>
                        <template v-slot:activator="{ on, attrs }">
                          <li v-bind="attrs" v-on="on" style="color:#0d1026; display:inline-block">{{shortenName(item.object_name, 19, breakpoint)}}</li>
                        </template>
                        {{item.object_name}}
                      </v-tooltip>
                    </span>

                    <span v-else>
                      <li style="color:#0d1026; display:inline-block">{{shortenName(item.object_name, 19, breakpoint)}}</li>
                    </span>
                  </span>

                  <span v-else>
                    <span v-if="item.name.length > 19">
                       <v-tooltip light right>
                        <template v-slot:activator="{ on, attrs }">
                          <li v-bind="attrs" v-on="on" style="color:#0d1026; display:inline-block">{{shortenName(item.name, 19, breakpoint)}}</li>
                        </template>
                        
                        {{item.name}}
                      </v-tooltip>
                      </span>

                      <span v-else>
                        <li style="color:#0d1026; display:inline-block">{{shortenName(item.name, 19, breakpoint)}}</li>
                      </span>
                  </span>
                </span>

            </router-link>
          </ul>
        </v-card>
        
        </v-col>
      </v-row>

      <v-row v-else justify="center" v-for="(item, idx) in lists" :key="idx">
       <router-link tag="a" :to="getLookupViewPath(item.object_sid, parentDomain, item.type_code, getCurrentSource)" >
        <span @click="push(item)">
          <v-tooltip light left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind:style="{color: item.color}" small v-bind="attrs" v-on="on" style="padding-bottom:.2em;">mdi-square-rounded</v-icon>
            </template>
            <span>{{item.type}}</span>
            </v-tooltip>
            <li v-if="item.object_name" style="color:#0d1026; display:inline-block">{{shortenName(item.object_name,19, breakpoint)}}</li>
            <li v-if="item.name" style="color:#0d1026; display:inline-block">{{shortenName(item.name, 19, breakpoint)}}</li>
          </span>
        </router-link>
      </v-row>
    </v-container>
</div>
</template>

<script>
import stringFunctions from '@/mixins/stringFunctions';
import objectFunctions from "@/mixins/objectFunctions";
import {mapGetters}    from "vuex";


export default {
  name: 'SideBar',
  props: { 
    parentItem: Object,
    parentDomain: String,
    breakpoint: Number,
  },
  mixins: [stringFunctions, objectFunctions],
  data() {
    return {
      set: {},
      load: false, 
      colNum: 4,
      lists: [],
    }
  },
  created() {
    this.cut()
  },

  computed: {
    ...mapGetters([
      'getCurrentSource'
    ]),
  },

  methods: {


    cut() {
      Array.prototype.unique = function() {
        var a = this.concat();
        for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
        if(a[i] === a[j])
          a.splice(j--, 1);}}
          return a;
      };


      //console.log('in cut()')
      //console.log(this.parentItem)
      let related = this.parentItem.volumes.domains[this.parentDomain]

      //create sub_object list if exists & make object_type relevant
      let sub_objects = []
      if (related.sub_objects) {
        //console.log('yes to sub_objects')
        sub_objects = related.sub_objects
        //console.log(sub_objects)
      }
      
      if (objectFunctions.productTypeList.includes(this.parentItem.type_code) ) {
        //console.log(this.parentItem.type_code)
        sub_objects = sub_objects.filter(x => (objectFunctions.productTypeList.includes(x.type_code))).splice(0, 10)
        //console.log(sub_objects)
      }
      else if (['BR', 'BE'].includes(this.parentItem.type_code)) {
        sub_objects = sub_objects.filter(x => ['BR', 'BE'].includes(x.type_code)).splice(0, 10)
      }
      
      
      //create parents list if exists
      let parents = []
      if (related.parents) {
        if (related.parents.length) {
          console.log('yes to parents')
          parents = related.parents;
        }
      }
      //else { console.log('parents have no lenght/domains')}


      //create tree (parents + sub) portion of list
      var direct_tree = parents.concat(sub_objects).unique()
      //console.log('direct_tree', direct_tree)


      //add linked objects if they exist & and cut if too long
      let linked_objects 
      let full_tree = direct_tree
      if (related.linked_objects) {
        //console.log('yes to linked_objects')
        linked_objects = related.linked_objects
        full_tree = direct_tree.concat(linked_objects).unique()
      }
       else { console.log('linked have no lenght/domains')}

      //console.log("linked_objects", linked_objects)
      //console.log('full_tree',full_tree)

      const result = [];
      const map = new Map();
      for (const item of full_tree) {
          if(!map.has(item.object_sid)){
              map.set(item.object_sid, true);    // set any value to Map
              result.push(item);
          }
      }
      //console.log("map", map)
      //console.log('result', result)
      full_tree = result
      //console.log('full tree', full_tree)

      //add correlated objects
      let correlated
      let tree_corr = full_tree.splice(0,40)
      //console.log(this.parentItem.correlation)
      if (this.parentItem.correlation) {
        if (this.parentItem.correlation.domains) {
          //console.log('yes correlated')
          correlated = this.parentItem.correlation.domains[this.parentDomain].objects;
          tree_corr = tree_corr.splice(0,25).concat(correlated)
        }
       // else {console.log('correlated have no length')}
      }
      //else {console.log('correlated dont exist')}

      // put market specific object types first, then sort by object type and name
      tree_corr.forEach(object => object.marketSpecific = objectFunctions.marketSpecificList.includes(object.type_code))
      tree_corr.sort((a, b) => {
        if (a.type_code === b.type_code) {
          return ((a.name || a.object_name).toLowerCase() > (b.name || b.object_name).toLowerCase()) ? 1 : -1
        }
        else if (a.marketSpecific === b.marketSpecific) {
          return (a.type_code > b.type_code) ? 1 : -1
        }
        else {
          return (a.marketSpecific < b.marketSpecific) ? 1 : -1
        }
      })

      //console.log("tree_corr", tree_corr)
      //console.log("correlated",correlated)

      const key = 'object_sid';

      const arrayUniqueByKey = [...new Map(tree_corr.map(item => [item[key], item])).values()];

      //console.log("arrayUniqueByKey", arrayUniqueByKey);

      this.setColorsAndNames(arrayUniqueByKey)
      //console.log('tree_corr', arrayUniqueByKey)
      this.$emit('alsolength', arrayUniqueByKey.length)

      this.sliceDice(arrayUniqueByKey)

    },

     sliceDice(set) {
      //console.log('inslice dice')

      if (set.length > 24) {
        this.colNum = 4
        this.lists = this.splitToChunks(set, 4)
      }
      else if (set.length == 1) {
        console.log('1', set)
        this.colNum = 1;
        this.lists = set
      }
      else {
        this.colNum = 3
        this.lists = this.splitToChunks(set, 3)
      }
      //console.log(this.colNum, this.lists)
        
    },

    splitToChunks(array, parts) {
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  },

  
    push(item) {
      this.load = true
      
      let myObject = {
        sid: item.object_sid,
        domain: this.parentDomain,
        type: item.type_code,
        source: this.$route.query.source
      }
      this.$emit('itemLoad', myObject)
    }
  }}


</script>

<style scoped>
#cardMe {
  background-color: 	white;
  border-radius: 12px;
  color:white;
  border:3px solid #273072;
}

@media only screen and (min-width: 960px) and (max-width: 1264px) {
  /* #cardMe {
    margin-left:01em !important;
  } */
}
</style>