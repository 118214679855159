<template>
  <div>
    <span v-if="getCurrentGap.allow_data_export">
      <span v-if="!['sm', 'xs'].includes(this.$vuetify.breakpoint.name)" style="float:right; position:relative; right:10em; top:2em; font-size:.8em;"><a class="csv-hover" @click="csv()">Download CSV</a></span>
    </span>
    <v-row>
      <span style="padding-left:5em; padding-top:1em;">'{{this.parentAct.name}}' <router-link class="percentile-link" target="_blank" tag="a" :to="{path: '/ConsumerForecast/help?page=definitions#raw'}">Penetration %</router-link> of '{{this.compBase}}'<span style="font-size:.8em; padding-left:.5em;"> (In {{this.parentDomainName}})</span>
      </span>
    </v-row>
    <v-row justify="center">
      <apexchart style="margin-left:1em; position:relative; top:1em;" width="850" height="450" type="line" :options="chartOptions" :series="series"></apexchart>
    </v-row>
    <v-container style="width:85%; overflow:auto; height:150px; padding-top:2em;">

    <v-row justify="center" @mouseover="hint=true" @mouseleave="hint=false">
      <ul v-for="item in this.ordered" :key="item" >
        <a @click="groupGraph(item)" v-on:dblclick="goTo(item)">
          <v-tooltip light left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind:style="{color: item.color}" v-bind="attrs" v-on="on">mdi-square-rounded</v-icon>
            </template>
            <span>{{item.type}}</span>
            
          </v-tooltip>
        <li style="color:#0d1026; display:inline-block;">{{shortenName(item.object_name, 18)}}</li></a>
      </ul>
    </v-row>
    <v-row v-if="hint" :class="{ 'on-hover': hover }">
      <div style="position:absolute; bottom:1em; right:2em;">
        <span style="font-size:.7em;"><b>Hint:</b> double-click on items to navigate to them in a new tab </span>
      </div>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import stringFunctions from '@/mixins/stringFunctions';
import processingMixin from '@/mixins/processingMixin';
import objectFunctions from "@/mixins/objectFunctions";
import { mapGetters } from 'vuex';

export default {
  name: 'relations-timelines',
  mixins: [stringFunctions, processingMixin, objectFunctions],
  props: {
    parentAct: Object,
    parentDomain: String,
    parentBP: Number,
    parentDomainName: String,
  },
  created() {
    this.compareGroups(this.parentAct, this.parentDomain)
    
  },
  computed: {
    ...mapGetters([
      'getCurrentGap',
      'getCurrentSource'
    ]),

    chartWidth() {
      let bp = this.$vuetify.breakpoint.width
      if (bp < 600) { return 20} //xs
      else if (bp > 600 & bp < 800) { return 500} //xs-sm
      else if (bp > 800 & bp < 960) { return 800} //sm
      else if (bp > 960 & bp <= 1264) { return 800} //md
      else if (bp > 1264 & bp < 1704) {return 800}
      else {return 900}//lgxl
    }

  },
  
  methods: {

    compareGroups(data, domain) {
      this.addColor(data.volumes.domains[domain].linked_objects);
      this.groups = data.volumes.domains[domain].linked_objects;
      // let ordered = this.sorted(this.groups)
      // console.log(ordered.length)
      this.ordered = this.sorted(this.groups)

      
      this.initialize(this.parentAct, this.parentDomain)
    },

    splitToChunks(array, parts) {
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  },

    sorted(set) {
      //console.log('sort')

       var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      let sep = groupBy(set, 'type_code')
       let one = []
      for (const i in Object.keys(sep)) {
        if (!isNaN(i)) {
          one.push(sep[Object.keys(sep)[i]].sort((a,b) => b.percent[24] - a.percent[24]))
        }
      } 
      let two = []
      for (const n in one) {
        if (!isNaN(n)) {
          if (n == 0) {
            two = one[n]
          }
          else {
            for (const m in one[n]) {
              if (!isNaN(m)) {
                two.push(one[n][m])
              }
              
            }
          }
        }
      }
      return two
    },

    initialize(data) {
      let item = this.ordered[0]
      this.myIt = item.object_name
      this.compBase = item.object_name
      this.compBase_sid = item.object_sid
       this.series = [
         {
            name: "'" + data.name + "'" + ' Penetration %' + ' of ' + "'" + item.object_name + "'",
            type: 'line',
            data: item.percent
          },
          {
            name: "'" + item.object_name + "'" + ' Volume',
            type: 'column',
            data: item.object_volumes
          }]
        this.chartOptions.labels = this.parentAct.volumes.month_date_set;
        
        this.chartOptions = {...this.chartOptions, ...{
          yaxis: [
            {
              title: {
              text: "'"+ this.parentAct.name + "'" + ' Penetration %'
            },
            labels: {
              formatter: function(val) { 
                if (val < 1) { return val.toFixed(2) + '%' }
                else { return val.toFixed(1) + '%' }
              }
            }
          },
          {
            opposite: true,
            title: {
                text: "'" + item.object_name + "'" + ' Volume'
              },
              labels: {
                show: true,
                formatter: (val) => {return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              }
          }
          ]
         }}

    },

    groupGraph(item) {
       this.compBase = item.object_name
       this.compBase_sid = item.object_sid
       this.series = [
         {
            name: "'" + this.parentAct.name + "'" + ' Penetration %' + ' of ' + item.object_name,
            type: 'line',
            data: item.percent
          },{
            name: "'" + item.object_name + "'" + ' Volume',
            type: 'column',
            data: item.object_volumes
          }]
        this.chartOptions.labels = this.parentAct.volumes.month_date_set;

         this.chartOptions = {...this.chartOptions, ...{
          yaxis: [
            {
              title: {
              text: "'" + this.parentAct.name + "'" + ' Penetration %'
            },
            labels: {
              formatter: function(val) { 
                if (val < 1) { return val.toFixed(2) + '%' }
                else { return val.toFixed(1) + '%' }
              }
            }
          },
          {
            opposite: true,
            title: {
                text: "'" + item.object_name + "'" + ' Volume'
              },
              labels: {
                show: true,
                formatter: (val) => {return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              }
          }
          ]
         }}
        
    },

    csv() {
      let data = this.series;
      let date = this.parentAct.volumes.month_date_set;
      let rows = [['Month', data[0].name, data[1].name]];
      
      for (const i in data[0].data) {
        if (!isNaN(i)) {
          rows.push([
          date[i],
          data[0].data[i], 
          data[1].data[i],
        ]) 
      }}
  
      let csvContent = "data:text/csv;charset=utf-8," 
      + rows.map(e => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.parentAct.name + " Penetration % of" + this.compBase + ' in ' + this.parentDomainName);
      document.body.appendChild(link); // Required for FF

      link.click();

      this.logAction({
        event: 'csv-download',
        app_name: 'CF',
        page_name: 'look-up-view',
        form_name: 'linked-comparison',
        object_sid: this.myObject.object_sid,
        domain_sid: this.myDomain, 
        source: 1
      });

    },

    goTo(item) {
      let routeData = this.$router.resolve(this.getLookupViewPath(item.object_sid, this.parentDomain, item.type_code, this.getCurrentSource))
      window.open(routeData.href, '_blank');
    },
  },

  data () {
    return {
      hint: false,
      groups: {},
      compBase: '',
      compBase_sid: '',
      firstSet: [],
      secondSet: [],
      myIt: '',
      ordered: [],
      boxColor: '',
      series: [{ 
        "name": "", 
        "type": "column", 
        "data": [] },
        {"name": "%", 
        "type": "line", 
        "data": []

        }
        ],
          chartOptions: {
            chart: {
              type: 'line',
              toolbar: {
                show: false,
              },
            },
            stroke: {
              width: 4,
              curve: 'smooth',
            },
            xaxis: {
              type: 'datetime',
              tickAmount: 'dataPoints',
              tooltip: {
                enabled: false
              },
              labels: {
                rotate: -45,
                rotateAlways: true,
                minHeight: 42,
                hideOverlappingLabels: false,
              }
            },
            states: {
              active: {
                filter: {
                  type: 'none' /* none, lighten, darken */
                }
              }
            },
            legend: {
              onItemClick: {
                toggleDataSeries: false
              },
              offsetY: 10,
              position: 'top',
            },
            colors: ['#252F52', '#A8B1C3'],
            fill: {
              opacity: 1
            },
            plotOptions: {
                bar: {
                  columnWidth: '80px',
                  distributed: false,
                  borderRadius: 2,
                }
            },
            title: {
              show:false
            },
            tooltip: {
              endabled: true,
              x: {
                format: 'MMM yyyy',
              },
              y: {
                
              }
            },
            dataLabels: {
              show:false,
            },
            responsive:  [{
            breakpoint: '960',
            options: {
              chart: {
                width: 650
              },
            }
          }],
            
            yaxis: [
              {
              labels: {
                show: true,
                formatter: function(val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  },
              }
            }, 

            {
              opposite: true,
              title: {
                text: '%'
              },
              labels: {
                formatter: function(val) {
                    if (val < 1) { return val.toFixed(2) + '%' }
                    else { return val.toFixed(1) + '%' }
                  }
              }
            }]
            
          },
          
    }
  }
}
</script>

<style scoped lang="scss">

ul > li {
  display: inline;
}

.percentile-link {
  color: #6387B0;
}
.percentile-link:hover {
  position:relative;
  bottom:.15em;
}

.csv-hover {
  color: #6387B0 !important;
}
.csv-hover:hover {
  position:relative;
  bottom:.2em;
}

.center-vert {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  }
  .margin {
      margin-left:0em;
    }


  @media only screen and (min-width: 1260px) {
    .margin {
      margin-left:4em;
    }

  }
</style>