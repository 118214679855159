<template>
<v-container style=" background-color:white; border-radius:12px;">
  <v-carousel 
      cycle
      height=100%
      width=100%
      hide-delimiter-background
      show-arrows-on-hover
      light
      hide-delimiters
      
    >
      <v-carousel-item
        v-for="slide in slides"
        :key="slide.quote"
        class="item"
        align="center"
      >
        <v-sheet
          color="white"
          align="center"
          style="padding:1em;"
               >
          
          <v-layout wrap row class="slides"  style="padding:1em; justify:center; text-align:center;">
              
              <v-row align="center"
      justify="center">
              <v-flex xs12 sm12 md12 lg12 style="padding-bottom:1.5em;">
                {{slide.quote}}
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 class="authors">
                <strong>{{slide.from}}</strong>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 >
                <img class="slide_logo center" :src="slide.img_src" />
              </v-flex>
            </v-row>
            </v-layout>
          
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    </v-container>
</template>

<script>
export default {
 data() {
  return {
    slides: [
      {quote: '"Social Standards delivers unparalleled insights about brands, products, trends, and influencers. At Prelude, we consider our partnership with Social Standards to be invaluable as we evaluate the market. We believe that they are a true competitive advantage for the brands they work with."', from: '- Alicia Sontag, Partner, Prelude Growth Partners', img_src:require("@/assets/color-logos/prelude.png")},
      {quote: '"Social Standards enables us to better understand our customer and their preferences in a way we never have before. The insights have helped drive strategy and partnerships to grow our business"', from:"- Amanda O'Keefe, Executive VP, Primal Kitchen", img_src:require('@/assets/color-logos/PK_full.png')},
      {quote: '"Social Standards has been very useful for uncovering trends in the new market — especially in the case of unknown unknowns."', from: '- Swethda Doppalapudi, Insights & Intelligence Manager, ZX Ventures', img_src:require('@/assets/color-logos/zx.png')},
      {quote:'"At J&J, we looked for quite some time to find a social analytics tool that would bring quality customer insights in real time, that would be easy to be used by all, and that would help us develop the right concepts and products for the future, reducing our development time. When we met with Social Standards, we felt they were the perfect partners for our needs and we have been using their tools and services with great benefits."', from:'- Sebastien Guillon, CEO, Michel at Augustin (former Global President J&J)', img_src:require('@/assets/color-logos/jj.png')},
      {quote: '"When we think about what we need to do with a product category and how to grow it, by and large our teams look at IRI data to determine which price points and brands are growing, then they infer why they’re growing. Because there’s no attributes in IRI, there’s no real way to understand WHY something might be growing, so we use Social Standards to help dissect and get more granular on the WHY. Social Standards helps us understand: Which brands are winning and why? How is the subcategory performing vs. the larger category? What’s driving growth or decline?"', from: '- Elizabeth Harris, VP Strategy, Constellation Brands', img_src:require("@/assets/color-logos/Const-Logo.png")},
     
    ]
  }
 }
}
</script>

<style>
.authors {
  font-size: .8em;
  padding-bottom:2em;
}
.slide_logo {
  width:10em
}
.slides {
  color:#808285; 
  text-align:center; 
  font-size:1.1em;
}
.item {
  padding:1em; 
}
@media only screen and (max-width: 1050px) {
  .slides {
    font-size:1.2em;
  }
  .item {
    padding-top:.5em;
  }
}
@media only screen and (max-width: 600px) {
  .slides {
    font-size:1.1em;
  }
  .item {
    padding-top:.5em;
  }
}

</style>