<template>
  <div style="height: 100%">
    <v-row justify="center">
      <div>
        <br />
        <v-card elevation="0">
          <div style="line-height: 2em"><br /></div>
          <v-card-content>
            <LoginForm />
          </v-card-content>
        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
// ***** V1 LOGIN *********
import { mapGetters } from "vuex";
import LoginForm from "@/components/LoginForm";
import processingMixin from "@/mixins/processingMixin";

export default {
  name: "login-page",
  mixins: [processingMixin],
  components: {
    LoginForm,
  },

  async created() {
    this.logPageLoad({
      app_name: "S",
      page_name: this.$options.name,
    });

  },
  computed: {
    ...mapGetters(["isLoggedIn", "isAdmin"]),
  },
};
</script>
<style></style>
