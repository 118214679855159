<template>
  <div>

    <v-card v-if="alreadyLoggedIn && !sameUser"
            elevation="0" justify="center" style="padding-top:6em; text-align:center;">
      You are currently logged in as {{loggedUser.name}} and you don't have permissions to
      reset the password for another account.
      <br>
      Go to <router-link :to="{name: 'client-home'}">Home</router-link>.
    </v-card>

    <v-card v-if="acceptError === 403 && resetLink && isLoggedIn"
            elevation="0" justify="center" style="padding-top:6em; text-align:center;">
     You're already logged in and you clicked on a link that is no longer active to reset your password. <br>
     If you want to reset the password go to the <router-link :to="{name: 'client-home'}">Home</router-link> page,
     click on My Account and you'll see a reset password option.
    </v-card>

    <v-card v-if="acceptError === 422 && resetLink && !isLoggedIn"
            elevation="0" justify="center" style="padding-top:6em; text-align:center;">
     You clicked on a link that is no longer active to reset your password. <br>
     If you want to reset the password, go to <a :href="$router.resolve({name: 'login'}).href">Forgot Password</a>.
    </v-card>

     <v-card v-if="!isLoggedIn && !acceptError" flat style="width: 350px">
      <v-form
        id="login_input"
        ref='form' 
        class="pl-6 pr-6 pb-6"
        >

        <v-alert 
          type="error" 
          v-if="failedLogin" 
          style="font-size:.85em; padding:.5em">
          <span style="line-height: 2em;">{{this.failedLoginMsg}}</span>
        </v-alert>

        <span v-if="forgotClicked" id="instructionInfo" style="margin-bottom: 8em;">
          Enter the email and we'll send instructions to reset your password.<br><br>
        </span>

        <span id="emailField">
          <v-text-field
            v-model="username" 
            label="Email">
          </v-text-field>
        </span>

        <v-alert
          type="error"
          v-if="!username && sendResetInstructions"
          dense
          outlined
          style="font-size:.85em; text-align:center; padding-right:6.5em;"
        >
          Please enter the email.
        </v-alert>

        <span v-if="!forgotClicked" id="passField" >
          <v-text-field
            @keydown.native.enter="login()"
            v-model="password"
            label="Password"
            type="password">
          </v-text-field>
        </span>
        
        <!--401 reset alert -->
        <v-alert 
          :type="errorType" 
          v-if="resetError" 
          style="font-size:.8em;">
            <span>{{this.resetError}}</span>
        </v-alert>
       
       <!--forgotPassword() buttons-->
        <div style="text-align: center;">
          <a 
            v-if="!resetError && !forgotClicked"
            id="needNew" 
            @click="forgotPassword()">
            Forgot Password
          </a>
          <a
            v-if="resetError && sendResetInstructions"
            id="needNew" 
            @click="sendResetPswdInstructions()">
            Try sending instructions again.
          </a>
        </div>

        <v-card-actions v-if="!forgotClicked" id="loginAction">
          <v-btn primary large block class="mt-4" @click="login()">Login</v-btn>
        </v-card-actions>

        <v-card-actions v-if="forgotClicked" id="sendResetPswd">
          <v-btn primary large block class="mt-4" @click="sendResetPswdInstructions()">Send instructions</v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </div>
</template>

<script>
  // *********** V2 LOGIN ************ 
  import { mapActions, mapState, mapGetters } from 'vuex'
  import processingMixin from '@/mixins/processingMixin';
  
  export default {  
    name: 'login-form',
    mixins: [processingMixin],
    data () {
      return {
        acceptError: null,
        username: '',
        password: '',
        token: '',
        isReset: false,
        tokenEmail: '',
        emailField: '',
        failedLogin: false,
        failedLoginMsg: '',
        resetError: '',
        errorType: '',
        dialog: false || this.$route.params.login,
        redirectUrl: null,
        resetLink: false,
        forgotClicked: '',
        alreadyLoggedIn: false,
        sameUser: false,
        sendResetInstructions: false
      }
    },

    async created () { 
      // set the redirect if supplied       
      this.redirectUrl = this.$route.query.redirect_url;

    try {
      await this.checkCredentials();
      if (!this.$route.query.token){
        this.$router.push({ name: "client-home" });
        return;
      }
    } catch (e) {
      console.log(e);
    }

    // redirect to password-reset page if we get the email and token params 
    if (this.$route.query.email && this.$route.query.token && !this.isLoggedIn) {
      // set the token via the SET_USER mutation
      this.username = this.$route.query.email;
      this.token = this.$route.query.token;
      this.isReset = true;

      console.log('Reset password login')
      this.login();
    }
    else if (this.$route.query.email && this.$route.query.token && this.isLoggedIn) {

      this.alreadyLoggedIn = true
      // check if the same user is logged in and trying to reset
        // yes => let them reset the pswd
        // no => msg 'you can NOT resset for someone else'
      if (this.loggedUser.email === this.$route.query.email) {
        this.sameUser = true
        this.username = this.$route.query.email;
        this.token = this.$route.query.token;
        this.isReset = true;
        this.login();
      }
      else {
        this.sameUser = false
      }

    } else if (this.isLoggedIn && this.redirectUrl) {
        console.log("redirect", this.redirectUrl)
        this.redirect(this.redirectUrl);
      }
      else if (this.isLoggedIn) {
        this.$router.push({name:'client-home'})
      }

    },

    computed: {
      ...mapState([
        'loggedUser',
        'access'
      ]),
      ...mapGetters([
        'isLoggedIn',
        'isAdmin'
      ])
    },
    
    methods: {
      ...mapActions([
        'getUser',
        'getPasswordReset',
        'setUser',
        'checkCredentials'
      ]),

      login: async function() {    
        await this.getUser({
          username: this.username, 
          password: this.password,
          token: this.token,
        }
        )
        .then( () => {
          if (this.isReset) {
              // we logged in with the temp token, reroute to reset password view
              this.$router.push({path: '/reset-password'+ '?token=' + this.token + '&full_name=' + this.loggedUser.full_name});

              location.reload()
          }  
          else if (this.isLoggedIn) {
            this.logAction({
              event: 'login',
              action: 200,
              form_name: 'login-form',
            });
            this.redirect(this.redirectUrl);
          } 
        })
        .catch(error => {
          // bad email or password
          if (error.response.status === 422) {

            if (this.$route.query.email && this.$route.query.token){
              // this isn't an alert because it's the old reset pswd link and user needs the latest one
              console.log('this isnt an alert because its the old reset pswd link and user needs the latest one');
              this.acceptError = error.response.status
              this.resetLink = true
              this.$router.push({name: 'login'})
            }
            else { // this will be shown as alert because a user should be able to use login form to correct password
              this.failedLogin = true;
              this.failedLoginMsg = 'Invalid Password. Note that credentials are case sensitive.'
            }

            this.logAction({
              event: 'login',
              action: error.response.status,
              form_name: 'login-form',
            });
          }

          // No roles on the account - access disabled
          if (error.response.status === 403) {
            this.$router.push({name:'disabled'})

            this.logAction({
              event: 'login',
              action: error.response.status,
              form_name: 'login-form',
            });
          }

          if (error.response.status === 404) {
            this.failedLogin = true;
            this.failedLoginMsg = 'Account with this email doesn\'t exist.'

            this.logAction({
              event: 'login',
              action: error.response.status,
              form_name: 'login-form',
            });
          }
          
          // password reset flows
          if (error.response.status === 401) {
            
            if (!this.isReset) {
              // user has to update password
              // send the submitted email (username) to the reset api
              this.getPasswordReset(this.username)
                .then(() => {
                  console.log('mandatory password reset');
                  this.errorType = 'error';
                  this.resetError = 'You require a mandatory password reset. Check your email for instructions.';
                })
                .catch(() => {
                  this.message = `We can't seem to find ${this.username}. Try another email or contact support.`;
                })
                
                this.logAction({
                  event: 'login',
                  action: error.response.status,
                  form_name: 'login-form',
                });             
            }
        }})        
      },

      forgotPassword(){
         this.forgotClicked = true;
         this.failedLogin = false;
      },

      sendResetPswdInstructions(){
        this.sendResetInstructions = true;
        var eField = document.getElementById('emailField');
        var sendResetPswdField = document.getElementById('sendResetPswd');
        var instructionInfoField = document.getElementById('instructionInfo');

        if (this.username) {
          this.getPasswordReset(this.username)
          .then( () => {
            this.sendResetInstructions = false;
            eField.style.display = 'none';
            sendResetPswdField.style.display = 'none';
            instructionInfoField.style.display = 'none';
            this.errorType = 'success'
            this.resetError = `Reset instructions have been sent to ${this.username}.`;
            this.$refs.form.reset();  
          })
          .catch( () => {
            this.errorType = 'error';
            this.resetError = `We can't seem to find ${this.username}. Try another email or contact support.`;
            sendResetPswdField.style.display = 'none';
          })
        }
      },

      redirect(url) {
        // decide where we're going after successful login. 
        // if we were sent a redirect url, go there, else the portal
        if (url) {
          window.location.href = url;
        }
        else if (this.access.length == 0) {
          this.$router.push({name:'disabled'})
        }
        else {
          let path = sessionStorage.getItem('redirectPath') || {name: 'client-home'}
          console.log(path.name)
          console.log(path == '{name: client-home}')
          if (path.name == 'client-home') {
           
            if (this.loggedUser.legacy === true) {
              this.$router.push({name: 'portal'})
            }

            else {
              console.log('here')
              let routeData = this.$router.resolve(path)
              window.open(routeData.href, '_blank');
            }
            
          }
          else {
            console.log('there')
            this.$router.push(sessionStorage.getItem('redirectPath'))
          }
          
          sessionStorage.removeItem('redirectPath');
          location.reload()
        }        
      }
    
    },


  }
</script>

<style>

.v-btn:hover:before {
      background-color: transparent;
      display: none; 
    }
.v-ripple__animation{ display: none; }

</style>