export default {
  methods: {
      
    unpackRoleNames(roles) {
      let roles_arr = [];
      for (const r in roles) {
          if (isNaN(r) == false) {
          roles_arr.push(roles[r].role_name)
          }
      }
      return roles_arr
    },

    presentRoleNames(array) {
      let str = '';
      for (let r in array) {
        if (!isNaN(r)) {
          if (r == array.length-1) {
            str += array[r]
          }
          else {
            str += array[r] + ', '
          }
        }	
      }
      return str
    },

    unpackRoleValues(roles) {
      let roles_arr = [];
      for (const r in roles) {
          if (isNaN(r) == false) {
          roles_arr.push(roles[r].role)
          }
      }
      return roles_arr
    },

    repackRoleValues(roles) {
      let   array_r = []
      for (const role in roles) {
      if (isNaN(role) == false) {
      array_r.push({role: roles[role]})
      }
      }
      return array_r
    },

    sendPackGaps(gaps) {
      let gaps_arr = []
      for (const g in gaps) {
        if (!isNaN(g)) {
          gaps_arr.push({gap_uid: gaps[g]})
        }
      }
      console.log(gaps)
      return gaps_arr
    },

    translateChosenItem(roles) {
      let roles_arr = [];
  
      if(roles.includes('Web User')) {
        roles_arr.push({role: 'portal_user'})
      }
      if (roles.includes('App Access')) {
        roles_arr.push({role: 'app_user'})
      }
      if (roles.includes('User Admin')) {
        roles_arr.push({role: 'user_admin'})
      }
      else if (roles.includes('Billing Admin')) {
        roles_arr.push({role: 'org_admin'})
      }
      return roles_arr
    },

}
}