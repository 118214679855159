<template>
  <div>
    <apexchart width="360" type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: 'geo',
  props: {
    parentObj: Object,
    parentDomain: Number,
  },

  data() {
    return {
      percentile: {},

      series: [],
      chartOptions: {
        labels: [],
        colors: ['#274b69', '#3f4d63', '#85a1c1', '#95724B', '#B08C63', '#C1A585', '#BDBDBD'],
        ///colors: ['#20246f', '#352d6a', '#6c2d6a', '#B83e5D', '#DC722F','#FFd032', '#BDBDBD'],
        chart: {
          type: 'donut',
        },
        dataLabels: {
            enabled: false
            },
            tooltip: {
          enabled: true,
          //theme: 'dark',
          y: {
            formatter: function formatter(val) {
              return (val).toFixed(1) + '%'},
          }
        },
        legend: {
          show: true,
          offsetY: -5,
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
            
        responsive: [{
            breakpoint: '960',
            options: {
              chart: {
                width: 340
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
      },
    }
  },

  created() {
    this.createVars(this.parentObj, this.parentDomain)
  },

  methods: {

    createVars(data, domain) {
      let set = data.global_region.domains[domain].percent
      let total = 0;

      let arr= []
      let labels = []
      for (const i in set) {
        if (!isNaN(i)) {
          arr.push(set[i].percent)
          labels.push(set[i].region)
          total += set[i].percent
        }
      }
      this.series = arr;
      arr.push(100-total);
      labels.push('Other');
      this.chartOptions.labels = labels

      this.chartOptions.tooltip = {
         y: {
            formatter: function(value, opts ) {
              let ending = 'th'
              if (value.toString()[1] == 1) {ending = 'st'}
              else if (value.toString()[1] == 2) {ending = 'nd'}
              else if (value.toString()[1] == 3) {ending = 'rd'}

              if (value != 100-total) {
                let item = data.global_region.domains[domain].percent[opts.dataPointIndex]
              
              return 'raw: '+ (value).toFixed(1) + '%' + '<br>' + 'percentile: ' + item.percentile + ending
              }
              else {
                return 'raw: '+ (value).toFixed(1) + '%'
              }
              },
            
            title: {
              formatter: function (seriesName) {
                return '  '+seriesName+':' + '<br>'
              }}
         }
      }
         
    },
  }
}
</script>
