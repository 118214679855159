//import Vue from "vue";

export default {
  SET_USER(state, user) {
    let loggedUser;

    if (user.account) {
      //admin and regular web users
      state.account = user.account;

      if (user.account.account_settings) {
        let if_admin = 0;
        if (user.account.primary_org_uid === "5eSi7cA1ix6rECjva5rgTs") {
          // Social Standards
          let userRoles = Object.keys(
            user.account.organizations[user.account.primary_org_uid].roles
          );
          if (
            userRoles.includes("internal_user") ||
            userRoles.includes("internal_admin")
          ) {
            if_admin = 1;
          }
        }

        loggedUser = {
          tu: null,
          tc: null,
          last_login: null,
          full_name: null,
          uid: user.account.account_uid,
          company_name: user.account.account_settings.primary_org_name,
          company_uid: user.account.account_settings.primary_org_uid,
          token: user.jwt,
          is_admin: if_admin,
          email: user.account.email,
          name: user.account.full_name,
          legacy: false,
          anon: false,
        };
      }
    } else if (user.organization) {
      //is anon temp user
      let load = {
        name: user.organization.name + " User",
        organization_name: user.organization.name,
        organization_uid: user.organization.organization_uid,
      };
      state.account = load;

      loggedUser = {
        tu: null,
        tc: null,
        last_login: null,
        full_name: null,
        uid: user.organization.organization_uid,
        company_name: user.organization.name,
        company_uid: user.organization.organization_uid,
        token: user.jwt,
        is_admin: false,
        email: null,
        name: user.organization.name + " User",
        legacy: false,
        anon: true,
      };
    } else if (user.legacy_user) {
      //is legacy user
      state.account = user.legacy_user;
      loggedUser = user.legacy_user;
      loggedUser.legacy = true;
    } else if (user.reset) {
      //reset password info
      state.account = user.reset;
      loggedUser = {
        tu: null,
        tc: null,
        last_login: null,
        full_name: user.reset.full_name,
        company_name: null,
        company_uid: null,
        token: user.jwt,
        is_admin: false,
        email: user.reset.email,
        name: null,
        legacy: false,
        anon: false,
      };
    }

    state.loggedUser = loggedUser;
  },
  SET_DASHBOARD_BLOB(state, data) {
    state.dashboardBlobData = data;
  },

  DELETED_DASHBOARD_BOOKMARK(state, data) {
    const dashboardList = [...state.dashboardList];

    const dashboardIndex = dashboardList.findIndex((dashboard) => {
      return dashboard.dashboard_uid === data.dashboardId;
    });

    if (dashboardIndex < 0) {
      return;
    }

    const dashboard = dashboardList[dashboardIndex];

    if (!dashboard || !dashboard.bookmarks) {
      return;
    }

    const bookmarks = dashboard.bookmarks.filter((bookmark) => {
      return bookmark.id !== data.bookmarkId;
    });

    dashboardList[dashboardIndex].bookmarks = bookmarks;

    state.dashboardList = dashboardList;
  },
  SET_DASHBOARD_LIST(state, data) {
    state.dashboardList = data;
  },
  SET_ACCOUNT_SET(state, load) {
    state.account.account_settings = load.account.account_settings;
  },

  SET_USER_NAME(state, user) {
    state.account.name = user.account.name;
    // legacy users and anon tmp users can't change name
  },

  SET_TOKEN(state, full) {
    /*
    cookies are used to store the auth-token which is used to permit viewing of
    protected documents
    localStorage is used by this app to store an all sorts of stuff about the session
    and user
    */
    // cookies config: (expireTime (180d), path, domain, secure, sameSite)
    let jwt;
    if (full.jwt) {
      jwt = full.jwt;
    } else if (full.token) {
      jwt = full.token;
    }

    state.token = jwt;

    // Vue.$cookies.set(
    //   "auth-token",
    //   jwt,
    //   60 * 60 * 24 * 180,
    //   "/",
    //   state.cookieUrl,
    //   "true",
    //   "Strict"
    // );
  },

  SET_INVITE_ORG(state, data) {
    state.invitedOrg = data;
  },

  SET_ACCESS(state, data) {
    if (data !== "legacy_user") {
      //anonymous temp user OR admin and regular web users
      let roles = data.organization
        ? data.organization.org_roles
        : data.organizations[data.account_settings.primary_org_uid].roles;
      state.access = Object.keys(roles);
    } else {
      state.access = data;
    }
  },

  SET_CURRENT_GAP(state, data) {
    if (data.account) {
      console.log("data.account is true", data);
      let org_uid = data.account.primary_org_uid;
      let gap_uid = data.account.primary_gap_uid;
      if (Object.keys(data.account.organizations[org_uid].profiles).length > 0) {
        if (gap_uid) { // state gap should get primary gap to match with jwt token, important for users with multiple gaps
          state.gap = data.account.organizations[org_uid].profiles[gap_uid];
        }
        else { // if there isn't a primary gap assign first in the list (not sure if there is ever a case with no primary gap)
          let idx = Object.keys(data.account.organizations[org_uid].profiles)[0];
          state.gap = data.account.organizations[org_uid].profiles[idx];
        }
        console.log(state.gap);
      }
    } else if (data.organization) {
      console.log("data.organization is true -- for anon users");
      if (Object.keys(data.organization.org_profiles).length > 0) {
        let idx = Object.keys(data.organization.org_profiles)[0];
        state.gap = data.organization.org_profiles[idx];
        console.log(state.gap);
        let profileSourceDomains = state.gap.profile_sources;
        console.log(profileSourceDomains);
        let sourceDomains = {};
        for (const key of Object.keys(profileSourceDomains)) {
          let domainsList = Object.values(profileSourceDomains[key]);
          domainsList.sort((a, b) => (a.domain_name > b.domain_name ? 1 : -1));
          sourceDomains[key] = domainsList;
        }
        state.availableSourceDomains = sourceDomains;
      }
    }
  },

  SWITCHED_GAP(state, data) {
    state.gap =
      state.account.organizations[state.currentOrg.company_uid].profiles[
        data.gap
      ];
    state.token = data.token;
  },

  SET_SOURCE_LIST(state, data) {
    let list = [];
    let items;
    if (data.organization) {
      if (Object.keys(data.organization.org_profiles).length > 0) {
        items = Object.keys(
          data.organization.org_profiles[
            Object.keys(data.organization.org_profiles)[0]
          ].profile_sources
        );
      }
    } else {
      let org_uid = data.account.primary_org_uid;
      if (data.account.account_settings.primary_gap_uid) {
        items = Object.keys(
          data.account.organizations[org_uid].profiles[
            data.account.account_settings.primary_gap_uid
          ].profile_sources
        );
      }
    }
    console.log(items);
    for (const i in items) {
      if (items[i] == 1) {
        list.push({ value: parseInt(items[i]), name: "Instagram" });
      } else if (items[i] == 3) {
        list.push({ value: parseInt(items[i]), name: "Twitter" });
      } else if (items[i] == 5) {
        list.push({ value: parseInt(items[i]), name: "Reddit" });
      }
      // else if (items[i] == 6) { list.push({value: parseInt(items[i]), name: 'News'})}
      else if (items[i] == 7) {
        list.push({ value: parseInt(items[i]), name: "TikTok" });
      }
    }
    if (list.length > 0) {
      state.currentSource = list[0].value;
      state.sourceList = list;
    }
  },

  SET_CURRENT_SOURCE(state, data) {
    state.currentSource = data;
  },

  SET_DOMAIN_ACCESS(state, data) {
    if (data.primary_gap_uid) {
      let idx = Object.keys(
        data.organizations[data.primary_org_uid].profiles
      )[0];

      let profileSourceDomains =
        data.organizations[data.primary_org_uid].profiles[idx].profile_sources;

      let sourceDomains = {};
      for (const key of Object.keys(profileSourceDomains)) {
        let domainsList = Object.values(profileSourceDomains[key]);
        domainsList.sort((a, b) => (a.domain_name > b.domain_name ? 1 : -1));
        sourceDomains[key] = domainsList;
      }
      state.availableSourceDomains = sourceDomains;
    } else {
      state.availableSourceDomains = "";
    }
  },

  SET_CURRENT_ORG(state, data) {
    if (data.legacy_user) {
      console.log("tourble");
    } else if (data.organization) {
      let currentOrg = {
        company_uid: data.organization.organization_uid,
        company_name: data.organization.name,
      };
      state.currentOrg = currentOrg;
    } else if (data.account.account_settings){
      let currentOrg = {
        company_uid: data.account.account_settings.primary_org_uid,
        company_name: data.account.account_settings.primary_org_name,
      };
      state.currentOrg = currentOrg;
    }
  },

  SET_DEFAULT_ORG(state, org) {
    state.defaultOrg = org.primary_org_name;
  },

  SET_MY_ORGS(state, data) {
    let dict = [];
    for (const o in Object.keys(data)) {
      if (!isNaN(o)) {
        dict.push({
          name: data[Object.keys(data)[o]].name,
          uid: data[Object.keys(data)[o]].organization_uid,
        });
      }
    }
    /*
    // code can be simplified to, but first need to check usage of state.myOrgs
    for (const uid in data) {
      if (!isNaN(uid)) {
        console.log(uid)
        dict.push({name:data[uid].name, uid: data[uid].organization_uid})
      }
    }
     */
    state.myOrgs = dict;
  },

  SET_ORGS_LIST(state, data) {
    state.orgList = data;
  },

  ASSUMED_ORG(state, data) {
    state.assumedOrg = data.data.organization.organization_uid;
  },

  SET_ASSUME_TOKEN(state, data) {
    state.assumeToken = data;
  },

  SET_APP(state, payload) {
    state.ConsumerForecast = payload;
  },

  SET_MODEL_TEST(state, modelTestResults) {
    state.modelTestRestuls = modelTestResults;
  },

  SET_WORKPRODUCTS(state, workProducts) {
    state.workProducts = workProducts;
    if (workProducts && workProducts.length > 0) {
      state.userGuides = state.workProducts.filter(
        (x) => x.category_name === "Customer Training"
      );
    }
  },
  SET_PUBLIC_WORKPRODUCTS(state, workProducts) {
    if (workProducts && workProducts.length > 0) {
      state.publicWorkProducts = workProducts;
    }
  },
  SET_COMPANY(state, company) {
    state.company = company[0];
  },
  LOGOUT_USER(state) {
    // deal with localStorage and cookies
    // TODO: settle on one approach
    //localStorage.removeItem("loggedUser");

    // no idea why this works but it does, without this localStorage.clear() doesn't work
    setTimeout(() => {
      localStorage.clear();
    }, 100);
   
    // Vue.$cookies.remove("auth-token", "/", state.cookieUrl);

    state.loggedUser = {};
    state.workProducts = {};
    state.company = {};
    state.userGuides = {};
    state.account = {};
    state.access = [];
    state.currentOrg = {};
    state.myOrgs = [];
    state.assumeToken = "";
    state.assumedOrg = {};
    state.gap = {};
    state.availableSourceDomains = {};
    state.currentSource = "";
    state.sourceList = [];
  },

  SET_COMPANIES(state, companies) {
    state.companies = companies;
  },
  SET_PERSONNEL(state, personnel) {
    state.personnel = personnel;
  },
  SET_TABS(state, tab) {
    state.portalTabEmit = tab;
  },
  SET_SETTINGS(state, settings) {
    state.userSettings = settings;
  },
  SET_DEMO_SHOW_PERCENTILES(state, isShowPercentiles) {
    state.demoShowPercentiles = isShowPercentiles;
  },
  SET_GEO_SHOW_PERCENTILES(state, isShowPercentiles) {
    state.geoShowPercentiles = isShowPercentiles;
  },
  SET_KEBAB_MENU_STATE(state, menuState) {
    state.kebabMenuState = menuState;
  },
};
