<template>
  <div style="background-image: linear-gradient(to bottom right,  #303A4B, #1a204c);
  color:white;  height:100%; color:#333;">
   
    <v-app-bar style="background-color: transparent;" elevation="0">
      <v-spacer></v-spacer>
      <router-link :to="{name: 'ObjectSearch'}"><v-btn class="btn-hover" style="background-color:transparent; color:#ffff;" elevation="0">Home</v-btn></router-link>
      <!-- <router-link :to="{name: 'portal'}"><v-btn style="background-color:transparent; color:white;" elevation="0">Worklists</v-btn></router-link>
      <router-link :to="{name: 'portal'}"><v-btn style="background-color:transparent;  color:white;" elevation="0">Bookmarks</v-btn></router-link> -->
      <a class="btn-hover" style="background-color:transparent; color:#ffff;" :href="lensesUrl" target="_blank"> Lenses</a>

      <router-link :to="{path: 'help?page=general'}"><v-btn class="btn-hover" style="background-color:transparent; color:#ffff;" elevation="0">FAQ</v-btn></router-link>
       <v-menu
        v-model="accountMenu"
        absolute
        offset-y
        style="max-width: 600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="btn-hover" id="no-background-hover" v-bind="attrs" v-on="on" style="background-color:transparent; margin: 0em 10em 0em 0em; color:#ffff;" elevation="0">Account</v-btn>
        </template>

        <v-list>
          <v-list-item link v-if="!loggedUser.anon">
            <settingsEditor v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">My Account</a>
              </template>
            </settingsEditor>
          </v-list-item>
          <v-list-item v-else>
            <GapSettings v-model="settingsWrap">
              <template v-slot:activator="{ open }">
                <a @click="start(open)" style="color:#333;">Account Settings</a>
              </template>
            </GapSettings>
          </v-list-item>
          <v-list-item>
            <a @click="loggingOff()" style="color:#333;">Logout</a>
          </v-list-item>
        </v-list>
       </v-menu>
    </v-app-bar>

    <v-container style="margin-top:.5em; background-color:#fff; height:1600px;" fluid>

      <v-card
        height="100vh"
        width="256"
        elevation="0"
      >
        <v-navigation-drawer permanent left app floating dark class="drawer" style="background-image: linear-gradient(to bottom left, rgb(48, 58, 75), rgb(26, 32, 76));">
          <v-list-item style="height:70px;">
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                <router-link :to="{name: 'Home'}"><img src="@/assets/SSLogo.png" width="200" style="margin: 1em 0em 0em .2em;"></router-link>
               
              </v-list-item-title>
              <v-list-item-subtitle>
                
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list dense nav >
            <v-list-item link @click="general()">
              General +
            </v-list-item>
            <span v-if="genSub">
              <router-link :to="helpPath('general')">
              <v-list-item link>
                <v-list-item-subtitle style="margin-left:1.5em;">
                  General Home
                </v-list-item-subtitle>
              </v-list-item>
              </router-link>
              <v-list-item link @click="helpPathPush('general#social')">
                <v-list-item-subtitle style="margin-left:1.5em;">
                  Social Standards Intro
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item link @click="helpPathPush('general#objects')">
                <v-list-item-subtitle style="margin-left:1.5em;">
                  What Are Objects?
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item link @click="helpPathPush('general#domains')">
                <v-list-item-subtitle style="margin-left:1.5em;">
                  What Are Market Verticals?
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item link @click="helpPathPush('general#object-space')">
                <v-list-item-subtitle style="margin-left:1.5em;">
                  The Social Standards<br> Object Space
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item link @click="helpPathPush('general#application'), subApp()">
                <v-list-item-subtitle style="margin-left:1.5em;">
                  Consumer Forecast <br>Application Info +
                </v-list-item-subtitle>
              </v-list-item>
                <span v-if="application">
                  <v-list-item link @click="helpPathPush('general#search')">
                  <v-list-item-subtitle style="margin-left:3em;">
                    Search
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item link @click="helpPathPush('general#lookup'), subLookup()">
                  <v-list-item-subtitle style="margin-left:3em;">
                    Lookup View +
                  </v-list-item-subtitle>
                </v-list-item>
                <span v-if="lookupNav">
                <v-list-item link @click="helpPathPush('general#key')">
                  <v-list-item-subtitle style="margin-left:4em;">
                    Key Metrics 
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item link @click="helpPathPush('general#thme')">
                  <v-list-item-subtitle style="margin-left:4em;">
                    Themes 
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item link @click="helpPathPush('general#rel-time')">
                  <v-list-item-subtitle style="margin-left:4em;">
                    Relation Timelines 
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item link @click="helpPathPush('general#demos')">
                  <v-list-item-subtitle style="margin-left:4em;">
                    Demographics
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item link @click="helpPathPush('general#geos')">
                  <v-list-item-subtitle style="margin-left:4em;">
                    Geographies
                  </v-list-item-subtitle>
                </v-list-item>
                <!--
                <v-list-item link @click="helpPathPush('general#psychographics')">
                  <v-list-item-subtitle style="margin-left:4em;">
                    Psychographics
                  </v-list-item-subtitle>
                </v-list-item>
                -->
                <v-list-item link @click="helpPathPush('general#see-also')">
                  <v-list-item-subtitle style="margin-left:4em;">
                    See Also
                  </v-list-item-subtitle>
                </v-list-item>
                </span>
                <v-list-item link @click="helpPathPush('general#worklists')">
                  <v-list-item-subtitle style="margin-left:3em;">
                    Worklists
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item link @click="helpPathPush('general#dashboards')">
                  <v-list-item-subtitle style="margin-left:3em;">
                    Dashboards
                  </v-list-item-subtitle>
                </v-list-item>
                </span>

            </span>


            <v-list-item link  @click="explore()">
              Explore +
            </v-list-item>
            <span v-if="expSub">
              <router-link v-for="(itm, idx) in this.exploreList" :key="idx" :to="helpPath(itm.pageId)">
                <v-list-item link>
                  <v-list-item-subtitle style="margin-left:1.5em;"  v-html="itm.name">

                  </v-list-item-subtitle>
                </v-list-item>
              </router-link>
            </span>


            <v-list-item link  @click="definitions()">
              Terminology +
            </v-list-item>
            <span v-if="defSub">
              <router-link v-for="(itm, idx) in this.terminologyList" :key="idx" :to="helpPath(itm.pageId)">
                <v-list-item link>
                  <v-list-item-subtitle style="margin-left:1.5em;" v-html="itm.name"></v-list-item-subtitle>
                </v-list-item>
              </router-link>
            </span>
            
          </v-list>

        </v-navigation-drawer>
      </v-card>

      <v-container style="position:absolute; top:3em; z-index:10;">

        <v-row v-if="gen && !exp && !def">
          <v-container style="width:80%; padding-top:3em;">
          <v-row justify="left">
            <span style="font-size:1.3em">General FAQ</span>
          </v-row>
          </v-container>
  
          <v-container style="width:80%; padding-top:1.5em;" id="social">
            <v-row>
              <b style="font-size:1.1em; text-decoration:underline;">Social Standards Intro</b>
            </v-row>
            <v-row justify="">
              <div style="padding-top:1em;">
                We capture billions of social data conversations from public posts on Instagram, Twitter & Reddit. We then identify and remove spam and other problematic posts, generating direct access to authentic consumer data. <br>
                <br>
                These data points are then hierarchically structured and normalized by market vertical -- creating a relational dataset where relationships between brands, products and conversation topics can be investigated.
                <br><br>
                This allows for market dynamics to be viewed through different market vertical lenses, where objects can be benchmarked, and their dynamics can be viewed in context. 
                 <br><br>
                Read more about our process <router-link tag="a" :to="{name: 'what-we-do'}">here</router-link>.
              </div>
            </v-row>
          </v-container>

          <v-container style="width:80%;" id="objects">
            <v-row>
              <b>What are Objects?</b>

            </v-row>
            <v-row justify="center">
              <div style="padding-top:1em;">
                Objects are topics of discussion, concepts or a descriptive terms, which are grouped by type. These 'type categories' include:
                <div style="padding-top:1em;">
                  <ul v-for="(obj,idx) in this.objectsList" :key="idx" style="list-style-type:none;">
                    <li><v-icon small :style = "{'color': obj.color}" style="position:relative; bottom:0.1em; padding-right:.3em;">mdi-square-rounded</v-icon>{{obj.name}}</li>
                  </ul>
                </div>
                <br> *Product/Service Type High/Low are differentiated by volume. Where Product/Service Type High objects are high-volume and Product/Service Type Low objects have lower volumes. These thresholds differ by <router-link tag="a" :to="helpPath('definitions#domain')">market vertical</router-link>.
                <br>
                <br> Individual objects can be viewed one at a time through the <router-link tag="a" :to="{name: 'ObjectSearch'}">Lookup application</router-link>. Do this by searching brands, or product types such as "Face Masks", or Benefits & Concerns such as "Vegan".

              </div>
            </v-row>
          </v-container>

          <v-container style="width:80%;" id="domains">
            <v-row>
              <b>What are Market Verticals?</b>
            </v-row>
            <v-row justify="left">
              <div style="padding-top:1em;">
              Social Standards data is structured by market verticals:
              <span v-for="(itm, idx) in this.verticalList" :key="idx">
                <span v-if="idx !== verticalList.length - 1">{{itm.name}}, </span>
                <span v-else> and {{itm.name}}. <br><br></span>
              </span>
              </div>
              <div> 
                 These verticals act as lenses through which we can analyze data.
                 Analyzing objects through a singular verticals at a time allows for precise and actionable benchmarking: by comparing objects to the performance of the average object in that market vertical.<br>
                 The benchmarked metric that is most prevalent in the application is <router-link tag="a" :to="helpPath('definitions#percentile')">Penetration Percentile</router-link>.
              </div>
               
            </v-row>
          </v-container>

          <v-container style="width:80%;" id="object-space">
            <v-row>
              <b>The Social Standards Object Space</b>
            </v-row>
            <v-row style="padding-top:1em;">
              <div>
                The hierarchically structured objects viewed through different market verticals creates the <i>Social Standards Object Space</i>. 
              </div>
            </v-row>
            <v-row>
              <div>
                To understand this better, let's use an example:
                <br><br>
                Let's use the object 'Lipstick' as an example. 'Lipstick' is in the Product/Service Type High category. 'Lipstick' also rolls up into 'Lip Products', which in turn rolls up into 'Face Products' and then into 'Cosmetics'.<br><br>
                 When we view 'Lipstick' in the Consumer Forecast Lite interface, we view it through the <b>market vertical lens</b>. In this case, let's say we're looking at 'Lipstick' through the 'Beauty' market vertical -- this means, that we are viewing 'beauty' social media conversations which mention 'Lipstick'. <br><br>
                 This allows us to benchmark and measure how 'Lipstick' conversations differ from the average Beauty conversation.
                <br><br>
                When analyzing percentiles, objects are categorized into three different buckets of similar object types - brands, produts and descriptive objects.
                This allows for more direct and relevant comparisons as object will be benchmarked against the group of related objects, and not against all objects.
                <br><br>
                <ul>
                  <li>Brands:
                      <span v-for="(itm, idx) in this.brandList" :key="idx">
                        <span v-if="idx !== brandList.length - 1">{{itm.text}}, </span>
                        <span v-else> and {{itm.text}}. <br></span>
                      </span>
                  </li>
                  <li>Products:
                    Products:
                      <span v-for="(itm, idx) in this.productList" :key="idx">
                        <span v-if="idx !== productList.length - 1">{{itm.text}}, </span>
                        <span v-else> and {{itm.text}}. <br></span>
                      </span>
                  </li>
                  <li>Descriptive Objects:
                      <span v-for="(itm, idx) in this.descriptiveList" :key="idx">
                        <span v-if="idx !== descriptiveList.length - 1">{{itm.text}}, </span>
                        <span v-else> and {{itm.text}}. <br></span>
                      </span>
                  </li>
                </ul>
                <br>
                For example, comparing 'e.l.f Cosmetics' penetration with 'Lipstick' relative to other Brands, Organizations, and Brand Extensions in the Beauty vertical rather than to all objects in the Beauty vertical.
              </div>
            </v-row>
          </v-container>

          <v-container style="width:80%; padding-top:3em;" id="application">
            <v-row>
              <b style="font-size:1.1em; text-decoration:underline;">Consumer Forecast Application</b>
            </v-row>
            <!-- <v-row style="padding-top:1em;">
              The Consumer Forecast Application has 3 main parts: 
            </v-row>
            <v-row>
              <ul>
                <li>Lookups</li>
                <li>Dashboards</li>
                <li>Worklists</li>
              </ul>
            </v-row> -->
          </v-container>
          <v-container style="width:80%;" id="search">
            <v-row>
              <b>Consumer Forecast Home: Search</b>
            </v-row>
            <v-row>
              <div id="advanced-search">
                The Consumer Forecast home screen allows the user to navigate to the three elements of the application: the Lookup Views, Workists and Dashboards.
                To access a Lookup view for an object you can search for it through the search bar, or the advanced search option.<br>
                <br>The advanced search option allows for object filtering and sorting:
              </div>
              <div >
                <v-img src="@/assets/ConsumerForecast/AdvancedSearch.png" width="450"></v-img>
              </div>
            </v-row>
            <v-row>
              <div style="padding-top:.5em;">
                If you don't know what to search, find some ideas <router-link tag="a" :to="helpPath('explore')">here</router-link>.
              </div>
            </v-row>
            <v-row>
              <div style="padding-top:1em;">
                <b>Search Results</b>
              </div>
            </v-row>
            <v-row>
              <div style="padding-top:.5em;">
                The generated search results are listed in a table. Running a simple search will bring up the most relevant objects to your search, no matter their object type.
                <br>
                <br>For example the results when the keyword "Tea" is simple-searched:
                <br><br> The first column has the object's <b>name</b>. If the object is a brand, it might have an image of its <b>logo</b>. The third column lists the <b>object's type</b>. The fourth column lists <b>relevant product types</b> for theat object. The last column lists <b>relevant market verticals</b> for the object. 
                <br><br>Note that some objects have insufficient data and cannot be viewed through the look-up tool. 
                <br><br> Click on the table's row to view the object, or right click and choose to open in another tab.
                When directing to an object from the search results page, the object's lookup view will be defaul to the object's <router-link tag="a" :to="{path: '/ConsumerForecast/help' + '?page=definitions#default-domain'}">default market vertical</router-link>.
              </div>
              <div style="padding-top:1em;">
                <v-img src="@/assets/ConsumerForecast/SimpleSearch.png" width="45vw"></v-img>
              </div>
            </v-row>
            <v-row style="padding-top:3em;">
              <div>
                When using the <a href="#advanced-search">advanced search</a>, the filter and sort items will appear above the table.
              </div>
              <div style="padding-top:1em;">
                <v-img src="@/assets/ConsumerForecast/AdvancedResults.png" width="45vw"></v-img>
              </div>
            </v-row>
             <v-row style="padding-top:2em;" id="bookmarks">
              <b>Consumer Forecast Home: Bookmarks</b>
            </v-row>
            <v-row style="padding-top:.5em;">
              Bookmarks allow you to quickly navigate to your favorite objects.
              <br> Add bookmarks by clicking the bookmark icon in the lookup view:
            </v-row>
            <v-row style="padding-top:.5em;">
              <div style="background-color: #D6E1EF; padding:1em;">
                <v-icon class="mark">mdi-bookmark-outline</v-icon>
              </div>
            </v-row>
            <v-row style="padding-top:1em;">
              You can view your bookmarks by clicking the 'Bookmarks' button in the home screen navigation menu.  

            </v-row>
          </v-container>
          <v-container style="width:80%;" id="lookup">
            <v-row>
              <b style="font-size:1.1em;">Lookup View</b>
            </v-row>
            <v-row>
              <div>
                Lookup Views are a way to view real-time data on a single object at a time. 
              </div>
            </v-row>
          </v-container>
          <v-container style="width:77%;" id="key">
            <v-row>
              <div>
                <b>Key Metrics</b>
              </div>
            </v-row>
            <v-row>
              <div style="padding-top:.5em;">
                The key metrics banner has 4 main parts:
                <ul style="padding-top:.7em; list-style-type: decimal">
                  <li>Timeline Chart</li>
                  <li>Growth Metrics</li>
                  <li>Current Metrics</li>
                  <li>Domain Distribution</li>
                </ul>
              </div>
            </v-row>
            <v-row>
              <div style="padding-top:1em;">
                <v-img src="@/assets/ConsumerForecast/KeyMetrics.png" width="45vw"></v-img>
              </div>
            </v-row>
            <v-row style="padding-top:2em;">
              <div>
                1. The Timeline Chart shows the object's activity over time. There are two overlay options, including <router-link :to="helpPath('definitions#retention')">1 month User Retention over time</router-link>, as well as <router-link :to="helpPath('definitions#spam')">Spam %</router-link>.
                <br><br>
                2. The two Growth Metrics include absolute year over year change in the market vertical, as well as relative year over year change in the vertical. If the metric is greater than 0, the color of its container is green, whereas if it's less than 0, its color is orange. 
                <br><br>
                3. The two Current Metrics include Activity in the domain as posts per month, and the current month's <router-link :to="helpPath('definitions#retention')">user retention</router-link>. Both boxes can be clicked on for further insight and information about the metric.
                <br><br>
                4. Market Vertical distribution is a table that lists the top three verticals for that object, and their respective post % allocations. This box is also interactive -- click on it to see more information and a visualization of the table: 
              </div>
              <div style="padding-top:1em;">
                <v-img src="@/assets/ConsumerForecast/DomainDist.png" width="45vw"></v-img>
              </div>
            </v-row>
            <v-row style="padding-top:1.5em;" id="thme">
              <div>
                <b>Themes</b>
                <br><br>Themes are combined relational objects that have a unifying theme or topic. <br><br>
              </div>
            </v-row>
            
<!--         KB pages are currently internal/private
            <v-row style="padding-top:1em;">
              The themes for the <div style="width:200px; position:relative; bottom:1.8em; margin-left:.5em; margin-right:.5em;">
              <v-select
                  v-model="themesSelect"
                  :items="themes"
                  item-value="domain"
                  item-text="domain"
                  :menu-props="{bottom: true, offsetY: true, maxHeight: 450}"
                  height="100%">
              </v-select></div>
              market vertical include:
            </v-row>
            
            <v-row style="position:relative; bottom:2em;">
              <v-col xs4>
                <ul v-for="(item,idx) in themes[themeValue].items.slice(0,startStop.step)" :key="idx">
                  <v-tooltip light left>
                    <template v-slot:activator="{ on, attrs }">
                      <li v-bind="attrs" v-on="on">{{item.name}}</li>
                    </template>
                    {{item.definition}}
                  </v-tooltip>
                </ul>
              </v-col>
              <v-col xs4>
                <ul v-for="(item,idx) in themes[themeValue].items.slice(startStop.step,startStop.step*2)" :key="idx">
                  <v-tooltip light left>
                    <template v-slot:activator="{ on, attrs }">
                      <li v-bind="attrs" v-on="on">{{item.name}}</li>
                    </template>
                    {{item.definition}}
                  </v-tooltip>
                </ul>
              </v-col>
              <v-col xs4>
                <ul v-for="(item,idx) in themes[themeValue].items.slice(startStop.step*2,startStop.len)" :key="idx">
                  <v-tooltip light left>
                    <template v-slot:activator="{ on, attrs }">
                      <li v-bind="attrs" v-on="on">{{item.name}}</li>
                    </template>
                    {{item.definition}}
                  </v-tooltip>
                </ul>
            </v-row>-->

            <v-row>
              <div>
                Themes are displayed in a 2 column table, as seen below:
              </div>
            </v-row>
            <v-row style="padding-top:1.5em;">
              <div>
                <v-img src="@/assets/ConsumerForecast/Themes.png" width="45vw"></v-img>
              </div>
            </v-row>
            <v-row style="padding-top:1em;">
              <div>
                <span style="text-decoration: underline">Percentile Column:</span>
                 Lists and visualizes the <router-link :to="helpPath('definitions#percentile')">Penetration Percentile</router-link> of the theme to the <router-link :to="helpPath('definitions#focus')">focus object</router-link>.
                <br><br><span style="text-decoration: underline">Chng Column:</span> Lists the relative 3 month change of the penetration percentile. This shows how the significance of the theme to the object has changed over the past 3 months.
              </div>
            </v-row>

            <v-row style="padding-top:2em;" id="rel-time">
              <div>
                <b>Relations Timelines</b>
              </div>
            </v-row>
            <v-row style="padding-top:.7em;">
              <div>
                Relations Timelines are available for Brands, Brand Extensions, Organizations and Product Types.
                <br><br>
                There can be two charts in this section: a chart of Linked-Objects and a chart of Sub-Objects. Not all objects will have both available, some might have only one, or neither.
                <br><br>Double click on the chart items to open their lookup view in a new tab.
                <br><br><span style="text-decoration:underline;">Linked Objects Relations Timeline</span> is composed of objects that are explicitly linked in our database to our object. In this chart, the objects are limited to (if your focus object is a brand, the parent organization) as well as Product Types High/Low.
                <br><br>The chart shows the <router-link :to="helpPath('definitions#raw')">raw penetration %</router-link> of the linked object to the <router-link :to="helpPath('definitions#focus')">focus object</router-link> over time, overlayed on the linked object's volume.
              </div>
            </v-row>
            <v-row style="padding-top:1em;">
              <div>
                <v-img src="@/assets/ConsumerForecast/linked.png" width="45vw"></v-img>
              </div>
            </v-row>
            <v-row style="padding-top:2em;">
              <div>
                <span style="text-decoration:underline;">Sub-Objects Relations Timeline</span> is composed of 'Sub Objects',  which are those that are children of <router-link :to="helpPath('definitions#focus')">focus object</router-link>, allowing them to be compared against <router-link :to="helpPath('definitions#focus')">focus object.</router-link> In this chart, the objects are limited to brands and brand extensions.
                <br><br>The chart shows the <router-link :to="helpPath('definitions#reverse')">reverse penetration %</router-link> of the <router-link :to="helpPath('definitions#focus')">focus object</router-link> to the sub-object over time, overlayed on the sub-object's volume.
              </div>
            </v-row>
            <v-row style="padding-top:2em;">
              <div>
                <v-img src="@/assets/ConsumerForecast/sub.png" width="45vw"></v-img>
              </div>
            </v-row>
            <v-row style="padding-top:1.5em;" id="demos">
              <div>
                <b>Demographics</b>
                <br><br>There are 4 dimension to demographics: Gender, Age, Race and Income. 
                <br><br>For all dimensions both Percentile and Raw % are shown. However, percentile is presented first, since the metric is benchmarked. This is especially important with regards to gender, since Instagram, for example, has more female than male users, skewing raw metrics. By viewing percentiles, we can understand how much a topic/object skews towards a gender more than an average conversation relative to similarly categorized objects within the selected market vertical.
                <br><br>Income data is split into 3 categories: Low, Mid and High. We define these as: 
                <br>
                <ul>
                  <li>Low: below $10,000 - $29,999 Annually</li>
                  <li>Mid: $30,000 - $74,999 Annually</li>
                  <li>High: $75,000 - $100,000 and over Annually</li>
                </ul>
              </div>
            </v-row>
            <v-row style="padding-top:1.5em;" id="geos">
              <div>
                <b>Geographies</b>
                <br><br>Geographies are shown for Global Regions/Global Countries and USA Regions/USA States/USA MSAs. The default view for both Global and USA are percentiles.
                <br><br>Percentiles allow for normalization, correcting the impact of region/country/state/MSA population size. 
                <br><br>You can toggle to view raw % by toggling here:
                <br><br><v-img src="@/assets/ConsumerForecast/geos.png" width="45vw"></v-img>

              </div>
            </v-row>
<!--            <v-row style="padding-top:1.5em;" id="psychographics">
              <div>
                <b>Psychographics</b>
                <br><br>Psychographics show the affinities and interests of those talking about the <router-link :to="helpPath('definitions#focus')">focus object</router-link>.
                <br><br>These are ordered by <router-link tag="a" :to="helpPath('definitions#percentile')">Penetration Percentile</router-link> descending, allowing you to see what sorts of topics the <router-link :to="helpPath('definitions#focus')">focus object</router-link> audience over-indexes with.
              </div>
            </v-row>-->
            <v-row style="padding-top:1.5em;" id="see-also">
              <div>
                <b>See Also</b>
                <br><br>See also links are navigatory. The see also configuration varies depending on the <router-link :to="helpPath('definitions#focus')">focus object's</router-link> object type. The list may include other objects the <router-link :to="helpPath('definitions#focus')">focus object</router-link> is hierarchically linked to, or similar objects.
              </div>
            </v-row>
          </v-container>
          <v-container style="width:80%;" id="worklists">
            <v-row>
              <b>Worklists</b>
            </v-row>
          </v-container>
          <v-container style="width:80%;" id="dashboards">
            <v-row>
              <b>Dashboards</b>
            </v-row>
          </v-container>

        </v-row>

        <v-row justify="center" v-if="!gen && !def && exp" id="space">
          <v-container style="width:80%; padding-top:3em;">
            <v-row justify="left">
            <span style="font-size:1.3em;">Explore</span> 
          </v-row>
          </v-container>

          <v-container style="width:80%;">
            <v-row>
              <b>What does the Social Standards <i>Object Space</i> Look like?</b>
            </v-row>
            <v-row style="padding-top:1em;">
              <div> 
                 The Social Standards Objects Space is composed of <router-link :to="helpPath('general#objects')">Objects</router-link> categorized by type, structured in hierarchies and viewed through <router-link :to="helpPath('general#domains')">Market Verticals.</router-link>
                 <br><br> Please read the <router-link :to="helpPath('general')">Social Standards Intro</router-link> section for a more detailed description.
              </div>
            </v-row>
            <v-row>
              <div style="padding-top:1em;">
                <b style="font-size:.9em;">So what sorts of things can be searched for?</b>
              </div>
              <div style="padding-top:1em;">
                Try searching for a brand that you interested in finding out more about, or a product that you've been hearing a lot about lately. Maybe an ingredient that you've been reading a lot about in skin care or wellness blogs. 
                You can also search for broader concepts and topics such as 'Hair Care' and 'Sustainability'.
              </div>
            </v-row>
            <v-row style="padding-top:2em;" id="lists">
              <div>
                <b style="font-size:1.2em;">Browse Products:</b>
              </div>
            </v-row>
            <v-row style="padding-top:2em;">
              <Browse />
            </v-row>
          </v-container>

        </v-row>

        <v-row justify="center" v-if="!gen && def && !exp">
          <v-container style="padding-top:3em; width:80%;">
            <v-row justify="left">
          <span style="font-size:1.3em;">Terminology</span>  
            </v-row>
          </v-container>

          <v-container style="width:80%;" id="domain">
            <v-row>
              <b>Market Vertical</b>
            </v-row>
            <v-row >
              <div class="a">
                Market Vertical definition
              </div>
            </v-row>
          </v-container>

          <v-container style="width:80%;" id="default-domain">
            <v-row>
              <b>Default Market Vertical</b>
            </v-row>
            <v-row >
              <div class="a">
                An object's default market vertical is the vertical to which most of its posts are allocated. 
              </div>
            </v-row>
          </v-container>
          <v-container style="width:80%;" id="focus">
            <v-row>
              <b>Focus Object</b>
            </v-row>
            <v-row >
              <div class="a">
                The object of interest. In the Lookup, this is the object whose page you're on.
              </div>
            </v-row>
          </v-container>

          <v-container style="width:80%;" id="percentile">
            <v-row>
              <b>Penetration Percentile</b>
            </v-row>
            <v-row justify="center">
              <div class="a">
                Percentiles provide information regarding where an object ranks on a distribution. The median of a distribution is the 50th percentile. If an object is in the 75th percentile, only 25% of the population ranks higher than it on the metric of interest relative to similarly categorized objects in the selected market vertical.
              </div>
            </v-row>
          </v-container>
          <v-container style="width:80%;" id="raw">
            <v-row>
              <b>Raw Penetration</b>
            </v-row>
            <v-row justify="center">
              <div class="a">
                Raw Penetration represents the percentage of the number of conversations related to an object among all conversations on a <router-link :to="helpPath('definitions#focus')">focus object</router-link>. This is a factual co-occurrence-based measure. If an object has a penetration of 3.7% this means that 3.7% of all conversations on a <router-link :to="helpPath('definitions#focus')">focus object</router-link> also mention the comparison object.
              </div>
            </v-row>
          </v-container>
          <v-container style="width:80%;" id="reverse">
            <v-row>
              <b>Reverse Penetration</b>
            </v-row>
            <v-row justify="center">
              <div class="a">
                Reverse penetration represents percentage of the number of conversations related to a focus subject among all conversations on a comparison subject. This is a factual co-occurrence-based measure. If a subject has a reverse penetration of 3.7% this means that 3.7% of all conversations on a comparison subject also mention the focus subject.
              </div>
            </v-row>
          </v-container>
          <v-container style="width:80%;" id="spam">
            <v-row>
              <b>Spam Percent</b>
            </v-row>
            <v-row justify="">
              <div class="a">
                The percentage of posts for an object that have been identified as spam. When viewing an object in the <router-link :to="helpPath('general#lookup')">Lookup View</router-link> the data is composed of "authentic posts", which means that all spam posts are removed. Therefore, spam % is calculated as (spam posts / (authentic posts + spam posts)).
              </div>
            </v-row>
          </v-container>
          <v-container style="width:80%;" id="retention">
            <v-row>
              <b>User Retention</b>
            </v-row>
            <v-row justify="center">
              <div class="a">
                User retention is Social Standards’ metric for understanding if an object is retaining its loyalists. In other words, are the people talking about an object last month (or 2, 3, 4, 5, or 6 months ago) still talking about the same object this month.
              </div>
            </v-row>
            <v-row style="padding-top:1em;">
              User Retention Metrics:
            </v-row>
            <v-row>
              <ul>
                <li>1 Month User Retention:</li>
                <li>User Retention Curve:</li>
              </ul>
            </v-row>
            <v-row style="padding-top:1em;">
              <div>
              To read more about your  <router-link :to="helpPath('definitions#focus')"> focus object's</router-link> user retention click on the 'Current 1 Month User Retention' box in the <router-link :to="helpPath('general#key')"> Key Metrics </router-link> section:
              </div>
            </v-row>
            <v-row style="padding-top:1em;">
              <div>
                <v-img src="@/assets/ConsumerForecast/UserRetMetric.png" width="40vw"></v-img>
              </div>
            </v-row>
          </v-container>
        </v-row>

      </v-container>
      
        
      
      
      <v-row justify="center">
        
      </v-row>
    </v-container>
       
       

  </div>
  
</template>

<script>
import {mapActions, mapState} from 'vuex';
import settingsEditor from '@/components/Navigation/SettingsEditor.vue';
import GapSettings from '@/components/AdminMgmt/GapSettings.vue';
import processingMixin from '@/mixins/processingMixin';
import Browse from '@/components/ConsumerForecast/Browse.vue';
import objectFunctions from '@/mixins/objectFunctions';



export default {
  name: 'faq-page',
  mixin: [processingMixin, objectFunctions],
  data() {
    return {
      genSub: false,
      defSub: false,
      expSub: false,
      application: false,
      lookupNav: false,
      objectsList: objectFunctions.allObjectTypes,
      brandList: objectFunctions.brands,
      productList: objectFunctions.products,
      descriptiveList: objectFunctions.descriptive,
      verticalList: objectFunctions.domains,
      exploreList:[
        {name: 'Explore Home', pageId: 'explore'},
        {name: 'What Does the Social Standards <br> Object Space Look Like?', pageId: 'explore#space'},
        {name: 'Browse Products', pageId: 'explore#lists'},
      ],
      generalList: [
        {name: 'General Home', pageId: 'general'},
        {name: 'Social Standards Intro', pageId: 'general#social'},
        {name: 'What Are Objects?', pageId: 'general#objects'},
        {name: 'What Are Market Verticals?', pageId: 'general#domains'},
        {name: 'The Social Standards <br> Object Space', pageId: 'general#object-space'},
        {name: 'Consumer Forecast <br> Application Info +', pageId: 'general#application',
           exapndSubList: function (){
              console.log("Exapned sublist")
              this.application = !this.application
              console.log(this.application)
              return this.application
           },
           subList: [
              {name: 'Search', pageId: 'general#search'},
              {name: 'Lookup View +', pageId: 'general#lookup',  exapndSubList: this.subLookup(),
                subList:[
                  {name: 'Key Metrics', pageId: 'general#key'},
                  {name: 'Themes', pageId: 'general#thme'},
                  {name: 'Relation Timelines', pageId: 'general#rel-time'},
                  {name: 'Demographics', pageId: 'general#demos'},
                  {name: 'Geographies', pageId: 'general#geos'},
                  {name: 'See Also', pageId: 'general#see-also'},
                ]
              },
              {name: 'Worklists', pageId: 'general#worklists'},
              {name: 'Dashboards', pageId: 'general#dashboards'},
           ]
        },

        ],
      terminologyList: [
        {name: 'Terminology Home', pageId: 'definitions'},
        {name: 'Market Vertical', pageId: 'definitions#domain'},
        {name: 'Default Market Vertikal', pageId: 'definitions#default-domain'},
        {name: 'Penetration Percentile', pageId: 'definitions#percentile'},
        {name: 'Raw Penetration', pageId: 'definitions#raw'},
        {name: 'Reverse Penetration', pageId: 'definitions#reverse'},
        {name: 'Spam Percent', pageId: 'definitions#spam'},
        {name: 'User Retention', pageId: 'definitions#retention'},
        ],
      themesSelect: '',
      themes: [
        {
          domain: 'Beauty', 
          domain_sid: 9022,
          theme_value: 0,
          items: [
            { name: 'Access & Affordability',
            definition: 'Combined object of topics relating to accessibility, affordable pricing and financial inequaly.' },
            { name: 'Active Ingredients',
            definition: '' },
            { name: 'Activism Lifestyle',
            definition: 'Combined object of topics related to public political actions such as protesting, boycotting and voting.' },
            { name: 'Adventurous Lifestyle',
            definition: 'Combined object of topics related to active outdoor and travel lifestyles.' },
            { name: 'Animal Welfare',
            definition: 'Combined object of topics related to topics around animal cruelty, and conservation.' },
            { name: 'Art',
            definition: '' },
            { name: 'Delivery',
            definition: '' },
            { name: 'Diversity & Inclusion',
            definition: '' },
            { name: 'Ecommerce',
            definition: '' },
            { name: 'Engagement',
            definition: '' },
            { name: 'Environmental Concerns',
            definition: '' },
            { name: 'Fruit',
            definition: '' },
            { name: 'Governance',
            definition: '' },
            { name: 'Gum/Mints',
            definition: '' },
            { name: 'Hair Care',
            definition: '' },
            { name: 'Having Fun',
            definition: '' },
            { name: 'Healthy Lifestyle',
            definition: '' },
            { name: 'Medical Devices',
            definition: '' },
            { name: 'Nail Care',
            definition: '' },
            { name: 'Natural',
            definition: '' },
            { name: 'Nightlife/Party Lifestyle',
            definition: 'Combined object of topics related to partying, food and drink locales, as well as nightlife activities.' },
            { name: 'Non Toxic Lifestyle',
            definition: 'Combined object of topics related to chemical, toxin and pesticide free attitudes.' },
            { name: 'Packaging',
            definition: '' },
            { name: 'Self Care Lifestyle',
            definition: '' },
            { name: 'Skin Care',
            definition: '' },
            { name: 'Supplements',
            definition: '' },
            { name: 'Travelcentric Lifestyle',
            definition: '' },
          ]
        },
        {
          domain: 'Apparel / Fashion', 
          domain_sid: 9549,
          theme_value: 1,
          items: [
            { name: 'Access & Affordability',
            definition: 'Combined object of topics related to accessibility, affordable pricing and financial inequaly.' },
            { name: 'Active Ingredients',
            definition: '' },
            { name: 'Adventurous Lifestyle',
            definition: 'Combined object of topics related to active outdoor and travel lifestyles.' },
            { name: 'Animal Welfare',
            definition: 'Combined object of topics related to topics around animal cruelty, and conservation.' },
            { name: 'Art',
            definition: '' },
            { name: 'Cannabis',
            definition: '' },
            { name: 'Delivery',
            definition: '' },
            { name: 'Diversity & Inclusion',
            definition: '' },
            { name: 'Ecommerce',
            definition: '' },
            { name: 'Engagement',
            definition: '' },
            { name: 'Environmental Concerns',
            definition: '' },
            { name: 'Exercise & Fitness',
            definition: '' },
            { name: 'Governance',
            definition: '' },
            { name: 'Hair Care',
            definition: '' },
            { name: 'Having Fun',
            definition: '' },
            { name: 'Healthy Lifestyle',
            definition: '' },
            { name: 'Human Rights & Community Relations',
            definition: '' },
            { name: 'Music',
            definition: '' },
            { name: 'Nail Care',
            definition: '' },
            { name: 'Natural',
            definition: '' },
            { name: 'Nightlife/Party Lifestyle',
            definition: 'Combined object of topics related to partying, food and drink locales, as well as nightlife activities.' },
            { name: 'Non Toxic Lifestyle',
            definition: 'Combined object of topics related to chemical, toxin and pesticide free attitudes.' },
            { name: 'Packaging',
            definition: '' },
            { name: 'Physically Active Lifestyle',
            definition: '' },
            { name: 'Self Care Lifestyle',
            definition: '' },
            { name: 'Skin Care',
            definition: '' },
            { name: 'Travelcentric Lifestyle',
            definition: '' },
            { name: 'Video Games',
            definition: '' },
            { name: 'Weather',
            definition: '' },
          ]
        },
        {
          domain: 'Personal Care', 
          domain_sid: 9549,
          theme_value: 2,
          items: [
            { name: 'Access & Affordability',
            definition: 'Combined object of topics related to accessibility, affordable pricing and financial inequaly.' },
            { name: 'Animal Welfare',
            definition: 'Combined object of topics related to topics around animal cruelty, and conservation.' },
            { name: 'Art',
            definition: '' },
            { name: 'Delivery',
            definition: '' },
            { name: 'Diversity & Inclusion',
            definition: '' },
            { name: 'Ecommerce',
            definition: '' },
            { name: 'Engagement',
            definition: '' },
            { name: 'Environmental Concerns',
            definition: '' },
            { name: 'Fruit',
            definition: '' },
            { name: 'Governance',
            definition: '' },
            { name: 'Hair Care',
            definition: '' },
            { name: 'Having Fun',
            definition: '' },
            { name: 'Medical Devices',
            definition: '' },
            { name: 'Nail Care',
            definition: '' },
            { name: 'Natural',
            definition: '' },
            { name: 'Packaging',
            definition: '' },
            { name: 'Self Care Lifestyle',
            definition: '' },
            { name: 'Skin Care',
            definition: '' },
            { name: 'Supplements',
            definition: '' },
            { name: 'Travelcentric Lifestyle',
            definition: '' },
          ]
        },
        {
          domain: 'Beverage Alcohol', 
          domain_sid: 1836,
          theme_value: 3,
          items: [
            { name: 'Access & Affordability',
            definition: 'Combined object of topics related to accessibility, affordable pricing and financial inequaly.' },
             { name: 'Adventurous Lifestyle',
            definition: 'Combined object of topics related to active outdoor and travel lifestyles.'},
            { name: 'Animal Welfare',
            definition: 'Combined object of topics related to topics around animal cruelty, and conservation.' },
            { name: 'Beer',
            definition: '' },
            { name: 'Candy',
            definition: '' },
            { name: 'Cannabis',
            definition: '' },
            { name: 'Cheese',
            definition: '' },
            { name: 'Coffee',
            definition: '' },
            { name: 'Cooking',
            definition: '' },
            { name: 'Delivery',
            definition: '' },
            { name: 'Dining',
            definition: '' },
            { name: 'Diversity & Inclusion',
            definition: '' },
            { name: 'Engagement',
            definition: '' },
            { name: 'Environmental Concerns',
            definition: '' },
            { name: 'Food',
            definition: '' },
            { name: 'Fruit',
            definition: '' },
            { name: 'Governance',
            definition: '' },
            { name: 'Gum/Mints',
            definition: '' },
            { name: 'Hard Seltzer',
            definition: '' },
            { name: 'Hair Care',
            definition: '' },
            { name: 'Having Fun',
            definition: '' },
            { name: 'Healthy Lifestyle',
            definition: '' },
            { name: 'Human Rights & Community Relations',
            definition: '' },
            { name: 'Meat Substitutes',
            definition: '' },
            { name: 'Music',
            definition: '' },
            { name: 'Music',
            definition: 'Natural' },
            { name: 'Nightlife/Party Lifestyle',
            definition: 'Combined object of topics related to partying, food and drink locales, as well as nightlife activities.' },
            { name: 'Nut and Seed Butters',
            definition: '' },
            { name: 'Packaging',
            definition: '' },
            { name: 'RTD Cocktails',
            definition: '' },
            { name: 'Seasoning/Spices',
            definition: '' },
            { name: 'Self Care Lifestyle',
            definition: '' },
             { name: 'Sparkling Wine',
            definition: '' },
             { name: 'Sugar and Sweeteners',
            definition: '' },             
            { name: 'Travelcentric Lifestyle',
            definition: '' },
            { name: 'Weather',
            definition: '' },
             { name: 'Wine',
            definition: '' },
          ]
        },

      ]
    }
  },
  components: {
    settingsEditor,
    Browse,
    GapSettings,
  },
  created() {
    this.themesSelect = 'Beauty'
    if (this.$route.query.dom) {
      this.themesSelect = this.$route.query.dom
    }
    console.log(this.themesSelect)
    this.log()
  },

  computed: {
    ...mapState([
      'loggedUser',
    ]),

    themeValue() {
      var value = 0;
      if (this.themesSelect === 'Apparel / Fashion') { value = 1}
      else if (this.themesSelect === 'Personal Care') { value = 2}
      else if (this.themesSelect === 'Beverage Alcohol') { value = 3}
      return value
    },

    startStop() {
      var value = {step: 9, len: 27 }
      if (this.themesSelect === 'Apparel / Fashion') { value.step = 10, value.len = 30 }
      else if (this.themesSelect === 'Personal Care') { value.step = 7, value.len = 20}
      else if (this.themesSelect === 'Beverage Alcohol') { value.step = 12, value.len = 36}
      return value
    },

    exp() {
      return this.$route.query.page === 'explore'
    },

    gen() {
      return this.$route.query.page === 'general'
    },

    def() {
      return this.$route.query.page === 'definitions'
    },

    lensesUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/reports-list' // Prod URL
        : 'https://app.socialstandards.net/reports-list'; // Dev URL
    },


  },

  methods: {

    ...mapActions([
      'logoutUser',
    ]),

    log() {
      this.logPageLoad({
        app_name: 'CF',
        page_name: this.$options.name,
        params: this.$route.query.page
    })
    },

    start: function(method) {
        setTimeout(() => method());
      },

    loggingOff() {
      this.logoutUser()
      this.$router.push({name: 'Home'});
      this.logAction({
        event: 'logout'
      });
    },

    explore() {
      this.expSub = !this.expSub
    },

    helpPath(id = 'general') {
      return {path: '/ConsumerForecast/help?page=' + id}
    },

    helpPathPush(id) {
      this.$router.push(this.helpPath(id))
    },

    general() {
      this.genSub = !this.genSub
    },

    definitions() {
      this.defSub = !this.defSub
    },

    subApp() {
      console.log("Exapned sublist")
      this.application = !this.application
      return this.application
    },
    subLookup() {
      this.lookupNav = !this.lookupNav
      return this.lookupNav
    }
  }
}
</script>

<style lang="scss" scoped>

.mark {
  color:#fff;
}
.mark:hover {
  color:#6387b0;
}

.btn-hover:hover {
  color:#71C6E6 !important;
}

#no-background-hover::before {
   background-color: transparent !important;
}

.drawer {
  color: #ffff !important;
  background-color:  #1a204c !important;
  border: 0px solid transparent;
  border-radius: 0px !important;
  
}

// .drawer-card {
  
//   //background-color: #f3faff !important;
//   z-index:1000;
//   background-color: transparent !important;
//   border-radius: 0px;
// }

div.a {
  text-indent: 50px;
  padding-top:1em;
}
</style>
