<template>
  <v-app-bar style="background-color: #131737; color: #ffff">
    <router-link :to="{ name: 'Home' }"
      ><img
        src="@/assets/SSLogo.png"
        width="200"
        style="margin: 0.5em 0em 0em 3em"
    /></router-link>
    <v-spacer></v-spacer>

    <span v-if="!isAdmin"
      ><router-link :to="{ name: 'client-home' }"
        ><a class="appbar-items" style="margin-right: 2em; color: #ffff"
          >Client Home</a
        ></router-link
      ></span
    >
    <span v-else
      ><router-link :to="{ name: 'client-home' }"
        ><a class="appbar-items" style="margin-right: 2em; color: #ffff"
          >Internal Home</a
        ></router-link
      ></span
    >

    <span v-if="!getLoggedUser.anon"
      ><router-link
        v-if="
          this.getCurrentGap.portal && !$route.path.includes('/AppView/portal')
        "
        :to="{ name: 'portal' }"
        ><a class="appbar-items" style="margin-right: 2em; color: #ffff"
          >Portal</a
        ></router-link
      ></span
    >

    <span v-if="!loggedUser.legacy"
      ><router-link
        v-if="this.getCurrentGap.consumer_forecast"
        :to="{ name: 'ObjectSearch' }"
        ><a class="appbar-items" style="margin-right: 1.5em; color: #ffff"
          >CF Lite</a
        ></router-link
      ></span
    >

    <span v-if="getCurrentGap.dashboards">
       <a class="appbar-items" style="margin-right: 2em; color: #ffff;"
          :href="lensesUrl" target="_blank">
            Lenses</a>
    </span>

    <span v-if="loggedUser.legacy == false && getCurrentGap.brand_pulse">
        <a class="appbar-items" style="margin-right: 2em; color: #ffff;"
           :href="brandMapsUrl" target="_blank">
          Brand Maps</a>
    </span>


    <v-toolbar-items v-if="isLoggedIn && !loggedUser.legacy && !loggedUser.anon"
      ><LoggedInMenu
    /></v-toolbar-items>
    <a
      v-else-if="isLoggedIn && loggedUser.legacy"
      @click="Logout()"
      class="appbar-items"
      style="margin-right: 2em; color: #ffff"
      >Logout</a
    >
    <a
      v-else-if="isLoggedIn && loggedUser.anon"
      @click="Logout()"
      class="appbar-items"
      style="margin-right: 2em; color: #ffff"
      >Exit Preview</a
    >
    <router-link v-if="isAdmin" :to="{ name: 'admin-view' }"
      ><a class="appbar-items" style="margin-right: 2em; color: #ffff"
        >Admin</a
      ></router-link
    >
  </v-app-bar>
</template>

<script>
import LoggedInMenu from "@/components/Navigation/LoggedInMenu";
import { mapActions, mapGetters, mapState } from "vuex";
import processingMixin from "@/mixins/processingMixin";

export default {
  name: "app-nav-bar",
  mixins: [processingMixin],
  components: {
    LoggedInMenu,
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isAdmin", "getLoggedUser", "getCurrentGap"]),
    ...mapState(["loggedUser"]),
    ...mapActions(["logoutUser"]),
    brandMapsUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/brand-maps' // Prod URL
        : 'https://app.socialstandards.net/brand-maps'; // Dev URL
    },
    lensesUrl() {
      const isProduction = process.env.NODE_ENV === 'production';
      return isProduction ? 'https://app.socialstandards.com/reports-list' // Prod URL
        : 'https://app.socialstandards.net/reports-list'; // Dev URL
    },
  },
  methods: {
    Logout() {
      this.$router.push({ name: "Home" });
      this.logoutUser();
      this.logAction({
        event: "logout",
      });
    },
    start: function (method) {
      setTimeout(() => method());
    },
  },
};
</script>

<style lang="scss" scoped>
.appbar-items:hover {
  color: #6ecaeb !important;
}
.appbar-items {
  font-size: 1.05em;
  letter-spacing: 2px;
}
</style>
