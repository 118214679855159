<template>
<div>
  <v-dialog 
   max-width="600"
   v-model="marksDialog" 
   v-bind="$attrs" 
   v-on="$listeners"
   >

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" :open="open"/>
    </template>

    <v-card style="height:100%; padding-bottom:1.5em;" v-if="!load">
      
        <v-card-title>
          Your Bookmarks
          <v-icon @click="openMarks()" style="position:absolute; right:.5em; top:.5em;" sm>mdi-close</v-icon>
        </v-card-title>
        <v-card-text v-if="getAccount.account_settings.bookmarks">
          <v-simple-table dense style="padding-top:1em;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Date Added
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, idx) in getAccount.account_settings.bookmarks"
                  :key="idx"
                > <td style="width:50px;"><a @click="removeBookmark(item)"><v-icon class="mark" small>mdi-bookmark</v-icon></a></td>
                  <td><a @click="pushBookmark(item)">{{ item.object_name }}</a></td>
                  <td>{{ transDateString(item.date_added) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-else>
          You have no bookmarks.<br>Learn how to add bookmarks <router-link class="card-titles" style="color:#0277BD;" target="_blank" tag="a" :to="{path: '/ConsumerForecast/help?page=general#bookmarks'}">here. </router-link>
        </v-card-text>
    
      </v-card>
      <v-card v-else>
        <v-card-text style="padding:4em;">
          <v-progress-linear
            indeterminate
            color="light-blue lighten-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    
  </v-dialog>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import stringFunctions from '@/mixins/stringFunctions';
import objectFunctions from '@/mixins/objectFunctions';

export default {
  name: 'bookmarks-dialog',
  props: {
    value: String
  },
  data() {
    return {
      marksDialog: false,
      load: false,

    }
  },
  mixins: [
    stringFunctions,
    objectFunctions
  ],
  computed: {
    ...mapGetters([
      'getAccount',
      'getCurrentSource',
    ])
  },
  methods: {
    ...mapActions([
      'updateSettings'
    ]),

    open() {
      this.marksDialog = true;
    },

    removeBookmark: async function(item) {
      let settings = this.getAccount.account_settings
      //console.log('remove bookmark')
      settings.bookmarks = settings.bookmarks.filter(x => x.object_sid != item.object_sid)
      let payload = {
        token: this.getAccount.token,
        settings: settings
      }
      await this.updateSettings(payload)
      .then((data) => {
        console.log(data);
        })     
      .catch((err) => {
        console.log(err)
        console.log(err.response)
        }) 
    },

    pushBookmark(item) {
      this.load = true
      if (this.$route.query.sid) { 
        console.log('Bookmark')
        console.log(item)
        this.$emit('bookmarkPush', {sid: item.object_sid, domain: item.domain,
                                                type: item.type_code, source: this.getCurrentSource})
      }
      else {
        console.log('Bookmark ELSE')
        console.log(this.$route.query)
        console.log(item)
        this.$router.push(this.getLookupViewPath(item.object_sid, item.domain, item.type_code, this.getCurrentSource))
      }
      
    },

    openMarks() {
      this.marksDialog = !this.marksDialog;
    }
  },
}
</script>

<style lang="scss" scoped>
  .mark {
    color: #274b69;
  }
  .mark:hover {
    color: #BDBDBD;
  }
</style>