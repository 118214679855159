<template>
  <nav id="nav" class="no-print" style="z-index: 999999; position: relative">
  <v-app-bar
      v-if="!$route.path.includes('ConsumerForecast/object-search')"
      id="navbar"
      app
      :style="background"
      style="height: 52px; padding: 0"
      elevation=0
      clipped-left
      fixed
  >

      <ForecastContent settingsWrap="" bookmarksWrap="" :isSticky="true" style="width: 100%"/>
    </v-app-bar>

    <ForecastContent settingsWrap="" bookmarksWrap="" :isSticky="false" style="padding-right: 1em" v-if="$route.path.includes('ConsumerForecast/object-search')" />
  </nav>

</template>
<script>
import Vue from "vue";
import ForecastContent from "@/components/Navigation/ForecastContent.vue";

export default Vue.extend({
  name: "forecast",
  components: {
    ForecastContent,
  },
  computed: {
    background() {
      if (this.$route.path.includes('ConsumerForecast/object-search')) {
        return 'background-color: transparent';
      }
      return "background-color: #131737"
    }
  }
});
</script>
<style>

#nav a {
  text-decoration: none;
}

#nav header > div {
  height: 52px !important;
  padding: 0 14px 0 0;
}

#nav {
  text-transform: uppercase;
}

#app > div.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  z-index: 9999999 !important;
}
</style>