<template>
  <v-row v-if="loaded">
        <v-col sm="4" style="border-right: 1px solid #E0E0E0;">
          <v-list dense shaped>
            <v-list-item-group v-model="topLevel">
              <v-list-item link v-for="(item, idx) in myItem.log_data" :key="idx" @click="info(item.event)"> 
              {{returnName(item.event)}}
            </v-list-item>
            </v-list-item-group>
            
          </v-list>
        </v-col>

        <v-col sm="4" style="border-right: 1px solid #E0E0E0;">
         
          <div  v-for="(item, idx) in myItem.log_data" :key="idx">
            <div v-if="item.event == showDetails">

              <span v-if="item.event == 'anon-login' || item.event == 'login' || item.event == 'logout'">
                <v-list dense>
                  <v-list-item>
                    <span><b>Total count:</b> {{item.event_count}}</span>
                  </v-list-item>

                  <v-list-item v-for="(it, idx) in item.info" :key="idx">
                    <v-list-item-subtitle v-if="idx == 0">
                      Last <span v-if="item.event =='login' || item.event =='anon-login'">login:</span> <span v-else>logout:</span>
                       {{transDateString(it.ts)}}
                   </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </span>

              <span v-if="item.event == 'object-load'">
                <v-list dense rounded>
                  <v-list-item>
                    <span><b>Total loaded:</b> {{item.event_count}}</span>
                  </v-list-item>
                  <v-list-item rounded link @click="ts(item.info, object, 'o')" v-for="(object, idx) in Object.keys(item.counts)" :key="idx">
                    <v-list-item-subtitle>
                      {{object}} : {{item.counts[object]}}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </span>

              <span v-if="item.event == 'page-load'">
                <v-list dense rounded>
                  <v-list-item>
                    <span><b>Total loaded:</b> {{item.event_count}}</span>
                  </v-list-item>
                  <v-list-item rounded link @click="ts(item.info, page, 'p')" v-for="(page, idx) in Object.keys(item.counts)" :key="idx">
                    <v-list-item-subtitle>
                      {{page}} : {{item.counts[page]}}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </span>

               <span v-if="item.event == 'domain-change'">
                <v-list dense rounded>
                  <v-list-item>
                    <span><b>Total events:</b> {{item.event_count}}</span>
                  </v-list-item>
                  <v-list-item rounded link @click="ts(item.info, page, 'p')" v-for="(page, idx) in Object.keys(item.counts)" :key="idx">
                    <v-list-item-subtitle>
                      {{page}} : {{item.counts[page]}}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </span>

              <span v-if="item.event == 'click'">
                <v-list dense rounded>
                  <v-list-item>
                    <span><b>Total clicks:</b> {{item.event_count}}</span>
                  </v-list-item>
                  <v-list-item rounded link @click="ts(item.info, page, 'f')" v-for="(page, idx) in Object.keys(item.counts)" :key="idx">
                    <v-list-item-subtitle>
                      {{page}} : {{item.counts[page]}}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </span>

              <span v-if="item.event == 'view-report'">
                <v-list dense rounded>
                  <v-list-item>
                    <span><b>Total views:</b> {{item.event_count}}</span>
                  </v-list-item>

                  <v-list-item rounded link @click="ts(item.info, item.event, 'r')" v-for="(page, idx) in Object.keys(item.counts)" :key="idx">
                    <v-list-item-subtitle>
                      view info
                    </v-list-item-subtitle>
                  </v-list-item> 

                </v-list>
              </span>

            </div>
          </div>
        </v-col> 

        <v-col sm="4">
          <v-list dense>
            <v-list-item>
              <span v-if="detailItem"><b><span v-if="detailItem != 'view-report'">{{detailItem}}</span> history:</b></span>
            </v-list-item>

            <span v-if="detailItem=='search-results'">
            <v-list-item v-for="(info, idx) in showTS" :key="idx">
              <v-list-item-subtitle>
                '{{info.params}}': {{info.ts}}
              </v-list-item-subtitle>
            </v-list-item>
            </span>

            <span v-else-if="detailItem=='surprise-me'">
            <v-list-item v-for="(info, idx) in showTS" :key="idx">
              <v-list-item-subtitle>
                '{{info.object_name}}' : {{info.ts}}
              </v-list-item-subtitle>
            </v-list-item>
            </span>

            <v-list-item v-else-if="detailItem == 'view-report'">
              <v-list-item-subtitle v-for="(info, idx) in showTS" :key="idx">
                <a @click="openReport(info.report_uid)">{{info.ts}}</a>
              </v-list-item-subtitle>
            </v-list-item>


            <v-list-item v-else v-for="(info, idx) in showTS" :key="idx">
              <v-list-item-subtitle>
                {{info}}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import stringFunctions from '@/mixins/stringFunctions';
import logQueries from '@/mixins/logQueries';

export default {
  name: 'component', 
  props: {
    parentData: Object,
    parentMonth: String
  },
  mixins: [
    stringFunctions, 
    logQueries
  ],
  data() {
    return {
      loaded: false,
      myItem: [],
      showDetails: 0,
      showTS: '', 
      detailItem: '', 
      topLevel: '', 
    }
  },

  mounted() {

    this.fetchUsers(this.parentMonth)

  },

    methods: {
      ...mapActions(['queryLogs']),

      returnName(event) {
        let item 
        switch(event) {
          case 'page-load':
            item =  'Page Loads'
            break;
          case 'anon-login':
            item = 'Preview (Anon) Logins'
            break;
          case 'object-load':
            item = 'CF Object Loads'
            break;
          case 'login':
            item = 'Logins'
            break;
          case 'view-report':
            item = 'Report Views'
            break;
          case 'domain-change':
            item = 'Domain Change'
            break;
          case 'logout':
            item = 'Logouts'
            break;
          case 'csv-download':
            item = 'CF Exports'
            break;
          case 'click':
            item = 'Button Clicks'
            break;
          default: 
            item = event
        }
        return item
      },

       info(event) {
        this.showDetails = event;
        this.showTS = '';
        
        this.detailItem = '';
      },

      async fetchUsers (month) {
        console.log(month)
        console.log(this.parentData)

        let payload = this.eventsOutput(this.parentData, month)
        await this.queryLogs(payload)
        .then((data) => {
          this.myItem = data.data
          this.nextTest(this.myItem, month)
          })
        .catch(err => console.warn(err))
      },

      nextTest: async function(item, month) {
        console.log(item)

        for (const i in item.log_data) {

          let result = this.addEventDetails(this.parentData, month, item.log_data[i].event);
          await this.queryLogs(result)
          .then((data) => {
            item.log_data[i].info = data.data.log_data
          })
          .catch((err) => { console.log(err)})
        }
        this.groupRelevant()
      },

      ts(info, key, type) {
        console.log(info, key)
        this.detailItem = key
        let result
        if (info == this.showTS) {
          this.showTS = ''
        }
        else if (key === 'search-results') {
          let set = info.filter(x => x.page_name == 'search-results')
          let arr = []
          set.forEach(x => arr.push({params: x.params, ts: this.transDateString(x.ts)}))
          this.showTS = arr
        }
        else if (key === 'surprise-me') {
          console.log('surpise me')
          let set = info.filter(x => x.form_name == 'surprise-me')
          
          let arr = []
          set.forEach(x => arr.push({object_name: x.object_name, ts: this.transDateString(x.ts)}))
          this.showTS = arr
        }
        else if (type === 'r') {
          let set = info
          let arr = []
          set.forEach(x => arr.push({report_uid: x.report_uid, ts: this.transDateString(x.ts)}))
          this.showTS = arr
        }
        else { 
          if (type == 'o') { result = info.filter(x => x.object_name == key) }
          else if (type == 'p') { result = info.filter(x => x.page_name == key) }
          else if (type == 'f') { result = info.filter(x => x.form_name == key) }
        }

        let array = []
        result.forEach(x => {array.push(this.transDateString(x.ts))})

        this.showTS = array.filter(this.onlyUnique)
        console.log(this.showTS)
      },

      onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      },

      openReport(uid) {
        window.open(
          'https://www.socialstandards.com/report/' + uid + '.html', "_blank");
      },

      groupRelevant() {

        this.myItem.log_data.forEach(x => {
          if (x.event == 'object-load') {
             x.counts = this.groupMe(x, 'object_name')
          }
          else if (x.event == 'page-load') {
             x.counts = this.groupMe(x, 'page_name')
          }
          else if (x.event == 'click') {
             x.counts = this.groupMe(x, 'form_name')
          }
          else if (x.event == 'view-report') {
             x.counts = this.groupMe(x, 'report_uid')
          }
        })

        this.loaded = true
        console.log(this.myItem)

      }}

}
</script>
