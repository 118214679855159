<template>
  <v-card>
    <v-app-bar color="#3f4d63" style="color:#FFFF;" elevation="0">
      Monthly Activity Details - {{myMonth}}
    </v-app-bar>
    
    <v-card-text>
      <UserLogElement :parentData="parentUser" :parentMonth="myMonth" :key="myMonth" />
    </v-card-text>
   
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import stringFunctions from '@/mixins/stringFunctions';
import logQueries from '@/mixins/logQueries';
import UserLogElement from '@/components/AdminMgmt/UserLogElement.vue'

  export default {

    props: {
      parentData: Object,
      parentUser: Object,
      parentMonth: Number
    },

    components: {
      UserLogElement
    },
    mixins: [ logQueries, stringFunctions ],

    data: () => ({
      active: [],
      open: [],
      users: [],
      months: [],
      myItem: [],
      showDetails: 0,
      loaded: false,
      showTS: '', 
      detailItem: ''
    }),

    mounted() {

      for (const i in this.parentData) {
        this.months.push(this.transMonthString(this.parentData[i].ts_month))
      }
    },

    computed: {

      myMonth() {
        if (this.parentMonth != 'first') {
          return  this.months[this.parentMonth]
        }
        else {
          return this.months[this.months.length-1]
        }
      },

    },


    methods: {
      ...mapActions(['queryLogs']),

      returnName(event) {
        let item 
        switch(event) {
          case 'page-load':
            item =  'Page Loads'
            break;
          case 'anon-login':
            item = 'Preview (Anon) Logins'
            break;
          case 'object-load':
            item = 'CF Object Loads'
            break;
          case 'login':
            item = 'Logins'
            break;
          case 'view-report':
            item = 'Report Views'
            break;
          case 'logout':
            item = 'Logouts'
            break;
          case 'csv-download':
            item = 'CF Exports'
            break;
          case 'click':
            item = 'Button Clicks'
            break;
        }
        return item
      },

      async fetchUsers (month) {

        let payload = this.eventsOutput(this.parentUser, month)
        await this.queryLogs(payload)
        .then((data) => {
          this.myItem = data.data
          this.nextTest(this.myItem, month)
          })
        .catch(err => console.warn(err))
      },

      nextTest: async function(item, month) {

        for (const i in item.log_data) {

          let result = this.addEventDetails(this.parentUser, month, item.log_data[i].event);
          await this.queryLogs(result)
          .then((data) => {
            item.log_data[i].info = data.data.log_data
          })
          .catch((err) => { console.log(err)})
        }
        this.groupRelevant()
      },

      ts(info, key, type) {
        this.detailItem = key
        if (info == this.showTS) {
          this.showTS = ''
        }
        else { 
          if (type == 'o') { this.showTS = info.filter(x => x.object_name == key) }
          else if (type == 'p') { this.showTS = info.filter(x => x.page_name == key) }
          else if (type == 'f') { this.showTS = info.filter(x => x.form_name == key) }
        }
        
      },

      info(event) {
        this.showDetails = event;
        this.showTS = '';
        this.detailItem = '';
      },

      groupRelevant() {

        this.myItem.log_data.forEach(x => {
          if (x.event == 'object-load') {
             x.counts = this.groupMe(x, 'object_name')
          }
          else if (x.event == 'page-load') {
             x.counts = this.groupMe(x, 'page_name')
          }
          else if (x.event == 'click') {
             x.counts = this.groupMe(x, 'form_name')
          }
        })

        this.loaded = true
      },
      
    },
  }
</script>